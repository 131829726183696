import React from "react";
import { StockOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useState } from "react";
import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { FlightOperations } from "~/components/baro/FlightOperations.jsx";

export const FlightOperationsModal = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <Modal
        title={<h1>Vliegbewegingen</h1>}
        visible={modalOpen}
        footer={<SluitButton key="sluit" onClick={() => setModalOpen(false)} />}
        onCancel={() => setModalOpen(false)}
        width={1000}
      >
        <FlightOperations />
      </Modal>

      <Button
        style={{ marginRight: 4 }}
        onClick={() => setModalOpen(true)}
        icon={<StockOutlined />}
      >
        Vliegbewegingen
      </Button>
    </div>
  );
};
