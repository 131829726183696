import React from "react";
import { version } from "../package.json";
import { Loader } from "./components/loader/loader.jsx";
import "./components/pwa/register";
import { config } from "./config.js";
import { i18n } from "./i18n.js";
import { api } from "./lib/api.js";
import { identifyUser } from "./lib/sentry.js";
import { Router } from "./router.jsx";
import { persistor, store } from "./store.jsx";

export class Root extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rehydrated: false,
      languageReady: false,
    };
  }

  async componentDidMount() {
    persistor.subscribe(() => {
      if (!config.tenant) {
        this.setState({ rehydrated: true });
        return;
      }

      // populate api for future calls
      const state = store.getState();
      api.store = store;
      api.backend_url = config.backend_url;
      const access_token = api.getToken();

      // if logged in, identify user in sentry
      if (access_token && config.sentry_key) {
        identifyUser(config.sentry_key, state?.persist?.profile?.email);
      }

      console.log(
        `Welkom bij %c${config.tenant} %czweef.app - %cv${version}`,
        "color: green",
        "color: default",
        "color: green"
      );

      // check initial network status, and update if required
      if (state.flights.network_online !== navigator.onLine) {
        store.dispatch({ type: navigator.onLine ? "ONLINE" : "OFFLINE" });
      }
      this.setState({ rehydrated: true });
    });

    await i18n.init();
    this.setState({ languageReady: true });
  }

  render = () => {
    return this.state.rehydrated && this.state.languageReady ? (
      <Router />
    ) : (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader style={{ margin: "auto" }} />
      </div>
    );
  };
}
