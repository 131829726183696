import { EyeFilled } from "@ant-design/icons";
import { Button, Modal, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import { $t } from "~/i18n.js";

import { api } from "~/lib/api.js";

function addedWachtwoord(users) {
  Modal.success({
    title: $t("Tijdelijk wachtwoord aangemaakt"),
    content: (
      <div>
        {!users || users.length === 0 ? (
          <>
            <p>
              {$t(
                "Excuses: Geen gebruikers gevonden met een tijdelijk wachtwoord."
              )}
            </p>
            <p>
              {$t(
                'Ga naar de admin pagina, en zet het vinkje "has_temporary_password" voor de gebruikers aan om dit te gebruiken.'
              )}
            </p>
          </>
        ) : (
          <>
            <p className="spacer">{$t("Je kan nu inloggen met")}:</p>

            {users.map((user, key) => (
              <div key={key} style={{ marginBottom: 12 }}>
                <p>
                  email:{" "}
                  <Typography.Text keyboard>{user.email}</Typography.Text>
                </p>
                <p>
                  wachtwoord:{" "}
                  <Typography.Text keyboard>{user.password}</Typography.Text>
                </p>
              </div>
            ))}

            <p className="small" style={{ marginTop: 12 }}>
              {$t(
                "Let op: dit wachtwoord is eenmalig te gebruiken en verloopt na 5 minuten."
              )}
            </p>
          </>
        )}
      </div>
    ),
  });
}

export const StartwagenWachtwoord = () => {
  const [loading, setLoading] = useState(false);

  const requestPassword = async () => {
    if (loading) return null;

    setLoading(true);
    const res = await api.get("auth/create_temp_password.json");
    if (res) addedWachtwoord(res.users);
    setLoading(false);
  };

  return (
    <Tooltip
      placement="top"
      title={$t(
        "Vraag een tijdelijk wachtwoord aan voor clubhuis en startwagen accounts. Dit kunnen alleen admin gebruikers doen."
      )}
    >
      <Button
        onClick={requestPassword}
        loading={loading}
        style={{ marginRight: 6 }}
        icon={<EyeFilled />}
      >
        {$t("Clubhuis/Startwagen wachtwoord")}
      </Button>
    </Tooltip>
  );
};
