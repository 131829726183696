import { LineChartOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";

import { Button, Modal } from "antd";
import { api } from "~/lib/api.js";
import { parseMinutesLong } from "~/lib/localize.js";
import { SluitButton } from "../buttons/SluitButton.jsx";
import { Loader } from "../loader/loader.jsx";
import { calculateRelativeCurrency } from "./RecencyBaro.jsx";
import { Chart } from "./chart.jsx";

const chartOptions = {
  chart: {
    type: "spline",
  },
  xAxis: {
    type: "datetime",
    labels: {
      overflow: "justify",
    },
    plotLines: null,
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      enabled: false,
    },
    min: 0,
    max: 30,
    minorGridLineWidth: 0,
    gridLineWidth: 0,
    plotBands: [
      {
        // Red
        from: 0,
        to: 10,
        color: "rgba(251, 105, 98, 0.3)",
      },
      {
        // Yellow
        from: 10,
        to: 20,
        color: "rgba(252, 252, 153, 0.3)",
      },
      {
        // Green
        from: 20,
        to: 30,
        color: "rgba(121, 222, 121, 0.3)",
      },
    ],
  },
  plotOptions: {
    spline: {
      lineWidth: 1,
      color: "black",
      marker: {
        enabled: true,
      },
      pointInterval: 3600000 * 24, // one hour
      pointStart: null,
    },
  },
  tooltip: {
    shared: true,
    crosshairs: true,
    formatter: function () {
      return `
        ${dayjs.unix(this.x / 1000).format("DD MMM 'YY")}: 
        ${this.points[0].point.name}
      `;
    },
  },
  series: null,
};

export const RecencyBaroVerloop = ({ user }) => {
  const [showModal, setShowModal] = useState(false);
  const onClose = () => setShowModal(false);

  const [isLoading, setisLoading] = useState(true);
  const [options, setOptions] = useState(null);

  const valid_labels = [
    { key: "checks_valid_to", label: $t("Trainingsvluchten") },
    { key: "starts_valid_to", label: $t("Starts") },
    { key: "vliegduur_valid_to", label: $t("Uren") },
    { key: "lier_valid_to", label: $t("Lierstart") },
    { key: "sleep_valid_to", label: $t("Sleepstart") },
    { key: "zelfstart_valid_to", label: $t("Zelfstart") },
    { key: "tmg-a_valid_to", label: $t("TMG Aerotow") },
    { key: "pax_valid_to", label: $t("Passagier") },
    { key: "tmg_check_valid_to", label: $t("TMG training") },
    { key: "tmg_starts_valid_to", label: $t("TMG starts") },
    { key: "tmg_uren_valid_to", label: $t("TMG uren") },
    { key: "fis_starts_valid_to", label: $t("FI(s) starts") },
    { key: "fis_uren_valid_to", label: $t("FI(s) uren") },
    {
      key: "fis_date_refresher_course_valid_to",
      label: $t("Refresher Training"),
    },
    {
      key: "fis_date_training_flight_valid_to",
      label: $t("Instr. flt under supervision"),
    },
  ];

  // add in lookup for recency items that end in validity
  const validity_lookup = {};
  if (user && user.recency) {
    valid_labels.forEach((v) => {
      if (v.key in user.recency) {
        const date = user.recency[v.key];

        if (!validity_lookup[date]) validity_lookup[date] = [];
        validity_lookup[date].push(v.label);
      }
    });
  }

  useEffect(() => {
    const loadData = async () => {
      setisLoading(true);
      setOptions(null);
      if (showModal && user && user.id) {
        const res = await api.get(`gegevens/${user.id}/currency_verloop.json`);

        // normalize data suited for highcharts input
        // array of points, with y (currency value) and name populated in case on that date flights occurred
        const series = [];
        if (res && res.length) {
          // iterate once over result to transform dates till dayjs
          const input = !res
            ? []
            : res.map((d) => ({
                ...d,
                date: dayjs(d.datum, "DD-MM-YYYY"),
                hours: d.vluchtduur / 60,
              }));

          // data currency is calculated over a running total, as such keep track of left/right dates
          let movingWindowLeft = dayjs().subtract(12, "months");
          let movingWindowRight = dayjs().subtract(6, "months");

          // iterate over all data
          let days = 92;
          if (window.location.search.indexOf("days") > 0) {
            days = parseInt(window.location.search.split("days=")[1]);
          }

          for (let d = 0; d <= 182 + days; d++) {
            // possibly add the index slice, if so (or on first go) we recalculate the currency
            let hours = 0;
            let starts = 0;
            let hasMarker = false;

            input.forEach((i) => {
              // add hours/starts if date is in bracket
              if (i.date.isAfter(movingWindowLeft)) {
                // end once the input date is after the right bracket, thus close
                if (i.date.isAfter(movingWindowRight)) return false;

                // if date is between left and right, we add the starts/hours
                hours += i.hours;
                starts += i.starts;

                // if date is same as right, then we use the current flights/starts for label
                if (i.date.isSame(movingWindowRight, "day")) {
                  hasMarker = true;
                }
              }
              return true;
            });

            const { relCurrency } = calculateRelativeCurrency(starts, hours);
            // console.log({relCurrency, starts, hours})
            series.push({
              y: relCurrency * 30,
              name: $t("${starts} starts ${min}", {
                starts,
                min: parseMinutesLong(parseInt(hours * 60)),
              }),
              marker: { enabled: hasMarker },
            });

            // add 1 day for next
            movingWindowLeft = movingWindowLeft.add(1, "day");
            movingWindowRight = movingWindowRight.add(1, "day");
          }

          // setup full options
          const newOptions = { ...chartOptions };

          // add in series
          newOptions.series = [
            {
              name: "Currency",
              showInLegend: false,
              data: series,
              zoneAxis: "x",

              // dotted line for future
              zones: [
                {
                  value: dayjs(),
                },
                {
                  color: "darkgray",
                  dashStyle: "dash",
                },
              ],
            },
          ];

          // add in vertical plot line on today
          newOptions.xAxis.plotLines = [
            {
              color: "black",
              dashStyle: "dot",
              width: 1,
              value: dayjs(),
              zIndex: 3,
            },
          ];

          // check all validity dates, plot them on axis as vertical lines

          Object.keys(validity_lookup).forEach((date) => {
            newOptions.xAxis.plotLines.push({
              color: "red",
              width: 2,
              value: dayjs(date, "YYYY-MM-DD"),
              zIndex: 3,
              label: { text: validity_lookup[date].join(", ") },
            });
          });

          // add in start y-axis
          newOptions.plotOptions.spline.pointStart = dayjs().subtract(
            6,
            "months"
          );

          setOptions(newOptions);
        }

        setisLoading(false);
      }
    };
    loadData();
  }, [user, showModal]);

  return (
    <div>
      <Button className="ml-1" onClick={() => setShowModal(true)} size="small">
        <LineChartOutlined />
      </Button>

      <Modal
        width="100%"
        title={$t("Currency verloop ${name}", { name: user.name })}
        open={showModal}
        onCancel={onClose}
        footer={[<SluitButton key='sluit' onClick={onClose} />]}
      >
        <div className="column" style={{ minHeight: "50vh" }}>
          {isLoading ? (
            <Loader style={{ margin: "auto" }} />
          ) : (
            <div style={{ width: "100%" }}>
              <Chart chartOptions={options} />
              <p className="small" style={{ marginLeft: 12 }}>
                {$t(
                  "Note: Vluchten over de afgelopen 6 maanden uitgevoerd als gezagvoerder of instructie starts als tweede-inzittende (of trainingsvluchten en examens) tellen mee."
                )}
              </p>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
