import { Checkbox, Tooltip } from 'antd'
import React from 'react'
import { $t } from '~/i18n.js'

import { useSelector } from 'react-redux'
import { SleepHoogteEdit } from './SleepHoogteEdit.jsx'
import { CategorySelect } from './categorySelect.jsx'

export const SectionChecks = ({
  readOnly, editInstructorDetails,
  flight, saveFlight,
  sleepFlight, saveSleepFlight,
  gezagvoerder, tweedeInzittende
}) => {
  const { club, profile } = useSelector((state) => state.persist)

  const isInstructor = gezagvoerder && gezagvoerder.tag === 'instructeur' && tweedeInzittende
  const canExamen = flight.is_examen || (isInstructor && ['solist', 'dbo'].includes(tweedeInzittende && tweedeInzittende.tag))
  const canCheck = flight.is_training || (isInstructor && ['brevet', 'instructeur'].includes(tweedeInzittende && tweedeInzittende.tag))

  // only instructors can adjust fis flights (or if the flight has been marked as that)
  const adjustFIS = flight.is_fis || profile && profile.is_instructeur && tweedeInzittende && gezagvoerder?.is_instructeur
  const adjustExam = flight.is_examen || profile && profile.is_instructeur &&  tweedeInzittende && ['solist', 'dbo'].includes(tweedeInzittende && tweedeInzittende.tag)
  const adjustProfOrTraining = flight.is_training || flight.is_profcheck || profile && profile.is_instructeur &&  tweedeInzittende && ['brevet', 'instructeur'].includes(tweedeInzittende && tweedeInzittende.tag)

  const hasSleep = sleepFlight && sleepFlight.uuid

  const _saveFIS = (data) => {
    // ensure that if you check exam or checkstart, we must ensure it's also a FIS flight.
    // Some edgecases this is overruled elsewhere, as such if we check this we make sure that fis is also always assigned
    if (isInstructor) data.is_fis = true
    saveFlight(data)
  }

  return (
    <div className='column' style={{ alignItems: 'flex-start' }}>

      <CategorySelect
        readOnly={readOnly}
        currentFlight={flight.uuid}
        currentValue={flight.category}
        categories={flight.start_methode === 'tmg' ? club.tmg_cat : club.flight_cat}
        onSelect={(value) => !readOnly && saveFlight({ category: value })}
      />

      {hasSleep && <CategorySelect
        readOnly={readOnly}
        currentFlight={sleepFlight.uuid}
        currentValue={sleepFlight.category}
        categories={club.sleep_cat}
        onSelect={(value) => !readOnly && saveSleepFlight({ category: value })}
                   />}

      {hasSleep && <SleepHoogteEdit
        readOnly={readOnly} currentHeight={sleepFlight.height}
        saveHeight={(value) => saveSleepFlight({ height: value })}
                   />}

      {gezagvoerder && <Checkbox
        className='large' style={{ margin: '5px 0' }}
        checked={flight.is_overland} disabled={readOnly}
        onChange={(click) => !readOnly && saveFlight({ is_overland: click.target.checked })}
                       >
        <Tooltip
          placement='top'
          title={$t('Indien de vlucht aangemeld is voor overland')}
        >
          <p className='regular'>{$t('Overland')}</p>
        </Tooltip>
      </Checkbox>}

      {adjustFIS && <Checkbox
        className='large' style={{ margin: '5px 0' }}
        checked={flight.is_fis} disabled={readOnly && !editInstructorDetails}
        onChange={(click) => saveFlight({ is_fis: click.target.checked })}
                    >
        <Tooltip
          placement='top'
          title={$t('Een FIS(S) vlucht is een instructievlucht uitgevoerd door een instructeur als PIC.')}
        >
          <p className='regular'>{$t('FI(S) vlucht')}</p>
        </Tooltip>
      </Checkbox>}

      {(adjustProfOrTraining || canCheck) && <Checkbox
        className='large' style={{ margin: '5px 0' }}
        checked={flight.is_training} disabled={readOnly && !editInstructorDetails}
        onChange={(click) => _saveFIS({ is_training: click.target.checked })}
                   >
        <Tooltip
          placement='top' disabled={readOnly}
          title={$t('Een trainingsvlucht is een EASA verplichting en voer je uit met een instructeur.')}
        >
          <p className='regular'>{$t('Trainingsvlucht')}</p>
        </Tooltip>
      </Checkbox>}

      {(adjustExam || canExamen) && <Checkbox
        className='large' style={{ margin: '5px 0' }}
        checked={flight.is_examen} disabled={readOnly && !editInstructorDetails}
        onChange={(click) => _saveFIS({ is_examen: click.target.checked })}
                    >
        <Tooltip
          placement='top' disabled={readOnly}
          title={$t('Een examen vlucht om je brevet te halen.')}
        >
          <p className='regular'>{$t('Examen / Skill test')}</p>
        </Tooltip>
      </Checkbox>}

      {(adjustProfOrTraining || canCheck) && <Checkbox
        className='large' style={{ margin: '5px 0' }}
        checked={flight.is_profcheck} disabled={readOnly && !editInstructorDetails}
        onChange={(click) => !readOnly && _saveFIS({ is_profcheck: click.target.checked })}
                   >
        <Tooltip
          placement='top' disabled={readOnly}
          title={$t('Een prof-check is een examen om een verlopen brevet weer geldig te laten zijn.')}
        >
          <p className='regular'>{$t('Proficiency check')}</p>
        </Tooltip>
      </Checkbox>}

    </div>
  )
}
