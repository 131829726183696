import { $t } from '~/i18n.js'
import React, { useEffect, useState } from 'react'
import { Button } from 'antd'

import { PrinterOutlined } from '@ant-design/icons'
import { updateIfNeeded } from '~/lib/helpers.js'
import { MainLayout } from '~/components/layout/layout.jsx'
import { api } from '~/lib/api.js'
import { toLocaleDate } from '~/lib/localize.js'
import { materiaalActions } from '~/redux/actions.js'
import { connect } from 'react-redux'
import { MeldingTable } from './sections/meldingTable.jsx'
import { AddMeldingButton } from './sections/addMeldingButton.jsx'
import { BreadcrumbHeader } from '~/components/layout/breadcrumbHeader.jsx'

const MeldingOverviewComponent = ({ persist, history, materiaal, getMaterialData }) => {
  const [loadingFile, setFileLoading] = useState(false)

  useEffect(() => {
    getMaterialData && updateIfNeeded(materiaal.lastUpdated, getMaterialData)
  }, [materiaal.lastUpdated, getMaterialData])

  return (
    <MainLayout history={history}>

      <BreadcrumbHeader
        breadcrumbs={[
          $t('Overzicht meldingen')
        ]}
        buttons={[
          (persist.profile.wijzig_melding || persist.profile.wijzig_melding_vliegend || persist.profile.wijzig_melding_rollend) &&
            <Button
              icon={<PrinterOutlined />}
              style={{ marginRight: 6 }}
              loading={loadingFile}
              onClick={async () => {
                setFileLoading(true)
                await api.open_file(
                  "materiaal/export.json",
                  `defects ${toLocaleDate(new Date())}.xlsx`
                );
                setFileLoading(false)
              }}
            >
              {$t('Exporteer')}
            </Button>,
          <AddMeldingButton key='1' history={history} />]}
      />

      <MeldingTable
        showArchive={false}
        history={history}
        isPending={materiaal.isPending}
        meldingen={materiaal.meldingen}
      />

    </MainLayout>
  )
}

const mapStateToProps = (state) => ({
  persist: state.persist,
  materiaal: state.materiaal
})

const mapDispatchToProps = (dispatch) => {
  return {
    getMaterialData: (d) => dispatch(materiaalActions.getMaterialData(d))
  }
}

export const MeldingOverviewPage = connect(mapStateToProps, mapDispatchToProps)(MeldingOverviewComponent)
