export const PricingVersionEN = "";

export const PricingTextEN = `


The aim of the Gliding.App is that we strive for affordability, especially for smaller clubs. We have set up two price plans for this:

> ### **Small** plan
>
> • Suitable for small clubs up to 106 members.
>
> • A variable amount of €0.80 per flying member per month, ex VAT, no fixed costs.


> ### **Big** plan
>
> • Suitable for larger clubs, onwards of 106 members.
>
> • A fixed amount of €85 per month, ex VAT, no variable costs per member.



The following conditions apply to both price plans:

- No connection costs, but the option to import data via de admin page (such as members, authorities, equipment and flights).

- Always available by email for questions and comments. If support via video call is required, a 'discouragement' rate of €50 per hour will be charged.

- Annual cancellation policy.

- Costs are charged annually in advance and can be adjusted based on governmental inflation figures.

- [General Terms and Conditions](https://app.gliding.app/terms) apply.

We also offer a **test account**.
With this test account you can add up to 6 users to try out the Gliding.App for free and without obligation for a period of 12 months.
During this period we offer the option to import your club's data.
View the [General Terms and Conditions](https://app.gliding.app/terms) for more information about this.

(This is an English translation. In case that the Dutch and English tekst differ, the Dutch text prevails. In case of translation errors, please let us know. Thanks)
  
`;
