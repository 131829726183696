import { Form, Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { $t } from '~/i18n.js'

export const PrivateKistModal = ({ visible, kist_id, registratie, type, callsign, flarm, closeModal, saveFlight }) => {
  if (!visible) return null

  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(false)
    return () => setLoading(false)
  }, [visible])

  useEffect(() => {
    form.setFieldsValue({
      registratie,
      type,
      callsign,
      flarm
    })
  }, [registratie, type, callsign, flarm])

  return (
    <Modal
      title={kist_id ? $t('Wijzig privé kist') : $t('Voeg privé kist toe')}
      open={visible}
      confirmLoading={loading}
      onCancel={() => {
        form.resetFields()
        closeModal()
      }}
      okText={$t('Opslaan')}
      cancelText={$t('Annuleren')}
      onOk={async () => {
        try {
          const values = await form.validateFields()
          setLoading(true)
          await saveFlight({
            add_kist: 1,
            ...values
          })
          closeModal(true)
          setLoading(false)
          form.resetFields()
        } catch (info) {
          console.log('Validate Failed:', info)
          setLoading(false)
        }
      }}
    >

      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name='kist'
        preserve={false}
        requiredMark={false}
        initialValues={{
          registratie,
          type,
          callsign,
          flarm
        }}
      >

        <Form.Item
          label={$t('Registratie')}
          name='registratie'
          rules={[{ required: true, message: $t('Voeg correcte registratie toe'), max: 10 }]}
        >
          <Input placeholder='Registratie' />
        </Form.Item>

        <Form.Item
          label={$t('Callsign')}
          name='callsign'
          rules={[{ required: false, message: $t('Maximaal ${max} characters', { max: 4 }), max: 4 }]}
        >
          <Input placeholder='Callsign' />
        </Form.Item>

        <Form.Item
          label={$t('Type')}
          name='type'
          rules={[{ required: false, message: $t('Maximaal ${c} characters', { c: 50 }), max: 50 }]}
        >
          <Input placeholder='Type' />
        </Form.Item>

        <Form.Item
          label={$t('FlarmID')}
          name='flarm'
          rules={[{ required: false }]}
        >
          <Input placeholder='123456' />
        </Form.Item>

      </Form>

    </Modal>
  )
}
