import { Divider, Result, Steps } from "antd";
import React, { useState } from "react";
import { $t } from "../../i18n.js";
import { ModalLayout } from "../../components/layout/modalLayout.jsx";
import { CardAdmin } from "./card.admin.jsx";
import { CardBevestig } from "./card.bevestig.jsx";
import { CardClub } from "./card.club.jsx";
import { CardIntro } from "./card.intro.jsx";

export const RegistratiePagina = () => {
  const [data, setData] = useState({
    timezone: "Europe/Amsterdam",
    country: "NL",
  });
  const [step, setStep] = useState(0);

  const saveAndNext = (newData) => {
    if (newData) setData({ ...data, ...newData });
    setStep(step + 1);
  };

  const prev = () => setStep(step - 1);

  return (
    <ModalLayout width="650px" noPadding>
      <div style={{ padding: 24 }}>
        <Steps size="small" current={step}>
          <Steps.Step title={$t("Start")} />
          <Steps.Step title={$t("Club")} />
          <Steps.Step title={$t("Admin")} />
          <Steps.Step title={$t("Bevestig")} />
        </Steps>

        <Divider />

        <div
          className="row"
          style={{ justifyContent: "center", width: 600, maxWidth: "100%" }}
        >
          {step === 0 && <CardIntro saveAndNext={saveAndNext} />}
          {step === 1 && (
            <CardClub data={data} prev={prev} saveAndNext={saveAndNext} />
          )}
          {step === 2 && (
            <CardAdmin data={data} prev={prev} saveAndNext={saveAndNext} />
          )}
          {step === 3 && (
            <CardBevestig data={data} prev={prev} saveAndNext={saveAndNext} />
          )}
        </div>

        {step > 3 && (
          <Result
            style={{ minHeight: 300 }}
            status="success"
            title={$t("Account aangemaakt")}
            subTitle={$t("Check snel je email met vervolg instructies")}
          />
        )}
      </div>
    </ModalLayout>
  );
};
