import {
  CloseOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
  StopOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Tabs,
  Tag,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { $t } from "~/i18n.js";
import { HelpBubble } from "~/components/help/help.bubble.jsx";
import {
  _parseDate,
  _parseDateTime,
  parseHoursLong,
} from "~/lib/localize.js";
import { saveMaterialTab } from "~/redux/materiaal/materiaal.actions.js";
import { EditTasksList } from "../../editTasks.jsx";

// using Custom controls form, clear out values in form
const ClearInterval = ({
  onChange,
  field_prefix,
  schedule,
  label,
  setActive,
}) => (
  <p className="row" style={{ width: 200, justifyContent: "space-between" }}>
    {schedule === "recurring" ? "Interval" : "Levensduur"} op {label}:
    <Button
      shape="circle"
      size="small"
      icon={<CloseOutlined />}
      onClick={() => {
        const data = {};
        data[field_prefix + "_next"] = 0;
        data[field_prefix + "_interval"] = 0;
        setActive(false);
        onChange(data);
      }}
    />
  </p>
);

const IntervalField = ({
  current,
  maintenance,
  schedule,
  label,
  units,
  field_prefix,
  isArc,
}) => {
  const [isActive, setActive] = useState(null);
  useEffect(() => {
    setActive(!!maintenance[field_prefix + "_next"]);
  }, [field_prefix]);

  return (
    <div style={{ margin: "9px 0" }}>
      {!isActive && (
        <p
          className="row"
          style={{ width: 200, justifyContent: "space-between" }}
        >
          {schedule === "recurring" ? $t("Interval") : $t("Levensduur")} {label}
          :
          <Button
            onClick={() => setActive(true)}
            shape="circle"
            size="small"
            icon={<PlusOutlined />}
          />
        </p>
      )}

      {isActive && (
        <>
          {!isArc && (
            <Form.Item name="clear">
              <ClearInterval
                isArc={isArc}
                field_prefix={field_prefix}
                schedule={schedule}
                label={label}
                setActive={setActive}
              />
            </Form.Item>
          )}

          {maintenance.part_is_installed && current && (
            <div className="row" style={{ marginBottom: 12 }}>
              <p style={{ width: 151, textAlign: "right", marginRight: 9 }}>
                Huidig:
              </p>
              <div
                className="row"
                style={{
                  justifyContent: "space-between",
                  width: 200,
                  padding: "0 12px",
                }}
              >
                <p>{current}</p>
                <p>{units}</p>
              </div>
            </div>
          )}

          <Form.Item
            label={
              <p>
                {$t("Volgende")}{" "}
                {field_prefix === "date" ? $t("op") : $t("bij")}
                {isArc && (
                  <HelpBubble content="Voor ARC inspecties, indien het vliegtuig register een latere datum bevat, overschrijft hij deze vanuit daar." />
                )}
              </p>
            }
            name={field_prefix + "_next"}
          >
            {field_prefix === "date" ? (
              <DatePicker
                format={localStorage.localeFormat}
                style={{ width: 200 }}
                disabledDate={(current) =>
                  current && current < dayjs().endOf("day")
                }
              />
            ) : (
              <Input style={{ width: 200 }} type="number" suffix={units} />
            )}
          </Form.Item>

          {schedule === "recurring" && (
            <Form.Item label={$t("Periode")} name={field_prefix + "_interval"}>
              <Input
                style={{ width: 200 }}
                suffix={units}
                type="number"
                disabled={isArc}
              />
            </Form.Item>
          )}

          <Form.Item
            label={
              <p>
                {$t("Waarschuwing")}{" "}
                {field_prefix === "date" ? $t("op") : $t("bij")}
                <HelpBubble
                  content={
                    <span>
                      {$t(
                        "Aantal ${units} voorafgaande aan volgende deadline. Hierna kleurt de taak geel",
                        { units }
                      )}
                    </span>
                  }
                />
              </p>
            }
            name={field_prefix + "_alert"}
          >
            <Input
              style={{ width: 200 }}
              suffix={units}
              type="number"
              placeholder={$t("resterende")}
            />
          </Form.Item>
        </>
      )}
    </div>
  );
};

export const ModalEditMaintenance = ({
  currentMaterial,
  maintenanceProgram,
  tasks,
  visible,
  closeModal,
}) => {
  // Arc inspections have limited editing possibilities
  const isArc = maintenanceProgram.category === "arc";

  const [form] = Form.useForm();
  const schedule = Form.useWatch("schedule", form);

  const [newTasks, setNewTasks] = useState(null);
  useEffect(() => {
    form.resetFields();
    setNewTasks(tasks);
  }, [maintenanceProgram, tasks]);

  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const saveData = async () => {
    try {
      setSaving(true);
      const values = await form.validateFields();
      const payload = {
        id: maintenanceProgram.id,
        ...values,
        ...values?.clear,
        date_next: values.date_next
          ? values.date_next.format("YYYY-MM-DD")
          : null,
        tasks: isArc ? [] : newTasks,
      };
      const res = await dispatch(
        saveMaterialTab({
          activeTab: "maintenance",
          materialID: currentMaterial.id,
          payload,
        })
      );
      if (res) {
        setSaving(false);
        closeModal();
      } else {
        setSaving(false);
      }
    } catch (info) {
      setSaving(false);
    }
  };

  const [deleting, setDeleting] = useState(false);
  const deleteProgram = async () => {
    setDeleting(true);
    const payload = { id: maintenanceProgram.id, action: "delete" };
    const res = dispatch(
      saveMaterialTab({
        activeTab: "maintenance",
        materialID: currentMaterial.id,
        payload,
      })
    );
    if (res) closeModal();
    setDeleting(false);
  };

  return (
    <Modal
      title={$t("Wijzig onderhoudsprogramma")}
      style={{ top: 20 }}
      width={800}
      open={visible}
      onCancel={closeModal}
      footer={[
        <Popconfirm
          key="remove"
          placement="bottom"
          title={$t("Zeker weten dat je dit programma wilt verwijderen")}
          onConfirm={deleteProgram}
          okText={$t("Ja")}
          cancelText={$t("Nee")}
        >
          <Button danger icon={<StopOutlined />} loading={deleting}>
            {$t("Verwijder")}
          </Button>
        </Popconfirm>,
        <AnnuleerButton  key="annuleren" onClick={closeModal} />,
        <Button
          key="opslaan"
          onClick={saveData}
          type="primary"
          loading={saving}
        >
          {$t("Opslaan")}
        </Button>,
      ]}
    >
      <Tabs>
        <Tabs.TabPane tab={$t("Algemeen")} key="general">
          {isArc && (
            <p className="small spacer">
              Note:{" "}
              {$t(
                "Een ARC is een standard ingeladen onderhoudsprogramma dat je beperkt kan wijzigen."
              )}
            </p>
          )}

          <Form
            form={form}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            name="maintenance"
            preserve={false}
            requiredMark={false}
            initialValues={{
              ...maintenanceProgram,
              date_next: maintenanceProgram.date_next
                ? dayjs(maintenanceProgram.date_next)
                : null,
            }}
          >
            <Form.Item
              label={$t("Naam")}
              name="name"
              rules={[
                {
                  required: true,
                  max: 90,
                  message: $t("Maximaal ${c} characters", { c: 50 }),
                },
              ]}
            >
              <Input disabled={isArc} />
            </Form.Item>

            <Form.Item
              label={$t("Schedule")}
              name="schedule"
              rules={[{ required: true }]}
            >
              <Select disabled={isArc}>
                <Select.Option key="recurring" value="recurring">
                  <RetweetOutlined /> {$t("Periodiek")}
                </Select.Option>
                <Select.Option key="lifetime" value="lifetime">
                  <VerticalAlignTopOutlined
                    style={{ transform: "rotate(90deg)" }}
                  />{" "}
                  {$t("Levensduur")}
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={$t("Beschrijving")}
              name="description"
              rules={[
                {
                  required: false,
                  max: 190,
                  message: $t("Maximaal ${c} characters", { c: 200 }),
                },
              ]}
            >
              <Input disabled={isArc} />
            </Form.Item>

            <Form.Item
              label={$t("Opmerking (vrij veld)")}
              name="remark"
              rules={[
                {
                  required: false,
                  max: 300,
                  message: $t("Maximaal ${c} characters", { c: 300 }),
                },
              ]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>

            <Divider orientation="left" plain>
              {$t("Onderhoudsintervallen")}
            </Divider>

            {!maintenanceProgram.part_is_installed && (
              <p>
                <Tag color="red">{$t("niet actief")}</Tag> Onderdeel is
                uitgebouwd op:{" "}
                {_parseDate(maintenanceProgram.part_date_uninstalled)}
              </p>
            )}

            <IntervalField
              maintenance={maintenanceProgram}
              schedule={schedule}
              label={$t("datum")}
              units={$t("maanden")}
              field_prefix="date"
              isArc={isArc}
            />

            {!isArc && maintenanceProgram.part_total_starts !== null && (
              <IntervalField
                maintenance={maintenanceProgram}
                current={maintenanceProgram.part_total_starts}
                schedule={schedule}
                label={$t("starts")}
                units={$t("starts")}
                field_prefix="starts"
              />
            )}

            {!isArc && maintenanceProgram.part_total_minutes !== null && (
              <IntervalField
                maintenance={maintenanceProgram}
                current={parseHoursLong(
                  maintenanceProgram.part_total_minutes,
                  0
                )}
                schedule={schedule}
                label={$t("uren")}
                units={$t("uren")}
                field_prefix="hours"
              />
            )}

            {!isArc &&
              currentMaterial.kist_configuration !== "Glider" &&
              maintenanceProgram.part_total_motor_minutes !== null && (
                <IntervalField
                  maintenance={maintenanceProgram}
                  current={parseHoursLong(
                    maintenanceProgram.part_total_motor_minutes,
                    1
                  )}
                  schedule={schedule}
                  label={$t("motor uren")}
                  units={$t("uren")}
                  field_prefix="motor_hours"
                />
              )}

            {(maintenanceProgram.part_total_starts === null ||
              maintenanceProgram.part_total_minutes === null ||
              (currentMaterial.kist_configuration !== "Glider" &&
                maintenanceProgram.part_total_motor_minutes === null)) && (
              <p className="small" style={{ maxWidth: 350 }}>
                <QuestionCircleOutlined />{" "}
                {$t(
                  "Als je de maintenance task wilt verbinden aan de starts, uren of motoruren moet je bij het part aangeven dat je de inservice lifetime wilt meten."
                )}
              </p>
            )}
          </Form>

          {maintenanceProgram.user_updated && (
            <p className="small" style={{ marginTop: 42 }}>
              {$t("Laatst gewijzigd door")}: {maintenanceProgram.user_updated},{" "}
              {_parseDateTime(maintenanceProgram.date_updated)}
            </p>
          )}
        </Tabs.TabPane>

        {!isArc && (
          <Tabs.TabPane tab={$t("Taken")} key="tasks">
            {isArc ? (
              <p className="small spacer">
                {$t("Taken van een Airworthiness review kan je niet wijzigen")}
              </p>
            ) : (
              <EditTasksList
                category={currentMaterial.category}
                tasks={newTasks}
                saveTasks={setNewTasks}
              />
            )}
          </Tabs.TabPane>
        )}
      </Tabs>
    </Modal>
  );
};
