import { FormOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { i18n } from '~/i18n.js'
import { parseMinutesLong } from '../../../lib/localize'
import { IGCButton } from '../../IGC/IGC.button'
import { LandingIcon, StartIcon } from '../../icon/icon'
import { TijdKnopAndEdit } from './tijdKnopAndEdit'

export const SectionButtons = ({ saveFlight, saveSleepFlight, readOnly, flight, sleepFlight, isPending, showFlightEditModal }) => {
  const [pendingAction, setPending] = useState(null)

  const callSaveFlight = async (action, data) => {
    // only conclude an action if not pending, quickly press on start/landing
    if (!pendingAction) {
      setPending(action)
      const result = await saveFlight(data)

      // for starts, also save to sleepFlight, they start together (but don't land together)
      if (action === 'start' && sleepFlight) {
        await saveSleepFlight({ manual_start: true, start_tijd: data.start_tijd, volg_nummer: result.volg_nummer, landings_tijd: sleepFlight.landings_tijd })
      }
      setPending(null)
      return result
    }
  }

  const disabledStart = readOnly || (!flight.registratie)
  const disabledLand = readOnly || (!flight.registratie || !flight.start_tijd)

  const triggerStart = (newTime) => disabledStart ? message.error('Kan de kist niet starten')
    // save start time, if starttime is empty, also make landing empty
    : callSaveFlight('start', {
      manual_start: true, start_ogn: -1, volg_nummer: flight.volg_nummer, start_tijd: newTime, landings_tijd: newTime ? flight.landings_tijd : null
    })

  const triggerLand = (newTime) => disabledLand ? message.error('Kan de kist niet landen')
    : callSaveFlight('land', {
      // only set action if no landingstime has been set, in which case server will overrule landingstime
      action: flight.landings_tijd ? '' : 'land',
      landings_ogn: -1,
      start_methode: flight.start_methode,
      volg_nummer: flight.volg_nummer,
      start_tijd: flight.start_tijd,
      landings_tijd: newTime
    })

  return (
    <>

      <TijdKnopAndEdit
        readOnly={readOnly}
        notActive={disabledStart}
        width={114}
        buttonAction={$t('Start')}
        fieldName={$t('start tijd')}
        ogn_indicator={flight.start_ogn}
        buttonIcon={<StartIcon />}
        currentTime={flight.start_tijd}
        saveTime={(newTime) => triggerStart(newTime)}
        size='medium'
      />

      <TijdKnopAndEdit
        readOnly={readOnly}
        notActive={disabledLand}
        width={114}
        buttonAction={$t('Land')}
        fieldName={$t('landings tijd')}
        ogn_indicator={flight.landings_ogn}
        buttonIcon={<LandingIcon />}
        currentTime={flight.landings_tijd}
        saveTime={(newTime) => triggerLand(newTime)}
        size='medium'
      />

      <div className='row' style={{ height: 40, width: 114, justifyContent: 'flex-end' }}>
        <p style={{ textAlign: 'right' }}>
          {flight && flight.vluchtduur > 0 ? parseMinutesLong(flight.vluchtduur) : '-'}
          {flight && flight.height > 0 && <><br />{i18n.parseLocaleHeightString(flight.height)}</>}
        </p>

        <IGCButton flight={flight} skipInfo />
      </div>

      <Button
        icon={<FormOutlined />} loading={isPending} style={{ width: 114 }}
        onClick={showFlightEditModal}
      >
        {$t('Details')}
      </Button>
    </>
  )
}
