import { $t } from '~/i18n'

export const updateIfNeeded = (dateLastRun, onUpdate, onSuccess = null, timeDelta = 60000) => {
  // Convenience function used if you want to update only if last_run is bit time ago. Run onSuccess if you return data
  // If no internet then we possibly don't need to run, unless there is no dateLastRun in which case we must
  // (with no baseData it makes no sense to have offline functionality)
  // timeDelta in milliseconds

  // date can be a string in case of persist date last updated
  let delta = -1
  let mustRun = false
  if (!dateLastRun) {
    delta = timeDelta + 1
    // no dateLastRun found -- we must have information initially to run, old is ok, but no data is not ok
    mustRun = false
  } else if (typeof dateLastRun === 'string' || dateLastRun instanceof String) {
    delta = (new Date()) - new Date(dateLastRun)
  } else {
    delta = (new Date()) - dateLastRun
  }

  if (navigator.onLine || mustRun) {
    if (delta > timeDelta) {
      onUpdate().then((data) => {
        // only run if success and data
        if (onSuccess && data) {
          onSuccess(data)
        }
      })
    } else {
      onSuccess && onSuccess()
    }
  }
}

export const delay = (s) => new Promise(res => setTimeout(res, s * 1000))
// Use in async function, await delay(5) for 5 seconds

export const selectElementByID = (list, field, matchValue) => {
  // convenience function, used when selecting a certain element from array based on id
  // e.g.: kist = returnElementByID(kisten, 'id', 3) return kist object with id 3

  // is not matching value then return zero
  if (!matchValue) return null

  let output = null
  list && list.some((object) => {
    // checks if field of object equals matched value, if so return
    if (object[field] === matchValue) {
      output = object
      // due to .some object it fill stop here
      return true
    }
    return false
  }
  )
  return output
}

export const generate_uuid = () => {
  // randomize uuid with date-component, of max length 30. Not fully RFC compliant but fast and easy
  return Date.now().toString(36) + Math.random().toString(36).substring(2)
}

export const normFileDuringFormUpload = e => {
  // Helper function to retrieve file from form upload
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}

export const getURLparams = () => {
  const request = {}
  const pairs = window.location.search.substring(window.location.search.indexOf('?') + 1).split('&')
  for (let i = 0; i < pairs.length; i++) {
    if (!pairs[i]) { continue }
    const pair = pairs[i].split('=')
    if (pair[1]) {
      try {
        request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
      } catch (error) {
        console.log('error in decoding uri: ', pair)
      }
    }
  }
  return request
}

export const validateEmail = (email) => {
  // simple email validator <word>@<domain>.<toplevel>
  const res = /\S+@\S+\.\S+/
  return res.test(email)
}

const MAXIMAGESIZE = 18 * 1000000
export const ruleCheckImageMaxSize = () => ({
  validator (ruleObject, files) {
    // validator for form object to verify that images are of max size
    // other file types are OK
    if (!files?.length) return Promise.resolve()
    let invalidFormat = false
    files.forEach(f => {
      if (['image/jpeg', 'image/png', 'image/gif'].indexOf(f.type) >= 0) {
        // only check sizes for images
        if (f.size > MAXIMAGESIZE) {
          invalidFormat = true
        }
      }
    })

    if (invalidFormat) return Promise.reject($t("Toegevoegde foto's mogen maximaal 18 MB zijn, selecteer (op je telefoon) een kleiner data formaat."))
    return Promise.resolve()
  }
})

export const formatMessage = (content, data) => {
  // Content parser based on double {{ }} data
  // data can be club.date

  // Create an array of variables that are found in the content using regular expressions
  content = content || ''
  const regex = /{{2}\s*((\w|\.)+)\s*}{2}/g
  const match = content.match(regex)
  match && match.forEach((variablePath) => {
    // for each match, replace the match in the content with the data found in data, e.g. {{domain.name}}
    const path = variablePath.substring(2, variablePath.length - 2).trim()

    // Then perform a walk down data, to achieve the final data value, nested in data
    let replace = data
    path.substring().split('.').forEach((part) => {
      if (part in replace) {
        replace = replace[part]
      }
      return ''
    })
    if (typeof replace === 'string' || typeof replace === 'number') {
      // Only if a string is found, replace data globally
      content = content.replace(variablePath, replace)
    }
  })
  return content
}
