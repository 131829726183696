export const TermsVersionEN = "Version 2, 17 feb 2023";

export const TermsTextEN = `

  If you create an account for your Gliding Club on https://gliding.app (from hereon known as Gliding.App), we will enter into agreements together. We believe it is important to be transparent. In this statement we record how we interact with each other and how we handle each other's data in a safe and privacy-friendly manner.

   Do you have any questions about the Gliding.App, about these conditions? Email us at [contact@gliding.app](mailto:contact@gliding.app).
  
  
   ### 1. Who is who?
  
   If you read 'you', 'you' or 'your(w)' in these conditions, we mean you as the admin user of the Gliding.App and “Representative” of your club. By “club” we mean the gliding club of which you are either a director (as registered in the Chamber of Commerce) or have been given the authority by the board to represent and to perform independent actions in the Gliding.App. We don't need to check this out. To the extent necessary, you hereby grant an irrevocable power of attorney to each Representative to independently represent your club.
  
   If you read 'we', 'us' or 'our', we mean the Gliding.App (also known as Zweef.App and registered with the Chamber of Commerce under number 88819051).
  
  
   ### 2. When do these conditions apply?
  
   This agreement is concluded when you use the Gliding.App for the first time, where you explicitly agree to the agreement before your club account is created. We always work on the basis of these general terms and conditions. We will only deviate from this if we have explicitly agreed to this by email.
  
  
  
   ### 3. De Gliding.App: what can you and cannot expect from us?
  
   We want you to enjoy using Gliding.App. We therefore do our best to ensure that Gliding.App always works well and to constantly improve it.
  
   However, we cannot guarantee that Gliding.App is always and fully available and works without disruptions. We also cannot guarantee that Gliding.App can be used on every operating system, browser, tablet or (mobile) device.
  
   In addition, we may sometimes temporarily decommission Gliding.App to perform maintenance, make adjustments or improve its operation. We try to do this during the winter or if necessary at night or during the week when we are not flying, so that it inconveniences you as little as possible.
  
   We are free to adapt, expand, delete or change parts of Gliding.App. This may mean that certain functionalities or services are no longer available at a certain time, or that the Gliding.App no longer works on certain operating systems or (mobile) devices. In any case, we ensure that no more personal data is processed or processed in an additional manner than described in Article 9.
  
   We do everything we can to ensure that Gliding.App is up to date, accurate and error-free, but we cannot guarantee this one hundred percent. For example, calculations, overviews or reports that you make in Gliding.App may contain errors. This may be the result of a system error in Gliding.App, but also of incorrect information or data that you have entered yourself.
  
   ### 4. What are your responsibilities?
  
   You are responsible for the proper use of the Gliding.App for your club and the actions performed with your user account. You are responsible for entering the correct information about your club. You are also responsible if someone else has used your password or if you let others use your account. We accept no liability for this.
  
   Do you suspect something strange or unusual is going on with your user account? Let us know via [contact@gliding.app](mailto:contact@gliding.app) and we can reset your password or (temporarily) deactivate your account.
  
  
   ### 5. What prices apply for the use of the Gliding.App?
  
   Creating a test account is free and without obligation. With this test account you can add up to 6 users to try out the Gliding.App for free and without obligation for a period of 12 months. During this period we offer the option to import your club's data.
  
   From the moment you activate more than 6 users, we assume that you will use the Gliding.App for your club, and the status of your account will change to a paid account.
  
   We currently offer a paid account that is based on the number of active members you have loaded into the “Glider” group. This may change in the future. You will find more information about this on our website.
  
   If you do not switch to a paid account within 12 months, we reserve the right to close your test account. We will discuss this with you, you may need more time to prepare your club for using the Gliding.App.
  
  
   ### 6. How does payment work?
  
   When your test account is converted, it is transferred to a paid account, you must provide valid payment information. We aim to send you an invoice every year in January for the total amount of that year. If you switch to a paid account later in the year, we will charge pro rata for the remaining part of the year.
  
   Make sure you pay the amount within the payment term of 4 weeks, stating the reference number. If you pay late, we may charge statutory commercial interest and collection costs. We can also temporarily deny you and your club access to the Gliding.App until you have paid the outstanding invoices. In addition, we have the right to terminate the agreement, stop our services to you and even delete your Gliding.App account. In that case, we are not liable for any damage you may suffer. We have included this clause for legal reasons, we assume that given the friendly and hobbyist nature of our sport, this will never be necessary.
  
  
   ### 7. Can we change the prices? And what can you do then?
  
   Prices can be adjusted annually based on the inflation correction from CBS. We are subject to a motivation obligation for this, we cannot simply change our prices. We may offer additional functionalities in the future for a different price. In both cases, if we change the prices, we will always notify you at least before October 1st. If you do not agree with the change, you can cancel our agreement free of charge at the end of the year. If you continue to use the Gliding.App after the price change takes effect in the new year, you indicate that you agree with the change.
  
  
   ### 8. Cancellation
  
   Your relationship with us has no end date. Do you still want to cancel this? You can do this annually by sending a cancellation email to [contact@gliding.app](mailto:contact@gliding.app) before December 28. After canceling, you will receive all your club data in a complete data dump, including file backups. We deactivate your account and store a backup of your data for 12 months after deactivation. After this period we will delete all data about you and your club.
  
   We can also terminate the relationship, and will do so with a notice period of 3 months. We can do this, among other things, if - for example - there is a suspicion that unlawful use is being made of our service, if it appears that information provided by you as a representative of your club is incorrect, if the continuation of our relationship with you results in liability risk for us if you do not want to pay on time.
  
   If we terminate the relationship because we as Gliding.App stop offering our service, we will do so with a notice period of 12 months and we will give all affiliated clubs access to the source code via a publicly accessible GIT repository, and the rights transfer the source code under Creative Commons Share-Alike licence. Together with the data dump from clubs, you can use this period to find a new party to continue the service or take the necessary steps to continue the service yourself.
  
   After termination of the relationship with you, we are not liable for any damage you suffer or costs you incur, for example for not downloading your club data on time.
  
  
   ### 9. Privacy and personal data
  
   In the Gliding.App you can record personal data of third parties (the members of your club), such as email addresses. As a result, we must both comply with the General Data Protection Regulation (GDPR). Legally, the club is regarded as responsible for the processing of the aforementioned personal data. We ourselves are regarded as the processor. We regard this provision as the processing agreement that we must conclude under the GDPR.
  
   For your information, we have a separate privacy notice for users who log in to the Gliding.App themselves, which you can read in the privacy statement on our website under [app.Gliding.app/privacy](https://app.Gliding.app /privacy).
  
  
   #### 9.1 General description
  
   We process data on behalf of the club with the aim of facilitating the club's digital gliding administration. Consider matters such as the starting administration, EASA Declared Training Organization (DTO) administration, authorities, or currency of members. The data of club members is processed as stated below:
  
   - Name
   - Contact details
   - Emergency contact details
   - Validity medical
   - Brevet, KNVvL, AML number
   - Take-offs made including date, time, aircraft registration number and role.
   - Progress and notes DTO
   - Powers within the club
   - Registrations, services and/or reservations
  
   This data is processed and made accessible to other members of the club or a specific group of club members (e.g. instructors, board or admin members), based on groups configured by the club in order to comply with the gliding club's legal obligations under the EASA and DTO rules, to be able to organize a flying company and to meet the conditions of, for example, the insurance of aircraft and equipment. We ensure that we comply with the requirements of legislation and regulations regarding the processing of personal data.
   
   #### 9.2 Instructions regarding processing
  
   We process the data as mentioned in article 9.1. We do not use the personal data for other purposes or in any other manner than described herein.
   
   #### 9.3 Confidentiality
  
   We both (you as a Representative of your club and we as the Gliding.App) ensure that all persons who have access to the said personal data will not share it with others and keep it confidential. We will only do so if we are obliged to do so due to a legal requirement, and we will inform the club about this as soon as possible.
   
   #### 9.4 Security
  
   We have taken appropriate technical and organizational measures to protect personal data. We have based the choice of these measures on the available technology, the implementation costs, the type of personal data we process and the risks associated with it. For example, our website has an SSL certificate, we use a firewall to prevent abuse and we have encryption on certain critical data. In addition, all our servers are within the European Union (European Economic Area) and hosted in locations where data cannot be shared with the US (think of the EU-US Privacy Shield).
   
   #### 9.5 Subprocessors
  
   We host our service on Amazon within the European Union (European Economic Area), which acts as a sub-processor with us, where we run both our servers and databases.
   
   #### 9.6 Privacy Rights
  
   The data that you or your clubmates enter in Gliding.App is always yours. We will therefore never claim ownership of data that you have entered yourself.
  
   As the person responsible for the processing of personal data, you are authorized to view, change or delete all data from your club if this proves necessary.
  
   A complaint or request from a data subject regarding the processing of personal data, as described in Article 9.1, will be forwarded by us to your club, which is responsible for handling the request. We will fully cooperate to ensure that you can comply with this within the legal deadlines.
   
   #### 9.7 Notification of a data breach
  
   As soon as we notice that a data breach, unlawful processing of personal data or a breach of our system has occurred, we will report this immediately so that we can assess the incident and take appropriate follow-up steps. Naturally, we will both assess the incident and determine together what measures we can take to limit the impact and prevent this in the future.
  
   #### 9.8 Storage period and data deletion
  
   Data of individual members entered by you as a club will be retained as long as the member is considered an active member by the club. After removal of the member, the data will be retained for a further 36 months to comply with EASA and DTO retention periods.
  
   We are obliged to delete the processed personal data upon termination of our relationship, and will do this as we described in Article 8.
   
   We continuously make backups of the data you store with us. We store backups for a maximum of 60 days. This means that deleted data can be retained in such a backup for a maximum of 60 days.
   
   #### 9.9 Audits
  
   If you would like to conduct an audit to see whether we comply with all legal obligations, we will cooperate. After consultation, you may request an independent auditor, who may conduct the audit at your expense. We provide our full cooperation in this regard.
  
   ### 10. Can we change these terms? And what can you do then?
  
   We may change these terms from time to time. We will always let you know this well in advance by e-mail, and we will explicitly ask you to agree to the terms and conditions again. If you do not agree with the change, you can immediately cancel our agreement.
  
  
   ### 11. Disputes: how do we resolve them?
  
   This is also a clause that is legally included in our terms and conditions, but hopefully will never be necessary. If we have a dispute, we will do our best to find a solution with you. Therefore, please let us know as soon as possible if you are not satisfied with something. Can't do the same thing?



  (This is an English translation. In case that the Dutch and English tekst differ, the Dutch text prevails. In case of translation errors, please let us know. Thanks)
`;
