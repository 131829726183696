import { TeamOutlined } from '@ant-design/icons'
import { Card, Table, Tabs, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { $t } from '~/i18n.js'
import { MainLayout } from '~/components/layout/layout.jsx'
import { NotitieModal } from '~/components/profile/notitieModal.jsx'
import { api } from '~/lib/api.js'
import { selectElementByID, updateIfNeeded } from '~/lib/helpers.js'
import { _parseDateTime, toLocaleDate } from '~/lib/localize.js'
import { daysActions, flightsActions } from '~/redux/actions.js'
import { sortableAanmeldTableColumns, sortableAanmeldTableNietVliegend, SortableFlightTable, sortableVliegerTable } from './dagVerslag.columns.jsx'
import { defaultTableConfig } from '~/components/layout/table.jsx'
import { UserModal } from '~/components/profile/userModal.jsx'
import { ViewFlightModal } from '~/components/selectedFlight/viewFlight.modal.jsx'
import { CardRooster } from '../aanmeldLijst/card.rooster.jsx'
import { MeteoCard } from './meteoCard.jsx'
import { BreadcrumbHeader } from '~/components/layout/breadcrumbHeader.jsx'
import { MessageCard } from '../aanmeldLijst/messageCard.jsx'
import { CardNotities } from './cards/card.notities.jsx'
import { CardStats } from './cards/card.stats.jsx'
import './dagVerslag.css'
import { DayNavigator } from './dayNavigator.jsx'
import { EmailDagVerslag } from './emailDagVerslag.jsx'

const emptyStats = {
  starts: 0,
  lier: 0,
  sleep: 0,
  overland: 0,
  dbo: 0,
  checks: 0,
  kisten: [],
  kist_types: [],
  instructeurs: [],
  dagRecord: 0,
  maxHeight: 0,
  halfuurs: 0,
  meteo: [],
  diensten: [],
  aanmeldingen: []
}

export const DagVerslagComponent = ({ history, match, flights, days, getBaseData, getAllDays, openDay, profile, club }) => {
  // setup base data
  useEffect(() => {
    updateIfNeeded(flights.lastUpdatedBaseData, getBaseData)
  }, [flights.lastUpdatedBaseData, getBaseData])
  useEffect(() => {
    updateIfNeeded(days.lastUpdated, getAllDays, () => days.lastUpdated && openDay(match.params.id))
  }, [days.lastUpdated, getAllDays, match.params.id, openDay])

  // load in data
  const [loading, setLoading] = useState(true)

  const [showNotitieModal, setShowNotitieModal] = useState(false)
  const [notitie, setNotitie] = useState(null)
  const [selectedPilot, setSelectedPilot] = useState(null)
  const [openedPilot, setOpenPilot] = useState(null)

  const [dagNotities, setDagNotities] = useState([])
  const [flightList, setFlights] = useState([])
  const [vliegers, setVliegers] = useState([])
  const [vliegerData, setVliegerData] = useState({})
  const [emailSettings, setEmailSettings] = useState({})
  const [stats, setStats] = useState({ ...emptyStats })
  const [flight, openFlight] = useState(null)

  // if not is_vliegend it will throw out a number of views
  const is_vliegend = days.activeDay?.is_vliegend

  useEffect(() => {
    flights.lastUpdatedBaseData && api.post('instructie/get_day.json', { dag_id: match.params.id })
      .then((res) => {
        // set data both ways
        const vliegerIDS = []
        const vliegers = []
        const vliegerdata = {}
        const data = { ...emptyStats, kist_types: [], instructeurs: [], meteo: [], diensten: [], aanmeldingen: [] }

        if (res) {
          data.meteo = res.meteo
          data.diensten = res.diensten
          data.aanmeldingen = res.aanmeldingen
          data.messages = res.messages

          data.starts = 0
          setFlights(res.flights)
          setEmailSettings(res.email_settings)

          // dayNotities is notities filtered with no Date
          setDagNotities(!res.notities ? [] : res.notities.filter((note) => note.user_id === null))

          // reduce all notities to 1 dictionary for the vlieger
          const vliegerNotities = !res.notities
            ? []
            : res.notities.reduce((all, notitie) => {
              if (notitie.user_id) {
                if (!all[notitie.user_id]) all[notitie.user_id] = []
                all[notitie.user_id].push(notitie)
              }
              return all
            }, {})

          // iterate over flights array, and append data to vlieger && flights
          res.flights.forEach((flight) => {
            if (!flight.is_deleted && !(flight.start_methode === 'tmg-a' && flight.sleep_uuid)) {
              // add data
              data.starts += 1
              if (flight.start_methode === 'lier') data.lier += 1
              if (flight.start_methode === 'sleep') data.sleep += 1
              if (flight.is_overland) data.overland += 1
              if (flight.is_fis && flight.tweede_inzittende_naam) data.dbo += 1
              if (flight.is_training) data.checks += 1
              if (flight.vluchtduur > data.dagRecord) data.dagRecord = flight.vluchtduur
              if (flight.height > data.maxHeight) data.maxHeight = flight.height
              if (flight.vluchtduur >= 30) data.halfuurs += 1
              if (flight.type && !data.kist_types.includes(flight.type)) data.kist_types.push(flight.type)
              if (flight.registratie && !data.kisten.includes(flight.callsign || flight.registratie)) data.kisten.push(flight.callsign || flight.registratie)
              if (flight.is_fis && !data.instructeurs.includes(flight.gezagvoerder_naam)) data.instructeurs.push(flight.gezagvoerder_naam);

              // create the base for both the gezagvoerder as the tweede inzittende
              ['gezagvoerder_id', 'tweede_inzittende_id'].forEach((field) => {
                if (flight[field]) {
                  // create field if not existing
                  if (!vliegerIDS.includes(flight[field])) {
                    vliegerIDS.push(flight[field])
                    vliegers.push({ id: flight[field], ...selectElementByID(flights.vliegers, 'id', flight[field]) })
                    vliegerdata[flight[field]] = {
                      starts: 0,
                      vluchtduur: 0,
                      lier: 0,
                      sleep: 0,
                      kist_types: [],
                      instructeurs: [],
                      has_dbo: false,
                      has_check: false,
                      has_examen: false,
                      has_overland: false,
                      afstand: 0,
                      notities: vliegerNotities[flight[field]]
                    }
                  }

                  // add data to vlieger
                  if (vliegerdata[flight[field]]) {
                    vliegerdata[flight[field]].starts += flight.starts
                    vliegerdata[flight[field]].vluchtduur += flight.vluchtduur
                    if (flight.start_methode === 'lier') vliegerdata[flight[field]].lier += flight.starts
                    if (flight.start_methode === 'sleep') vliegerdata[flight[field]].sleep += flight.starts
                    if (!vliegerdata[flight[field]].kist_types.includes(flight.type)) vliegerdata[flight[field]].kist_types.push(flight.type)
                    if (field === 'tweede_inzittende_id') {
                      if (flight.is_examen) vliegerdata[flight[field]].has_examen = true
                      if (flight.is_training) vliegerdata[flight[field]].has_check = true
                      if (flight.is_fis && !vliegerdata[flight[field]].instructeurs.includes(flight.gezagvoerder_naam)) vliegerdata[flight[field]].instructeurs.push(flight.gezagvoerder_naam)
                      if (flight.is_fis) vliegerdata[flight[field]].has_dbo = true
                    }
                    if (flight.is_overland) vliegerdata[flight[field]].has_overland = true
                    if (flight.afstand > 0) vliegerdata[flight[field]].afstand += flight.afstand
                  }
                }
              })
            }
          })

          // sort vlieger Kisten
          data.kisten.sort((a, b) => {
            // sort method to deal with [M11, PH-1233, M2] being sorted to [M2, M11, PH-1233]
            const diff = parseInt(a.substr(1)) - parseInt(b.substr(1))
            if (isNaN(diff)) return ('' + a).localeCompare(b)
            return diff
          })
          data.kist_types.sort()

          vliegers.sort((a, b) => ('' + a.lowercase).localeCompare(b.lowercase))

          setLoading(false)
        } else {
          setLoading(false)
        }

        // finally add data to state
        setVliegerData(vliegerdata)
        setVliegers(vliegers)
        console.log('set stats', {data})
        setStats(data)
      })
  }, [match.params.id, flights.lastUpdatedBaseData, flights.vliegers])

  const addVliegerNotitie = (vlieger) => {
    setSelectedPilot(vlieger)
    setShowNotitieModal(true)
  }

  const onAddNotitie = (notitie) => {
    setShowNotitieModal(false)
    setSelectedPilot(null)

    // if notitie contains pilot add it to the vliegerData, otherwise to dag notities overview
    if (notitie.user_id) {
      const newVliegerData = { ...vliegerData }
      newVliegerData[notitie.user_id].notities = notitie?.action === 'delete' ? [] : [notitie]
      setVliegerData(newVliegerData)
    } else {
      let newNotities = dagNotities ? dagNotities.filter(n => n.id !== notitie.id) : []
      if (!notitie.action || notitie.action !== 'deleted') {
        newNotities = [notitie].concat(newNotities)
      }
      setDagNotities(newNotities)
    }
  }

  return (
    <MainLayout history={history} isLoading={days.isPending || loading}>

      <UserModal
        visible={openedPilot && openedPilot.id}
        onClose={() => setOpenPilot(null)}
        pilot={openedPilot}
      />

      <ViewFlightModal
        onClose={() => openFlight(null)}
        flight={flight}
        currentUser={null}
        updateFlight={null}
        onUpdateFlight={null}
      />

      <NotitieModal
        visible={showNotitieModal}
        notitie={notitie}
        onCancel={() => {
          setShowNotitieModal(false)
          setNotitie(null)
        }}
        onOk={onAddNotitie}
        activeDay={days.activeDay}
        pilot={selectedPilot}
      />

      <BreadcrumbHeader
        breadcrumbs={[
          <Link key='1' to='/dagverslag' className='title'>{$t('Dagverslagen')}</Link>,
          <p key='2' style={{ display: 'flex' }}>{toLocaleDate(new Date(days.activeDay.datum))}</p>
        ]}
        buttons={[
          <DayNavigator history={history} key='3' />,
          <EmailDagVerslag
            key='4'
            profile={profile}
            notes={dagNotities}
            club={club}
            flights={flightList}
            emailSettings={emailSettings}
            activeDay={days.activeDay}
            history={history}
          />
        ]}
      />

      <div className='column' style={{ marginTop: 6, alignItems: 'flex-start' }}>
        {!days.activeDay.gaat_door && <Tag style={{ marginRight: 6 }} color='red'>{$t('Geannuleerd')}</Tag>}
        <p className='title'>{days.activeDay.type_dag}{days.activeDay.titel && days.activeDay.titel.length > 0 && (': ' + days.activeDay.titel)}
          {!days.activeDay.is_vliegend && <span style={{ marginLeft: 12 }}><TeamOutlined /> {$t('Niet vliegend')}</span>}
        </p>
        {days.activeDay.gaat_door && <p>{$t('briefing om')} {days.activeDay.briefing_tijd || '??'}, {days.activeDay.vertrek_vliegveld}</p>}
        {days.activeDay.updated_name && days.activeDay.updated_date && <p className='small'>{$t('aangepast door')} {days.activeDay.updated_name}, {_parseDateTime(days.activeDay.updated_date)}</p>}
      </div>

      <div className='dagVerslag'>

        {is_vliegend && <CardStats stats={stats} vliegers={vliegers} />}

        {stats.messages?.length > 0 && <MessageCard messages={stats.messages || []} canEditMessage={false} />}

        <CardNotities setNotitie={setNotitie} setShowNotitieModal={setShowNotitieModal} dagNotities={dagNotities} profile={profile} />

        {stats.diensten.length > 0 &&
          <Card size='small' className='topCard' title={$t('Diensten')}>
            <CardRooster aanmeldingen={stats.aanmeldingen} diensten={stats.diensten} group_name_lookup={flights.group_name_lookup} />
          </Card>}

        {club.country === 'NL' && <MeteoCard meteo={stats.meteo} />}

      </div>

      <Tabs defaultActiveKey='vliegers'>

        {is_vliegend && <Tabs.TabPane tab={$t('Vliegers')} key='vliegers'>
          <Table
            style={{ marginTop: 12, width: '95vw', overflowX: 'auto' }}
            pagination={defaultTableConfig}
            size='small'
            rowKey='id'
            dataSource={vliegers}
            columns={sortableVliegerTable(vliegerData, stats, addVliegerNotitie, setOpenPilot)}
          />
        </Tabs.TabPane>}

        {is_vliegend && <Tabs.TabPane tab={$t('Vluchten')} key='vluchten'>
          <Table
            style={{ marginTop: 12, width: '95vw', overflowX: 'auto' }}
            pagination={defaultTableConfig}
            size='small'
            className='table--clickable'
            rowKey='id'
            dataSource={flightList.filter((f) => !(f.start_methode === 'tmg-a' && f.sleep_uuid))}
            columns={SortableFlightTable()}
            onRow={(flight) => {
              return {
                onClick: () => openFlight(flight)
              }
            }}
          />
        </Tabs.TabPane>}

        <Tabs.TabPane tab={$t('Aanmeldingen')} key='aanmeldingen'>
          <Table
            style={{ marginTop: 12, width: '95vw', overflowX: 'auto' }}
            pagination={defaultTableConfig}
            size='small'
            rowKey='id'
            dataSource={stats.aanmeldingen}
            columns={is_vliegend ? sortableAanmeldTableColumns() : sortableAanmeldTableNietVliegend()}
          />
        </Tabs.TabPane>

      </Tabs>

    </MainLayout>
  )
}

const mapStateToProps = (state) => ({
  flights: state.flights,
  days: state.days,
  profile: state.persist.profile,
  club: state.persist.club,
  lastUpdated: state.materiaal.lastUpdated

})

const mapDispatchToProps = (dispatch) => {
  return {
    openDay: (d) => dispatch(daysActions.openDay(d)),
    getAllDays: (d) => dispatch(daysActions.getAllDays(d)),
    getBaseData: (d) => dispatch(flightsActions.getBaseData(d))
  }
}

export const DagVerslagPagina = connect(mapStateToProps, mapDispatchToProps)(DagVerslagComponent)
