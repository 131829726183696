import { $t } from '~/i18n.js'
import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Input, Modal, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { api } from '../../../../lib/api'

export const ConfirmTaskModal = ({ closeModal, task, action, confirmTask, material_id, currentProfile }) => {
  const [remark, setRemark] = useState('')
  const [files, setFiles] = useState([])
  const [check, setCheck] = useState(true)
  const [checkSub, setCheckSub] = useState(true)
  useEffect(() => task && setRemark(task.remark), [task])

  const [loading, setLoading] = useState(false)
  const triggerSave = async () => {
    setLoading(true)

    // double check for restrictions
    if (!task.restriction || currentProfile[task.restriction]) {
      // don't files, don't wait
      files.forEach((file) => {
        api.post_form('materiaal/save_workorder_file.json', { task_id: task.id, file: file.originFileObj, material_id })
      })

      await confirmTask(task, action, remark)

      // iterate downwards to confirm all subtasks
      const confirm = (subTask, action) => {
        if ((action === 'sign' && !subTask.signed_date) || (action === 'check' && !subTask.checked_date)) {
          // and double check restriction
          if (!subTask.restriction || currentProfile[subTask.restriction]) confirmTask(subTask, action, '')
        }
        subTask.tasks.forEach(_t => confirm(_t, action))
      }
      task.tasks.forEach(subTask => confirm(subTask, action))
    }
    setLoading(false)
    closeModal()
  }

  return (
    <Modal
      title={$t('Taak goedkeuren')}
      open={!!task}
      confirmLoading={loading}
      okText='Vink af'
      cancelText={$t('Annuleren')}
      onCancel={closeModal}
      onOk={triggerSave}
      okButtonProps={{ loading, disabled: !check || !checkSub }}
    >

      <p className='spacer'>Taak: <span className='bold'>{task.name}</span></p>
      {task.description && <p className='spacer userInput'>{task.description}</p>}

      <p>Laat opmerking achter:</p>
      <Input.TextArea
        rows={3} value={remark}
        onChange={e => setRemark(e.target.value)}
      />

      <p style={{ marginTop: 12 }}>Bijlage (optioneel):</p>
      <Upload
        name='file' listType='picture' multiple
        onChange={(f) => setFiles(f.fileList || [])}
        beforeUpload={(file) => false}
        showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
      >
        <Button icon={<UploadOutlined />}>
          {$t('Selecteer 1 of meerdere bestanden')}
        </Button>
        <p className='small' style={{ marginTop: 4 }}>{$t('Of maak een foto via je mobiel.')}</p>
      </Upload>

      <Checkbox checked={check} onChange={e => setCheck(e.target.checked)} style={{ margin: '12px 8px' }}>
        {$t('Hierbij bevestig ik dat de taak goed zijn is ${action}.', { action: action === 'sign' ? 'afgerond' : 'gecontroleerd' })}
      </Checkbox>

      {task.tasks.length > 0 && <Checkbox checked={checkSub} onChange={e => setCheckSub(e.target.checked)}>
        {$t('Hierbij bevestig ik dat alle ${action1} subtaken goed zijn ${action2}.', { action1: action === 'sign' ? task.tasks_remain : task.critical_remain, action2: action === 'sign' ? 'afgerond' : 'gecontroleerd' })}
      </Checkbox>}

    </Modal>
  )
}
