import React from "react";
import { Switch } from "antd";
import { $t } from "~/i18n.js";

export const SwitchYesNo = ({id='', value='', onChange= e => {}}) => {
  return (
    <Switch 
      checked={value} 
      onChange={onChange}
      checkedChildren={$t('Ja')}
      unCheckedChildren={$t('Nee')}
    />
  );
};
