export const onderhoudState = {

  isPending: true,
  lastUpdated: null,
  periods: [],
  current_period: {
    name: '',
    status: 'concept',
    verplichtingen: [],
    registraties: [],
    open_registraties: []
  },

  lastUpdatedMijnVerplichting: null,
  verplichting: {
    period: null
  },
  registraties: []
}
