import { CheckCircleTwoTone, DownCircleTwoTone, ExclamationCircleTwoTone, MessageOutlined, RightOutlined, UpCircleTwoTone } from '@ant-design/icons'
import { Button, Collapse, List, Tag } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { $t } from '~/i18n.js'

import { _parseDate } from '../../../lib/localize'
import { colors } from '../../../theme/colors'
import { MaintenanceStatusTag, MeldingCategoryTag, MeldingStatusTag } from '../materialTags'

const MeldingenOverview = ({ meldingen }) => {
  if (meldingen.length === 0) return <p>Géén meldingen</p>
  return (
    <List
      size='small'
      dataSource={meldingen}
      renderItem={(melding) => <List.Item extra={[
        <Link key={melding.id} to={`/melding/${melding.id}`}>
          <Button icon={<RightOutlined />} size='small' shape='circle' />
        </Link>
      ]}
                               >
        <div className='row' style={{ gap: 12, flexWrap: 'wrap', maxWidth: '70vw' }}>
          <MeldingCategoryTag category={melding.category} />
          <p>{melding.titel}</p>
          {(melding.category === 'defect' || melding.category === 'klacht') && (melding.status !== 'aangemeld' || melding.workorder_id) && <MeldingStatusTag status={melding.status} workorder_id={melding.workorder_id} />}
          <p className='small gray'>{_parseDate(melding.date_created)}</p>
          {melding.date_verwacht_afgehandeld && <p className='small gray'>afgerond voor {_parseDate(melding.date_verwacht_afgehandeld)}</p>}
        </div>
                               </List.Item>}
    />
  )
}

export const OverviewTab = ({ material, meldingen, search, searchTag, inspections }) => {
  const material_list = material?.filter(row => {
    const inCategory = searchTag.length === 0 || searchTag.some(t => row.kist_category === t || row.category === t)
    if (search) {
      return inCategory && row.material_name?.toLowerCase().indexOf(search) > -1
    }
    return inCategory
  })

  return (
    <Collapse
      style={{ marginTop: 42, width: '100%', background: 'white' }}
      expandIcon={({ isActive }) => isActive ? <UpCircleTwoTone className='clickable' twoToneColor='blue' /> : <DownCircleTwoTone className='clickable' twoToneColor='blue' />}
    >
      {material_list.map((material, key) => {
        const _melding_filtered = meldingen?.filter(m => m.material_id === material.id)
        const url = `/material/${material.id}`
        return (
          <Collapse.Panel
            key={key}
            collapsible={_melding_filtered.length === 0 ? 'disabled' : ''}
            showArrow={_melding_filtered.length > 0}
            header={<div className='row' style={{ gap: 18 }}>
              {_melding_filtered.length === 0 && <DownCircleTwoTone className='clickable' twoToneColor={colors.gray_light} style={{ marginLeft: 3, marginRight: -5, fontSize: 12 }} />}
              <Link onClick={e => e.stopPropagation()} to={url}>{material.material_name} {material.kist_category === 'prive' && <Tag color='green'>{$t('prive')}</Tag>}</Link>
              {material.status !== 'in bedrijf' && <MeldingStatusTag status={material.status} />}
              {material.maintenance_status !== 'green' && <MaintenanceStatusTag maintenance_status={material.maintenance_status} />}
            </div>}
            extra={[
              inspections && material.category === 'kist' && <span style={{ marginRight: 12 }} key={1}>
                {inspections.indexOf(material.id) >= 0 ? <CheckCircleTwoTone twoToneColor={colors.green} /> : <ExclamationCircleTwoTone twoToneColor={colors.gray_light} />}
                                                             </span>,
              <Link key={2} onClick={e => e.stopPropagation()} to={url}>
                <Button type='primary' size='small'>
                  {$t('Open')}
                </Button>
              </Link>
            ]}
          >
            <div style={{ padding: 12 }}>
              {material.remark && <p style={{ marginBottom: 6 }}><MessageOutlined /> {material.remark}</p>}
              <MeldingenOverview meldingen={_melding_filtered} />
            </div>
          </Collapse.Panel>
        )
      }
      )}
    </Collapse>
  )
}
