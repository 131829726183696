import { Modal } from "antd";
import React from "react";
import { SluitButton } from "../buttons/SluitButton.jsx";
import { UserProfile } from "./userProfile.jsx";

export const UserModal = ({
  visible,
  onClose,
  pilot,
  currentUser,
  newNotities,
  link,
}) => {
  if (!pilot) return null;

  return (
    <Modal
      width={750}
      title={pilot.name}
      open={visible}
      onCancel={onClose}
      footer={[<SluitButton key="sluit" onClick={onClose} />]}
    >
      <UserProfile
        link={link}
        currentUser={currentUser}
        pilot={pilot}
        newNotities={newNotities}
      />
    </Modal>
  );
};
