import React from "react";
import { $t } from "~/i18n.js";

import { MailOutlined } from "@ant-design/icons";
import { Button, Divider, Modal } from "antd";

import { Icon, nood } from "../icon/icon.jsx";
import { Phone } from "./phone.jsx";
import { SluitButton } from "../buttons/SluitButton.jsx";

export const ContactModal = ({ gegevens, visible, onCancel }) => {
  return (
    <Modal
      title={$t("Contactgegevens")}
      open={visible}
      onCancel={onCancel}
      footer={[<SluitButton key="sluit" onClick={onCancel} />]}
    >
      <div className="row">
        <Button
          type="primary"
          icon={<MailOutlined />}
          size="small"
          shape="circle"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          target="_blank"
          href={gegevens ? `mailto:${gegevens.email}` : null}
        />
        <p style={{ marginLeft: 6, marginRight: 18 }}>
          {gegevens ? gegevens.email : $t("onbekend")}
        </p>
      </div>

      {gegevens && gegevens.address1 && !gegevens.private_mode ? (
        <>
          <p>{gegevens.address1}</p>
          {gegevens.address2 && <p>{gegevens.address2}</p>}
          <p>
            {gegevens.zipcode}, {gegevens.city}
          </p>
        </>
      ) : (
        <p>{$t("Woonadres onbekend/afgeschermd")}</p>
      )}

      <Divider />

      <p>
        <Icon icon={nood} viewBox="0 0 1124 1024" />{" "}
        {$t("Contactpersoon in geval van nood")}
      </p>

      {gegevens && gegevens.emergency_contact ? (
        <div>
          <p style={{ margin: "12px 0" }}>
            <span className="bold">{gegevens.emergency_contact}</span> (
            {gegevens.emergency_contact_relation})
          </p>
          <Phone number={gegevens.emergency_contact_phone} />
        </div>
      ) : (
        <p>{$t("Onbekend")}</p>
      )}
    </Modal>
  );
};
