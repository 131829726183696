import PropTypes from "prop-types";
import React from "react";

import { SelectLanguage } from "../profile/selectLanguage.jsx";
import "./modalLayout.css";

export class ModalLayout extends React.Component {
  static propTypes = {
    width: PropTypes.string,
    noPadding: PropTypes.bool,
  };

  static defaultProps = {
    onClose: null,
    title: "",
    subTitle: "",
    width: "350px",
    noPadding: false,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render({ children, width, noPadding } = this.props) {
    return (
      <div className="modalLayout">
        <div className="modalContent slideInFromTop">
          <div
            className="modal"
            style={{ maxWidth: width, padding: noPadding ? null : "42px 32px" }}
          >
            {children}
          </div>
        </div>

        <div className="centerBackground fadeIn" />

        <div className="column slideInFromBottom footer">
          <div
            className={`logo  ${
              window.isZweef() ? "ZweefWhiteLogo" : "GlideWhiteLogo"
            } `}
          />
          <div className="row">
            <SelectLanguage naked />
          </div>
        </div>
      </div>
    );
  }
}
