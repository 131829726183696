import { SyncOutlined } from "@ant-design/icons";
import { Badge, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $t } from "~/i18n.js";
import { saveFlightFromQueue, deleteFromQueue } from "~/redux/flights/flights.actions.js";

// rerun each seconds to check the queue to sync flights if possibles
const INTERVAL_TIMEOUT = 120 * 1000;

export const FlightQueueComponent = ({ syncData }) => {
  const dispatch = useDispatch();

  const { flight_queue, network_online } = useSelector(
    (state) => state.flights
  );

  const [syncing, setSync] = useState(false);

  // setup dummy run
  // issue: setInterval does not have access to last state
  // as such we set an interval to increment a run, and set a useEffect on this run
  const [run, setRun] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setRun((run) => run + 1);
    }, INTERVAL_TIMEOUT);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // setup useEffect to trigger on run
  // will trigger each time run is updated
  // each time it will have the last state loaded
  useEffect(() => {
    if (!navigator.onLine) return undefined;

    if (flight_queue && Object.keys(flight_queue).length > 0) {
      message.info(
        $t("Internet hersteld, syncronisatie ${l} vluchten gestart", {
          l: Object.keys(flight_queue).length,
        })
      );
      _fireSync();
    }
  }, [run, network_online]);

  const syncFlights = async () => {
    if (flight_queue && Object.keys(flight_queue).length === 0) {
      message.info($t("Geen vluchten"));
      return null;
    }

    if (!window.navigator.onLine) {
      message.error(
        $t("Internet offline, ${l} vluchten in geheugen", {
          l: flight_queue && Object.keys(flight_queue).length,
        })
      );
      return null;
    }

    message.info(
      $t("Syncroniseer ${l} vluchten", {
        l: flight_queue && Object.keys(flight_queue).length,
      })
    );
    _fireSync();
  };

  const _fireSync = () => {
    setSync(true);
    // async save all flights
    Object.keys(flight_queue).forEach((uuid) => {
      // iterate over all flight events
      
      // _t is the time the flight was added to the queue
      const _t = flight_queue[uuid]._t;
      
      // if the flight was added to the queue more than 2 days ago, skip it
      if (!_t || new Date() - new Date(_t) > 2 * 24 * 60 * 60 * 1000) {
        dispatch(deleteFromQueue(flight_queue[uuid]));
      }

      dispatch(saveFlightFromQueue(flight_queue[uuid]));
    });
    setSync(false);
  };

  const flights_badge = flight_queue && Object.keys(flight_queue).length;

  return (
    <div className="row">
      <Button
        style={{ marginLeft: 8, marginRight: 12 }}
        size="small"
        loading={syncing}
        onClick={() => (flights_badge > 0 ? syncFlights() : syncData())}
        icon={
          <SyncOutlined
            style={{ color: flights_badge > 0 ? "red" : "#C4CED8" }}
            spin={flights_badge > 0}
          />
        }
      >
        {flights_badge > 0 ? (
          <Badge size="small" count={flights_badge} />
        ) : (
          <p className="small">sync</p>
        )}
      </Button>
    </div>
  );
};
