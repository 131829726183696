import { EditOutlined, EyeOutlined, MessageOutlined } from '@ant-design/icons'
import { Button, Card, Tag } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { $t } from '~/i18n.js'

import { _parseDate } from '../../../../lib/localize'
import { TaskStatus } from './modalViewWorkorder'

export const WorkorderCard = ({ workorder, openModal, readOnly, showAsOverview }) => {
  const url = `/materiaal/${workorder.material_id}/workorders/${workorder.id}/`

  const actions = showAsOverview
    ? [
      <Link key='open' to={url}>
        <Button type='primary' size='small'>
          {$t('Open')}
        </Button>
      </Link>
      ]
    : [
      <Link key='open' to={url}><Button shape='circle' size='small' icon={<EyeOutlined />} /></Link>,
      !readOnly && <Link key='edit' to={url + 'edit'}><Button style={{ marginLeft: 6 }} shape='circle' size='small' icon={<EditOutlined />} /></Link>
      ]

  return (
    <Card
      type='inner' size='small' style={{ width: '100%' }}
      title={
        <div className='row'>
          <Link key='open' to={url} className='inherit'>
            <p className='clickable'>
              {showAsOverview && <Tag style={{ marginRight: 12 }}>{workorder.material_name}</Tag>}
              {workorder.name}
            </p>
          </Link>
        </div>
}
      extra={actions}
    >

      {workorder.state !== 'completed' && <TaskStatus workorder={workorder} />}

      {(workorder.expected_start_date || workorder.expected_end_date) && workorder.state === 'active' && <p>Verwachte doorlooptijd:
        {workorder.expected_start_date ? ' van ' + _parseDate(workorder.expected_start_date) : ''}
        {workorder.expected_end_date ? ' tot ' + _parseDate(workorder.expected_end_date) : ''}
                                                                                                         </p>}

      <div>
        {workorder.list_maintenance?.map((m, idx) => <Tag key={idx}>{m.name}</Tag>)}
        {workorder.list_meldingen?.map((m, idx) => <Tag key={idx}>{m.name}</Tag>)}
      </div>

      {workorder.remark && workorder.state === 'active' && <p><MessageOutlined /> {workorder.remark}</p>}

      {workorder.state === 'active' && <p className='small' style={{ margin: '4px 0' }}>{workorder.user_updated}</p>}

      {workorder.state === 'completed' && <p className='small'>{(`Afgerond op ${_parseDate(workorder.date_updated)} door ${workorder.user_updated}`)}</p>}

    </Card>
  )
}
