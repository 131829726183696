import { Alert, Button, Descriptions, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { InputFileSingle } from "~/components/form/InputFileSingle.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { generate_uuid } from "~/lib/helpers.js";
import { ClusterChecklist } from "./ClusterChecklist.jsx";
import { splitText } from "./ExportCluster.js";

export const SharedDTOgooglelink = () => {
  return (
    <a
      href="https://drive.google.com/drive/folders/102W-UIHPFWxrEj619hDCXCCjDXn_4Jpf"
      target="_blank"
      rel="noopener noreferrer"
    >
      {$t("Link")}
    </a>
  );
};

export function ImportDialogDTO({
  isModal = false,
  closeModal = () => {},
  refreshAfterImport = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [cluster, setCluster] = useState(null);
  const [value, setValue] = useState("");

  const [form] = Form.useForm();

  const reset = () => {
    form.resetFields();
    setLoading(false);
    setError(false);
    setCluster(null);
    setValue("");
  };

  useEffect(() => {
    isModal && reset();
  }, [isModal]);
  useEffect(() => {
    if (!value) return;

    let cluster = {};

    const parts = value.split(splitText);
    if (parts.length < 1) {
      setError(true);
      return;
    }

    // split on variable and load in via json
    cluster = JSON.parse(parts[1]);

    // Load in data, with custom action and id
    cluster = {
      ...cluster,
      order: 0,
      category: cluster.category.map((c) => {
        return {
          ...c,
          action: "add",
          id: generate_uuid(),
          bevoegdheid: c.bevoegdheid.map((b) => {
            return {
              ...b,
              action: "add",
              id: generate_uuid(),
            };
          }),
        };
      }),
    };
    setCluster(cluster);
  }, [value]);

  const startImport = async () => {
    setLoading(true);

    console.log({ cluster });

    const { response, data } = await api.post2("admin/dto.json", cluster);

    if (response.ok && data) {
      closeModal();
      refreshAfterImport();
    }

    setLoading(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setValue(e.target.result.toString());
      };
      reader.readAsText(file);
    }
  };

  const saveCategories = (newCategories) => {
    setCluster({ ...cluster, category: newCategories });
  };

  return (
    <>
      <Modal
        title={$t("Importeer Cluster")}
        open={isModal}
        onCancel={!loading && closeModal}
        footer={[
          <Button
            key="opslaan"
            disabled={!value || error || !cluster}
            onClick={startImport}
            type="primary"
            loading={loading}
          >
            {$t("Importeer")}
          </Button>,
          <SluitButton key="sluit" disabled={loading} onClick={closeModal} />,
        ]}
      >
        <p className="bold" style={{ marginBottom: 6, marginTop: 24 }}>
          1. {$t("Selecteer bestand")}:
        </p>

        <Alert
          style={{ margin: "6px 0" }}
          type="info"
          showIcon
          message={
            <p>
              {$t(
                "Alleen bestanden die uit de export van de ZweefApp komen kunnen worden geïmporteerd."
              )}{" "}
              {$t("Zie link voor de gedeelde folder")}: <SharedDTOgooglelink />
            </p>
          }
        />

        <Form form={form}>
          <Form.Item
            name="file"
            rules={[{ required: false }]}
            onChange={handleFileChange}
          >
            <InputFileSingle accept=".txt" />
          </Form.Item>
        </Form>

        <p className="bold" style={{ marginBottom: 6, marginTop: 24 }}>
          2. {$t("Bekijk de data")}
        </p>

        {error && (
          <Alert
            type="warning"
            message={$t(
              "Bestand is niet correct, zorg voor een goed import bestand."
            )}
          />
        )}

        {cluster && (
          <Descriptions size="small" column={1} layout="horizontal">
            <Descriptions.Item label={$t("Naam")}>
              <Input
                type="text"
                value={cluster.name}
                onChange={(e) =>
                  setCluster({
                    ...cluster,
                    name: e.target.value.substring(0, 99),
                  })
                }
              />
            </Descriptions.Item>
            <Descriptions.Item label={$t("Curriculum versie")}>
              {cluster.curriculum_versie}
            </Descriptions.Item>
          </Descriptions>
        )}

        {cluster && (
          <ClusterChecklist
            categories={cluster?.category || []}
            saveCategories={saveCategories}
          />
        )}
      </Modal>
    </>
  );
}
