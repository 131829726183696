import React from "react";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { Button, Menu } from "antd";
import { $t } from "~/i18n";

export function MobileMenu({
  renderHeadless = false,
  showCheckDataButton = false,
  triggerShowCheckModal = () => {},
  profile = {},
  club = {},
}) {
  return (
    <Menu
      className={`mobileMenu ${renderHeadless ? "headlessMobileMenu" : "blueBackground"}`}
      triggerSubMenuAction="click"
      mode="horizontal"
    >
      <Menu.SubMenu key="mobile" icon={<MenuOutlined />} popupOffset={[-10, 0]}>
        {profile.vliegt && showCheckDataButton && (
          <Menu.Item key="hide_werkbijmobile" style={{ margin: 12 }}>
            <Button onClick={triggerShowCheckModal}>
              {$t("Werk gegevens bij")}
            </Button>
          </Menu.Item>
        )}

        <Menu.Divider />

        {(profile.vliegt || profile.iedereen_aanmelden) && (
          <Menu.Item key="register">
            <Link to="/register">{$t("Aanmeldlijst")}</Link>
          </Menu.Item>
        )}

        {profile.vliegt && (
          <Menu.Item key="reservations">
            <Link to="/reservations">{$t("Reserveren")}</Link>
          </Menu.Item>
        )}

        {(profile.vliegt || profile.wijzig_start) && (
          <Menu.Item key="start">
            <Link to="/start">{$t("Startadministratie")}</Link>
          </Menu.Item>
        )}
        {profile.wijzig_sleep && (
          <Menu.Item key="tow">
            <Link to="/tow">{$t("Sleepadministratie")}</Link>
          </Menu.Item>
        )}
        {profile.ziet_dto && (
          <Menu.Item key="dailyreport">
            <Link to="/dailyreport">{$t("Dagverslagen")}</Link>
          </Menu.Item>
        )}
        {profile.vliegt && (
          <Menu.Item key="rosters">
            <Link to="/rosters">{$t("Roosters")}</Link>
          </Menu.Item>
        )}

        <Menu.Divider />

        {(profile.vliegt || profile.iedereen_aanmelden) && (
          <Menu.Item key="material">
            <Link to="/material">{$t("Materieel")}</Link>
          </Menu.Item>
        )}
        {(profile.vliegt || profile.iedereen_aanmelden) && (
          <Menu.Item key="report">
            <Link to="/report">{$t("Meldingen")}</Link>
          </Menu.Item>
        )}
        {(profile.wijzig_winteruren || profile.goedkeuren_uren) && (
          <Menu.Item key="maintenancehours">
            <Link to="/maintenancehours">{$t("Onderhoudsuren")}</Link>
          </Menu.Item>
        )}

        <Menu.Divider />
        <Menu.Item key="members">
          <Link to="/members">{$t("Ledenlijst")}</Link>
        </Menu.Item>
        {profile.ziet_dto && (
          <Menu.Item key="dto">
            <Link to="/dto">{$t("DTO lijst")}</Link>
          </Menu.Item>
        )}
        {profile.ziet_emaillijst && (
          <Menu.Item key="email">
            <Link to="/email">{$t("Email")}</Link>
          </Menu.Item>
        )}

        <Menu.Item key="documents">
          <Link to="/documents">{$t("Documenten")}</Link>
        </Menu.Item>

        {profile.wijzig_findings && (
          <Menu.Item key="findings">
            <Link to="/findings">{$t("Bevindingen")}</Link>
          </Menu.Item>
        )}

        {profile.ziet_staff && (
          <>
            <Menu.Divider />

            <Menu.Item key="flights">
              <Link to="/admin/flights">{$t("Vluchten")}</Link>
            </Menu.Item>

            {profile.wijzig_leden && (
              <Menu.Item key="users">
                <Link to="/admin/users">{$t("Gebruikers")}</Link>
              </Menu.Item>
            )}
            {profile.wijzig_leden && (
              <Menu.Item key="groups">
                <Link to="/admin/groups">{$t("Groepen")}</Link>
              </Menu.Item>
            )}
            {profile.wijzig_dto && (
              <Menu.Item key="dto_settings">
                <Link to="/admin/dto_settings">{$t("DTO Settings")}</Link>
              </Menu.Item>
            )}

            <Menu.Item key="settings">
              <Link to="/admin/settings">{$t("Club Settings")}</Link>
            </Menu.Item>
          </>
        )}

        <Menu.Divider />

        {club && club.voorval_link && (
          <Menu.Item key="hide_voorval" style={{ margin: 12 }}>
            <Button
              target="_blank"
              rel="noopener noreferrer"
              href={club.voorval_link}
            >
              {$t("Meld voorval")}
            </Button>
          </Menu.Item>
        )}
      </Menu.SubMenu>
    </Menu>
  );
}
