import { api } from '../../lib/api'

export const getAanmeldingen = ({ dag_id, normalizeAsVliegers }) => async (dispatch) => {
  dispatch({
    type: 'AANMELDING_PENDING'
  })
  const data = await api.post('aanmeldingen/get_dag.json', { dag_id })
  data && dispatch({
    type: 'AANMELDING_SUCCESS',
    normalizeAsVliegers,
    default_vliegers: data.default_vliegers,
    dag_id,
    aanmeldingen: data.aanmeldingen ? data.aanmeldingen : [],
    notities: data.notities ? data.notities : [],
    diensten: data.diensten ? data.diensten : [],
    messages: data.messages || []
  })
  return data
}

export const saveAanmelding = (payload) => async (dispatch) => {
  const data = await api.post('aanmeldingen/save.json', payload)
  data && dispatch({
    type: 'AANMELDING_SUCCESS',
    dag_id: payload.dag_id,
    aanmeldingen: data.aanmeldingen ? data.aanmeldingen : [],
    notities: data.notities ? data.notities : [],
    diensten: data.diensten ? data.diensten : [],
    messages: data.messages || []
  })
  data && dispatch({
    type: 'UPDATE_MIJN_AANMELDINGEN',
    dag_id: payload.dag_id,
    aanmelding: payload.action === 'meld_af' ? null : 1
  })

  payload.kist && dispatch({
    type: 'SET_PERSONAL_PREFERENCES',
    preferences: { kist_aanmelding: payload.kist }
  })
  return data
}

export const saveMessage = (payload) => async (dispatch) => {
  const data = await api.post('aanmeldingen/message.json', payload)
  data && dispatch({
    type: 'SAVE_AANMELD_MESSAGE',
    action: payload.action,
    message: data
  })
  return data
}
