import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMedia from "use-media";
import { updateIfNeeded } from "~/lib/helpers.js";
import { CheckDataModal } from "~/pages/profile/checkDataModal.jsx";
import { persistActions } from "../../redux/actions.js";
import { Loader } from "../loader/loader.jsx";
import { AppHeader } from "./components/AppHeader.jsx";
import { LargeMenu } from "./components/LargeMenu.jsx";
import { MobileMenu } from "./components/MobileMenu.jsx";
import "./layout.css";
import { Termsmodal } from "./terms/terms.jsx";
import { VersionPopup } from "./version.popup.jsx";

const { Sider, Content } = Layout;

export function MainLayout({
  children,
  style = {},
  renderHeadless,
  isLoading,
}) {
  const dispatch = useDispatch();

  const persist = useSelector((state) => state.persist);
  const profileAction = (d) => dispatch(persistActions.profileAction(d));
  const checkShown = (d) => dispatch(persistActions.checkShown(d));

  // get last profile action
  useEffect(() => {
    persist.isAuthenticated &&
      updateIfNeeded(persist.lastUpdated, profileAction);
  }, [persist.lastUpdated, profileAction]);

  const [showCheckDataModal, setShowCheckDataModal] = useState(null);
  const triggerShowCheckModal = (newState) => {
    checkShown({ save: false });
    setShowCheckDataModal(newState);
  };

  useEffect(() => {
    // force show modal if last done < 48 hours ago
    if (
      persist.profile.check_required &&
      persist.check_last_shown &&
      new Date() - new Date(persist.check_last_shown) > 60000 * 60 * 48
    ) {
      setShowCheckDataModal(persist.profile.check_required);
    }
  }, [persist.profile.check_required, persist.check_last_shown, isLoading]);

  const isNarrow = useMedia({ maxWidth: "1000px" }, true);
  const showMobileMenu = isNarrow || renderHeadless;

  return (
    <Layout className="Layout" style={{ minHeight: "100vh" }}>
      <Termsmodal profile={persist.profile} profileAction={profileAction} />

      {renderHeadless && (
        <>
          {!isLoading &&
            MobileMenu({
              renderHeadless: true,
              showCheckDataButton: persist.profile.check_required,
              profile: persist.profile,
              club: persist.club,
            })}
        </>
      )}

      {!renderHeadless && (
        <AppHeader triggerShowCheckModal={() => setShowCheckDataModal(true)} />
      )}

      <Layout>
        {!showMobileMenu && (
          <>
            <Sider
              width={200}
              style={{
                top: 64,
                zIndex: 1,
                overflowX: "none",
                overflowY: "auto",
                height: "calc(100vh - 64px)",
                position: "fixed",
                left: 0,
                backgroundColor: "#edf4fb",
              }}
            >
              <LargeMenu
                triggerShowCheckModal={() => setShowCheckDataModal(true)}
                showCheckDataButton={persist.profile.check_required}
                profile={persist.profile}
                club={persist.club}
              />

              <div className="bottomBackgroundElement" />
              <VersionPopup>
                <div
                  className={`logoMenuBar ${
                    window.isZweef() ? "ZweefLogo" : "GlideLogo"
                  } `}
                />
              </VersionPopup>
            </Sider>
          </>
        )}

        <Content className="site-layout-background">
          <CheckDataModal
            persist={persist}
            modalVisible={
              !!(showCheckDataModal && persist.profile.date_gdpr_confirmed)
            }
            closeModal={() => triggerShowCheckModal(null)}
            profileAction={profileAction}
            checkShown={checkShown}
          />

          <div
            className=""
            style={{
              marginTop: renderHeadless ? 0 : 64,
              marginLeft: showMobileMenu ? 0 : 200,
              padding: renderHeadless ? 10 : "10px 10px 100px 10px",
              // width: `calc(100vw - ${showMobileMenu ? 10 : 210}px)`
              ...style,
            }}
          >
            {isLoading ? (
              <div className="loaderLayout">
                <Loader />
              </div>
            ) : (
              children
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
