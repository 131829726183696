import React from 'react'
import { $t } from '~/i18n.js'

import { Form, Input } from 'antd'

import { CardBase } from '../../components/timeLine/cardBase'

export const CardAdmin = ({ data, saveAndNext, prev }) => {
  const [form] = Form.useForm()

  const handleSave = async () => {
    try {
      const values = await form.validateFields()
      saveAndNext(values)
    } catch (info) {
      console.log('validation failed: ', info)
    }
  }

  return (

    <CardBase prev={prev} next={handleSave} minHeight={300}>

      <Form
        style={{ maxWidth: 400, margin: '0 auto' }}
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name='club'
        preserve={false}
        requiredMark={false}
        initialValues={data}
      >

        <p style={{ marginBottom: 24 }}>{$t('Voeg nu je eerste gebruiker toe. Dit is de club admin, en heeft de rechten om andere gebruikers te beheren.')}</p>

        <Form.Item
          label={$t('Voornaam')}
          autoFocus
          name='first_name'
          rules={[{ required: true, message: $t('Voeg een naam toe') }]}
        >
          <Input placeholder='' />
        </Form.Item>

        <Form.Item
          label={$t('Achternaam')}
          name='last_name'
          rules={[{ required: true, message: $t('Voeg een naam toe') }]}
        >
          <Input placeholder='' />
        </Form.Item>

        <Form.Item
          label={$t('Email')}
          autoFocus
          name='email'
          rules={[{ required: true, type: 'email' }]}
        >
          <Input type='email' placeholder='Emailadres' />
        </Form.Item>

      </Form>

    </CardBase>
  )
}
