import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { Button, Form, Input, Modal, Popconfirm, Select } from 'antd'
import { AnnuleerButton } from '~/components/buttons/AnnuleerButton.jsx'

export const ChapterModal = ({ closeModal, chapter, saveChapter, tab, allTabs }) => {
  const [form] = Form.useForm()

  React.useEffect(() => {
    if (chapter) form.setFieldsValue(chapter)
    return () => form.resetFields()
  }, [chapter, form])

  const [saving, setSaving] = useState(null)

  const deleteChapter = async () => {
    setSaving('delete')
    const result = await saveChapter({ action: 'delete', id: chapter.id })
    result && closeModal()
    setSaving(null)
  }

  const triggerSave = async () => {
    try {
      setSaving('save')
      const values = await form.validateFields()
      const result = await saveChapter({ ...chapter, ...values })
      if (result) {
        closeModal()
        form.resetFields()
      }
      setSaving(null)
    } catch (info) {
      console.log('Validate Failed:', info)
      setSaving(null)
    }
  }

  const footer = [
    <AnnuleerButton  key="annuleren" onClick={closeModal} />,
    <Button type='primary' key='ok' onClick={triggerSave} loading={saving === 'save'}>
      {$t('Opslaan')}
    </Button>]

  return (
    <Modal
      title={chapter && chapter.id ? $t('Pas hoofdstuk aan') : $t('Voeg nieuw hoofdstuk toe')}
      open={!!chapter}
      onCancel={closeModal}
      okText={$t('Opslaan')}
      cancelText={$t('Annuleren')}

      footer={chapter && chapter.id
        ? [<Popconfirm
            placement='bottom' title={$t('Zeker weten dat je alle documenten hieronder wilt verwijderen?')} key='delete'
            onConfirm={deleteChapter}
            okText={$t('Ja')} cancelText={$t('Nee')}
           >
          <Button danger style={{ marginRight: 32 }} loading={saving === 'delete'}>
          {$t('Verwijder')}
          </Button>
        </Popconfirm>].concat(footer)
        : footer}
    >

      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name='hoofstuk'
        preserve={false}
        requiredMark={false}
        initialValues={{ ...chapter, tab_id: tab.id }}
      >

        <Form.Item
          label={$t('Naam')}
          name='name'
          rules={[
            { required: true, max: 50, message: $t('Maximaal ${c} characters', { c: 50 }) }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={$t('Positie')} name='order'>
          <Input
            type='number'
            prefix='#'
          />
        </Form.Item>

        <Form.Item
          label={$t('Tab')}
          name='tab_id'
        >
          <Select mode='single'>
            {allTabs.map((tab, key) => <Select.Option value={tab.id} key={key}>{tab.name}</Select.Option>)}
          </Select>
        </Form.Item>

      </Form>

    </Modal>
  )
}
