export const setCookie = (name, value) => {
  // construct cookie with expiration date and domain
  // function to set cookie value
  // console.log('>>> setting cookie: ', `${name}=${value};path=/;SameSite=Strict;max-age=3600000;domain=${window.location.hostname}`)
  let cookieValue = `${name}=${value};path=/;SameSite=Strict;max-age=3600000;domain=${window.location.hostname}`

  if (window.location?.hostname) {
    cookieValue = cookieValue + `;domain=${window.location.hostname}`
  }
  document.cookie = cookieValue
}

export const getCookie = (name) => {
  // gets a cookie name from stored cookie
  let value = null
  document.cookie.split(';').some((cookie) => {
    const split = cookie.split('=')
    if (split[0].trim() === name) {
      value = decodeURI(split[1])
      return true
    }
  })
  return value
}

export const getLocalStorage = (name) => {
  try {
    return localStorage.getItem(name)
  } catch (e) {
    console.log(`getting Localstorage does not work for ${name}: ${e}`)
  }
}

export const setLocalStorage = (name, value) => {
  try {
    localStorage.setItem(name, value)
  } catch (e) {
    console.log(`setting Localstorage does not work in setting ${name}: ${e}`)
  }
}
