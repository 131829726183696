import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

export const InputFileSingle = ({ accept="image/png, image/jpeg", id = "", value = "", onChange = (e) => {} }) => {
  return (
    <Upload
      name="file"
      listType="picture"
      accept={accept}
      multiple={false}
      maxCount={1}
      beforeUpload={(file) => false}
      onChange={(data) => {
        onChange(
          data.fileList.length > 0 ? data.fileList[0].originFileObj : null
        );
      }}
    >
      <Button icon={<UploadOutlined />}>{$t("Selecteer bestand")}</Button>
    </Upload>
  );
};
