import { PrinterOutlined } from '@ant-design/icons'
import { Button, Switch, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { $t } from '~/i18n.js'

import { updateIfNeeded } from '../../../lib/helpers'
import { toLocaleDate } from '../../../lib/localize'
import { aanmeldingenActions, daysActions, flightsActions } from '../../../redux/actions'

import { Loader } from '../../../components/loader/loader'
import { PrintRow } from './print.row'

export const PrintAanmeldLijstComponent = ({ match, aanmeldingen, flights, days, getBaseData, getAanmeldingen, getAllDays, openDay }) => {
  const { club } = useSelector((state) => state.persist)

  // setup base data
  useEffect(() => {
    updateIfNeeded(flights.lastUpdatedBaseData, getBaseData)
  }, [flights.lastUpdatedBaseData, getBaseData])
  useEffect(() => {
    updateIfNeeded(days.lastUpdated, getAllDays, () => days.lastUpdated && openDay(match.params.id))
  }, [days.lastUpdated, getAllDays, match.params.id, openDay])
  useEffect(() => {
    getAanmeldingen({ dag_id: match.params.id })
  }, [match.params.id, getAanmeldingen])

  // // concat all unique types
  const types = flights.club_kisten.reduce((all, kist) => {
    if (!all[kist.type]) all[kist.type] = kist.type_group
    return all
  }, {})

  const lijst_aanmeldingen = aanmeldingen.aanmeldingen.filter((a) => a.aangemeld)
  lijst_aanmeldingen.sort((a, b) => a.order - b.order)

  const [sorted, setSorted] = useState(true)

  const gast_vliegers_lijst = []
  const dbo_lijst = []
  const solo_lijst = []
  const brevet_lijst = []
  const prive_lijst = []

  let squeeze_left = false
  let squeeze_right = false

  if (sorted) {
    lijst_aanmeldingen.forEach((v) => {
      if (v.vlieger && v.vlieger.group_keys && v.vlieger.group_keys?.includes('gastvlieger')) {
        gast_vliegers_lijst.push(v)
      } else if (v.vlieger && v.vlieger.group_keys && v.vlieger.group_keys?.includes('solist')) {
        solo_lijst.push(v)
      } else if (v.vlieger && v.vlieger.group_keys && v.vlieger.group_keys?.includes('dbo')) {
        dbo_lijst.push(v)
      } else if (v.kist && v.kist.includes('privé')) {
        prive_lijst.push(v)
      } else {
        brevet_lijst.push(v)
      }
    })

    // determine squeeze left/right
    squeeze_left = (dbo_lijst.length + solo_lijst.length + gast_vliegers_lijst.length) > 15
    squeeze_right = (brevet_lijst.length + prive_lijst.length) > 15
  }

  if (days.activeDay.datum && document) document.title = `Aanmeldlijst_${days.activeDay.datum}`

  if (!days.activeDay.datum || flights.isPending || days.isPending) return <Loader />

  return (
    <div className='AanmeldPrintLijst'>

      <div className='row spacer' style={{ alignItems: 'center', position: 'absolute', marginLeft: 12 }}>
        {!days.activeDay.gaat_door && <Tag style={{ marginRight: 6 }} color='red'>{$t('Geannuleerd')}</Tag>}
        <p><span className='bold'>{toLocaleDate(new Date(days.activeDay.datum), {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        })} - {days.activeDay.type_dag}{days.activeDay.titel && days.activeDay.titel.length > 0 && (': ' + days.activeDay.titel)}
        </span>
          <br />{$t('briefing om')} {days.activeDay.briefing_tijd || '??'} {$t('vanaf')} {days.activeDay.vertrek_vliegveld}, {lijst_aanmeldingen.length} {$t('aanmeldingen')}
        </p>

        <div className='row' style={{ alignItems: 'center', marginLeft: 24 }}>

          <p className='noprint small' style={{ marginRight: 12 }}>{$t('Gesorteerd')}: <Switch size='small' checked={sorted} onChange={(checked) => setSorted(checked)} /></p>

          <Button className='noprint' type='primary' icon={<PrinterOutlined />} onClick={() => window.print()}>
            {$t('Print')}
          </Button>
        </div>

      </div>

      {!sorted && (
        <div className='column' style={{ alignItems: 'flex-start' }}>

          <div style={{ flex: '50%', marginTop: 65 }}>
            {lijst_aanmeldingen.map((aanmelding, key) => PrintRow(club.groups_aanmeld, aanmelding, key, types, days.activeDay.briefing_tijd, lijst_aanmeldingen.length > 30))}
          </div>

        </div>
      )}

      {sorted && (
        <div className='row' style={{ alignItems: 'flex-start' }}>

          <div style={{ flex: '50%', marginTop: 65 }}>
            {dbo_lijst.length > 0 && <div>
              {dbo_lijst.map((aanmelding, key) => PrintRow(club.groups_aanmeld, aanmelding, key, types, days.activeDay.briefing_tijd, squeeze_left))}
            </div>}
            {solo_lijst.length > 0 && <div className='printList'>
              {solo_lijst.map((aanmelding, key) => PrintRow(club.groups_aanmeld, aanmelding, key, types, days.activeDay.briefing_tijd, squeeze_left))}
            </div>}
            {gast_vliegers_lijst.length > 0 && <div className='printList'>
              {gast_vliegers_lijst.map((aanmelding, key) => PrintRow(club.groups_aanmeld, aanmelding, key, types, days.activeDay.briefing_tijd, squeeze_left))}
            </div>}
          </div>

          <div style={{ flex: '50%', marginTop: brevet_lijst.length > 15 ? 10 : 65 }}>
            <div>
              {brevet_lijst.map((aanmelding, key) => PrintRow(club.groups_aanmeld, aanmelding, key, types, days.activeDay.briefing_tijd, squeeze_right))}
            </div>
            {prive_lijst.length > 0 && <div className='printList'>
              {prive_lijst.map((aanmelding, key) => PrintRow(club.groups_aanmeld, aanmelding, key, types, days.activeDay.briefing_tijd, true, true))}
            </div>}
          </div>

        </div>
      )}

    </div>
  )
}

const mapStateToProps = (state) => ({
  aanmeldingen: state.aanmeldingen,
  days: state.days,
  flights: state.flights,
  profile: state.persist.profile,
  lastUpdated: state.materiaal.lastUpdated

})

const mapDispatchToProps = (dispatch) => {
  return {
    getBaseData: (d) => dispatch(flightsActions.getBaseData(d)),
    openDay: (d) => dispatch(daysActions.openDay(d)),
    getAanmeldingen: (d) => dispatch(aanmeldingenActions.getAanmeldingen(d)),
    getAllDays: (d) => dispatch(daysActions.getAllDays(d))
  }
}

export const PrintAanmeldLijstPagina = connect(mapStateToProps, mapDispatchToProps)(PrintAanmeldLijstComponent)
