import { $t } from '~/i18n.js'
import React, { useEffect, useState } from 'react'

import { Loader } from '../../components/loader/loader'
import { api } from '../../lib/api'
import { config } from '../../config'

export const DocumentShortcutPage = ({ match }) => {
  const [error, setError] = useState(null)

  // get documents
  useEffect(() => {
    api.post('documents/view_document.json', { uuid: match.params.uuid }).then((data) => {
      if (data && data.file) {
        window.location.assign(config.media_prefix + data.file)
      } else {
        setError($t('Deze link is ongeldig'))
      }
    })
  }, [match.params.uuid])

  return (
    <div style={{ width: '95vw', height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {error ? <p>{error}</p> : <Loader />}
    </div>
  )
}
