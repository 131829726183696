import { onderhoudState } from './onderhoud.state'
import { normalizePeriod } from './onderhoud.normalizers'

export const onderhoudReducer = (state = onderhoudState, action) => {
  switch (action.type) {
    case 'ONDERHOUD_PENDING':
      return {
        ...state,
        isPending: true
      }

    case 'ONDERHOUD_DONE':
      return {
        ...state,
        isPending: false
      }

    case 'ONDERHOUD_SET_PERIOD':
      return {
        ...state,
        isPending: false,
        lastUpdated: new Date(),
        periods: action.periods,
        current_period: normalizePeriod(action.current_period)
      }

    case 'SET_MIJN_ONDERHOUD':
      return {
        ...state,
        isPending: false,
        lastUpdatedMijnVerplichting: new Date(),
        verplichting: { ...action.verplichting, period: action.period },
        registraties: action.registraties
      }

    case 'SET_ONDERHOUD_REGISTRATIES':
      if (action.is_current) {
        return {
          ...state,
          current_period: normalizePeriod({ ...state.current_period, registraties: action.registraties })
        }
      } else {
        return {
          ...state,
          registraties: action.registraties
        }
      }

    case 'RESET_ONDERHOUD_STATE':
      return {
        ...onderhoudState
      }

    default:
      return state
  }
}
