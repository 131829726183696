import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { $t } from "~/i18n.js";

import { PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Input, Table } from "antd";

import { BreadcrumbHeader } from "~/components/layout/breadcrumbHeader.jsx";
import { MainLayout } from "~/components/layout/layout.jsx";
import { defaultTableConfig } from "~/components/layout/table.jsx";
import { api } from "~/lib/api.js";
import { updateIfNeeded } from "~/lib/helpers.js";
import { _parseDate, toLocaleDate } from "~/lib/localize.js";
import { adminActions } from "~/redux/actions.js";
import { AddFindingsModal } from "./addFindingModal.jsx";

const columns = () => [
  {
    title: $t("Datum"),
    dataIndex: "date_created",
    render: (d) => _parseDate(d),
  },
  { title: $t("Titel"), dataIndex: "title" },
  {
    title: $t("Status"),
    dataIndex: "status",
    defaultFilteredValue: ["aangemeld"],
    filters: [
      { text: $t("aangemeld"), value: "aangemeld" },
      { text: $t("afgerond"), value: "afgerond" },
      { text: $t("afgewezen"), value: "afgewezen" },
    ],
    onFilter: (value, campaign) => campaign.status === value,
  },
  {
    title: $t("Einddatum"),
    dataIndex: "date_expected_completion",
    render: (d) => _parseDate(d),
  },
  {
    title: $t("Categorie"),
    dataIndex: "category",
    filters: [
      { text: $t("intern"), value: "intern" },
      { text: $t("extern"), value: "extern" },
    ],
    onFilter: (value, campaign) => campaign.category === value,
  },
  { title: $t("Afdeling"), dataIndex: "department" },
  { title: $t("Eigenaar"), dataIndex: "owner_name" },
];

const FindingsPageComponent = ({ admin, getFindings, history }) => {
  // get campaigns
  useEffect(() => {
    updateIfNeeded(admin.findingsUpdated, getFindings);
  }, [admin.findingsUpdated, getFindings]);

  const [search, setSearch] = useState("");
  const [loadingFile, setLoadingFile] = useState(false);
  const [findings, setFindings] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  useEffect(() => {
    let findings = admin.findings;
    if (search) {
      const searchText = search.toLowerCase();
      findings = findings.filter(
        (c) => c.title.toLowerCase().indexOf(searchText) > -1
      );
    }
    setFindings(findings);
  }, [search, admin.findings]);

  return (
    <>
      <MainLayout history={history} isLoading={admin.isPending}>
        {openAddModal && (
          <AddFindingsModal
            visible
            closeModal={() => setOpenAddModal(false)}
            activeFinding={{}}
          />
        )}

        <BreadcrumbHeader
          breadcrumbs={[$t("Bevindingen")]}
          buttons={[
            <Button
              key='1'
              type="primary"
              style={{ marginRight: 6 }}
              icon={<PlusOutlined />}
              onClick={() => setOpenAddModal(true)}
            >
              {$t("Voeg toe")}
            </Button>,
            <Button
              key='2'
              icon={<PrinterOutlined />}
              loading={loadingFile}
              onClick={async () => {
                setLoadingFile(true);
                await api.open_file(
                  "findings/export.json",
                  `findings ${toLocaleDate(new Date())}.xlsx`
                );
                setLoadingFile(false);
              }}
            >
              {$t("Exporteer")}
            </Button>,
          ]}
        />

        <Input.Search
          style={{ maxWidth: 250 }}
          value={search}
          allowClear
          size="small"
          placeholder={$t("Zoek")}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />

        <Table
          style={{ marginTop: 12, width: "95vw", cursor: "pointer" }}
          pagination={{ ...defaultTableConfig, defaultPageSize: 100 }}
          size="small"
          rowKey="id"
          dataSource={findings}
          columns={columns()}
          onRow={(finding) => {
            return {
              onClick: () => history.push(`/findings/${finding.id}`),
            };
          }}
        />
      </MainLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  persist: state.persist,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getFindings: (d) => dispatch(adminActions.getFindings(d)),
  };
};

export const FindingsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(FindingsPageComponent);
