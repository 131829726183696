import React from 'react'
import { $t } from '~/i18n.js'

import { ClockCircleOutlined, EyeTwoTone, SoundTwoTone } from '@ant-design/icons'
import { Tag, Tooltip } from 'antd'

import { HelpBubble } from '../../components/help/help.bubble.jsx'
import { StartIcon } from '../../components/icon/icon.jsx'
import { parseHoursLong } from '../../lib/localize.js'
import { colors } from '../../theme/colors.js'

const lookup = {
  aangemeld: 'processing', // $t('aangemeld')
  opgepakt: 'orange', // $t('opgepakt')
  afgerond: 'default', // $t('afgerond')
  afgewezen: 'default', // $t('afgewezen')
  uitgesteld: 'cyan', // $t('uitgesteld')
  nvt: 'default', // $t('nvt')

  'in bedrijf': 'blue', // $t('in bedrijf')
  'let op': 'magenta', // $t('let op')
  'niet inzetbaar': 'error' // $t('niet inzetbaar')
}

export const MeldingStatusTag = ({ status, workorder_id }) => {
  status = status || 'nvt'
  if (workorder_id && status !== 'afgewezen' && status !== 'afgerond') status = 'opgepakt'
  return (
    <Tag color={lookup[status]}>
      {$t(status)}
    </Tag>
  )
}

const maintenance_lookup = {
  red: 'Verlopen', // $t("Verlopen")
  yellow: 'Bijna verlopen', // $t("Bijna verlopen")
  green: 'OK' // $t("OK")
}

export const MaintenanceStatusTag = ({ maintenance_status, default_value, hideTag }) => {
  if (!maintenance_status) return default_value || ''
  return (
    <div className='currency row'>
      <div className='icon' style={{ backgroundColor: colors[maintenance_status], position: 'relative' }} />
      {!hideTag && <p className='small'>{$t(maintenance_lookup[maintenance_status])}</p>}
    </div>
  )
}

export const MeldingCategoryTag = ({ category }) => {
  if (category === 'defect') return <Tag color='error'>{$t('defect')}</Tag>
  if (category === 'klacht') return <Tag color='warning'>{$t('klacht')}</Tag>
  if (category === 'visueel') return <EyeTwoTone twoToneColor='blue' />
  if (category === 'algemeen') return <SoundTwoTone twoToneColor='green' />
  return <Tag color='default'>{$t(category) || 'nvt'}</Tag>
}

const _melding_beschrijving = {
  // melding type help by is_vliegend and melding category
  true: {
    // voor vliegend:
    defect: 'Een defect beinvloed de luchtwaardigheid of de minimum equipment list. Na het melden kan materiaal niet meer worden ingezet.', // $t("Een defect beinvloed de luchtwaardigheid of de minimum equipment list. Na het melden kan materiaal niet meer worden ingezet.")
    klacht: 'Een klacht is een melding of opmerking over de vliegprestatie die je verholpen wilt hebben, maar in géén geval beinvloed dit de luchtwaardigheid.', // $t("Een klacht is een melding of opmerking over de vliegprestatie die je verholpen wilt hebben, maar in géén geval beinvloed dit de luchtwaardigheid.")
    visueel: 'Een visuele melding is een kras of deuk die niet (direct) zal worden verholpen, in géén geval beinvloed dit de luchtwaardigheid.', // $t("Een visuele melding is een kras of deuk die niet (direct) zal worden verholpen, in géén geval beinvloed dit de luchtwaardigheid.")
    algemeen: 'Een algemene opmerking rondom het gebruik van de kist, dient als doel om medevliegers te informeren.' // $t("Een algemene opmerking rondom het gebruik van de kist, dient als doel om medevliegers te informeren.")
  },
  false: {
    // voor rollend en overig materiaal
    defect: 'Een defect heeft invloed op het gebruik van het materiaal. Na het melden kan materiaal niet meer worden ingezet.', // $t("Een defect heeft invloed op het gebruik van het materiaal. Na het melden kan materiaal niet meer worden ingezet.")
    klacht: 'Een klacht is een melding die je verholpen wilt hebben, maar in géén geval beinvloed dit de beschikbaarheid van het materiaal.', // $t("Een klacht is een melding die je verholpen wilt hebben, maar in géén geval beinvloed dit de beschikbaarheid van het materiaal.")
    visueel: 'Een visuele melding is een kras of deuk die niet (direct) zal worden verholpen, je kan het materiaal blijven inzetten.', // $t("Een visuele melding is een kras of deuk die niet (direct) zal worden verholpen, je kan het materiaal blijven inzetten.")
    algemeen: 'Een algemene opmerking rondom het gebruik van het materiaal, dient als doel om medegebruikers te informeren.' // $t("Een algemene opmerking rondom het gebruik van het materiaal, dient als doel om medegebruikers te informeren.")
  }
}

export const MeldingCategoryBeschrijving = (isVliegend, category) => $t(_melding_beschrijving[!!isVliegend][category])

export const MeldingCategoryLine = ({ isVliegend, category }) => {
  return (
    <p>
      <MeldingCategoryTag category={category} />
      <span style={{ marginLeft: 6 }}>{MeldingCategoryBeschrijving(isVliegend, category)}</span>
      {isVliegend && category === 'defect' && <HelpBubble
        title={$t('Defect')} content={
          <div>
            <p>
              {$t('Een gemeld defect beinvloed in de basis altijd de inzetbaarheid van het materiaal. Een defect kan een technicus')} <span className='bold'>{$t('uitstellen')}</span> {$t('als de defect niet de luchtwaardigheid van het materiaal beinvloed of niet een component omvat  dat op de minimum equipment list staat.')}
            </p>
            <p>
              {$t('Indien een defect is uitgesteld of opgelost is het materiaal weer inzetbaar.')}
            </p>
          </div>
    }
                                              />}
    </p>
  )
}

export const InFlightService = ({ element }) => <div className='row' style={{ gap: 12, flexWrap: 'wrap' }}>
  {element.total_starts > 0 && <span> <StartIcon /> {element.total_starts.toLocaleString('nl')}</span>}
  {element.total_minutes > 0 && <span><ClockCircleOutlined /> {parseHoursLong(element.total_minutes)}</span>}
  {element.total_motor_minutes > 0 &&
    <Tooltip placement='right' title={$t('Motortijd')}>
      <span><span className='bold'>M</span> {parseHoursLong(element.total_motor_minutes, 1)}</span>
    </Tooltip>}
                                                </div>
