import { CheckCircleFilled } from '@ant-design/icons'
import { Modal } from 'antd'
import React from 'react'
import { $t } from '~/i18n.js'
import { _parseDateTime, parseMinutesLong } from '../../../../lib/localize'
import { colors } from '../../../../theme/colors'

export const Inspection = ({ inspection }) => {
  if (!inspection) return null

  const showInspection = () => Modal.success({
    title: $t('Inspectie voltooid'),
    content: <div>
      <p>{_parseDateTime(inspection.date_created)}, {inspection.user_name}</p>
      {inspection.total_motor_minutes && <p><span className='bold'>M</span> {parseMinutesLong(inspection.total_motor_minutes)}</p>}
    </div>

  })

  return <p className='clickable small' onClick={showInspection}><CheckCircleFilled style={{ marginRight: 6, color: colors.green, fontSize: 19 }} /> {$t('OK')} </p>
}
