import { CloseOutlined, PlusOutlined, StopOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Form, Input, Modal, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import {
  permissions,
  translateUserPermission,
} from "../../users/components/translateUserPermission.jsx";

export const GroupForm = ({
  history,
  onSave = () => {},
  updateGroup = (g) => {},
  group = {},
}) => {
  const [form] = Form.useForm();

  const [openModal, setOpenModal] = useState(true);
  const closeModal = () => {
    setOpenModal(false);
    history.replace("/admin/groups");
  };

  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    setOpenModal(true);
    setSelectedGroup(group);
  }, [group]);

  const [loading, setLoading] = useState(false);
  const saveForm = async () => {
    const values = await form.validateFields();
    setLoading(true);

    const payload = {
      ...values,
      id: group.id,
    };

    const { response, data } = await api._call_method(
      group.isNew ? "POST" : "PATCH",
      "admin/groups.json",
      payload
    );

    if (response.ok && data) {
      closeModal();
      onSave();
    }
    setLoading(false);
  };

  const [pendingDelete, setPendingDelete] = useState(false);
  const deleteGroup = async () => {
    setPendingDelete(true);
    const { response, data } = await api._call_method(
      "DELETE",
      "admin/groups.json",
      { id: group.id }
    );

    if (response.ok && data) {
      closeModal();
      onSave();
    }
    setPendingDelete(false);
  };

  const [loadingPermission, setloadingPermission] = useState({});
  const savePermission = async (permission, addOrDelete = "add") => {
    const newLoadingPermission = { ...loadingPermission };
    newLoadingPermission[permission] = addOrDelete;
    setloadingPermission(newLoadingPermission);
    const { data } = await api._call_method(
      addOrDelete == "add" ? "POST" : "DELETE",
      "admin/group_permissions.json",
      { group_id: group.id, permission }
    );
    if (data) {
      setSelectedGroup(data);
      updateGroup(data);
    }
    newLoadingPermission[permission] = "";
    setloadingPermission({ ...newLoadingPermission });
  };
  const removeFromGroup = async (permission) => {
    await savePermission(permission, "delete");
  };

  const addToGroup = async (permission) => {
    await savePermission(permission, "add");
  };

  return (
    <Modal
      title={group?.isNew ? $t("Voeg groep toe") : $t("Wijzig groep")}
      open={openModal}
      onCancel={() => {
        if (!loading) {
          closeModal();
        }
      }}
      footer={[
        !group.is_default && (
          <Popconfirm
            key="remove"
            placement="bottom"
            title={$t("Zeker weten?")}
            onConfirm={deleteGroup}
            okText={$t("Ja")}
            cancelText={$t("Nee")}
          >
            <Button
              danger
              disabled={group?.isNew && !group?.is_default}
              icon={<StopOutlined />}
              style={{ marginRight: 24 }}
              loading={pendingDelete}
            >
              {$t("Verwijder")}
            </Button>
          </Popconfirm>
        ),

        <AnnuleerButton  key="annuleren" disabled={loading} onClick={closeModal} />,
        <Button
          key="opslaan"
          disabled={loading}
          loading={loading}
          onClick={saveForm}
          type="primary"
        >
          {$t("Opslaan")}
        </Button>,
      ]}
    >
      <Form
        form={form}
        initialValues={group}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="group"
        preserve={false}
        requiredMark={false}
      >
        <Form.Item label={$t("Naam")} name="name">
          <Input />
        </Form.Item>

        {group?.isNew && (
          <Form.Item
            label={$t("Key")}
            name="key"
            rules={[{ required: true, message: $t("Voeg unieke sleutel toe") }]}
          >
            <Input
              disabled={!group.isNew}
              style={{ width: "100%", maxWidth: 300 }}
            />
          </Form.Item>
        )}

        {group?.is_default && (
          <Alert
            type="warning"
            message={$t(
              "Deze groep is deel van de basis setup en kan je niet verwijderen."
            )}
          ></Alert>
        )}

        {!group?.isNew && selectedGroup && (
          <section>
            <Divider />

            <p className="bold">{$t("Toegekende permissies")}:</p>

            {selectedGroup?.permissions?.length == 0 && <p>{$t("Geen")}</p>}

            {permissions.map(
              (permission) =>
                selectedGroup?.permissions?.indexOf(permission) > -1 && (
                  <p key={permission}>
                    <Popconfirm
                      placement="bottom"
                      title={$t("Zeker weten?")}
                      onConfirm={() => removeFromGroup(permission)}
                      okText={$t("Ja")}
                      cancelText={$t("Nee")}
                    >
                      <Button
                        loading={loadingPermission[permission] == "delete"}
                        style={{ marginRight: 6 }}
                        shape="circle"
                        size="small"
                        icon={<CloseOutlined />}
                      />
                    </Popconfirm>

                    <span>{translateUserPermission(permission)}</span>
                  </p>
                )
            )}

            <p style={{ marginTop: 12 }} className="bold">
              {$t("Niet toegekende permissies")}:
            </p>

            {permissions.map(
              (permission) =>
                selectedGroup?.permissions?.indexOf(permission) == -1 && (
                  <p key={permission}>
                    <Button
                      loading={loadingPermission[permission] == "add"}
                      onClick={() => addToGroup(permission)}
                      style={{ marginRight: 6 }}
                      shape="circle"
                      size="small"
                      icon={<PlusOutlined />}
                    />

                    <span>{translateUserPermission(permission)}</span>
                  </p>
                )
            )}
          </section>
        )}
      </Form>
    </Modal>
  );
};
