import { $t } from '~/i18n.js'
import React from 'react'
import { Card, Tabs } from 'antd'

export const MeteoCard = ({ meteo }) => {
  return (
    <Card size='small' className='topCard' title={$t('Meteo')}>

      <Tabs>
        {meteo.map((m, key) => <Tabs.TabPane tab={m.timestamp} key={key}>
          <p className='small meteo' dangerouslySetInnerHTML={{ __html: m.message }} />

          <p className='small gray' style={{ marginTop: 6 }}>Bron: <a target='_blank' rel='noreferrer' href='https://knmi.nl/nederland-nu/luchtvaart/weerbulletin-kleine-luchtvaart'>KNMI</a></p>
        </Tabs.TabPane>)}

      </Tabs>
    </Card>
  )
}
