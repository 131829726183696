import { Divider, Modal, Result, Steps } from "antd";
import React, { useState } from "react";
import useMedia from "use-media";
import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { $t } from "~/i18n.js";
import { CardBrevet } from "./cards/card.brevet.jsx";
import { CardContact } from "./cards/card.contact.jsx";
import { CardEmergency } from "./cards/card.emergency.jsx";
import { CardLidmaatschap } from "./cards/card.lidmaatschap.jsx";
import { CardPersonalia } from "./cards/card.personalia.jsx";
import { CardStart } from "./cards/card.start.jsx";

export const CheckDataModal = ({
  persist,
  profileAction,
  modalVisible,
  closeModal,
  checkShown,
}) => {
  const [pendingSave, setPendingSave] = useState(false);
  const [step, setStep] = useState(0);

  const isWide = useMedia({ minWidth: "500px" }, true);

  const onSave = async (data) => {
    setPendingSave(true);
    // next step always if you don't save or if you want to remain (e.g. avatar saving)
    let next_step = !data || !data.remain;
    if (data) {
      const res = await profileAction(data);

      // keep to current step if saving action gave profile (e.g. correct)
      next_step = next_step && !!res.profile;
    }
    if (next_step) setStep(step + 1);
    setPendingSave(false);
  };

  const finishModal = async () => {
    setPendingSave(true);
    await checkShown({ save: true });
    closeModal();
    setPendingSave(false);
  };

  const steps = [
    {
      title: $t("Start"),
      content: (
        <CardStart
          club={persist.club}
          profile={persist.profile}
          gegevens={persist.gegevens}
          saveData={onSave}
          type="check"
          message={modalVisible}
        />
      ),
    },
    {
      title: $t("Foto"),
      content: (
        <CardPersonalia
          profile={persist.profile}
          saveData={onSave}
          type="check"
        />
      ),
    },
    {
      title: $t("Brevet"),
      content: (
        <CardBrevet
          club={persist.club}
          profile={persist.profile}
          gegevens={persist.gegevens}
          saveData={onSave}
          type="check"
        />
      ),
    },
    {
      title: $t("Contact"),
      content: (
        <CardContact
          profile={persist.profile}
          gegevens={persist.gegevens}
          saveData={onSave}
          type="check"
        />
      ),
    },
    {
      title: $t("Nood"),
      content: (
        <CardEmergency
          gegevens={persist.gegevens}
          saveData={onSave}
          type="check"
        />
      ),
    },
    persist.club &&
      persist.club.has_bank && {
        title: $t("Bank"),
        content: (
          <CardLidmaatschap
            profile={persist.profile}
            club={persist.club}
            saveData={onSave}
            type="check"
            hideLidmaatschap
          />
        ),
      },
  ];

  return (
    <Modal
      title={$t("Verifiëer je gegevens")}
      confirmLoading={pendingSave}
      open={!!modalVisible}
      onCancel={closeModal}
      footer={null}
      width="700px"
    >
      {isWide && (
        <>
          <Steps size="small" current={step}>
            {steps
              .filter((e) => e)
              .map((item) => (
                <Steps.Step key={item.title} title={item.title} />
              ))}
          </Steps>

          <Divider />
        </>
      )}

      <div className="myDataCards row" style={{ justifyContent: "center" }}>
        {steps[step]?.content}
      </div>

      {step > (persist.club && persist.club.has_bank ? 5 : 4) && (
        <Result
          status="success"
          title={$t("Dank je wel!")}
          subTitle={$t(
            "Al je gegevens zijn gecheckt en bijgewerkt, we zullen dit over 6 maanden weer van je vragen"
          )}
          extra={[<SluitButton key="sluit" onClick={finishModal} />]}
        />
      )}
    </Modal>
  );
};
