import { normalizeFlights } from '../flights/flights.normalizers'

/**
 * normalizes event data for frontend
 * @param {*} data
 * @param {*} default_vliegers
 * @returns
 */
export const normalizeVliegers = (data, default_vliegers) => {
  let output = []
  const uniqueID = {}
  data.map((single) => {
    // only map into output if ID found, in case users are forwardeddouble
    if (!uniqueID[single.id]) {
      uniqueID[single.id] = single.id
      return output.push(normalizeVliegerData(single))
    }
  })

  if (default_vliegers && default_vliegers.length > 0 && data.length > 0) {
    const def = default_vliegers.split(',').map((v, idx) => ({ id: -1 * (idx + 1), name: v, lowercase: v, last_name: '', tag: null, currency: 'nvt', group_names: [], vlieger: {}, kist: [] }))
    output = def.concat(output)
  }
  return output
}

/**
 *
 * @param {*} vliegerData
 * @returns
 */
export const normalizeVliegerData = (vliegerData = {}) => {
  // dto_training and behaalde_bevoegdheden is array, must become lookup for speed in rendering
  //    is:     [{bevoegdheid_id: 1...}, {bevoegdheid_id: 2,...})
  //    becomes {1: {bevoegdheid_id: 1...}, 2: {bevoegdheid_id: 2...}}

  const today = new Date()
  const _v = vliegerData.vlieger ? vliegerData.vlieger : vliegerData

  return {
    ...vliegerData,
    ..._v, // consist of currency, starts, uren, lier, sleep, solo, dbo, last_checks_start and tab
    ..._v.currency, // include fields on first level, e.g. id, name, lid_nummer, is_instructeur

    flights: _v.flights ? normalizeFlights(_v.flights) : [],
    vlieger: {},
    kist: [],
    key: _v.id,
    lowercase: _v.name ? _v.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '',

    // group names are the groups the vlieger is member of
    is_instructeur: _v.group_keys ? _v.group_keys?.includes('instructeur') : _v.as_instructeur,

    // optionally reduce
    behaalde_bevoegdheden: !_v.behaalde_bevoegdheden ? {} : _v.behaalde_bevoegdheden.reduce((all, item) => {

      const days_left = item.date_geldig ? Math.floor((new Date(item.date_geldig) - new Date()) / (1000 * 60 * 60 * 24)) : null
      const assigned = item.score === 5 || item.score == 'assigned'
      all[item.bevoegdheid_id] = {
        ...item,

        // assigned only if item is scored 5 of no score at all
        assigned: assigned,

        // Check number of days left for this bevoegdheid, date_geldig is in format: "2025-11-07"
        days_left: assigned && days_left,
        nearly_invalid: assigned && days_left && days_left < 31,

      }
      return all
    }, {}),

    // dto_training reduce to dictionary
    dto_training: !_v.dto_training
      ? {}
      : _v.dto_training.reduce((all, item) => {
        all[item.cluster_id] = item
        return all
      }, {})

  }
}

/**
 * dictionary with roles required for easy lookup
 * @param {*} profile
 * @returns
 */
export const getRoles = (profile) => {
  // ['a', 'b'] -> {'a': true, 'b': true}
  const roles = profile.app_permissions.reduce((all, role) => {
    all[role] = true
    return all
  }, {})

  // manually add instructeur because this is used in too many instances
  roles.is_instructeur = roles.ziet_dto

  // for workorder, we have levels of restrictions
  // restriction level is true/false so we can easily check it
  const groups = profile?.group_keys || profile?.groups || []
  roles.all = groups.includes('zweefvlieger')

  // ARC/Vliegend/POM cascades, arc can do everything
  roles.technicus_arc = groups.includes('technicus_arc')
  roles.technicus_vliegend = roles.technicus_arc || groups.includes('technicus_vliegend') || groups.includes('technicus')
  roles.technicus_pom = roles.technicus_vliegend || groups.includes('technicus_pom')

  roles.technicus_rollend = groups.includes('technicus_rollend') || groups.includes('technicus')

  return roles
}
