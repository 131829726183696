import { api } from '../../lib/api'

export const getEmailCampaigns = () => {
  return async (dispatch) => {
    dispatch({ type: 'ADMIN_PENDING' })
    const res = await api.get('campaign.json')
    res && dispatch({
      type: 'SET_CAMPAIGNS',
      campaigns: res.campaigns
    })
    return res && res.campaigns
  }
}

const _upload_campaign_files = async (campaign_id, files, dispatch) => {
  if (files && files.length > 0 && campaign_id) {
    for (const _file of files) {
      const res = await api.post_form('campaign/save_file.json', {
        campaign_id,
        file_id: _file.id,
        action: _file.action,
        file: _file.originFileObj,
        content_type: _file.content_type,
        size: _file.size
      })
      res && res.file && dispatch({
        type: 'ADD_CAMPAIGN_FILE',
        file: res.file,
        campaign_id,
        action: _file.action
      })
    }
  }
}

export const saveEmailCampaign = ({ campaign, files }) => {
  return async (dispatch) => {
    if (campaign.id) {
      dispatch({
        type: 'SAVE_CAMPAIGN',
        campaign: { ...campaign, savingAttachments: true }
      })
    }

    const res = await api.post('campaign.json', campaign)

    if (campaign.action === 'delete') {
      dispatch({
        type: 'DELETE_CAMPAIGN',
        campaign_id: campaign.id
      })
    } else {
      res && res.campaign && dispatch({
        type: 'SAVE_CAMPAIGN',
        campaign: { ...res.campaign, savingAttachments: false }
      })
      campaign.reply_to && dispatch({
        type: 'SET_PERSONAL_PREFERENCES',
        preferences: {
          preferred_reply_to: campaign.reply_to,
          preferred_reply_to_name: campaign.reply_to_name
        }
      })
    }

    await _upload_campaign_files(res?.campaign?.id, files, dispatch)

    return res && res.campaign
  }
}

export const getFindings = () => {
  return async (dispatch) => {
    dispatch({ type: 'ADMIN_PENDING' })
    const res = await api.get('findings.json')
    res && dispatch({
      type: 'SET_FINDINGS',
      findings: res.findings
    })
    return res && res.findings
  }
}

export const saveFinding = ({ finding }) => {
  return async (dispatch) => {
    const res = await api.post('findings.json', finding)
    res && res.finding && dispatch({
      type: 'SAVE_FINDING',
      finding: res.finding
    })
    return res && res.finding
  }
}

export const saveFindingMessage = (payload) => {
  return async (dispatch) => {
    const res = await api.post('findings/add_message.json', payload)
    res && res.finding && dispatch({
      type: 'SAVE_FINDING',
      finding: res.finding
    })
    // return message_id so that files can be saved onto this message
    return res && res
  }
}

export const saveFindingFile = ({ message_id, file }) => {
  return async () => {
    const res = await api.post_form('findings/save_file.json', { message_id, file })
    return res && res.file
  }
}

export const getDocuments = () => {
  return async (dispatch) => {
    dispatch({ type: 'ADMIN_PENDING' })
    const res = await api.get('documents.json')
    res && dispatch({
      type: 'SET_DOCUMENTS',
      tabs: res.tabs
    })
    return res && res.tabs
  }
}

export const saveTab = (payload) => {
  return async (dispatch) => {
    const res = await api.post('documents/tab.json', payload)
    res && dispatch({
      type: 'SET_DOCUMENTS',
      tabs: res.tabs
    })
    return res && res.tabs
  }
}

export const saveChapter = (payload) => {
  return async (dispatch) => {
    const res = await api.post('documents/chapter.json', payload)
    res && dispatch({
      type: 'SET_DOCUMENTS',
      tabs: res.tabs
    })
    return res && res.tabs
  }
}

export const saveDocument = (payload) => {
  return async (dispatch) => {
    const res = await api.post_form('documents/document.json', payload)
    res && dispatch({
      type: 'SET_DOCUMENTS',
      tabs: res.tabs
    })
    return res && res.tabs
  }
}
