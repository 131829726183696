import {
  FileExcelOutlined,
  FileTextOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Flex, Input, Pagination, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min.js";
import { ImportButton } from "~/components/buttons/ImportButton.jsx";
import { BreadcrumbHeader } from "~/components/layout/breadcrumbHeader.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { selectElementByID } from "~/lib/helpers.js";
import { AdminFlightColumns } from "./components/AdminFlightColumns.jsx";
import { AdminFlightEditModal } from "./components/AdminFlightEditModal.jsx";
import { ImportDialogFlights } from "./components/ImportDialogFlights.jsx";
import { useSelector } from "react-redux";

export function AdminFlights({ history }) {

  const { profile } = useSelector((state) => state.persist)

  const [showImportModal, setShowImportModal] = useState(false);

  const [flights, setFlights] = useState([]);
  const updateFlight = (newFlight) => {
    let newFlights = flights.map((u) => {
      if (u.id == newFlight.id) return newFlight;
      return u;
    });
    setFlights(newFlights);
  };

  const [currentPageNumber, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    nextPage: null,
    prevPage: null,
    numberFlights: null,
  });

  const [query, setQuery] = useState([]);

  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const years = [];
  for (let year = currentYear; year >= 1990; year--) years.push(year);

  const constructFlightURL = (endpoint) =>
    `${endpoint}?query=${query}&year=${year}`;
  const onExportClick = (format = "csv") =>
    api.openFileGET(
      `${constructFlightURL("admin/flight_export.json")}&format=${format}`
    );

  const [hasUpdated, setHasUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getFlights();
  }, []);
  const getFlights = async (newPage = null) => {
    let page = newPage || currentPageNumber;
    setLoading(true);
    const data = await api.get(
      `${constructFlightURL("admin/flights.json")}&page=${page}`
    );
    if (data) {
      setFlights(data.results);
      setPagination({
        nextPage: data.next,
        prevPage: data.previous,
        numberFlights: data.count,
      });
      setHasUpdated(false);
    }
    setLoading(false);
  };

  const columns = AdminFlightColumns();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const [loadSaveFlights, setLoadSaveFlights] = useState("");
  const saveFlightsIsDeleted = async (deleteOrRecover) => {
    setLoadSaveFlights(deleteOrRecover);

    let newFlights = flights;

    for (const uuid of selectedRowKeys) {
      const { data } = await api.post2("flights/save_flight.json", {
        action: deleteOrRecover,
        uuid,
        skip_currency: true,
      });
      if (data) {
        newFlights = newFlights.map((f) => {
          if (f.uuid == data.uuid) return data;
          return f;
        });
      }
    }
    setFlights(newFlights);
    setLoadSaveFlights("");
  };

  const renderedFlightForm = ({ history, match }) => {
    let flight = selectElementByID(flights, "uuid", match.params.uuid);
    return (
      <AdminFlightEditModal
        flight={flight}
        history={history}
        updateFlight={updateFlight}
      />
    );
  };

  return (
    <Flex gap="middle" vertical>
      <Switch>
        {flights.length && (
          <Route path="/admin/flights/:uuid" component={renderedFlightForm} />
        )}
      </Switch>

      <Flex align="center" justify="space-between">
        <BreadcrumbHeader breadcrumbs={[$t("Flights")]} />

        { profile.wijzig_leden && <ImportButton onClick={() => setShowImportModal(true)} />}

        { profile.wijzig_leden &&
          <ImportDialogFlights
            refreshAfterImport={getFlights}
            isModal={showImportModal}
            closeModal={() => setShowImportModal(false)}
          />
        }
      </Flex>

      <Flex gap={12}>
        <Input.Search
          style={{ maxWidth: 250 }}
          value={query}
          allowClear
          size="small"
          placeholder={$t("Zoek")}
          onChange={(event) => {
            setHasUpdated(true);
            setQuery(event.target.value);
          }}
        />

        <Select
          showSearch
          style={{ width: 200 }}
          size="small"
          value={year}
          filterOption={(input, option) => option?.key.includes(input)}
          onChange={(year) => {
            setHasUpdated(true);
            setYear(year);
          }}
        >
          {years.map((year) => (
            <Select.Option key={year} value={year}>
              {year}
            </Select.Option>
          ))}
        </Select>

        <Button
          type="primary"
          disabled={!hasUpdated}
          size="small"
          onClick={(e) => getFlights()}
          icon={<SearchOutlined />}
        >
          {$t("Zoek")}
        </Button>
      </Flex>

      <Flex align="center" gap="middle">
        <p>{$t("Exporteer")}:</p>

        <Button
          icon={<FileTextOutlined />}
          size="small"
          onClick={() => onExportClick("csv")}
        >
          CSV
        </Button>

        <Button
          icon={<FileExcelOutlined />}
          size="small"
          onClick={() => onExportClick("xlsx")}
        >
          Excel
        </Button>
      </Flex>

      <Flex align="center" gap="middle">
        <p>
          {$t("${n} geselecteerde vlucht(en)", { n: selectedRowKeys.length })}
        </p>

        <Button
          size="small"
          danger
          disabled={!hasSelected}
          loading={loadSaveFlights == "delete"}
          onClick={() => saveFlightsIsDeleted("delete")}
        >
          {$t("Verwijder")}
        </Button>

        <Button
          size="small"
          disabled={!hasSelected}
          loading={loadSaveFlights == "recover"}
          onClick={() => saveFlightsIsDeleted("recover")}
        >
          {$t("Herstel")}
        </Button>
      </Flex>

      <Table
        rowSelection={rowSelection}
        loading={loading}
        style={{
          marginTop: 12,
          width: "95vw",
          overflowX: "auto",
          cursor: "pointer",
        }}
        pagination={false}
        size="small"
        rowKey="uuid"
        dataSource={flights}
        columns={columns}
        onRow={(flight) => {
          return {
            onClick: () => history.push(`/admin/flights/${flight.uuid}`),
          };
        }}
      />

      <Pagination
        simple={true}
        onChange={(newPage) => {
          setCurrentPage(newPage);
          getFlights(newPage);
        }}
        pageSize={100}
        current={currentPageNumber}
        hideOnSinglePage={true}
        total={pagination["numberFlights"] || 0}
      />
    </Flex>
  );
}
