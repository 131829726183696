import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { connect } from "react-redux";
import { $t } from "~/i18n.js";

import { ModalLayout } from "~/components/layout/modalLayout.jsx";
import { persistActions } from "~/redux/actions.js";

const successWachtwoordReset = () => {
  Modal.success({
    title: $t("Je wachtwoord is hersteld"),
    content: $t(
      "Vanaf nu kun je je nieuwe wachtwoord gebruiken bij het inloggen."
    ),
  });
};

const PasswordresetComponent = ({
  login_with_password_link,
  persist,
  match,
  history,
}) => {
  return (
    <ModalLayout width="500px">
      <h1>{$t("Nieuw wachtwoord")}</h1>

      <p style={{ marginBottom: 24 }}>
        {$t("Kies hier je nieuwe wachtwoord.")}
      </p>

      <Form
        layout="vertical"
        requiredMark={false}
        name="basic"
        onFinish={async (values) => {
          const profile = await login_with_password_link({
            ...values,
            password_reset_code: match.params.password_reset_code,
            history,
          });

          if (profile) successWachtwoordReset();
        }}
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: $t("Voeg wachtwoord toe!"),
            },
          ]}
        >
          <Input.Password placeholder={$t("Wachtwoord")} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={persist.isPending}>
            {$t("Herstel wachtwoord en login")}
          </Button>
        </Form.Item>

        <Button
          type="link"
          onClick={() => history.push("/forgot")}
          style={{ paddingLeft: 0 }}
        >
          {$t("Terug naar inloggen")}
        </Button>
      </Form>
    </ModalLayout>
  );
};

const mapStateToProps = (state) => ({
  persist: state.persist,
});

const mapDispatchToProps = (dispatch) => {
  return {
    login_with_password_link: (data) =>
      dispatch(persistActions.login_with_password_link(data)),
  };
};

export const PasswordResetPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordresetComponent);
