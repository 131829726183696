import { message } from "antd";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { $t } from "~/i18n.js";
import { copy, copy_active, Icon } from "./icon.jsx";

export class CopyIcon extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      copied: false,
    };
  }

  render({ text } = this.props) {
    return (
      <CopyToClipboard
        text={text}
        onCopy={() => {
          this.setState({ copied: true });
          message.success(`"${text}" ` + $t("gekopieerd"));
          setTimeout(() => this.setState({ copied: false }), 2000);
        }}
      >
        <div className="row clickable">
          <Icon
            color="#1890ff"
            size={22}
            icon={this.state.copied ? copy_active : copy}
          />
        </div>
      </CopyToClipboard>
    );
  }
}
