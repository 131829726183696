import { Form, Select, Switch } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { DataCard } from '../../../components/dataCards/dataCard'
import { TermsmodalReadOnly } from '../../../components/layout/terms/terms'

export const CardPrivacy = ({ profile, club, saveData, type }) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const saveForm = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      // manipulate values for save
      const res = await saveData({
        set_private_mode: values.private_mode ? 1 : -1,
        set_private_igc: values.private_igc,
        profile: {
          subscribed_to_mail: values.subscribed_to_mail
        }
      })
      setLoading(false)
      return res
    } catch (info) {
      console.log('Validate Failed:', info)
      setLoading(false)
    }
  }

  return (
    <DataCard title={$t('Privacysettings')} onSave={saveForm} loading={loading} type={type}>

      <Form
        form={form}
        name='privacy'
        initialValues={{
          private_mode: profile.private_mode,
          private_igc: profile.private_igc ? 1 : -1,
          subscribed_to_mail: profile.subscribed_to_mail
        }}
        requiredMark={false}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 16 }}
      >

        <p style={{ marginBottom: 6}}>{$t('Kies of andere leden je currency, telefoon, email, huisadres en gemaakte vluchten kunnen zien. Alleen instructeurs, sleepvliegers (bij sleepvluchten) en systeembeheerders kunnen dit altijd.')}</p>

        <Form.Item
          label={$t('Privacy')}
          name='private_mode'

          valuePropName='checked'
        >
          <Switch checkedChildren={$t('aan')} unCheckedChildren={$t('uit')} />
        </Form.Item>

        {club.private_igc && <>
          <p style={{ marginBottom: 6}}>
            {$t('Kies of je IGC tracks standard zichtbaar zijn, of dat je per vlucht hem open wilt zetten.  Bij het maken van de track wordt gekeken naar de instelling van beide vliegers.')}
          </p>

          <Form.Item
            label={$t('Kaart')}
            name='private_igc'
          >
            <Select>
              <Select.Option value={-1} key={-1}>{$t('Standaard zichtbaar')}</Select.Option>
              <Select.Option value={1} key={1}>{$t('Bepaal per vlucht')}</Select.Option>
            </Select>
          </Form.Item>
        </>}

        <p style={{ marginBottom: 6}}>{$t('Privacy werkt wederkerig, dus als je zelf private mode aan hebt staan zie je ook niks van anderen. Om misbruik te voorkomen kan je dit slechts 1  keer per maand aanzetten.')}</p>

        <p className='clickable' style={{ marginBottom: 24}} onClick={TermsmodalReadOnly}>
          {$t('Lees')} <span className='primary'>{$t('hier')}</span> {$t('de gehele privacyverklaring.')}
        </p>

        <p style={{ marginBottom: 6}}>{$t('Je kan je afmelden voor emails die je vanuit je club ontvangt')}</p>
        <Form.Item
          label={$t('Ontvang emails')}
          name='subscribed_to_mail'
          valuePropName='checked'
        >
          <Switch checkedChildren={$t('aan')} unCheckedChildren={$t('uit')} />
        </Form.Item>

      </Form>
    </DataCard>
  )
}
