import { $t } from '~/i18n.js'
import React from 'react'
import { Tabs } from 'antd'
import { TotalTypeTable } from '../../../components/profile/tabs/overviewFlights.jsx'
import { FESRecencyTable, FISRecencyTable, RecencyTable, TMGRecencyTable } from '../../../components/profile/recency.jsx'

export const CardTypeTotals = ({ recency, tweede, gezagvoerder }) => {
  return (
    <div className='overviewFlightTab'>

      <Tabs defaultActiveKey={recency ? 'easa' : 'gezagvoerder'}>

        {recency && recency.starts >= 0 && <Tabs.TabPane tab={$t('EASA SPL')} key='easa'>
          <div style={{ height: 230, overflowY: 'auto', overflowX: 'hidden' }}>
            <RecencyTable recency={recency} />
          </div>
        </Tabs.TabPane>}

        {recency && recency.tmg_starts && <Tabs.TabPane tab={$t('EASA TMG')} key='easatmg'>
          <div style={{ height: 230, overflowY: 'auto', overflowX: 'hidden' }}>

            <TMGRecencyTable recency={recency} />

          </div>
        </Tabs.TabPane>}

        {recency && recency.fis && <Tabs.TabPane tab={$t('EASA FI(s)')} key='easafis'>
          <div style={{ height: 230, overflowY: 'auto', overflowX: 'hidden' }}>

            <FISRecencyTable recency={recency} />

          </div>
        </Tabs.TabPane>}

        {recency && recency.is_fes && <Tabs.TabPane tab={$t('EASA FE(s)')} key='easafes'>
          <div style={{ height: 230, overflowY: 'auto', overflowX: 'hidden' }}>

            <FESRecencyTable recency={recency} />

          </div>
        </Tabs.TabPane>}

        <Tabs.TabPane tab={$t('Gezagvoerder')} key='gezagvoerder'>
          <div style={{ height: 230, overflowY: 'auto', overflowX: 'hidden' }}>
            <p>{$t('Starts afgelopen laatste 6 maanden')}:</p>
            <TotalTypeTable data={gezagvoerder} />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab={$t('Tweede inzittende')} key='tweede'>
          <div style={{ height: 230, overflowY: 'auto', overflowX: 'hidden' }}>
            <p>{$t('Starts afgelopen laatste 6 maanden')}:</p>
            <TotalTypeTable data={tweede} />
          </div>
        </Tabs.TabPane>

      </Tabs>

    </div>
  )
}
