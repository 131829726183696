import { Input, Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { $t } from '~/i18n'

export const CategorySelect = ({ readOnly, currentFlight, currentValue, categories, onSelect }) => {
  const [showModal, setShowModal] = useState(false)
  const [newValue, setNewValue] = useState('')

  useEffect(() => setNewValue(currentValue), [currentFlight])

  const split_categories = categories.split(',')
  if (!split_categories || !split_categories.length || split_categories.length < 1) return null

  const label = split_categories[0]
  const isInput = split_categories[1] === 'other'

  if (!categories || split_categories.length < 1) return null

  const triggerSelect = (value) => {
    if (value === 'other') setShowModal(true)
    else {
      setShowModal(false)
      onSelect(value.substr(0, 15))
    }
  }

  const saveIfRequired = () => {
    if (newValue !== currentValue) onSelect(newValue)
  }

  if (isInput) {
    return (
      <div className='row' style={{ margin: '2px 0 6px', width: '100%', justifyContent: 'space-between' }}>
        <p style={{ marginRight: 2 }}>{label}:</p>
        <Input
          autoFocus={false}
          type='text'
          value={newValue}
          onChange={(element) => setNewValue(element.target.value)}
          onPressEnter={() => saveIfRequired(newValue)}
          onBlur={() => saveIfRequired(newValue)}
        />
      </div>
    )
  }
  return (

    <div className='row' style={{ margin: '2px 0 6px', width: '100%', justifyContent: 'space-between' }}>
      {showModal && (
        <Modal
          width={340}
          title={`${$t('Wijzig')} ${label}`}
          visible={showModal}
          onCancel={() => triggerSelect('')}
          onOk={() => triggerSelect(newValue)}
        >
          <p>{$t('Voeg een andere waarde toe voor')} {label}:</p>
          <Input
            autoFocus
            type='text'
            value={newValue}
            onChange={(element) => setNewValue(element.target.value)}
            onPressEnter={() => triggerSelect(newValue)}
          />
        </Modal>
      )}

      <p style={{ marginRight: 2 }}>{label}:</p>
      <Select
        value={currentValue} style={{ minWidth: 100 }}
        disabled={readOnly}
        onChange={triggerSelect}
      >
        {split_categories.map((value, id) => id > 0 &&
          <Select.Option key={id} value={value.trim()}>{value === 'other' ? $t('Anders') : value}</Select.Option>
        )}
      </Select>
    </div>
  )
}
