import { ApiTwoTone, SyncOutlined, WifiOutlined } from "@ant-design/icons";
import { Button, Modal, Radio, Select } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $t } from "~/i18n.js";
import {
  saveOGNmode,
  updateWebsocketEvent,
} from "../../../redux/flights/flights.actions.js";

import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { api } from "~/lib/api.js";
import { SelectLanguage } from "../../../components/profile/selectLanguage.jsx";
import { config } from "../../../config.js";

export const LiveFlightConnection = ({ club, dayId, muteSound }) => {
  const {
    network_online,
    default_flight_cat,
    default_sleep_cat,
    default_tmg_cat,
    ogn_mode,
  } = useSelector((state) => state.flights);

  const [connected, setConnection] = useState(-1);
  const ws = useRef({});
  const mounted = useRef(false);
  const dispatch = useDispatch();

  const setupNewWebsocket = useCallback(() => {
    try {
      // useRef is stored element that does not cause to rerender the underlying object
      // we use a callback to ensure this function is only re-rendered if the dayId changes
      if (!mounted.current) {
        return null;
      }

      if (ws && ws.current && ws.current.close) {
        console.log("Closing websocket before opening new");
        try {
          ws.current.close();
        } catch (e) {
          console.log("not closing, error", e);
        }
      }

      ws.current = new WebSocket(
        `${config.ws_url}/flights/${
          club.schema_name
        }/${dayId}/${api.getToken()}`
      );

      ws.current.onopen = () => {
        console.log("Websocket: connection opened for", dayId);
        setConnection(1);
      };
      ws.current.onclose = () => {
        // only reopen if still mounted
        if (mounted.current) setTimeout(() => setupNewWebsocket(), 5000);
      };
      ws.current.onerror = (event) => {
        console.log("Websocket: error ");
        setConnection(0);
      };

      ws.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log('receiving message', {message})
        dispatch(updateWebsocketEvent(message, muteSound));
      };
    } catch (e) {
      console.log("Websocket: error faced, retrying in 5 seconds", e);
      setConnection(0);
      setTimeout(() => setupNewWebsocket(), 5000);
    }
  }, [club.schema_name, dayId, dispatch]);

  useEffect(() => {
    // we use a callback to ensure this function is only re-rendered if the dayId changes
    mounted.current = true;
    setupNewWebsocket();

    // Return cleanup event - in case you close the page and remove the connection
    return () => {
      console.log("Websocket: cleaning up connection");
      mounted.current = false;
      ws && ws.current && ws.current.close();
    };
  }, [dayId, setupNewWebsocket]);

  const [showOGNdialog, setShowOGNdialog] = useState(false);
  const [newOGNmode, setNewOGNmode] = useState("manueel"); // manueel, auto
  const [pendingSave, setPendingSave] = useState(false);

  const saveNewMode = async () => {
    setPendingSave(true);
    const res = await dispatch(
      saveOGNmode({ ogn_mode: newOGNmode, dag_id: dayId, ...newCatLookup })
    );
    res && setShowOGNdialog(false);
    setPendingSave(false);
  };

  const onCancel = () => {
    setShowOGNdialog(false);
    setPendingSave(false);
  };

  const flight_cat = club.flight_cat ? club.flight_cat.split(",") : null;
  const sleep_cat = club.sleep_cat ? club.sleep_cat.split(",") : null;
  const tmg_cat = club.tmg_cat ? club.tmg_cat.split(",") : null;

  const [newCatLookup, setNewCatLookup] = useState("");
  useEffect(
    () =>
      setNewCatLookup({
        default_flight_cat,
        default_sleep_cat,
        default_tmg_cat,
      }),
    [default_flight_cat, default_sleep_cat, default_tmg_cat]
  );

  const _selectValue = (categories, key) => (
    <div key={key} className="row" style={{ marginTop: 4 }}>
      <p>
        {$t("Standaard")} {categories[0]}:
      </p>
      <Select
        value={newCatLookup[key]}
        style={{ minWidth: 100, marginLeft: 6 }}
        onChange={(value) => {
          const lookup = { ...newCatLookup };
          lookup[key] = value;
          setNewCatLookup(lookup);
        }}
      >
        {categories.map(
          (value, id) =>
            id > 0 &&
            value !== "other" && (
              <Select.Option key={id} value={value.trim()}>
                {value}
              </Select.Option>
            )
        )}
      </Select>
    </div>
  );

  return (
    <>
      {showOGNdialog && (
        <Modal
          title={$t("Methode van tijdschrijven")}
          open={showOGNdialog}
          width="600px"
          confirmLoading={pendingSave}
          okText={$t("Opslaan")}
          cancelText={$t("Annuleren")}
          onCancel={onCancel}
          footer={[
            <AnnuleerButton key="annuleren" onClick={onCancel} />,
            <Button key='3' onClick={saveNewMode} type="primary">
              {$t("Opslaan")}
            </Button>,
          ]}
        >
          <SelectLanguage />

          {(flight_cat || sleep_cat || tmg_cat) && (
            <>
              <p className="bold">{$t("Selecteer standaard waardes")}</p>

              <p>
                {$t(
                  "De geselecteerde waarde kun je gedurende de vliegdag aanpassen en zullen bij nieuwe vluchten geselecteerd worden."
                )}
              </p>

              {flight_cat && _selectValue(flight_cat, "default_flight_cat")}
              {sleep_cat && _selectValue(sleep_cat, "default_sleep_cat")}
              {tmg_cat && _selectValue(tmg_cat, "default_tmg_cat")}

              <p className="bold" style={{ marginTop: 24 }}>
                {$t("OGN modus")}
              </p>
            </>
          )}

          <div className="row" style={{ gap: 12 }}>
            <p>{$t("Methode van tijdschrijven")}: </p>
            <Radio.Group
              buttonStyle="solid"
              style={{ margin: "12px 0" }}
              value={newOGNmode}
              onChange={(event) => setNewOGNmode(event.target.value)}
            >
              <Radio.Button value="manueel">{$t("Manueel")}</Radio.Button>
              <Radio.Button value="auto">{$t("Automatisch")}</Radio.Button>
              <Radio.Button value="auto_land">
                {$t("Automatisch landen")}
              </Radio.Button>
            </Radio.Group>
          </div>

          <p>
            <span className="bold">{$t("Manueel")}</span>:{" "}
            {$t(
              "Start/landings tijden worden manueel door de tijdschrijver toegekend."
            )}
          </p>
          <p>
            <span className="bold">{$t("Automatisch")}</span>:{" "}
            {$t(
              "Start/landingstijden van vluchten worden automatisch aangevuld. Indien een kist nog niet is ingedeeld krijg je te zien dat hij vertrokken is, en de melding een vlieger te selecteren."
            )}
          </p>
          <p>
            <span className="bold">{$t("Automatisch landen")}</span>:{" "}
            {$t(
              "Alleen de landings tijden worden automatisch aangevuld, deze modus kan handig zijn als de OGN receiver niet goed is gericht op de landingsb"
            )}
            aan.
          </p>

          <div style={{ marginTop: 20 }}>
            <p className="bold">{$t("Club receiver status")}</p>
            <img
              alt="receiver"
              style={{ width: 200, margin: "12px 0" }}
              src={config.backend_url + "/api/flightlogs/club_receiver.json"}
            />
            <p>
              {$t(
                "OGN werkt het beste als er een club receiver geinstalleerd is die rechtstreeks zicht heeft op het veld"
              )}
              .
            </p>
          </div>
        </Modal>
      )}

      <Button
        size="small"
        className="clickable"
        style={{ display: "flex", alignItems: "center", marginRight: 4 }}
        onClick={() => {
          setNewOGNmode(ogn_mode);
          setPendingSave(false);
          setShowOGNdialog(true);
        }}
      >
        {network_online && connected === -1 && <SyncOutlined spin />}

        {network_online && connected === 1 && (
          <div className="row" style={{ alignItems: "center", marginRight: 6 }}>
            <WifiOutlined style={{ color: "green", marginRight: 4 }} />
            <span className="small">{ogn_mode.replace("_", " ")}</span>
          </div>
        )}

        {(!network_online || connected === 0) && (
          <div className="row" style={{ alignItems: "center", marginRight: 6 }}>
            <ApiTwoTone
              style={{ marginRight: 6 }}
              className="pulse"
              twoToneColor="#E14D52"
            />
            <span className="small">
              {$t("offline")} {!network_online ? "wifi" : ""}
            </span>
          </div>
        )}
      </Button>
    </>
  );
};
