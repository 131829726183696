import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { Suspense } from "react";
import { $t } from "../../i18n.js";

import { lazy } from "react";
import { config } from "../../config.js";
import { api } from "../../lib/api.js";
import { _parseDate } from "../../lib/localize.js";
// import { IGCViewer } from './IGC.viewer'

import { SluitButton } from "../buttons/SluitButton.jsx";
import { Loader } from "../loader/loader.jsx";
import "./IGC.modal.css";

const IGCViewer = lazy(() => import("./IGCViewer.jsx"));

export const IGCModal = ({
  onClose,
  flight,
  visible,
  getOverlappingFlights,
}) => {
  if (!flight) return null;

  return (
    <Modal
      width="100%"
      wrapClassName="IGC__modal"
      style={{ top: 10, padding: 0, margin: 0 }}
      title={$t("Vluchtkaart ${key}", {
        key: `${_parseDate(flight.datum)} ${
          flight.volg_nummer ? " #" + flight.volg_nummer : ""
        }`,
      })}
      open={visible}
      onCancel={onClose}
      footer={[
        <Button
          type="link"
          key="download"
          onClick={() => {
            const filesplit = flight.igc.split("/");
            api.open_file(
              null,
              filesplit[filesplit.length - 1],
              config.media_prefix + flight.igc
            );
          }}
          disabled={!flight.igc}
          icon={<CloudDownloadOutlined />}
          size="small"
        >
          {$t("Download")}
        </Button>,
        <SluitButton key="sluit" onClick={onClose} />,
      ]}
    >
      <Suspense fallback={<Loader />}>
        <IGCViewer
          flight={flight}
          getOverlappingFlights={getOverlappingFlights}
        />
      </Suspense>
    </Modal>
  );
};
