import { EditOutlined, PrinterOutlined, SaveOutlined } from '@ant-design/icons'
import { Badge, Button, Popconfirm } from 'antd'
import React, { useState } from 'react'
import { HelpBubble } from '../../../components/help/help.bubble'
import { $t } from '~/i18n.js'
import { api } from '../../../lib/api'
import { _parseDateTime, toLocaleDate } from '../../../lib/localize'
import { RenderAvailibility } from './socialSelect'

const statusExplanation = {
  concept: 'Concept: Het rooster is niet-zichtbaar voor leden, en je kan het rooster met diensten invullen.', // $t('Concept: Het rooster is niet-zichtbaar voor leden, en je kan het rooster met diensten invullen.')
  open_registration: 'Open voor beschikbaarheid: Het rooster staat open voor leden om hun beschikbaarheid of voorkeur door te geven. Er wordt geen mail verstuurd.', // $t('Open voor beschikbaarheid: Het rooster staat open voor leden om hun beschikbaarheid of voorkeur door te geven. Er wordt geen mail verstuurd.')
  active: 'Actief: Het rooster is op dit moment actief, alle diensten zijn zichtbaar in de aanmeldlijsten.', // $t('Actief: Het rooster is op dit moment actief, alle diensten zijn zichtbaar in de aanmeldlijsten.')
  closed: 'Gesloten: Het rooster is nu gesloten, diensten zijn niet meer zichtbaar.' // $t('Gesloten: Het rooster is nu gesloten, diensten zijn niet meer zichtbaar.')
}

export const RoostersActions = ({
  rooster, saveRooster, setRooster, openRooster,
  canSaveAvailability, bekijkAlsRoostermaker
}) => {
  const [loadingAction, setLoadingAction] = useState('')

  const saveAvailability = async () => {
    setLoadingAction('availability')
    const availability = []
    rooster?.days.forEach(day => {
      // iterate through dienst lookup
      Object.keys(day.dienst_lookup).forEach(key => {
        const dienst = day.dienst_lookup[key]
        if (dienst.availability && dienst.saveAvailability) availability.push({ dienst_id: dienst.id, availability: dienst.availability, remark: dienst.remark || '' })
      })
    })
    const data = await api.post('rooster/save_availability.json', availability)
    data && setRooster({ ...rooster, saveAvailability: 0 })
    setLoadingAction('')
  }

  const saveDiensten = async () => {
    setLoadingAction('wijzigingen')
    const diensten = []
    rooster?.days.forEach(day => {
      // iterate through dienst lookup
      Object.keys(day.dienst_lookup).forEach(key => {
        const dienst = day.dienst_lookup[key]
        if (dienst.must_save) diensten.push(dienst)
      })
    })
    const data = await api.post('rooster/save_diensten.json', diensten)
    data && setRooster({ ...rooster, canSave: 0 })
    setLoadingAction('')
  }

  const saveAction = async (action) => {
    setLoadingAction(action)
    await saveRooster({ id: rooster.id, action })
    setLoadingAction('')
  }

  const _actionButton = (triggerAction, enabledStateList, buttonText, popupText, helpText) => (
    <div>
      <Popconfirm
        placement='bottom' title={popupText}
        disabled={!enabledStateList.includes(rooster.status)}
        onConfirm={() => saveAction(triggerAction)}
        okText={$t('Ja')} cancelText={$t('Nee')}
      >
        <Button
          style={{ marginRight: 4 }}
          disabled={!enabledStateList.includes(rooster.status)}
          loading={loadingAction === triggerAction}
        >
          {buttonText}
        </Button>
      </Popconfirm>
      <HelpBubble content={helpText} />
    </div>
  )
  return (
    <>

      {canSaveAvailability &&
        <div className='row' style={{ justifyContent: 'space-between' }}>
          <div>
            <p className='small'>{$t('Uitleg')}:</p>
            <p className='small'><RenderAvailibility availability='star' /> <span style={{ marginLeft: 4 }}>{$t('Ik wil deze dienst')}</span></p>
            <p className='small'><RenderAvailibility availability='yes' /> <span style={{ marginLeft: 4 }}>{$t('Ik heb voorkeur voor')}</span></p>
            <p className='small'><RenderAvailibility availability='z_no' /> <span style={{ marginLeft: 4 }}>{$t('Niet beschikbaar')}</span></p>
          </div>

          <Badge count={rooster.saveAvailability}>
            <Button
              disabled={!rooster || !rooster.saveAvailability} type='primary'
              loading={loadingAction === 'availability'}
              icon={<SaveOutlined />} onClick={() => saveAvailability(rooster)}
            >
              {$t('Opslaan beschikbaarheid')}
            </Button>
          </Badge>
        </div>}

      {bekijkAlsRoostermaker && <div>

        <div className='row' style={{ gap: 12, flexWrap: 'wrap' }}>

          <Button icon={<EditOutlined />} onClick={() => openRooster(rooster)}>
            {$t('Wijzig')}
          </Button>

          <Badge count={rooster.canSave}>
            <Button
              disabled={!rooster || !rooster.canSave} type='primary'
              loading={loadingAction === 'wijzigingen'}
              icon={<SaveOutlined />} onClick={() => saveDiensten(rooster)}
            >
              {$t('Opslaan')}
            </Button>
          </Badge>

        </div>

        <p style={{ marginTop: 24 }}>{$t(statusExplanation[rooster.status])}</p>
        {rooster.updated_date && <p className='small' style={{ marginBottom: 12 }}>{$t('Laatste wijziging')}: {rooster.updated_name}, {_parseDateTime(rooster.updated_date)}</p>}

        <div className='row' style={{ gap: 12, flexWrap: 'wrap' }}>
          {rooster.has_social_option && rooster.status !== 'open_registration' && _actionButton('open_registration', ['concept', 'closed'], $t('Open beschikbaarheid'),
            $t('Bevestig dat leden zich nu op kunnen geven voor hun beschikbaarheid.'),
            $t('Met deze actie open je het rooster voor leden om hun beschikbaarheid of voorkeur op te geven.'))}

          {rooster.has_social_option && rooster.status === 'open_registration' && _actionButton('close_registration', ['open_registration'], $t('Sluit beschikbaarheid'),
            $t('Bevestig dat je de registratie voor beschikbaarheid wilt sluiten.'),
            $t('Deze actie sluit het rooster voor leden om hun beschikbaarheid of voorkeur op te geven.'))}

          {_actionButton('populate_diensten', ['concept', 'closed'], $t('Vul diensten'),
            rooster.date_populated ? $t('Weet je zeker dat je opnieuw het rooster automatisch wilt opmaken?') : $t('Weet je zeker dat je de diensten automatisch wilt invullen?'),
            $t('Rooster zal waar mogelijk automatisch ingevuld worden. Let op: Als een rooster al eens is ingevuld zal dit zorgen dat alle diensten leeggehaald worden, en opnieuw ingevuld worden.'))}

          {_actionButton('activate', ['concept', 'activate', 'closed'], $t('Activeer'),
            $t('Bevestig dat diensten zichtbaar worden.'),
            $t('Het activeren van een rooster zorgt ervoor dat de diensten zichtbaar worden voor de leden (en direct aangemeld worden voor de dag).'))}

          {_actionButton('close', ['active'], $t('Sluit'),
            $t('Bevestig dat diensten zichtbaar worden.'),
            $t('Het sluiten van een rooster zorgt ervoor dat de diensten niet meer zichtbaar worden voor de leden.'))}
        </div>

        {rooster.date_populated && <p>{$t('Rooster automatisch gevuld op')}: { _parseDateTime(rooster.date_populated)}</p>}

        {rooster.has_social_option && <p>
          {$t('Aantal gebruikers met opgegeven voorkeur')}: {rooster.n_users_with_preference}
          <Button
            icon={<PrinterOutlined />}
            size='small'
            style={{ marginLeft: 12 }}
          // shape='circle'
            loading={loadingAction === 'export'}
            onClick={async () => {
              setLoadingAction('export')
              await api.open_file(`rooster/${rooster.id}/export_availability.json`, `Beschikbaarheid ${rooster.description} ${toLocaleDate(new Date())}.xlsx`)
              setLoadingAction('')
            }}
          >{$t('Exporteer')}
          </Button>
        </p>}

                                </div>}
    </>
  )
}
