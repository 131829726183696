import { ApiTwoTone } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

export const OnlineIndicator = () => {
  const [connected, setConnection] = useState(true);

  useEffect(() => {
    // execute once upon init lifetime component
    setConnection(navigator.onLine);
  }, []);

  useEffect(() => {
    setConnection(navigator.onLine);
  }, [navigator.onLine]);

  if (connected) return null;
  return (
    <div className="row" style={{ alignItems: "center", marginRight: 6 }}>
      <ApiTwoTone
        style={{ marginRight: 6 }}
        className="pulse"
        twoToneColor="white"
      />
      <span className="small" style={{ color: "white" }}>
        offline
      </span>
    </div>
  );
};
