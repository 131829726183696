import { ConfigProvider, theme } from "antd";
import React, { useEffect } from "react";
import { Provider } from "react-redux";

// Localize ant description, and update first day of week for datePicker
import de from "antd/es/locale/de_DE.js";
import en from "antd/es/locale/en_GB.js";
import fr from "antd/es/locale/fr_FR.js";
import nl from "antd/es/locale/nl_NL.js";
import "./components/pwa/register";
import { Root } from "./Root.jsx";

const getAntLocal = () => {
  const lng = localStorage.language;
  if (lng === "en") return en;
  if (lng === "de") return de;
  if (lng === "fr") return fr;
  // default nl or nl-be
  return nl;
};

const { defaultAlgorithm, darkAlgorithm } = theme;

// Empty state props a
export function App({ store }) {
  // detect users preferred theme
  // const value = window
  //   .getComputedStyle(document.documentElement)
  //   .getPropertyValue('content')
  //   .replace(/"/g, '')

  const initialValue = localStorage.getItem("theme") || "light";

  useEffect(() => {
    const theme = initialValue;
    document.body.className = theme;
  });

  return (
    <Provider store={store}>
      <ConfigProvider
        locale={getAntLocal()}
        theme={{
          algorithm: initialValue === "dark" ? darkAlgorithm : defaultAlgorithm,
        }}
      >
        <Root />
      </ConfigProvider>
    </Provider>
  );
}
