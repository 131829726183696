import { $t } from '~/i18n.js'
import React from 'react'
import { Button } from 'antd'

export class MaintenancePage extends React.Component {
  render ({ history } = this.props) {
    return (
      <div className='not_found'>

        <h2>Excuses.</h2>
        <p style={{ marginBottom: 24 }}>{$t('Wij zijn even onderhoud aan het plegen.')}</p>

        <p style={{ text: 'center', padding: '36px 40px' }}>{$t('Op dit moment zijn we hard aan het werken om de Zweef.App, en werkt de app eventjes niet. Probeer het later nog eens.')}</p>

        <Button className='homeButton' type='primary' onClick={() => this.props.history.push('/')}>
          {$t('Terug')}
        </Button>

      </div>
    )
  }
}
