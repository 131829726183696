export const compareClubKisten = (a, b) => {
  // sort method to deal with [M11, PH-1233, M2] being sorted to [M2, M11, PH-1233]
  const diff = parseInt(a.callsign?.replace(/\D+/g, '')) - parseInt(b.callsign?.replace(/\D+/g, ''))
  if (isNaN(diff)) return a.callsign > b.callsign
  return diff
}

export const sortClubKisten = (kisten) => {
  return kisten.sort(compareClubKisten)
}

const _material_order = (mat) => {
  // special order to ensure kisten above, then rollend and below overig
  if (mat.category === 'kist') {
    // for kisten, we check the callsign, and ensure M11 is sorted after M1 and M9
    const registration = mat.name.split(':')[0]

    // replacing all non-digits, e.g. M7 and GO1 becomes 7 and 1
    let tailnumber = parseInt(registration?.replace(/\D+/g, ''))

    if (isNaN(tailnumber)) {
      // in case tailnumber is something like KUBA or CF, create ascii character of first two digits
      tailnumber = registration.charCodeAt(0) * 100 + registration.charCodeAt(1)
    }
    return `${tailnumber}`
  }

  if (mat.category === 'rollend') return 'r' + mat.name?.charCodeAt(0) + mat.name?.charCodeAt(1) + mat.name?.charCodeAt(2)
  return 'x' + mat.id
}

export const normalizeMeldingen = (meldingen, material, technici) => {
  // add join in frontend between meldingen and material, reduces load on backend
  const material_lookup = material.reduce((all, mat) => {
    all[mat.id] = mat
    return all
  }, {})
  const technici_lookup = technici.reduce((all, mat) => {
    all[mat.id] = mat
    return all
  }, {})

  meldingen.map(m => {
    m.material = material_lookup[m.material_id]
    m.material_name = m.material?.name
    m.technicus = technici_lookup[m.technicus_id]
    m.technicus_name = m.technicus?.name
  })
  return meldingen
}

export const normalizeMaterial = (material) => {
  // add total minutes per kist
  let output = []
  material.forEach(mat => output.push(normalizeSingleMaterial(mat)))

  // sort for table
  output = output.sort((a, b) => {
    const diff = a.order - b.order
    if (isNaN(diff)) return a.order.localeCompare(b.order)
    return diff
  })
  return output
}

export const normalizeSingleMaterial = (mat) => {
  mat.material_name = mat.name

  // to simplify ordering we add in the string in the callsign, or name
  mat.order = _material_order(mat)
  mat.name_lowercase = mat.name?.toLowerCase()

  return mat
}

export const normalizePartsListForCascade = (parts) => {
  // reduce partslist for cascade function to parse, contains a recursive function on itself
  if (!parts) return []
  const normalize = (part) => ({ value: part.id, label: part.name, children: part.parts.map(p => normalize(p)) })
  return parts?.map(part => normalize(part))
}
