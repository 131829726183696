import {
  CheckCircleFilled,
  InfoCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Flex } from "antd";
import React from "react";
import { colors } from "~/theme/colors.js";

const ScoreCircle = ({ score, backgroundColor }) => {
  return (
    <Flex
      align="center"
      justify="center"
      style={{
        fontSize: 14,
        color: "white",
        width: 18,
        height: 18,
        textAlign: "center",
        borderRadius: "50%",
        backgroundColor: backgroundColor,
      }}
    >
      <p className="bold">{score}</p>
    </Flex>
  );
};

export const BevoegdheidScore = ({ score }) => {
  // options: "briefing", "demo", 0, 1, 2, 3, 4, 5, "assigned", "revoked"

  if (score == "assigned") {
    return (
      <CheckCircleFilled style={{ color: colors.groen_dark, fontSize: 18 }} />
    );
  }

  if (score === 0 || score == "revoked") {
    return (
      <MinusCircleOutlined style={{ color: colors.gray_light, fontSize: 18 }} />
    );
  }

  if (score == "briefing" || score == "demo") {
    return (
      <ScoreCircle
        score={score.substring(0, 1).toUpperCase()}
        backgroundColor={colors.groen}
      />
    );
  }

  if (isFinite(score) && score > 0) {
    return (
      <ScoreCircle
        score={score}
        backgroundColor={score === 5 ? colors.groen_dark : colors.orange}
      />
    );
  }

  return (
    <InfoCircleOutlined style={{ color: colors.gray_light, fontSize: 18 }} />
  );
};
