import { CheckOutlined, CloseOutlined, EnterOutlined, SaveOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, DatePicker, Modal, Popconfirm } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { $t } from '~/i18n.js'
import { _parseDate } from '../../../lib/localize'
import { persistActions } from '../../../redux/actions'

export const DtoTrainingUpdateModal = ({ visible, onClose, pilot, data, saveNewResult }) => {
  const [newStart, setStart] = useState(null)
  const [newCompleted, setCompleted] = useState(null)
  useEffect(() => {
    setStart(null)
    setCompleted(null)
  }, [data, setStart, setCompleted])
  if (!data) return null

  const dispatch = useDispatch()

  const [pendingSave, setPendingSave] = useState('')
  const onSaveTraining = async (newData) => {
    setPendingSave(newData.action)
    const payload = {
      user_id: pilot.id,
      ...data,
      ...newData
    }
    const res = await dispatch(persistActions.saveDTOtraining(payload))
    saveNewResult(res)
    setPendingSave('')
    onClose()
  }

  return (
    <Modal
      title={$t('Wijzig trainingsprogramma')}

      open={visible}
      confirmLoading={pendingSave}
      onCancel={onClose}
      footer={[
        <Button key='back' onClick={onClose}>
          {$t('Sluit')}
        </Button>
      ]}
    >
      <p style={{ margin: 12 }}>{$t('Wijzig trainingsprogramma')}</p>

      <div className='row'>
        <Button
          style={{ marginLeft: 12 }} type='primary'
          loading={pendingSave === 'started'}
          disabled={data.status === 'started'}
          onClick={() => onSaveTraining({ action: 'started' })}
          icon={<EnterOutlined />}
        >
          {$t('Start')}
        </Button>
        <Button
          style={{ marginLeft: 12 }} type='primary'
          disabled={data.status !== 'started'}
          loading={pendingSave === 'completed'}
          onClick={() => onSaveTraining({ action: 'completed' })}
          icon={<CheckOutlined />}
        >
          {$t('Rond af')}
        </Button>

        <Popconfirm
          placement='bottom' title={$t('Zeker weten dat je de opleiding wilt staken?')}
          key='staak'
          onConfirm={() => onSaveTraining({ action: 'discontinued' })}
          okText={$t('Ja')} cancelText={$t('Nee')}
        >
          <Button
            style={{ marginLeft: 12 }} danger
            loading={pendingSave === 'discontinued'}
            disabled={data.status !== 'started'}
            icon={<CloseOutlined />}
          >
            {$t('Staak')}
          </Button>
        </Popconfirm>

        <Popconfirm
          placement='bottom' title={$t('Zeker weten dat je de gehele voor deze leerling wilt verwijderen?')}
          key='delete'
          onConfirm={() => onSaveTraining({ action: 'delete' })}
          okText={$t('Ja')} cancelText={$t('Nee')}
        >
          <Button
            style={{ marginLeft: 12 }} danger
            loading={pendingSave === 'delete'}
            disabled={data.status !== 'started'}
            icon={<WarningOutlined />}
          >
            {$t('Verwijder')}
          </Button>
        </Popconfirm>

      </div>

      {(data.started_date || data.completed_date) &&
        <div style={{ marginTop: 32, marginLeft: 12 }}>

          <p style={{ marginBottom: 6 }}>          {$t('Wijzig in en uistroom data:')}
          </p>

          {data.started_date && <div className='row'>
            <p style={{ marginRight: 12 }}>{$t('Start')}:</p>
            <DatePicker
              format={localStorage.localeFormat}
              onChange={(value) => setStart(value ? value.format('YYYY-MM-DD') : null)}
              placeholder={_parseDate(data.started_date)}
            />
          </div>}

          {data.completed_date && <div className='row'>
            <p style={{ marginRight: 12 }}>{$t('Einde')}:</p>

            <DatePicker
              format={localStorage.localeFormat}
              onChange={(value) => setCompleted(value ? value.format('YYYY-MM-DD') : null)}
              placeholder={_parseDate(data.completed_date)}
            />
          </div>}

          <div className='row' style={{ marginTop: 12 }}>
            <Button
              type='primary'
              disabled={!newStart && !newCompleted}
              onClick={() => onSaveTraining({
                action: 'save',
                started_date: newStart || null,
                completed_date: newCompleted || null
              })}
              loading={pendingSave === 'save'}
              icon={<SaveOutlined />}
            >
              {$t('Opslaan')}

            </Button>

          </div>

        </div>}

    </Modal>
  )
}
