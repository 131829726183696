import * as Sentry from '@sentry/browser'

import { version } from '../../package.json'

export const setupSentry = (token = '', environment = '') => {
  if (!token) {
    throw new Error('Token is required')
  }

  Sentry.init({
    dsn: token,
    environment,
    release: version
  })
}

export const identifyUser = (token = '', email = '') => {
  if (!token) {
    throw new Error('Token is required')
  }

  try {
    Sentry.configureScope((scope) => {
      scope.setUser({ username: email })
    })
  } catch (e) {
    console.error('Sentry identify error: ', e)
  }
}

export const logException = (err, msg) => {
  try {
    Sentry.captureMessage(msg)
    err && Sentry.captureException(err)
  } catch (e) {
    console.error('Sentry error: ', e)
    console.log(msg)
    console.log(err)
  }
}
