import { Tag } from 'antd'
import React from 'react'
import { $t } from '~/i18n.js'
import { ReadMore } from '../../../components/readmore/readMore'
import { AangepasteUren } from './aangepaste.uren'
import { RegistratieStatus } from './registratie.status'

const Naam = () => ({
  title: $t('Naam'),
  dataIndex: 'user_name',
  sorter: (a, b) => ('' + a.user_name).localeCompare(b.user_name)
})

const Datum = () => ({
  title: $t('Datum'),
  dataIndex: 'date',
  sorter: (a, b) => ('' + a.date).localeCompare(b.date)
})

const Uren = () => ({
  title: $t('Uren'),
  dataIndex: 'uren',
  render: t => <p>{t.toFixed(1)}</p>
})

const Materiaal = () => ({
  title: $t('Materiaal'),
  dataIndex: 'project',
  sorter: (a, b) => ('' + a.project).localeCompare(b.project),
  render: (text, record) => <p>{record.is_prive && <Tag>prive</Tag>} {text}</p>
})

const Beschrijving = () => ({
  title: $t('Beschrijving'),
  dataIndex: 'beschrijving',
  render: (text) => <ReadMore text={text} maxLength={50} />
})

const Registratie = (status, openRegistratie, magGoedkeuren) => {
  return {
    title: '',
    render: (_, registratie) => <RegistratieStatus periodState={status} openRegistratie={openRegistratie} registratie={registratie} magGoedkeuren={magGoedkeuren} />,
    sorter: (a, b) => ('' + a.status).localeCompare(b.status),
    filters: [
      { text: $t('in aanvraag'), value: 'in_aanvraag' },
      { text: $t('geaccepteerd'), value: 'geaccepteerd' },
      { text: $t('afgewezen'), value: 'afgewezen' }
    ],
    onFilter: (value, record) => record.status === value
  }
}

export const registratieKolommen = (status, openRegistratie, skipMaterial, magGoedkeuren) => {
  if (skipMaterial) {
    return [
      Naam(),
      Datum(),
      Uren(),
      Beschrijving(),
      Registratie(status, openRegistratie, magGoedkeuren)
    ]
  } else {
    return [
      Naam(),
      Datum(),
      Uren(),
      Materiaal(),
      Beschrijving(),
      Registratie(status, openRegistratie, magGoedkeuren)
    ]
  }
}

export const overzichtVerplichtingKolommen = (wijzig_winteruren) => [
  {
    title: $t('Naam'),
    dataIndex: 'user_name',
    sorter: (a, b) => ('' + a.user_name).localeCompare(b.user_name)
  },
  {
    title: $t('Club starts'),
    dataIndex: 'club_starts',
    sorter: (a, b) => a.club_starts - b.club_starts
  },
  {
    title: $t('Club uren'),
    dataIndex: 'club_uren',
    sorter: (a, b) => a.club_uren - b.club_uren
  },
  {
    title: $t('Verplichting'),
    render: (_, verplichting) => <AangepasteUren verplichting={verplichting} wijzig_winteruren={wijzig_winteruren} />,
    sorter: (a, b) => a.uren_verplichting - b.uren_verplichting,
    filters: [
      { text: $t('Aangepast'), value: 0 },
      { text: $t('Zoals berekend'), value: 1 }
    ],
    onFilter: (value, record) => value === 1
      ? record.uren_berekend === record.uren_verplichting
      : record.uren_berekend !== record.uren_verplichting
  },
  {
    title: $t('Gemaakt'),
    dataIndex: 'uren_gemaakt',
    sorter: (a, b) => a.uren_gemaakt - b.uren_gemaakt,
    render: t => <p>{t.toFixed(1).replace('.0', '')}</p>
  },
  {
    title: $t('Openstaand'),
    dataIndex: 'uren_open',
    sorter: (a, b) => a.uren_open - b.uren_open,
    render: t => <p>{t.toFixed(1).replace('.0', '')}</p>
  }

]
