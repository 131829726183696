import {
  LockFilled,
  RightOutlined,
  TeamOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { Button, Space, Tag, Tooltip } from "antd";
import React from "react";
import { ReadMore } from "~/components/readmore/readMore.jsx";
import { $t } from "~/i18n.js";
import { parseMinutes, toLocaleDate } from "~/lib/localize.js";

const renderValue = (value) => (
  <Space>
    <p>{value}</p>
  </Space>
);

const day = (mobile) => ({
  title: $t("Datum"),
  dataIndex: "datum",
  width: 120,
  render: (_, dag) => (
    <p>
      {toLocaleDate(new Date(dag.datum), {
        weekday: "short",
        month: "2-digit",
        day: "2-digit",
      })}
      {mobile && dag.briefing_tijd !== "09:00" && ` om ${dag.briefing_tijd}`}
    </p>
  ),
});

const vliegveld = () => ({
  title: $t("Locatie"),
  dataIndex: "vertrek_vliegveld",
});
const soort = () => ({
  title: $t("Dag"),
  dataIndex: "type_dag",
  render: (value, dag) => (
    <p>
      {!dag.gaat_door && (
        <Tag style={{ marginRight: 4 }} color="red">
          {$t("Geannuleerd")}
        </Tag>
      )}

      <span style={{ margin: "0 4px" }}>
        {value}
        {dag.titel && dag.titel.length > 0 && ": " + dag.titel}
      </span>

      {dag.gaat_door && dag.status === "locked" && (
        <Tooltip placement="top" title={$t("Dag is gesloten voor wijzigingen")}>
          <LockFilled style={{ color: "black", marginRight: 4 }} />
        </Tooltip>
      )}

      {dag.gaat_door && dag.status === "closed" && (
        <Tooltip
          placement="top"
          title={$t("Dag is gesloten maar open voor wijzigingen")}
        >
          <UnlockOutlined style={{ color: "black", marginRight: 4 }} />
        </Tooltip>
      )}

      {dag.gaat_door && dag.status === "open" && dag.can_start && (
        <Tag style={{ marginRight: 4 }} color="blue">
          {$t("Open")}
        </Tag>
      )}

      {!dag.is_vliegend && <TeamOutlined />}
    </p>
  ),
});

const starts = (totals) => ({
  title: $t("Starts"),
  dataIndex: "starts",
  width: 60,
  render: (_r, row) =>
    renderValue(totals[row.dag_id] && totals[row.dag_id].starts),
});

const uren = (totals) => ({
  title: $t("Uren"),
  dataIndex: "uren",
  width: 60,
  render: (_r, row) =>
    renderValue(parseMinutes(totals[row.dag_id] && totals[row.dag_id].minutes)),
});
const lier = (totals) => ({
  title: $t("Lier"),
  dataIndex: "lier",
  width: 60,
  render: (_r, row) =>
    renderValue(totals[row.dag_id] && totals[row.dag_id].lier),
});
const sleep = (totals) => ({
  title: $t("Sleep"),
  dataIndex: "sleep",
  width: 60,
  render: (_r, row) =>
    renderValue(totals[row.dag_id] && totals[row.dag_id].sleep),
});
const zelf = (totals) => ({
  title: $t("Zelf"),
  dataIndex: "zelf",
  width: 60,
  render: (_r, row) =>
    renderValue(totals[row.dag_id] && totals[row.dag_id].zelf),
});

const aanmeldingen = (totals, mijn_aanmeldingen, mijn_diensten) => ({
  title: $t("Aanmeldingen"),
  dataIndex: "aanmeldingen",
  render: (_r, row) =>
    row.gaat_door && (
      <div>
        <span style={{ marginRight: 6 }}>
          {totals[row.dag_id] && totals[row.dag_id].aanmeldingen}
        </span>
        {mijn_diensten &&
        mijn_diensten[row.dag_id] &&
        Array.isArray(mijn_diensten[row.dag_id])
          ? mijn_diensten[row.dag_id].map((dienst, key) => (
              <Tag key={key} color="geekblue">
                {dienst.rooster_name}
              </Tag>
            ))
          : mijn_aanmeldingen &&
            mijn_aanmeldingen[row.dag_id] && (
              <Tag color="geekblue">{$t("aangemeld")}</Tag>
            )}
      </div>
    ),
});

const notitie = (totals) => ({
  title: $t("Notitie"),
  dataIndex: "notitie",
  key: "notitie",
  render: (_r, row) =>
    renderValue(
      totals[row.dag_id] && totals[row.dag_id].notitie && (
        <ReadMore
          text={totals[row.dag_id].notitie.bericht}
          hideMore
          maxLength={60}
        />
      )
    ),
});

const actions = {
  title: "",
  width: 40,
  render: () => (
    <Space>
      <Button
        type="primary"
        icon={<RightOutlined />}
        size="small"
        shape="circle"
      />
    </Space>
  ),
};

export const dagenColumns = (
  totals,
  mijn_aanmeldingen,
  mijn_diensten,
  link_to
) => {
  const briefing_tijd = { title: $t("Briefing"), dataIndex: "briefing_tijd" };

  if (!link_to || link_to === "register") {
    return [
      day(),
      vliegveld(),
      briefing_tijd,
      soort(),
      aanmeldingen(totals, mijn_aanmeldingen, mijn_diensten),
      actions,
    ];
  }
  if (link_to === "dailyreport") {
    return [
      day(),
      vliegveld(),
      briefing_tijd,
      soort(),
      starts(totals),
      notitie(totals),
      actions,
    ];
  }
  if (link_to === "start") {
    return [
      day(),
      vliegveld(),
      briefing_tijd,
      soort(),
      starts(totals),
      uren(totals),
      lier(totals),
      sleep(totals),
      zelf(totals),
      actions,
    ];
  }
  if (link_to === "tow") {
    return [
      day(),
      vliegveld(),
      soort(),
      starts(totals),
      sleep(totals),
      actions,
    ];
  }
};

export const dagenMobileColumns = (
  totals,
  mijn_aanmeldingen,
  mijn_diensten,
  link_to
) => {
  if (!link_to || link_to === "register") {
    return [
      day(true),
      soort(),
      aanmeldingen(totals, mijn_aanmeldingen, mijn_diensten),
    ];
  }
  if (link_to === "dailyreport") {
    return [day(true), vliegveld(), soort(), starts(totals)];
  }
  if (link_to === "start") {
    return [day(true), vliegveld(), soort(), starts(totals), sleep(totals)];
  }
  if (link_to === "tow") {
    return [day(true), vliegveld(), soort(), starts(totals), sleep(totals)];
  }
};
