import React from 'react'

export const DescriptionRow = ({ title, children }) => <div className='row' style={{ justifyContent: 'space-between' }}>
  <p>
    {title}
  </p>

  <p style={{ lineHeight: '14px' }}>
    {children}
  </p>
                                                       </div>
