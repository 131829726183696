import React from "react";
import { Switch } from "react-router-dom";
import { $t } from "~/i18n.js";
import { PrivateRoute } from "~/router.jsx";
import { MainLayout } from "../../components/layout/layout.jsx";
import { AdminDTO } from "./dto/AdminDTO.jsx";
import { AdminFlights } from "./flights/AdminFlights.jsx";
import { AdminGroups } from "./groups/AdminGroups.jsx";
import { AdminSettings } from "./settings/AdminSettings.jsx";
import { AdminUsers } from "./users/AdminUsers.jsx";

export function AdminRoutes({ children = [] }) {
  return (
    <MainLayout>
      <Switch>
        <PrivateRoute
          path="/admin/flights"
          component={AdminFlights}
          access_required={["ziet_staff"]}
          title={$t("Flights")}
        />
        <PrivateRoute
          path="/admin/users"
          component={AdminUsers}
          access_required={["wijzig_leden"]}
          title={$t("Gebruikers")}
        />
        <PrivateRoute
          path="/admin/groups"
          component={AdminGroups}
          access_required={["wijzig_leden"]}
          title={$t("Groepen")}
        />
        <PrivateRoute
          path="/admin/dto_settings"
          component={AdminDTO}
          access_required={["wijzig_dto"]}
          title={$t("DTO Settings")}
        />
        <PrivateRoute
          path="/admin/settings"
          component={AdminSettings}
          access_required={["ziet_staff"]}
          title={$t("Club Settings")}
        />
        {children}
      </Switch>
    </MainLayout>
  );
}
