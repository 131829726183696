import { message } from 'antd'
import { $t } from '~/i18n'
import { config } from '../../config'
import { api } from '../../lib/api'
import { identifyUser } from '../../lib/sentry'
import { resetAllStates } from '../reducer'
import { normalizeVliegerData } from './persist.normalizers'

export const getAllClubs = () => {
  return async (dispatch) => {
    const all_clubs = await api.get('', `${config.base_backend_url}/all/clubs.json`)
    if (all_clubs) {
      dispatch({
        type: 'SET_CLUBS',
        all_clubs
      })
    }
  }
}

export const profileAction = (data) => {
  return async (dispatch) => {
    // get or save profile
    const res = data
      ? (
          data.avatar
            ? await api.post_form('gegevens/profile.json', data)
            : await api.post('gegevens/profile.json', data)
        )
      : await api.get('gegevens/profile.json')

    if (res) {
      dispatch({
        type: 'SET_PROFILE_EXTENDED_DATA',
        profile: res.profile,
        gegevens: res.gegevens,
        club: res.club,
        bestanden: res.bestanden
      })
    } else {
      dispatch({
        type: 'PERSIST_ERROR'
      })
    }
    return res
  }
}

export const checkShown = ({ save }) => {
  return async (dispatch) => {
    dispatch({ type: 'PROFILE_SHOW_CHECK', save })
    if (save) api.get('gegevens/checked_data.json')
  }
}

export const setPreferences = (data) => {
  return async (dispatch) => {
    dispatch({ type: 'SET_PERSONAL_PREFERENCES', preferences: data })
  }
}

export const getVliegerData = ({ year }) => {
  return async (dispatch) => {
    // get vlieger data of the vlieger itself
    const vlieger_data = await api.post('gegevens/view_profile.json', { year })
    vlieger_data && dispatch({
      type: 'SET_VLIEGER_DATA',
      vlieger_data
    })
  }
}

export const getVliegerProfile = ({ id }) => {
  return async (dispatch) => {
    // get vlieger data of the vlieger itself
    let vlieger_data = await api.post('gegevens/view_profile.json', { id })
    if (vlieger_data) {
      vlieger_data = normalizeVliegerData(vlieger_data)
      dispatch({
        type: 'UPDATE_VLIEGER_IN_LIST',
        id,
        vlieger_data
      })
    }
    return vlieger_data
  }
}

export const saveDTOtraining = ({ user_id, cluster_id, action, started_date, completed_date }) => {
  return async (dispatch) => {
    // save dto training programma
    const payload = { user_id, cluster_id, action }
    if (started_date) payload.started_date = started_date
    if (completed_date) payload.completed_date = completed_date
    const res = await api.post('bevoegdheid/save_training.json', { user_id, cluster_id, action, started_date, completed_date })
    if (res) {
      dispatch({
        type: 'UPDATE_PERSIST_VLIEGER_DATA_DTO_TRAINING',
        cluster_id,
        dto_training: res.dto_training
      })
      if (res.message) {
        const msg_lookup = {
          deleted: $t('Training verwijderd van gebruiker'),
          visible: $t('Training gestart, programma nu zichtbaar in het profiel'),
          completed: $t('Training succesvol afgerond'),
          stopped: $t('Training gestaakt')
        }
        message.success(msg_lookup[res.message] || res.message)
      }
      return res.dto_training
    }
  }
}

const _completeLogin = (dispatch, res, history, navigateTo) => {
  if (res && res.access_token) {
    // set token in api so we can make auth calls again
    api.setToken(res.access_token)

    // set sentry
    if (config.sentry_key) identifyUser(config.sentry_key, res.profile.user_name)

    dispatch({
      type: 'SET_PROFILE_EXTENDED_DATA',
      profile: res.profile,
      gegevens: res.gegevens,
      club: res.club,
      bestanden: res.bestanden
    })
    dispatch({
      type: 'SET_AUTHENTICATED'
    })

    // and navigate
    const urlParams = new URLSearchParams(window.location.search);
    const redirect = urlParams.get('redirect');
    
    history.push(navigateTo || redirect || '/')
  } else {
    dispatch({
      type: 'PERSIST_ERROR'
    })
  }
  return res && res.profile
}

export const login = ({ email, password, remember, history }) => {
  return async (dispatch) => {
    dispatch({
      type: 'PERSIST_PENDING'
    })
    const data = {
      grant_type: 'login',
      client_secret: config.client_secret,
      email,
      password,
      remember
    }
    const res = await api.post('auth/login.json', data)
    return _completeLogin(dispatch, res, history)
  }
}

export const login_with_password_link = ({ password, password_reset_code, history }) => {
  return async (dispatch) => {
    dispatch({
      type: 'PERSIST_PENDING'
    })
    const data = {
      grant_type: 'password_link',
      client_secret: config.client_secret,
      password,
      password_reset_code
    }
    const res = await api.post('auth/login.json', data)
    return _completeLogin(dispatch, res, history)
  }
}

export const login_with_email_activation_link = ({ new_email, email_code, history }) => {
  return async (dispatch) => {
    dispatch({
      type: 'PERSIST_PENDING'
    })
    const data = {
      grant_type: 'email_activation_link',
      client_secret: config.client_secret,
      new_email,
      email_code
    }
    const res = await api.post('auth/login.json', data)
    return _completeLogin(dispatch, res, history, '/mijn_gegevens')
  }
}

export const login_with_accesstoken = ({ token, history }) => {
  return async (dispatch) => {
    dispatch({
      type: 'PERSIST_PENDING'
    })
    const data = {
      grant_type: 'access_token',
      client_secret: config.client_secret,
      token
    }
    const res = await api.post('auth/login.json', data)
    return _completeLogin(dispatch, res, history)
  }
}

export const getFeedback = () => {
  return async (dispatch) => {
    const feedback = await api.get('feedback.json')
    dispatch({
      type: 'SET_FEEDBACK',
      feedback
    })
  }
}

export const logout = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'PERSIST_PENDING'
      })
      await api.post('auth/logout.json', {})
      resetAllStates(dispatch)
    } catch (error) {
      resetAllStates(dispatch)
    }
  }
}
