import { Button, Card } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { $t } from '~/i18n.js'
import { MemberRow } from '../tabs/data/card.members'

export const TeamsOverviewTab = ({ teams, search, searchTag }) => {
  const list_teams = teams?.filter((row) => {
    const inCategory =
      searchTag.length === 0 ||
      searchTag.some(
        (t) => row.kist_category === t || row.material_category === t
      )
    if (search) {
      let searchString = row.name
      row.members.forEach((m) => (searchString += ' ' + m.user?.name))
      return inCategory && searchString?.toLowerCase().indexOf(search) > -1
    }
    return inCategory
  })
  return (
    <div className='column' style={{ gap: 12, alignItems: 'flex-start' }}>
      {list_teams.map((team, key) => {
        if (team.members?.length === 0) return null
        const url = `/material/${team.id}/data`
        return (
          <Card
            size='small'
            key={key}
            type='inner'
            title={
              <Link key='open' to={url} className='inherit'>
                <p className='clickable'>{team.name}</p>
              </Link>
            }
            style={{ width: '100%' }}
            extra={[
              <Link key='open' to={url}>
                <Button type='primary' size='small'>
                  {$t('Open')}
                </Button>
              </Link>
            ]}
          >
            {team.members.map((member, idx) => (
              <MemberRow key={idx} member={member} />
            ))}
          </Card>
        )
      })}
    </div>
  )
}
