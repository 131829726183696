import { Button, Descriptions, Table } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { $t } from '~/i18n.js'

import { PlusOutlined } from '@ant-design/icons'
import { BreadcrumbHeader } from '../../components/layout/breadcrumbHeader.jsx'
import { MainLayout } from '../../components/layout/layout.jsx'
import { defaultTableConfigWithSizing } from '../../components/layout/table.jsx'
import { updateIfNeeded } from '../../lib/helpers.js'
import { getMijnOnderhoud } from '../../redux/onderhoud/onderhoud.actions.js'
import { RegistratieForm } from '../onderhoud/registratie.form.jsx'
import { registratieKolommen } from '../onderhoud/section/columns.onderhoud.uren.jsx'

export const MijnOnderhoudPagina = ({ history }) => {
  const { onderhoud } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [activeRegistratie, setActiveRegistratie] = useState(null)

  useEffect(() => {
    updateIfNeeded(onderhoud.lastUpdatedMijnVerplichting, () => dispatch(getMijnOnderhoud()))
  }, [onderhoud.lastUpdatedMijnVerplichting, dispatch])

  const v = onderhoud.verplichting
  const uren_gemaakt = onderhoud.registraties.reduce((all, r) => {
    if (r.status !== 'afgewezen' && !r.is_prive) all += r.uren
    return all
  }, 0)

  return (
    <MainLayout history={history} isLoading={onderhoud.isPending}>

      {activeRegistratie && onderhoud.verplichting && <RegistratieForm
        visible={!!activeRegistratie}
        registratie={activeRegistratie}
        showAllUsers={false}
        closeModal={() => setActiveRegistratie(null)}
                                                      />}

      <BreadcrumbHeader
        breadcrumbs={[
          <p key='1' className='title'>{$t('Mijn onderhoudsuren')} - {v.period?.naam} {v.period?.status === 'gesloten' ? ` (${$t('gesloten')})` : ''}</p>
        ]}
      />

      {!v.period &&
        <p>{$t('Er zijn op dit moment geen onderhoudsperiodes actief. Zodra er een periode actief is zie je hier je verplichting aan werkuren op basis van je gebruik van clubmateriaal.')}
        </p>}

      {v.period && (
        <div>
          {v.period.remark && <p style={{ margin: '6px 0' }}>{v.period.remark}</p>}
          <p style={{ margin: '6px 0' }}>{$t('Onderhoudsformule = factor * √ (starts op clubkisten * uren op clubkisten)')}</p>

          {v.period.status === 'concept'
            ? <div style={{ marginBottom: 6 }}>
              <p>{$t('Op dit moment is de onderhoudsperiode nog in concept. Zodra de definitieve factor en minimum uren bekend zijn volgt hier je verplichting.')}</p>
              <p>{$t('Je kan in de tussentijd alvast wel je uren registreren.')}</p>
            </div>
            : <Descriptions size='small' column={2}>

              <Descriptions.Item label={$t('Factor')}>{v.period.factor || 1} x</Descriptions.Item>
              <Descriptions.Item label={$t('Minimum')}>{v.period.minimum_uren || 0} {$t('uren')}</Descriptions.Item>

              <Descriptions.Item label={$t('Club starts')}>{v.club_starts || 0} {$t('starts')}</Descriptions.Item>
              <Descriptions.Item label={$t('Club uren')}>{v.club_uren || 0} {$t('uren')} </Descriptions.Item>

              <Descriptions.Item label='Verplichting'>{v.uren_verplichting || 0} uren</Descriptions.Item>
              <Descriptions.Item label='Gemaakte uren'>{parseInt(uren_gemaakt)} {v.uren_verplichting > 0 &&
                <span style={{ whiteSpace: 'pre' }}> ({uren_gemaakt === 0 ? '0' : parseInt(uren_gemaakt / v.uren_verplichting * 100)}%)</span>}
              </Descriptions.Item>
            </Descriptions>}

          <Button
            style={{ margin: '0 6px 8px 0' }} icon={<PlusOutlined />}
            type='primary'
            disabled={v.period.status === 'gesloten'}
            onClick={() => setActiveRegistratie({
              date: dayjs().format('YYYY-MM-DD')
            })}
          >
            {$t('Voeg uren toe')}
          </Button>

          <Table
            style={{ marginTop: 12, width: '95vw', overflowX: window.innerWidth < 500 ? 'auto' : 'default' }}
            pagination={defaultTableConfigWithSizing}
            size='small'
            rowKey='id'
            dataSource={onderhoud.registraties}
            columns={registratieKolommen(v.period.status, (r) => setActiveRegistratie(r))}
          />

        </div>
      )}

    </MainLayout>
  )
}
