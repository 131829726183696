import { DashOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React from 'react'
import { $t } from '~/i18n.js'
import { colors } from '~/theme/colors.js'

export const ReadMore = ({ text, maxLength=25, hideMore=false }) => {
  if (!text) return ''
  return (
    <p style={{ maxWidth: 200, marginBottom: 0 }}>
      {text.substr(0, (maxLength || 35))}
      {text.length > (maxLength || 35) &&
        <span className={hideMore ? '' : 'clickable'} onClick={hideMore ? null : (e) => {
          e.preventDefault()
          Modal.info({ content: text, icon: <DashOutlined />, okText: $t('Sluit') })
        }
        }>
          ... {!hideMore && <span style={{ color: colors.primary }}> meer</span>}
        </span>}
    </p>
  )
}
