export const aanmeldingenState = {

  isPending: false,

  lastUpdated: null,
  dag_id: null,
  diensten: [],
  messages: [],
  notities: {},
  aanmeldingen: [],
  sleep_vliegers: [],
  kisten: [],
  rollen: []
}
