import { $t } from '~/i18n.js';
import { validateEmail } from '~/lib/helpers.js';

export function LengthValidator(max = 100, required = true) {
    return {
        required: required,
        max: max,
        message: $t("Maximaal ${max} characters", { max: max }),
    }
}

export function EmailValidator(required=false) {
    return ({
        validator(_, email) {
            
            let isValid = true
            if (!email) { 
                if (required) isValid = false
            } else {
                if (!validateEmail(email)) isValid = false
            }

            if (!isValid) return Promise.reject($t('Voeg valide emailadres toe'))
        
            return Promise.resolve()
            
        }
    })
}


export function NumberValidator(min = 0, max = 100) {
    return ({
        validator(_, value) {

            if (value > max) return Promise.reject($t("Maximaal ${max}", { max: max }))
            if (value < min) return Promise.reject($t("Minimaal ${min}", { min: min }))

            return Promise.resolve()

        }
    })
}

export const ValidateEmailListValidator = () => ({
    validator(_, values) {

        if (!values) { return Promise.resolve() }

        // existing group or correct email should validate properly
        const incorrectValues = []
        values.split(',').map((v) => {
            if (
                !validateEmail(v)
            ) {
                incorrectValues.push(v)
            }
        })
        if (incorrectValues.length > 0) {
            return Promise.reject(`"${incorrectValues.join(', ')}" ${$t('is incorrect, voeg valide emailaddressen toe.')} `)
        }
        return Promise.resolve()
    }
})