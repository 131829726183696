import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons'
import { Table, Tag } from 'antd'
import React, { useState } from 'react'
import { useMedia } from 'use-media'
import { $t, i18n } from '~/i18n.js'
import { MobileFlightColumns, WideFlightColumns } from './tabelVluchten.columns.jsx'

import { IGCButton } from '~/components/IGC/IGC.button.jsx'
import { LandingIcon, StartIcon } from '~/components/icon/icon.jsx'
import { defaultTableConfig } from '~/components/layout/table.jsx'
import { TijdKnopAndEdit } from '~/components/selectedFlight/sections/tijdKnopAndEdit.jsx'
import { selectElementByID } from '~/lib/helpers.js'
import { parseMinutes } from '~/lib/localize.js'
import './tabelVluchten.css'
import { OGNlandingsTijd, OGNstartTijd } from "~/components/selectedFlight/ogn.jsx";

export const TabelVluchten = ({
  getPending,
  allFlights,
  activeFlightUUID,
  showLandingButton,
  updateFlight,
  openFlight,
  renderFullWidth,
}) => {
  const isHigh = useMedia({ minHeight: "750px" }, false);

  const columns = renderFullWidth
    ? WideFlightColumns(updateFlight, showLandingButton)
    : MobileFlightColumns();

  const landFlight = async (sleep_flight, newTime) => {
    await updateFlight({
      uuid: sleep_flight.uuid,
      start_tijd: sleep_flight.start_tijd,
      landings_tijd: newTime,
      start_methode: sleep_flight.start_methode,
      // only set action if no landingstime has been set, in which case server will overrule landingstime
      action: sleep_flight.landings_tijd ? "" : "land",
    });
  };

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const triggerExpandSleep = (expanded, flight) => {
    // toggle flight from expanded list, and open flight if toggled
    const newKeys = expanded
      ? expandedRowKeys.concat(flight.uuid)
      : expandedRowKeys.filter((k) => k !== flight.uuid);
    setExpandedRowKeys(newKeys);
    expanded && showLandingButton && openFlight(flight.uuid);
  };

  const sleepExpandableRow = (flight) => {
    const sleep_flight = selectElementByID(
      allFlights,
      "uuid",
      flight.sleep_uuid
    );

    return (
      sleep_flight && (
        <div className="row" style={{ alignItems: "center" }}>
          <p style={{ marginLeft: 50 }}>
            {sleep_flight.registratie ? (
              <span>
                {sleep_flight.callsign} {sleep_flight.registratie}
              </span>
            ) : (
              <Tag color="red" className="bounce">
                {$t("kist ontbreekt")}
              </Tag>
            )}
          </p>
          <p style={{ marginLeft: 25 }}>
            {!sleep_flight.start_tijd || sleep_flight.gezagvoerder_naam ? (
              sleep_flight.gezagvoerder_naam
            ) : (
              <Tag color="red" className="bounce">
                {$t("vlieger ontbreekt")}
              </Tag>
            )}
          </p>

          <div style={{ marginLeft: 25 }}>
            <p>
              <OGNstartTijd
                ogn_indicator={sleep_flight.start_ogn}
                time={sleep_flight.start_tijd}
                default_value={$t("in afwachting")}
              />
            </p>
          </div>

          <div style={{ marginLeft: 25 }}>
            {!showLandingButton ? (
              <p>
                <OGNlandingsTijd ogn_indicator={sleep_flight.landings_ogn} time={sleep_flight.landings_tijd} default_value={$t("Nog niet geland")} />
              </p>
            ) : (
              <TijdKnopAndEdit
                ogn_indicator={sleep_flight.landings_ogn}
                buttonAction={$t("Land sleep")}
                fieldName="landingstijd sleepvlucht"
                buttonIcon={<LandingIcon />}
                currentTime={sleep_flight.landings_tijd}
                notActive={sleep_flight.is_deleted || !sleep_flight.start_tijd}
                saveTime={(newTime) => landFlight(sleep_flight, newTime)}
                size="small"
              />
            )}
          </div>
          {sleep_flight && (
            <>
              {sleep_flight.vluchtduur > 0 && (
                <p style={{ marginLeft: 12 }}>
                  {parseMinutes(sleep_flight.vluchtduur)}
                </p>
              )}
              {sleep_flight.height > 0 && (
                <p style={{ marginLeft: 12 }}>
                  {i18n.parseLocaleHeightString(sleep_flight.height)}
                </p>
              )}
              {sleep_flight.category && (
                <p style={{ marginLeft: 12 }}>
                  <Tag>{sleep_flight.category}</Tag>
                </p>
              )}
              {sleep_flight.igc && (
                <div style={{ marginLeft: 12 }}>
                  <IGCButton flight={sleep_flight} title="IGC" />
                </div>
              )}
            </>
          )}
        </div>
      )
    );
  };

  // width depends on the user
  return (
    <div className="tableVluchten">
      <Table
        style={{
          width: renderFullWidth ? "97vw" : "calc(98vw - 210px)",
          overflowX: "auto",
          minWidth: 600,
        }}
        loading={getPending}
        pagination={defaultTableConfig}
        size="small"
        rowKey="uuid"
        className="table--clickable"
        dataSource={allFlights.filter(
          (f) => !(f.start_methode === "tmg-a" && f.sleep_uuid)
        )}
        columns={columns}
        rowClassName={(flight) => [
          flight.uuid === activeFlightUUID && "activeRow",
          showLandingButton && "tableVluchten_row--new",
          showLandingButton &&
            flight.start_tijd &&
            "tableVluchten_row--started",
          showLandingButton &&
            flight.landings_tijd &&
            "tableVluchten_row--landed",
        ]}
        scroll={
          showLandingButton &&
          renderFullWidth &&
          isHigh && { y: "calc(100vh - 340px)" }
        }
        expandable={{
          // directly open the flight in the bar above if you have landingbutton rights
          // show all flights that have been opened manually, or are selected as flight
          rowExpandable: (flight) =>
            flight.start_methode === "sleep" && flight.sleep_uuid,
          expandedRowRender: sleepExpandableRow,
          onExpand: triggerExpandSleep,
          expandedRowKeys: expandedRowKeys.concat(
            allFlights.map(
              (f) =>
                f.start_methode === "sleep" &&
                f.sleep_uuid &&
                !f.landings_tijd &&
                f.uuid
            )
          ),
          expandIcon: ({ expanded, record }) => {
            if (
              !record ||
              !record.sleep_uuid ||
              record.start_methode !== "sleep"
            )
              return null;
            return expanded ? (
              <MinusCircleTwoTone
                twoToneColor="#bfbfbf"
                onClick={() => triggerExpandSleep(false, record)}
              />
            ) : (
              <PlusCircleTwoTone
                twoToneColor="#306AD2"
                onClick={() => triggerExpandSleep(true, record)}
              />
            );
          },
        }}
        onRow={(flight) => {
          return {
            onClick: (element) => {
              if (!element.target.outerHTML.includes("weglide")) {
                openFlight(flight.uuid);
              }
            },
          };
        }}
      />
    </div>
  );
};
