import { WarningTwoTone } from '@ant-design/icons'
import { Input, Table, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import useMedia from 'use-media'
import { $t } from '~/i18n.js'

import { useSelector } from 'react-redux'
import { defaultTableConfigWithSizing } from '../../components/layout/table'
import { overzichtVerplichtingKolommen, registratieKolommen } from './section/columns.onderhoud.uren'
import { PeriodButtons } from './section/period.buttons'
import { PeriodStats } from './section/period.stats'

export const CurrentPeriodSection = ({ period, savePeriod, wijzig_winteruren }) => {
  const { profile } = useSelector((state) => state.persist)
  const magGoedkeuren = profile?.wijzig_winteruren || profile?.goedkeuren_uren

  const [search, setSearch] = useState('')
  useEffect(() => {
    setSearch('')
  }, [period, setSearch])

  const isNarrow = useMedia({ maxWidth: '600px' }, true)

  return (
    <>

      <PeriodStats period={period} />
      <PeriodButtons period={period} savePeriod={savePeriod} wijzig_winteruren={wijzig_winteruren} />

      <Input.Search
        style={{ maxWidth: 250 }}
        value={search}
        allowClear
        size='small'
        placeholder={$t('Zoek op naam')}
        onChange={(event) => {
          setSearch(event.target.value.toLowerCase())
        }}
      />

      <Tabs defaultActiveKey={period.status !== 'gesloten' && period.open_registraties.length > 0 ? 'aanvragen' : 'overzicht'}>

        {period.status !== 'gesloten' && <Tabs.TabPane tab={$t('Open aanvragen')} key='aanvragen'>

          <Table
            style={{ marginTop: 12, width: '95%', overflowX: (isNarrow ? 'auto' : '') }}
            pagination={{ ...defaultTableConfigWithSizing, defaultPageSize: 100 }}
            size='small'
            rowKey='id'
            dataSource={search === ''
              ? period.open_registraties
              : period.open_registraties.filter((r) => r.user_name.toLowerCase().includes(search))}
            columns={registratieKolommen(period.status, null, false, magGoedkeuren)}
          />

        </Tabs.TabPane>}

        <Tabs.TabPane tab={$t('Verplichtingen')} key='overzicht'>

          {search === '' && period.verplichtingen?.length === 0
            ? <p style={{ margin: 6 }}><WarningTwoTone twoToneColor='red' /> Bereken de uren om de verplichtingen aan te maken </p>
            : <Table
                style={{ marginTop: 12, width: '95%', overflowX: (isNarrow ? 'auto' : '') }}
                pagination={{ ...defaultTableConfigWithSizing, defaultPageSize: 100 }}
                size='small'
                rowKey='id'
                dataSource={search === ''
                  ? period.verplichtingen
                  : period.verplichtingen.filter((r) => r.user_name.toLowerCase().includes(search))}
                columns={overzichtVerplichtingKolommen(wijzig_winteruren)}
              />}

        </Tabs.TabPane>

        <Tabs.TabPane tab={$t('Gemaakte uren')} key='uren'>

          <Table
            style={{ marginTop: 12, width: '95%', overflowX: (isNarrow ? 'auto' : '') }}
            pagination={{ ...defaultTableConfigWithSizing, defaultPageSize: 100 }}
            size='small'
            rowKey='id'
            dataSource={search === ''
              ? period.registraties
              : period.registraties.filter((r) => r.user_name.toLowerCase().includes(search))}
            columns={registratieKolommen(period.status, null, false, magGoedkeuren)}
          />

        </Tabs.TabPane>

      </Tabs>

    </>

  )
}
