import { Tag } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

export const DifCheckTag = ({action}) => {

    if (action === 'add') return <Tag color = 'green'>{$t('Nieuw')}</Tag>
    if (action === 'update') return <Tag color = 'blue'>{$t('Aangepast')}</Tag>
    if (action === 'delete') return <Tag color = 'red'>{$t('Verwijderd')}</Tag>
    
    return null
    
}