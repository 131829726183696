import React, { useState } from 'react'
import { $t } from '~/i18n'

import { UploadOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Modal, Upload, Divider } from 'antd'

import {
  normFileDuringFormUpload,
  ruleCheckImageMaxSize
} from '~/lib/helpers'
import { MeldingTag } from './MeldingTag'

export const AddBerichtModal = ({
  pendingAction,
  onSave,
  onClose,
  saveExternalWorkorder,
  is_technicus
}) => {
  const [form] = Form.useForm()

  const [saving, setSaving] = useState(false)
  const triggerSave = async () => {
    try {
      setSaving(true)
      const values = await form.validateFields()
      const result = await onSave({
        ...values,
        bericht: saveExternalWorkorder
          ? `Workorder: ${values.workorder_number || 'niet toegevoegd'}${
              values.bericht ? '\n' + values.bericht : ''
            }`
          : values.bericht
      })
      if (result) {
        if (saveExternalWorkorder && !values.workorder_number) {
          Modal.error({
            title: $t('Workorder mist'),
            content: $t(
              'Let op, voor het sluiten van een Defect heb je een workorder nummer nodig. Laat deze achter in de notities'
            )
          })
        }

        onClose()
        form.resetFields()
      }
      setSaving(false)
    } catch (info) {
      console.log('Validate Failed:', info)
      setSaving(false)
    }
  }

  return (
    <Modal
      title={
        pendingAction === 'nieuw_bericht'
          ? $t('Voeg nieuw bericht toe')
          : $t('Bevestig')
      }
      open={!!pendingAction}
      confirmLoading={saving}
      okText={$t('Opslaan')}
      cancelText={$t('Annuleren')}
      onCancel={onClose}
      onOk={triggerSave}
    >
      <Form
        form={form}
        layout='vertical'
        name='bericht'
        preserve={false}
        requiredMark={false}
      >
        {pendingAction !== 'nieuw_bericht' && (
          <p className='spacer'>
            <span>
              {$t('Nieuwe status')}:{' '}
              <MeldingTag type={pendingAction} />
            </span>
          </p>
        )}

        <Divider />

        {saveExternalWorkorder && (
          <Form.Item
            label={$t('Workorder nummer (verplicht voor defecten)')}
            name='workorder_number'
            rules={[
              {
                required: false,
                message: $t(
                  'Voor het oplossen van defecten moet een een Workorder toevoegen.'
                )
              }
            ]}
          >
            <Input />
          </Form.Item>
        )}

        <Form.Item
          label={$t('Notitie')}
          name='bericht'
          rules={[
            {
              required: false,
              max: 700,
              message: $t('Maximaal ${c} characters', { c: 700 })
            }
          ]}
        >
          <Input.TextArea rows={4} autoFocus />
        </Form.Item>

        {is_technicus && pendingAction === 'nieuw_bericht' && (
          <Form.Item name='only_for_technicians' valuePropName='checked'>
            <Checkbox className='small'>
              {$t('Alleen zichtbaar voor technici')}
            </Checkbox>
          </Form.Item>
        )}

        <Form.Item
          name='files'
          label={
            saveExternalWorkorder
              ? $t('Certificate of Release to Service (CRS)')
              : $t('Bijlage')
          }
          valuePropName='fileList'
          getValueFromEvent={normFileDuringFormUpload}
          rules={[
            {
              required: false,
              message: $t(
                'Voor het oplossen van defecten moet een een CRS toevoegen.'
              )
            },
            ruleCheckImageMaxSize
          ]}
        >
          <Upload
            name='file'
            listType='picture'
            multiple
            beforeUpload={(file) => false}
            showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
          >
            <Button icon={<UploadOutlined />}>
              {$t('Selecteer 1 of meerdere bestanden')}
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  )
}
