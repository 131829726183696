import { $t } from '~/i18n.js'
import { StopOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Popconfirm, Tag } from 'antd'
import React, { useState } from 'react'

export const DeleteMaterial = ({
  readOnly,
  saveData,
  currentMaterial,
  history
}) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState('')
  const [loading, setLoading] = useState(false)

  const closeModal = () => {
    setShowConfirmDelete(false)
    setConfirmDelete('')
    setLoading(false)
  }

  const deleteMaterial = async () => {
    setLoading(true)
    const res = await saveData({ action: 'delete' })
    if (res) {
      closeModal()
      history.push('/')
    }
  }

  return (
    <div>
      {!readOnly && (
        <div style={{ marginTop: 42 }}>
          <Popconfirm
            title={$t('Weet je zeker dat je het materiaal wilt archiveren?')}
            disabled={readOnly}
            onConfirm={() => setShowConfirmDelete(true)}
          >
            <Button
              danger
              disabled={readOnly}
              size='small'
              icon={<StopOutlined />}
            >
              {$t('Archiveer')}
            </Button>
          </Popconfirm>
        </div>
      )}

      {!readOnly && showConfirmDelete && (
        <Modal
          title={$t('Bevestig')}
          confirmLoading={loading}
          open={showConfirmDelete}
          onCancel={closeModal}
          onOk={!readOnly && deleteMaterial}
          okButtonProps={{ disabled: confirmDelete.replace(/\s+/g, '').toLowerCase() !== currentMaterial.name.replace(/\s+/g, '').toLowerCase() }}
        >


          <p>
            <span className='bold'>{$t('Let op')}</span>,
            {$t(
              " je gaat dit materiaal archiveren, inclusief alle onderdelen, documenten en maintenance programma's."
            )}
          </p>

          <p>
            {$t('Om te "bevestigen" dat je dit bewust doet, schrijf')}{' '}
            <Tag>{currentMaterial.name}</Tag> {$t('in het input hieronder.')}
          </p>

          <Input
            type='text'
            style={{ width: 150, margin: '10px auto' }}
            value={confirmDelete}
            onChange={(elm) => setConfirmDelete(elm.target.value)}
          />
        </Modal>
      )}
    </div>
  )
}
