export const getKistStatus = (kisten, flights) => {
  // create a club_kist status report
  const kistLookup = {}
  flights.forEach(flight => {
    if (flight.kist_id && flight.start_methode !== 'tmg-a') {
      let status_found = ''
      const kist_already_assigned = kistLookup[flight.kist_id]

      // gestart of ingedeelt krijgt altijd voorrang
      if (flight.start_tijd && !flight.landings_tijd) {
        status_found = 'gestart'
      } else if (!flight.start_tijd && !flight.landings_tijd) {
        status_found = flight.gezagvoerder_id ? 'ingedeeld' : 'gepland'
      } else if (!kist_already_assigned && flight.start_tijd && flight.landings_tijd) {
        status_found = 'beschikbaar'
      }

      // add only if we need to assign latest status
      if (status_found) kistLookup[flight.kist_id] = { status: status_found, flight_uuid: status_found === 'beschikbaar' ? null : flight.uuid }
    }
  })

  // lookup all clubkisten and add info
  const kistStatus = []
  kisten.forEach((kist) => {
    if (kistLookup[kist.id]) {
      // add in status and idx to openFlight on
      kistStatus.push({ ...kistLookup[kist.id], ...kist })
    } else {
      kistStatus.push({ status: 'hangaar', ...kist })
    }
  })

  return kistStatus
}

export const getFlightOrder = (flight) => {
  // order is taken first a volg_nummer, if not found start time, if

  // sort on volg_nummer, largest above
  if (flight.volg_nummer && flight.landings_tijd) return flight.volg_nummer

  // if start time found, replace non-digits and return the integer value

  if (flight.start_tijd) {
    return (flight.start_tijd_int || parseInt(flight.start_tijd.replace(/\D/g, ''))) * 100
  }

  // if not started, sort on index, newest is concatenated below, as such sorted inverse
  return 1000000 + (flight.registratie ? 0 : 1000) + (flight.index || 0)
}

export const normalizeFlights = (flights) => {
  // normalizes event data for frontend
  const output = []
  flights.forEach((flight) => {
    flight.start_tijd_int = flight.start_tijd ? parseInt(flight.start_tijd.replace(/\D/g, '')) : 9999
    flight.order = getFlightOrder(flight)
    flight.date_time = flight.start_tijd ? Date.parse(flight.datum + 'T' + flight.start_tijd) : new Date(flight.datum)
    output.push(flight)
  })
  return output
}
