import { CheckCircleFilled } from '@ant-design/icons'
import { Checkbox, Input, Modal, Radio, Tag, Timeline } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { HelpBubble } from '../../../../components/help/help.bubble'
import { $t } from '~/i18n.js'
import { saveMaterialTab } from '../../../../redux/materiaal/materiaal.actions'
import { colors } from '../../../../theme/colors'

export const CompleteWorkorder = ({
  visible,
  workorder,
  currentMaterial,
  closeModal,
  onComplete
}) => {
  const dispatch = useDispatch()
  const { profile, gegevens } = useSelector((state) => state.persist)

  const [signoff_remaining_tasks, set_signoff_remaining_tasks] = useState(true)
  const [limitations, setLimitations] = useState('')
  const [meldingenOK, setMeldingen] = useState(false)
  const [maintenanceOK, setMaintenance] = useState(false)
  const [overallOK, setOverall] = useState(false)
  const [signOK, setSignOK] = useState(false)

  const hasMeldingen = workorder?.list_meldingen?.length > 0
  const hasMaintenance =
    workorder.category !== 'arc' && workorder?.list_maintenance?.length > 0

  // tasks completed can be set on workorder, or found in currentMaterial if tasks are completed inbetween
  const defferedTasks =
    workorder.n_tasks -
    (currentMaterial.n_tasks_completed || workorder.n_tasks_completed)
  const limitationMissing = limitations === '' && defferedTasks > 0

  const isTechnicus = profile?.groups?.some(
    (g) => g === 'technicus_vliegend' || g === 'technicus_arc'
  )
  const number = isTechnicus ? gegevens?.aml_nummer : gegevens?.brevet_nummer
  const disabled =
    (hasMeldingen && !meldingenOK) ||
    (hasMaintenance && !maintenanceOK) ||
    !overallOK ||
    !signOK ||
    (defferedTasks > 0 && signoff_remaining_tasks === false && !limitations)

  const [saving, setSaving] = useState(false)
  const triggerCompleteWorkorder = async () => {
    setSaving(true)
    const payload = {
      id: workorder.id,
      action: 'complete',
      limitations,
      signoff_remaining_tasks
    }
    const res = await dispatch(
      saveMaterialTab({
        activeTab: 'workorders',
        materialID: currentMaterial.id,
        payload
      })
    )
    if (res) {
      closeModal()
      onComplete()
      Modal.success({
        title: $t('Afgerond'),
        content: $t(
          'De workorder is afgerond en een CRS is als document toegevoegd.'
        )
      })
    }
    setSaving(false)
  }

  return (
    <Modal
      title={$t('Rond workorder af')}
      confirmLoading={saving}
      open={visible}
      onCancel={closeModal}
      okButtonProps={{ disabled }}
      onOk={triggerCompleteWorkorder}
    >
      <Timeline>
        {defferedTasks > 0 && (
          <Timeline.Item color={!limitationMissing ? 'green' : 'blue'}>
            <p> Er zijn {defferedTasks} onvoltooide taken:</p>

            <Radio.Group
              onChange={(e) => set_signoff_remaining_tasks(e.target.value)}
              value={signoff_remaining_tasks}
              className='column'
              style={{ alignItems: 'flex-start' }}
            >
              <Radio value>
                <span className='bold'>{$t('Voltooi')}</span>{' '}
                {$t('openstaande taken')}{' '}
                <HelpBubble
                  content={$t(
                    'Alle openstaande taken zullen door jou worden afgetekend, en dit is zichtbaar op de CRS.'
                  )}
                />
              </Radio>
              <Radio value={false}>
                <span className='bold'>Defer</span> {$t('openstaande taken')}{' '}
                <HelpBubble
                  content={$t(
                    'Alle openstaande kunnen worden "deferred", wat betekend dat ze niet uitgevoerd zijn. Dit wordt expliciet op de CRS vermeld.'
                  )}
                />
              </Radio>
            </Radio.Group>

            {!signoff_remaining_tasks && (
              <>
                <p className='spacer'>
                  {$t('Voeg (mogelijke) operationele limitaties toe')}{' '}
                  <HelpBubble
                    content={$t(
                      'Wanneer onderhoud niet kan worden opgeleverd, mag een CRS alsnog worden uitgegeven binnen de toegestaande vliegtuig limieten. Mogelijk dat dit invloed heeft op de operationele limitaties. Deze kan je hier toevoegen.'
                    )}
                  />
                  :
                </p>
                <Input.TextArea
                  rows={3}
                  style={{ width: 300 }}
                  value={limitations}
                  status={limitationMissing && 'error'}
                  onChange={(event) => setLimitations(event.target.value)}
                />
                <p className='alert' style={{ minHeight: 22 }}>
                  {limitationMissing
                    ? 'Voeg limitaties toe toe of vermeld dat er geen limitaties zijn'
                    : ' '}
                </p>
              </>
            )}
          </Timeline.Item>
        )}

        {hasMaintenance && (
          <Timeline.Item color={maintenanceOK ? 'green' : 'blue'}>
            <Checkbox
              checked={maintenanceOK}
              onChange={(e) => setMaintenance(e.target.checked)}
            >
              <span className='bold'>Maintenance</span>:
              {workorder.category === 'amp' && (
                <span>
                  {' '}
                  {$t(
                    'Hierbij verklaar ik dat alle afgeronde onderhoudstaken correct zijn uitgevoerd volgens M.A.801 en 145.A.50.'
                  )}
                </span>
              )}
              {workorder.category === 'non_aviation' && (
                <span>
                  {' '}
                  {$t(
                    'Hierbij verklaar ik dat alle onderhoudstaken correct zijn uitgevoerd.'
                  )}
                </span>
              )}
            </Checkbox>

            <div style={{ marginLeft: 24 }}>
              {workorder?.list_maintenance?.map((m, key) => (
                <p key={key}>
                  <CheckCircleFilled style={{ color: colors.green }} /> {m.name}
                </p>
              ))}
            </div>
          </Timeline.Item>
        )}

        {hasMeldingen && (
          <Timeline.Item color={meldingenOK ? 'green' : 'blue'}>
            <Checkbox
              checked={meldingenOK}
              onChange={(e) => setMeldingen(e.target.checked)}
            >
              <span className='bold'>Meldingen</span>:
              {workorder.category === 'amp' && (
                <span>
                  {' '}
                  {$t(
                    'Hierbij verklaar ik dat deze workorder de volgende meldingen correct zijn verholpen.'
                  )}
                </span>
              )}
              {workorder.category === 'non_aviation' && (
                <span>
                  {' '}
                  {$t(
                    'Hierbij verklaar ik dat deze workorder de volgende meldingen correct zijn verholpen.'
                  )}
                </span>
              )}
            </Checkbox>

            <div style={{ marginLeft: 24 }}>
              {workorder?.list_meldingen?.map((m, key) => (
                <p key={key}>
                  <CheckCircleFilled style={{ color: colors.green }} /> {m.name}
                </p>
              ))}
            </div>

            <p className='small' style={{ marginTop: 12, marginLeft: 24 }}>
              Als er meldingen niet zijn afgehandeld,{' '}
              <Link
                to={`/materiaal/${currentMaterial.id}/workorders/${workorder.id}/edit`}
              >
                hier
              </Link>{' '}
              kan je de meldingen verwijderen.
            </p>
          </Timeline.Item>
        )}

        <Timeline.Item color={overallOK ? 'green' : 'blue'}>
          <Checkbox
            checked={overallOK}
            onChange={(e) => setOverall(e.target.checked)}
          >
            {workorder.category === 'amp' && (
              <p className='spacer'>
                {isTechnicus
                  ? (
                    <span>
                      {$t(
                        'Hierbij verklaar ik dat de het gespecifieerde werk, tenzij anders gespecificeerd, is uitgevoerd conform Part-ML, en met betrekking tot dit werk kan een vrijgave voor gebruik van het vliegtuig gegeven worden.'
                      )}
                    </span>
                    )
                  : (
                    <span>
                      {$t(
                        'Hierbij verklaar ik dat de het pilot-owner onderhoud, tenzij anders gespecificeerd, is uitgevoerd conform Part-ML, en met betrekking tot dit werk kan een vrijgave voor gebruik van het vliegtuig gegeven worden.'
                      )}
                    </span>
                    )}
              </p>
            )}
            {workorder.category === 'arc' && (
              <p className='spacer'>
                {$t(
                  'Hierbij bevestig ik dat de Airworthiness review is uitgevoerd conform Part-M subpart I, M.A.901.'
                )}
              </p>
            )}
            {workorder.category === 'non_aviation' && (
              <p className='spacer'>
                {$t(
                  'Hierbij bevestig ik dat dit onderhoud correct is uitgevoerd.'
                )}
              </p>
            )}
          </Checkbox>
        </Timeline.Item>

        {workorder.category !== 'non_aviation' && (
          <Timeline.Item color={signOK ? 'green' : 'blue'}>
            {number && (
              <Checkbox
                checked={signOK}
                onChange={(e) => setSignOK(e.target.checked)}
              >
                {workorder.category === 'amp' && (
                  <p className='spacer'>
                    {isTechnicus
                      ? (
                        <span>
                          {$t(
                            'Hierbij teken ik digitaal de Certificate of release to service (CRS), conform M.A.801(e).5, met mijn Maintenance Licence (AML) nummer: ${gevens}',
                            { gevens: gegevens?.aml_nummer || 'ONBEKEND' }
                          )}
                        </span>
                        )
                      : (
                        <span>
                          {$t(
                            'Hierbij teken ik digitaal de Certificate of release to service (CRS), conform M.A.801(e).5, met mijn Brevet nummer: ${gevens}',
                            { gevens: gegevens?.brevet_nummer || 'ONBEKEND' }
                          )}
                        </span>
                        )}
                  </p>
                )}

                {workorder.category === 'arc' && (
                  <p className='spacer'>
                    {$t(
                      'Hierbij teken ik digitaal de Airworthiness review certificate (ARC), conform M.A.801(e).5, met mijn Maintenance Licence (AML) nummer: ${gegevensnumber}.',
                      { gegevensnumber: gegevens?.aml_nummer }
                    )}
                  </p>
                )}
              </Checkbox>
            )}

            {!number && (
              <p>
                <Tag color='red'>{$t('Let op')}</Tag>{' '}
                {$t('Geen ${isTechnicus} bekend. Ga naar', {
                  istechnicus: isTechnicus
                    ? 'Maintenance Licence (AML) nummer'
                    : 'Brevet nummer'
                })}{' '}
                <Link to='/mijn_gegevens'>{$t('mijn gegevens')}</Link>{' '}
                {$t('om je nummer toe te voegen.')}
              </p>
            )}
          </Timeline.Item>
        )}

        {workorder.category === 'non_aviation' && (
          <Timeline.Item color={signOK ? 'green' : 'blue'}>
            <Checkbox
              checked={signOK}
              onChange={(e) => setSignOK(e.target.checked)}
            >
              <p className='spacer'>{$t('Hierbij teken ik digitaal.')}</p>
            </Checkbox>
          </Timeline.Item>
        )}
      </Timeline>
    </Modal>
  )
}
