import {
  EditOutlined,
  EyeInvisibleOutlined
} from "@ant-design/icons";
import { Button, Space, Tag } from "antd";
import dayjs from "dayjs";
import React from "react";
import { $t, i18n } from "~/i18n.js";

import { ActualFlightTime } from "~/components/clock/actualFlightTime.jsx";
import { LandingIcon, WeGlideIcon } from "~/components/icon/icon.jsx";
import { FlightTagColumn } from "~/components/selectedFlight/flight.tags.jsx";
import {
  OGNlandingsTijd,
  OGNstartTijd,
} from "~/components/selectedFlight/ogn.jsx";
import { parseMinutes } from "~/lib/localize.js";
import { compareClubKisten } from "~/redux/materiaal/materiaal.normalizers.js";

const Volg = () => ({ title: "#", dataIndex: "volg_nummer", width: 35 });

const Kist = () => ({
  title: $t("Kist"),
  sorter: (a, b, sortOrder) => {
    if (sortOrder === "ascend") {
      return compareClubKisten(a, b);
    }
    return compareClubKisten(b, a);
  },
  render: (_, flight) => (
    <p>
      {flight.callsign} {flight.registratie} <br />
      {flight.type}
    </p>
  ),
});

const Gezagvoerder = () => ({
  title: $t("PIC/Instr"),
  dataIndex: "gezagvoerder_naam",
  sorter: (a, b, sortOrder) => {
    if (sortOrder === "ascend") {
      if (sortOrder === "ascend") {
        return ("" + a.gezagvoerder_naam).localeCompare(b.gezagvoerder_naam);
      }
    }
    return ("" + a.gezagvoerder_naam).localeCompare(b.gezagvoerder_naam);
  },
  render: (text, flight, index) => {
    if (flight.start_tijd && !flight.gezagvoerder_naam) {
      return (
        <Tag color="red" className="bounce">
          {$t("ontbreekt")}
        </Tag>
      );
    }
    if (flight.gezagvoerder_naam === "-") return <EyeInvisibleOutlined />;
    return <p>{flight.gezagvoerder_naam}</p>;
  },
});

const Pax = () => ({
  title: $t("Pax/DBO/2e"),
  dataIndex: "tweede_inzittende_naam",
});

const Meth = () => ({
  title: $t("Meth."),
  dataIndex: "start_methode",
  width: 60,
  render: (t) => $t(t),
});

const Tijden = () => ({
  title: $t("Tijden"),
  width: 125,
  sorter: (a, b, sortOrder) => {
    if (sortOrder === "ascend") {
      if (sortOrder === "ascend") {
        return ("" + a.start_tijd).localeCompare(b.start_tijd);
      }
    }
    return ("" + a.start_tijd).localeCompare(b.start_tijd);
  },
  render: (_, flight) => {
    return (
      <div className="row">
        {flight.start_tijd && (
          <OGNstartTijd
            ogn_indicator={flight.start_ogn}
            time={flight.start_tijd}
          />
        )}
        {flight.landings_tijd && (
          <OGNlandingsTijd
            ogn_indicator={flight.landings_ogn}
            time={flight.landings_tijd}
          />
        )}
        {flight.start_tijd && !flight.landings_tijd && flight.flarm && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="WeGlide"
            href={"https://weglide.org/live/" + flight.flarm}
          >
            <WeGlideIcon />{" "}
          </a>
        )}
      </div>
    );
  },
});

const Duur = () => ({
  title: $t("Duur"),
  dataIndex: "vluchtduur",
  width: 70,
  render: (text, flight) => {
    if (flight.landings_tijd) return parseMinutes(text);
    if (flight.start_tijd) return <ActualFlightTime flight={flight} />;
  },
});

const Height = () => ({
  title: $t("Hoogte"),
  dataIndex: "height",
  width: 70,
  render: (value) => i18n.parseLocaleHeightString(value),
});

export const MobileFlightColumns = () => [
  Volg(),
  Kist(),
  Gezagvoerder(),
  Pax(),
  Meth(),
  Tijden(),
  Duur(),
  Height(),
  FlightTagColumn(),
];

export const WideFlightColumns = (updateFlight, showLandingButton) => [
  Volg(),
  Kist(),
  Gezagvoerder(),
  Pax(),
  Meth(),
  Tijden(),
  Duur(),
  Height(),
  FlightTagColumn(),
  {
    title: "",
    width: 80,
    render: (text, flight) => {
      if (flight.is_locked) return null;
      if (showLandingButton) {
        return (
          <Space>
            {!flight.landings_tijd && (
              <Button
                disabled={
                  flight.is_deleted || !flight.registratie || !flight.start_tijd
                }
                onClick={() =>
                  updateFlight({
                    action: "land",
                    volg_nummer: flight.volg_nummer,
                    uuid: flight.uuid,
                    start_tijd: flight.start_tijd,
                    landings_tijd: dayjs().format("HH:mm"),
                  })
                }
                type="primary"
                icon={<LandingIcon />}
                size="small"
              >
                {$t("Land")}
              </Button>
            )}
          </Space>
        );
      }
      // in other cases show that you can edit
      return (
        <Button
          type="primary"
          icon={<EditOutlined />}
          size="small"
          shape="circle"
        />
      );
    },
  },
];
