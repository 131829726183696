import { message } from "antd";
import React, { useState } from "react";
import { version } from "../../../package.json";

export const VersionPopup = ({ children }) => {
  const [click, setClick] = useState(0);

  const onClick = () => {
    if (click === 2) {
      message.success(`Version "${version}"`);
      setClick(0);
    } else {
      setClick(click + 1);
    }
  };

  return React.cloneElement(children, { onClick });
};
