import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { $t } from '~/i18n.js'

import { DatePicker, Form, Input, message, Modal, Select } from 'antd'
import { adminActions } from '../../redux/actions'

export const AddFindingsModal = ({ visible, closeModal, activeFinding, onSave }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (!visible) {
      form.resetFields()
      setLoading(false)
    }
  }, [visible])

  const { flights } = useSelector((state) => state)
  const dispatch = useDispatch()

  const saveForm = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)

      const newFinding = await dispatch(adminActions.saveFinding({
        finding: {
          ...values,
          id: activeFinding.id,
          date_created: values?.date_created ? values?.date_created.format('YYYY-MM-DD') : null,
          date_expected_completion: values?.date_expected_completion ? values?.date_expected_completion.format('YYYY-MM-DD') : null
        }
      }))

      onSave && onSave(newFinding)

      setLoading(false)
      if (newFinding) {
        closeModal()
      }
    } catch (info) {
      message.error(`Data is incorrect, ${info?.errorFields?.length} fouten gemeld`)
      console.log('error saving: ', info)
      setLoading(false)
    }
  }

  return (
    <Modal
      title={activeFinding.id ? $t('Pas bevinding aan') : $t('Voeg nieuwe bevinding toe')}
      open={visible}
      confirmLoading={loading}
      onCancel={closeModal}
      okText={$t('Opslaan')}
      cancelText={$t('Annuleren')}
      onOk={saveForm}
    >

      <Form
        form={form}
        layout={{
          labelCol: { span: 8 },
          wrapperCol: { span: 16 }
        }}
        name='finding'
        preserve={false}
        requiredMark={false}
        initialValues={activeFinding
          ? {
              ...activeFinding,
              date_created: activeFinding.date_created && dayjs(activeFinding.date_created, 'YYYY-MM-DD'),
              date_expected_completion: activeFinding.date_expected_completion && dayjs(activeFinding.date_expected_completion, 'YYYY-MM-DD')
            }
          : {}}
      >

        <Form.Item
          label={$t('Titel')}
          name='title'
          rules={[
            { required: false, max: 50, message: $t('Maximaal ${c} characters', { c: 50 }) }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={$t('Categorie')}
          name='category'
          rules={[{ required: true }]}
        >
          <Select>
            <Select.Option value='intern'>{$t('Intern')}</Select.Option>
            <Select.Option value='extern'>{$t('Extern')}</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={$t('Afdeling')}
          name='department'
          rules={[
            { required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={$t('Eigenaar')}
          name='owner_id'
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {flights?.vliegers.map((_user, key) => _user?.id > 0 && <Select.Option key={key} value={_user.id}>{_user.name}</Select.Option>)}
          </Select>
        </Form.Item>

        <Form.Item label={$t('Datum aangemeld')} name='date_created' rules={[{ required: true }]}>
          <DatePicker format={localStorage.localeFormat} />
        </Form.Item>

        <Form.Item label={$t('Verwachte einddatum')} name='date_expected_completion'>
          <DatePicker format={localStorage.localeFormat} />
        </Form.Item>

        {!activeFinding.id && <Form.Item
          label={$t('Beschrijving')}
          name='message'
          rules={[
            { required: true, max: 1000, message: $t('Maximaal ${c} characters', { c: 1000 }) }
          ]}
                              >
          <Input.TextArea rows={4} />
        </Form.Item>}

      </Form>

    </Modal>
  )
}
