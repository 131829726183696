import React from "react";
import { Select } from "antd";

export const ListSelectToString = ({id="", value="", onChange=e=>{}, list=[], nameLookup={}, allowAddNew=false}) => {
  const selectedValue = value ? value.split(',') : []
  return (
    <Select mode={allowAddNew ? 'tags' : 'multiple'} value={selectedValue} onChange={e => onChange(e.join(','))}>
      {list.map((value, index) => <Select.Option value={value} key={index}>{nameLookup ? nameLookup[value] : value}</Select.Option>)}
    </Select>
  );
};