import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Descriptions, Flex, Input, List, Tag } from "antd";
import React, { useState } from "react";
import { $t } from "~/i18n.js";
import { generate_uuid } from "~/lib/helpers.js";
import { DifCheckTag } from "./DifCheckTag.jsx";
import { EditBevoegdheidModal } from "./EditBevoegdheidModal.jsx";
import {
  getNextOrderInList,
  OrderDeleteButtons,
} from "./OrderDeleteButtons.jsx";

export const CategoryCollapse = ({
  category = {},
  saveCategory = (c) => {},
}) => {
  const [openBevoegheid, setOpenBevoegheid] = useState(null);

  if (category.action === "delete")
    return (
      <p style={{ margin: "12px 24px" }}>
        {$t("Wordt bij opslaan verwijderd")}
      </p>
    );

  const addNewBevoegdheid = () => {
    let newBevoegheid = {
      id: generate_uuid(),
      action: "add",
      order: getNextOrderInList(category?.bevoegdheid),
      name: "",
      label: "",
      description: "",
      easa_key: "",
      endorse_title: "",
      endorse_body: "",
      scoring: "boolean",
      geldigheid: 0,
      group_key: "",
    };
    setOpenBevoegheid(newBevoegheid);
    let newBevoegheden = (category?.bevoegdheid || []).concat([newBevoegheid]);
    saveCategory({ ...category, bevoegdheid: newBevoegheden });
  };

  const saveBevoegdheden = (newBevoegheden) => {
    saveCategory({ ...category, bevoegdheid: newBevoegheden });
  };

  let sortedBevoegdheden = category?.bevoegdheid?.sort(
    (a, b) => a.order - b.order
  );

  const saveBevoegdheid = (newBevoegdheid) => {
    let newBevoegheden = sortedBevoegdheden.map((b) => {
      if (b.id === newBevoegdheid.id) return { ...b, ...newBevoegdheid };
      return b;
    });
    saveCategory({ ...category, bevoegdheid: newBevoegheden });
  };

  const saveField = (value, field, max) => {
    let payload = {
      id: category.id,
      action: category.action === "add" ? "add" : "update",
    };
    payload[field] = value.substring(0, max);
    saveCategory(payload);
  };

  return (
    <div className="partCard" style={{ margin: "12px 0 12px 12px" }}>
      <EditBevoegdheidModal
        bevoegdheid={openBevoegheid}
        open={openBevoegheid !== null}
        saveBevoegheid={saveBevoegdheid}
        closeModal={() => setOpenBevoegheid(null)}
      />

      <Descriptions size="small" column={1} style={{ maxWidth: 700 }}>
        <Descriptions.Item label={$t("Label")}>
          <Input
            type="text"
            value={category.label}
            onChange={(e) => saveField(e.target.value, "label", 99)}
          />
        </Descriptions.Item>
        <Descriptions.Item label={$t("Referentie")}>
          <Input
            type="text"
            value={category.name}
            onChange={(e) => saveField(e.target.value, "name", 99)}
          />
        </Descriptions.Item>
        <Descriptions.Item label={$t("Beschrijving")}>
          <Input.TextArea
            rows={4}
            value={category.description}
            onChange={(e) => saveField(e.target.value, "description", 299)}
          />
        </Descriptions.Item>
      </Descriptions>

      {sortedBevoegdheden.length > 0 && (
        <List
          size="small"
          style={{ marginRight: 14 }}
          bordered
          dataSource={sortedBevoegdheden}
          renderItem={(bevoegdheid, index) => (
            <List.Item
              actions={[
                <Flex key='1' gap={6} vertical={false}>
                  <OrderDeleteButtons
                    task={bevoegdheid}
                    task_index={index}
                    tasks={sortedBevoegdheden}
                    saveTasks={saveBevoegdheden}
                  />
                </Flex>,
              ]}
            >
              <Flex
                gap={6}
                className="clickable"
                onClick={() => setOpenBevoegheid(bevoegdheid)}
              >
                <EditOutlined />
                {bevoegdheid.name ? (
                  bevoegdheid.name
                ) : (
                  <Tag>{$t("naam mist")}</Tag>
                )}
                <DifCheckTag action={bevoegdheid.action} />
              </Flex>
            </List.Item>
          )}
        />
      )}

      <Button
        style={{ width: 120, marginTop: 12 }}
        onClick={addNewBevoegdheid}
        size="small"
        icon={<PlusOutlined />}
      >
        {$t("Voeg toe")}{" "}
      </Button>
    </div>
  );
};
