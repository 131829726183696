import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { $t } from '~/i18n.js'

import { CheckOutlined, CloseOutlined, EditOutlined, PlusOutlined, SmileOutlined } from '@ant-design/icons'
import { Button, Card, Divider } from 'antd'

import { Bericht } from '~/components/bericht/bericht.jsx'
import { MainLayout } from '~/components/layout/layout.jsx'
import { api } from '~/lib/api.js'
import { _parseDate } from '~/lib/localize.js'

import { BreadcrumbHeader } from '~/components/layout/breadcrumbHeader.jsx'
import { AddFindingMessageModal } from './addFindingMessageModal.jsx'
import { AddFindingsModal } from './addFindingModal.jsx'

export const FindingDetailPage = ({ history, match }) => {
  const [loading, setLoading] = useState(true)
  const [activeFinding, setActiveFinding] = useState(null)

  const [showEdit, setShowEdit] = useState(false)

  const [transition, setActiveTransition] = useState(null)
  const [showAddMessage, setAddMessage] = useState(false)
  const openAddMessage = (transition) => {
    setActiveTransition(transition)
    setAddMessage(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      const finding = await api.get(`findings/${match.params.id}.json`)
      setActiveFinding(finding)
      setLoading(false)
    }
    fetchData()
  }, [match.params.id])

  const onAddFile = async (data) => {
    const newFinding = { ...activeFinding }
    newFinding.messages.forEach(m => {
      if (m.id === data.message_id) {
        m.files.push(data)
      }
    })
    await setActiveFinding(newFinding)
  }

  return (
    <MainLayout history={history} isLoading={loading}>

      <AddFindingsModal
        visible={showEdit}
        closeModal={() => setShowEdit(false)}
        onSave={(finding) => setActiveFinding(finding)}
        activeFinding={activeFinding}
      />

      <AddFindingMessageModal
        visible={showAddMessage}
        closeModal={() => setAddMessage(false)}

        transition={transition}
        activeFinding={activeFinding}

        onSave={(finding) => {
          console.log('saving finding', finding)
          setActiveFinding(finding)
        }}
        onAddFile={onAddFile}
      />

      <BreadcrumbHeader
        breadcrumbs={[
          <Link key='1' to='/findings' className='title'>{$t('Bevindingen')}</Link>,
        `#${activeFinding?.id}`
        ]}
        buttons={[
          activeFinding && <Button style={{ marginRight: 6 }} onClick={() => setShowEdit(true)} icon={<EditOutlined />}>
            {$t('Wijzigen')}
                           </Button>,

          activeFinding?.status === 'aangemeld' &&
            <Button
              onClick={() => openAddMessage('afgerond')}
              style={{ marginRight: 6 }} icon={<SmileOutlined />}
            >
              {$t('Oplossen')}
            </Button>,

          activeFinding?.status !== 'afgerond' &&
            <Button
              onClick={() => openAddMessage('afgewezen')}
              danger style={{ marginRight: 6 }} icon={<CloseOutlined />}
            >
              {$t('Afwijzen')}
            </Button>,

          activeFinding?.status !== 'aangemeld' &&
            <Button
              onClick={() => openAddMessage('aangemeld')}
              style={{ marginRight: 6 }} icon={<CheckOutlined />}
            >
              {$t('Her-open melding')}
            </Button>
        ]}
      />

      {activeFinding && <div>

        <Card title={<span className='bold'>{activeFinding.title}</span>} style={{ marginTop: 24 }}>

          <p>{$t('Status')}: <span className='bold'>{activeFinding.status}</span></p>
          <p>{$t('Categorie: ${activeFinding}', { activeFinding: activeFinding.category })}</p>
          <p>{$t('Afdeling: ${activeFinding},', { activeFinding: activeFinding.department || $t('Geen afdeling') })}</p>

          <Divider style={{ margin: '12px 0' }} />

          <p>{$t('Toegewezen aan: ${activeFinding}', { activeFinding: activeFinding.owner_name || $t('Geen eigenaar') })}</p>
          <p>{$t('Datum verwachtte afhandeling: ${activeFinding}', { activeFinding: _parseDate(activeFinding.date_expected_completion) })}</p>

        </Card>

        <div style={{ padding: 24 }}>
          <div className='header row clickable' onClick={() => openAddMessage('')}>
            <Button type='primary' icon={<PlusOutlined />} size='small' shape='circle' style={{ marginRight: 10 }} />
            <div className='row' style={{ alignItems: 'center', marginBottom: 6 }}>
              {$t('Notities')}
            </div>
          </div>

          {activeFinding.messages?.map((message, index) => (
            <Bericht
              user={message.user}
              date_created={message.date_created}
              transition={message.transition}
              key={index}
              message={message.message}
              files={message.files}
            />
          ))}
        </div>

                        </div>}

    </MainLayout>
  )
}
