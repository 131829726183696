import { Dropdown } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { $t } from "~/i18n";
import { getUserAvatar, getUserAvatarError } from "../../profile/selectPilot";
import { VersionPopup } from "../version.popup";
import "./ProfileMenu.css";

export function ProfileMenu({ persist }) {
  const items = [
      {
          disabled: true,
          label: (
              <VersionPopup>
          <p>
            {$t("Welkom")} {persist.profile.first_name}
          </p>
        </VersionPopup>
      ),
    },
    { type: "divider" },
    persist.profile.vliegt && {
      label: <Link to="/my_starts">{$t("Mijn starts")}</Link>,
    },
    persist.profile.vliegt && {
      label: <Link to="/my_maintenance">{$t("Mijn onderhoud")}</Link>,
    },
    persist.profile.vliegt && {
      label: <Link to="/my_details">{$t("Mijn gegevens")}</Link>,
    },
    { type: "divider" },
    {
      label: <Link to="/logout">{$t("Uitloggen")}</Link>,
    },
  ];
  return (
    <Dropdown className="mobileMenu blueBackground" menu={{ items }}>
      <a onClick={(e) => e.preventDefault()} style={{ display: "flex" }}>
        <img
          className="avatar"
          alt=""
          src={getUserAvatar(persist.profile, true)}
          onError={getUserAvatarError}
        />
      </a>
    </Dropdown>
  );
}
