import React from 'react'
import { SelectLanguage } from '../../components/profile/selectLanguage'
import { CardBase } from '../../components/timeLine/cardBase'
import { $t } from '~/i18n.js'

export const CardIntro = ({ saveAndNext }) => {
  return (
    <CardBase next={saveAndNext} minHeight={300} title={$t('Start')}>

      <p className='bold title' style={{ marginBottom: 12 }}>{$t('Meld je club aan')}</p>

      <SelectLanguage forceReload />

      <p style={{ margin: '12px 0' }}>{$t('Het aanmaken van een club testaccount is gratis en vrijblijvend')}.</p>

      <p style={{ marginBottom: 12 }}>{$t('Met dit testaccount kun gedurende een periode van 12 maanden de Zweef.App uit te proberen. Tijdens deze periode bieden wij de mogelijkheid om de data van jouw club te importeren')}.</p>

      <p>{$t('We zijn benieuwd naar jullie ervaringen, mail ons op')} <a href='mailto:contact@zweef.app'>contact@zweef.app</a>. </p>

    </CardBase>
  )
}
