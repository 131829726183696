import { Button } from 'antd'
import React from 'react'
import { $t } from '~/i18n.js'

export const CardBase = ({ prev, loading, next, title, children, minHeight }) => {
  return (
    <div style={{ width: '100%', minHeight, display: 'flex', flexDirection: 'column' }}>

      <div>
        {children}
      </div>

      <div className='row' style={{ justifyContent: 'flex-end', marginTop: 'auto' }}>

        {prev && <Button size='small' onClick={prev} style={{ marginRight: 6 }}>
          {$t('Terug')}
        </Button>}

        <Button size='small' type='primary' loading={loading} onClick={() => next()}>
          {title || $t('Volgende')}
        </Button>
      </div>
    </div>
  )
}
