import { UploadOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Tag, Upload, message } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { DataCard } from '~/components/dataCards/dataCard.jsx'
import { HelpBubble } from '~/components/help/help.bubble.jsx'
import { config } from '~/config.js'
import { $t } from '~/i18n.js'
import { api } from '~/lib/api.js'
import { _parseDate } from '~/lib/localize.js'

export const CardMedical = ({ profile, gegevens, saveData, type }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const [files, setFile] = useState([])

  const validateForm = async () => {
    try {
      return await form.validateFields()
    } catch (info) {
      console.log('Validate Failed:', info)
      setLoading(false)
      return null
    }
  }

  const saveForm = async () => {
    const values = await validateForm()
    if (!values) return null

    setLoading(true)

    const medical_valid_from = (values.medical_valid && values.medical_valid[0]) ? values.medical_valid[0].format('YYYY-MM-DD') : null
    const medical_valid_to = (values.medical_valid && values.medical_valid[1]) ? values.medical_valid[1].format('YYYY-MM-DD') : null

    if (!medical_valid_from || !medical_valid_to) {
      message.error('Vul datum van medical in')
      setLoading(false)
      return null
    }

    let medical_id = null

    // check if medical is in form
    if (files.length) {
      const extension = files[0].originFileObj?.name.split('.').slice(-1)[0]
      const name = `Medical ${(new Date()).getFullYear()}.${extension}`
      const data = { name, read_only: false, is_visible: true, user_id: profile?.id, file: files[0].originFileObj }
      const res = await api.post_form('gegevens/file.json', data)

      if (res) {
        dispatch({
          type: 'ADD_TO_PROFILE_FILES',
          file: res
        })
        medical_id = res.id
        setFile([])
      }
    }

    // manipulate values for save
    const res = await saveData({
      gegevens: { medical_valid_from, medical_valid_to, medical_id, reset_medical: true }
    })

    setLoading(false)
    return res
  }

  return (
    <DataCard title={$t('Medical')} onSave={saveForm} loading={loading} type={type}>

      <Form
        form={form}
        name='Medical'
        layout='vertical'
        initialValues={{
          medical_valid: [gegevens.medical_valid_from && dayjs(gegevens.medical_valid_from, 'YYYY-MM-DD'), gegevens.medical_valid_to && dayjs(gegevens.medical_valid_to, 'YYYY-MM-DD')]
        }}
        requiredMark={false}
      >
        <p>{$t('Wanneer is je medical geldig (tot)')}?</p>
        <Form.Item
          rules={[{ required: true, message: $t('Vul in') }]}
          name='medical_valid'
        >
          <DatePicker.RangePicker
            format={localStorage.localeFormat}
            placeholder={[$t('Vanaf'), $t('Tot')]}
          />
        </Form.Item>

        <p><span style={{ marginRight: 6 }}>{$t('Bestand')}:</span>

          {!gegevens.medical && <Tag color='red'>{$t('Onbekend')}</Tag>}

          {gegevens.medical && <a target='_blank' rel='noopener noreferrer' title='file' href={config.media_prefix + gegevens.medical.file}>{gegevens.medical.name}</a>}

        </p>

        {gegevens.medical && gegevens.is_dto && <div className='small' style={{ color: 'grey' }}>

          {gegevens.medical_checked_by && <span>{$t('Afgevinkt door ${medicalcheckedby} op ${medicalcheckeddate}', { medicalcheckedby: gegevens.medical_checked_by, medicalcheckeddate: _parseDate(gegevens.medical_checked_at) })}</span>}

          {!gegevens.medical_checked_by && <span>{$t('Nog niet gecontrolleerd')}</span>}

          <HelpBubble content={$t('De verantwoordelijkheid voor een correcte medical ligt bij de DTO, elke instructeur kan je medical controleren.')} />
        </div>}

        <p style={{ marginTop: 12 }}>{$t('Sla een nieuw medical op')}:</p>

        <Upload
          name='file' listType='picture' style={{ marginBottom: 12 }}
          fileList={files}
          onChange={element => setFile([...element.fileList.slice(-1)])}
          beforeUpload={(file) => false}
          showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
        >
          <Button icon={<UploadOutlined />}>
            {$t('Selecteer 1 bestand')}
          </Button>
        </Upload>
      </Form>

    </DataCard>
  )
}
