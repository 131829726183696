import { ExclamationCircleOutlined, SwapOutlined } from '@ant-design/icons'
import { Button, Divider, Modal, Popconfirm, Tooltip } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'
import { api } from '../../../lib/api'

const dienstOvergenomen = (name) => {
  Modal.success({
    title: $t('Dienst overgenomen'),
    content: name ? $t('De dienst is overgenomen en ${name} heeft een mail ontvangen ter bevestiging.', { name }) : $t('Dank je wel voor het invullen van deze dienst.')
  })
}

const DienstRow = ({ group_name_lookup, dienst, user, openAanmelding, aanmeldingen}) => {
  const [loading, setLoading] = useState(false)
  const [swapped, setSwapped] = useState(false)

  const neem_over = async () => {
    setLoading(true)
    const res = await api.post('rooster/neem_dienst_over.json', { dienst_id: dienst.id })
    if (res) {
      dienstOvergenomen(dienst.user_name)
      setSwapped(true)
      openAanmelding()
    }

    setLoading(false)
  }
  
  let isAangemeld = aanmeldingen.filter(d => d.aangemeld && d.vlieger?.id == dienst?.user_id).length > 0

  return (
    <div>
      <div
        className='row'
        style={{ justifyContent: 'space-between', marginTop: 6 }}
      >
        <div>
          <Tooltip placement='top' title={group_name_lookup[dienst.group_key] || dienst.group_key}>
            <p className='small'>{dienst.rooster_name}</p>
          </Tooltip>
          <p className={!isAangemeld || !dienst.user_name ? 'bold alert' : ''} style={{ marginRight: 4 }}>
            {!isAangemeld && <ExclamationCircleOutlined style={{marginRight: 6}} />}
            {swapped ? user.name : (dienst.user_name ? dienst.user_name : 'OPEN')}
          </p>
        </div>

        {user && <p style={{ lineHeight: '14px' }}>
          {!swapped && dienst.user_id !== user.id && user.group_keys?.includes(dienst.group_key) &&
            <Popconfirm
              placement='bottom' title={$t('Zeker weten dat je deze dienst wilt overnemen?')}
              onConfirm={() => neem_over()}
              okText={$t('Ja')} cancelText={$t('Nee')}
            >
              <Button size='small' icon={<SwapOutlined />} loading={loading} shape='circle' />
            </Popconfirm>}
        </p>}

      </div>

      <Divider style={{ margin: '2px 0' }} />
    </div>
  )
}

export const CardRooster = ({ group_name_lookup, diensten, user, openAanmelding, aanmeldingen }) => diensten.map((dienst, key) => <DienstRow aanmeldingen={aanmeldingen} group_name_lookup={group_name_lookup} key={key} dienst={dienst} user={user} openAanmelding={openAanmelding} />)
