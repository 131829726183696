import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Radio } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { api } from '../../../lib/api'
import { phValidator } from '../../../lib/localize'

export const AddMaterial = ({ readOnly, history }) => {
  const [form] = Form.useForm()
  const [showAdd, setShowAdd] = useState(false)
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState('')

  const registration = Form.useWatch('registration', form)

  const closeModal = () => {
    setShowAdd(false)
    setLoading(false)
  }

  const saveForm = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      // manipulate values for save
      // name is concatenated from registatie/callsign only for kist
      values.callsign = values.callsign?.toUpperCase()
      values.registration = values.registration?.toUpperCase()
      const name = category === 'kist'
        ? (values.callsign ? `${values.callsign}: ${values.registration}` : values.registration)
        : values.name
      const res = await api.post('materiaal/create_new.json', { ...values, category, name })
      if (res?.id) history.push('/material/' + res.id)
      setLoading(false)
    } catch (info) {
      console.log('Validate Failed:', info)
      setLoading(false)
    }
  }

  return (
    <div>

      {!readOnly && <Button onClick={() => setShowAdd(true)} disabled={readOnly} size='small' icon={<PlusOutlined />}>{$t('Voeg toe')}</Button>}

      {!readOnly && showAdd && <Modal
        title={$t('Voeg nieuw materiaal toe')}
        confirmLoading={loading}
        open={showAdd}
        onCancel={closeModal}
        okText={$t('Ja')} cancelText={$t('Nee')}
        onOk={!readOnly && saveForm}
                               >

        <div className='row' style={{ justifyContent: 'center' }}>
          <Radio.Group className='spacer' size='small' value={category} onChange={(event) => setCategory(event.target.value)}>
            <Radio.Button value='kist'>{$t('Vliegend')}</Radio.Button>
            <Radio.Button value='rollend'>{$t('Rollend')}</Radio.Button>
            <Radio.Button value='overig'>{$t('Overig')}</Radio.Button>
          </Radio.Group>
        </div>

        {category && <Form
          form={form}
          name='material'
          requiredMark={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
                     >

          {category !== 'kist' && <Form.Item
            label={$t('Naam')}
            name='name'
            rules={[{ required: true, message: $t('Maximaal ${c} characters', { c: 100 }), max: 100 }]}
                                  >
            <Input disabled={readOnly} />
                                  </Form.Item>}

          {category === 'kist' && <Form.Item
            label={$t('Registratie')}
            name='registration'
            rules={[phValidator]}
                                  >
            <Input disabled={readOnly} />
                                  </Form.Item>}

          {category === 'kist' && <Form.Item
            label={$t('Callsign')}
            name='callsign'
            rules={[{ required: false, message: $t('Maximaal ${c} characters', { c: 10 }), max: 10 }]}
                                  >
            <Input disabled={readOnly} />
                                  </Form.Item>}

          {(category !== 'kist' || (registration?.length > 2 && registration?.substr(0, 2).toLowerCase() !== 'ph')) && <Form.Item
            label={$t('Type')}
            name='type'
            rules={[{ required: true, message: $t('Maximaal ${c} characters', { c: 200 }), max: 200 }]}
                                                                                                                       >
            <Input disabled={readOnly} />
                                                                                                                       </Form.Item>}

        </Form>}

                               </Modal>}
    </div>
  )
}
