import { $t } from '~/i18n.js'
import React, { useState } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Button, Input, Modal } from 'antd'
import { api } from '../../../lib/api'

export const AangepasteUren = ({ verplichting, wijzig_winteruren }) => {
  const [loading, setLoading] = useState(false)
  const [editDialogOpen, showEditDialog] = useState(false)
  const [tmpUren, setTmpUren] = useState(0)
  const [nieuweUren, setNieuweUren] = useState(null)

  const uren = nieuweUren || verplichting.uren_verplichting
  const is_aangepast = verplichting.uren_berekend !== uren

  const saveUren = async () => {
    setLoading(true)
    const payload = { id: verplichting.id, uren: tmpUren || 0 }
    const res = await api.post('onderhoud/pas_verplichting_aan.json', payload)
    if (res) {
      setNieuweUren(res.uren)
    }
    showEditDialog(false)
    setLoading(false)
  }

  return (
    <>

      {wijzig_winteruren && <Modal
        title={$t('Pas uren verplichting van ${verplichingname} aan', { verplichingname: verplichting.user_name })}
        open={editDialogOpen}
        confirmLoading={loading}
        okText={$t('Opslaan')}
        cancelText={$t('Annuleren')}
        onCancel={() => showEditDialog(false)}
        onOk={() => saveUren()}
                            >

        <div style={{ margin: 12 }}>

          <p>Pas de werkuren van <em>{verplichting.user_name}</em> {$t('aan als die meer of minder dan zijn berekende uren moet maken deze periode.')}</p>

          <p style={{ margin: '12px 0' }}>{$t('Berekende uren: ${verplich} uren', { verplich: verplichting.uren_berekend })}</p>

          <p>Aangepaste uren:</p>
          <Input
            type='number'
            style={{ width: 120 }}
            value={tmpUren}
            suffix='uren'
            onChange={(element) => setTmpUren(element.target.value >= 0 ? element.target.value : 0)}
          />
        </div>

      </Modal>}

      <p>

        {uren}

        {is_aangepast && <span className='gray'>({verplichting.uren_berekend})</span>}

        {wijzig_winteruren && <Button
          shape='circle' type={is_aangepast ? 'primary' : 'ghost'}
          className='revokeButton'
          style={{ marginLeft: 4 }}
          onClick={() => {
            setTmpUren(uren)
            showEditDialog(true)
          }}
          icon={<EditOutlined />} size='small'
                              />}

      </p>
    </>

  )
}
