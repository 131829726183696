import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, DatePicker, Modal, Tabs, Tag } from "antd";
import React, { useState } from "react";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { SluitButton } from "../buttons/SluitButton.jsx";
import { Loader } from "../loader/loader.jsx";
import { LastFlights, Recency } from "./tabs/overviewFlights.jsx";

export const RecencyHistoricCalculation = ({ user }) => {
  const [visible, setVisible] = useState(false);
  const [activeDate, setActiveDate] = useState(null);
  const [hasNewValue, setHasNewValue] = useState(false);

  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState({});
  const [flights, setFlights] = useState([]);

  const getNew = async () => {
    setLoading(true);
    const data = await api.post("instructie/currency.json", {
      user_id: user.id,
      to_date: activeDate.format("YYYY-MM-DD"),
    });
    if (data) {
      setCurrency(data.currency);
      setFlights(data.flights);
    }
    setLoading(false);
    setHasNewValue(false);
  };

  return (
    <>
      <div style={{ marginLeft: 12 }}>
        <Button
          onClick={() => setVisible(true)}
          icon={<ClockCircleOutlined />}
          size="small"
        />
      </div>

      <Modal
        style={{ top: 10, padding: 0 }}
        title={$t("Historische recency berekening")}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={[<SluitButton key="sluit" onClick={() => setVisible(false)} />]}
      >
        <p>
          <Tag color="red">{$t("Let op")}</Tag>{" "}
          {$t(
            "Alleen de vluchten die bekend zijn in het systeem zijn meegenomen"
          )}
          .
        </p>

        <div className="row" style={{ marginTop: 12 }}>
          <p>{$t("Selecteer datum")}: </p>
          <DatePicker
            style={{ marginLeft: 4 }}
            format={localStorage.localeFormat}
            placeholder=""
            value={activeDate}
            onChange={(newValue) => {
              setActiveDate(newValue);
              setHasNewValue(true);
            }}
            allowClear={false}
          />

          <Button
            type="primary"
            disabled={!hasNewValue}
            loading={loading}
            style={{ marginLeft: 24 }}
            size="small"
            onClick={getNew}
          >
            {$t("OK")}
          </Button>
        </div>

        {loading && (
          <div
            className="row"
            style={{ marginTop: 45, justifyContent: "center" }}
          >
            <Loader />
          </div>
        )}

        {!loading && (
          <Tabs
            items={[
              {
                label: $t("Recency"),
                key: "recency",
                children: (
                  <div style={{ maxHeight: 480, overflowY: "auto" }}>
                    <Recency recency={currency.recency} />
                  </div>
                ),
              },
              {
                label: $t("Vluchten"),
                key: "vluchten",
                children: (
                  <div style={{ maxHeight: 480, overflowY: "auto" }}>
                    <p>
                      {$t(
                        "Laatste ${x} vluchten, indien bekend, t/m de geselecteerde datum:",
                        { x: 60 }
                      )}
                    </p>
                    <LastFlights flights={flights} pilot={user} />
                  </div>
                ),
              },
            ]}
          />
        )}
      </Modal>
    </>
  );
};
