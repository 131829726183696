export const config = {
  mode: 'development',
  debugRedux: !!localStorage.debugRedux,

  media_prefix: 'http://localhost:2001/media/',

  client_secret: window.localStorage.client_secret || 'test',
  sentry_key: null, // leave blank to disable,
  test_users:
    [
      ['Contact', 'Superadmin'],
      ['Admin', 'Gebruiker'],
      ['Leden', 'Admin'],
      ['HOT', 'Coordinator'],
      ['Bestuur', 'Voorzitter'],
      ['Zweef', 'Vlieger'],
      ['Startwagen', 'Combi'],
      ['Clubhuis', 'Bel'],
      ['Instructeur', 'Piet'],
      ['Technicus', 'Wim'],
      ['Rooster', 'Maker'],
      ['Sleepvlieger', 'Henk'],
      ['DBO', 'Leerling'],
      ['Solist', 'Leerling'],
      ['Gast', 'Vlieger'],
      ['Henk', 'Brevet'],
      ['Ingrid', 'Brevet'],
      ['Jan', 'Brevet'],
      ['Prive', 'Brevet'],
      ['Niet', 'Vliegend']
    ]
}
