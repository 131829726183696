export const PricingVersionNL = "";

export const PricingTextNL = `


Het doel van de Zweef.App is dat we streven naar kostendekking en betaalbaarheid, met name voor kleinere clubs. Hiervoor hebben we een twee prijsplannen opgezet:

> ### **Klein** plan
> 
> • Geschikt voor kleine clubs tot 106 leden.
> 
> • Een variabel bedrag van €0.80 per vliegend lid per maand, ex BTW, geen vaste kosten.


> ### **Groot** plan
> 
> • Geschikt voor grotere clubs vanaf 106 leden.
> 
> • Een vast bedrag van €85,- per maand, ex BTW, geen variabele kosten per lid.



Voor beide prijsplannen gelden de volgende voorwaarden:

- Géén aansluitkosten, wél de mogelijkheid om via de admin data te importeren (denk aan leden, bevoegdheden, materieel en vluchten).

- Altijd per email bereikbaar voor vragen en opmerkingen. Indien nodig support via video call, zal een ontmoedigings-tarief van €50 per uur in rekening gebracht worden.

- Clubs kunnen jaarlijks opzeggen.

- Kosten worden jaarlijks vooraf in rekening gebracht en kunnen aangepast worden op basis van CBS inflatie cijfers. 

- [Algemene Voorwaarden](https://app.zweef.app/terms) zijn van toepassing.

Daarnaast bieden we een **testaccount** aan. 
Met dit testaccount kun je maximaal 6 gebruikers toevoegen om gedurende een periode van 12 maanden de Zweef.App gratis en vrijblijvend uit te proberen. 
Tijdens deze periode bieden wij de mogelijkheid om de data van jouw club te importeren.
Bekijk de [Algemene Voorwaarden](https://app.zweef.app/terms) voor meer informatie hierover.

  
`;
