import { $t } from '~/i18n'
import React from 'react'
import { Tag } from 'antd'

const statusses = ['aangemeld', 'uitgesteld', 'afgerond', 'afgewezen']

export const MeldingTag = ({ type = '' }) => {
  return (
    <span>
      {type === 'aangemeld' && <Tag>{$t('aangemeld')}</Tag>}
      {type === 'uitgesteld' && <Tag>{$t('uitgesteld')}</Tag>}
      {type === 'afgerond' && <Tag>{$t('afgerond')}</Tag>}
      {type === 'afgewezen' && <Tag color='red'>{$t('afgewezen')}</Tag>}
    </span>
  )
}
