import { CoffeeOutlined } from "@ant-design/icons";
import { Card, Divider, Tag } from "antd";
import React from "react";
import { $t, i18n } from "~/i18n.js";
import { DescriptionRow } from "~/components/descriptionRow/descriptionRow.jsx";

import { toLocale } from "~/lib/localize.js";

export const CardStats = ({ stats, vliegers }) => {
  return (
    <Card size="small" className="topCard" title={$t("Statistieken")}>
      <DescriptionRow
        title={
          <span>
            {$t("Dagrecord")} <CoffeeOutlined />{" "}
            {stats.maxHeight > 0 &&
              i18n.parseLocaleHeightString(stats.maxHeight)}
          </span>
        }
      />
      <p className="small" style={{ marginLeft: 23 }}>
        {$t("met ${stats} starts van 30+ min", {
          stats: toLocale(stats.halfuurs),
        })}
      </p>

      <Divider style={{ margin: "10px 0" }} />

      <DescriptionRow title={$t("Starts")}>
        {toLocale(stats.starts)}
      </DescriptionRow>
      <p className="small" style={{ marginLeft: 23 }}>
        {$t("waarvan")} {toLocale(stats.lier)} {$t("lier")},{" "}
        {toLocale(stats.sleep)} {$t("sleep")}, {toLocale(stats.overland)}{" "}
        {$t("overland")}
      </p>
      <p className="small" style={{ marginLeft: 23 }}>
        {$t("met")} {toLocale(stats.dbo)} {$t("dbo")} & {toLocale(stats.checks)}{" "}
        {$t("trainingsvluchten")}
      </p>

      <Divider style={{ margin: "10px 0" }} />

      <DescriptionRow title={$t("Vliegers")}>{vliegers.length}</DescriptionRow>
      <p className="small" style={{ marginLeft: 23 }}>
        {$t("waarvan")} {toLocale(stats.instructeurs.length)}{" "}
        {$t("instructeurs")}
      </p>
      <p className="small" style={{ marginLeft: 23 }}>
        {$t("met")} {toLocale(stats.aanmeldingen.length)} {$t("aanmeldingen")}
      </p>

      <Divider style={{ margin: "10px 0" }} />

      <DescriptionRow title={$t("Kisten")}>
        {stats.kisten.length}
      </DescriptionRow>
      <p className="row" style={{ flexWrap: "wrap" }}>
        {stats.kisten.map((kist, key) => (
          <Tag style={{ marginTop: 9 }} key={key}>
            {kist}
          </Tag>
        ))}
      </p>
    </Card>
  );
};
