import { DatePicker, Form, Input, Modal, Radio, Select } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from '../../components/loader/loader'
import { $t } from '~/i18n.js'
import { updateIfNeeded } from '../../lib/helpers'
import { getMaterialData } from '../../redux/materiaal/materiaal.actions'
import { getMijnOnderhoud, registreerUren } from '../../redux/onderhoud/onderhoud.actions'

export const RegistratieForm = ({ visible, registratie, showAllUsers, closeModal }) => {
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()
  const [category, setCategory] = useState('kist')

  const { flights, materiaal } = useSelector((state) => state)
  const dispatch = useDispatch()
  const { onderhoud } = useSelector((state) => state)

  useEffect(() => {
    updateIfNeeded(materiaal.lastUpdated, () => dispatch(getMaterialData()))
  }, [materiaal.lastUpdated, dispatch])
  useEffect(() => {
    updateIfNeeded(onderhoud.lastUpdatedMijnVerplichting, () => dispatch(getMijnOnderhoud()))
  }, [onderhoud.lastUpdatedMijnVerplichting, dispatch])

  const period = onderhoud?.verplichting?.period

  const saveRegistratie = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)

      const result = await dispatch(registreerUren({
        period_id: period.id,
        id: registratie.id,
        workorder_id: registratie.workorder_id || null,
        date: values.date.format('YYYY-MM-DD'),
        uren: values.uren,
        beschrijving: values.beschrijving,
        material_id: values.material_id || registratie.material_id,
        user_id: showAllUsers ? values.user_id : null
      }))

      if (result) {
        closeModal()
        form.resetFields()
      }
      setLoading(false)
    } catch (info) {
      setLoading(false)
      console.log('Validate Failed:', info)
    }
  }

  return (
    <Modal
      title={(registratie && registratie.id) ? $t('Wijzig uren registratie') : $t('Uren opgeven voor ${periode}', { periode: period?.naam || '' })}
      open={visible}
      confirmLoading={loading}
      onCancel={closeModal}
      onOk={saveRegistratie}
      okText={$t('Opslaan')}
      cancelText={$t('Annuleren')}
      okButtonProps={{ disabled: !period }}
    >

      {onderhoud.isPending && <Loader />}

      {!onderhoud.isPending && !period && <p>{$t('Er is geen onderhoudsperiode actief om werkuren op te schrijven.')}</p>}

      {period && !onderhoud.isPending &&
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          name='registratie'
          preserve={false}
          initialValues={{
            date: dayjs(registratie.date),
            uren: registratie.uren,
            beschrijving: registratie.beschrijving,
            period_id: period?.id,
            material_id: registratie.material_id ? registratie.material_id : (registratie.id ? '_overig' : null),
            user_id: registratie.user_id,
            project: registratie.project
          }}
          requiredMark={false}
        >

          {showAllUsers && (
            <Form.Item label={$t('Lid')} name='user_id'>
              <Select
                showSearch
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {flights.vliegers && flights.vliegers.map((user, key) =>
                  <Select.Option key={key} value={user.id}>{user.name}</Select.Option>
                )}
              </Select>
            </Form.Item>
          )}

          <Form.Item label={$t('Datum')} name='date'>
            <DatePicker
              format={localStorage.localeFormat}
              disabledDate={(current) => current && current > dayjs().endOf('day')}
            />
          </Form.Item>

          {!registratie.project && <Radio.Group
            className='spacer' size='small'
            style={{ marginLeft: 165 }}
            value={category} onChange={(event) => setCategory(event.target.value)}
                                   >
            <Radio.Button value='kist'>{$t('Vliegend')}</Radio.Button>
            <Radio.Button value='rollend'>{$t('Rollend')}</Radio.Button>
            <Radio.Button value='overig'>{$t('Overig')}</Radio.Button>
          </Radio.Group>}

          {!registratie.project && <Form.Item
            label={$t('Materiaal')} name='material_id'
            rules={[
              {
                required: true,
                message: $t('Selecteer project')
              }]}
                                   >
            <Select placeholder='Selecteer project' style={{ width: '100%' }}>
              {materiaal?.material?.map((mat, key) => mat.category === category && <Select.Option key={key} value={mat.id}>{mat.name}</Select.Option>)}
              {category === 'overig' && <Select.Option key='_overig' value='_overig'>Overige projecten</Select.Option>}
            </Select>
          </Form.Item>}

          <Form.Item label={$t('Uren')} name='uren'>
            <Input
              type='number'
              suffix='uren'
              placeholder={6}
              style={{ width: 120 }}
            />
          </Form.Item>

          <Form.Item
            label={$t('Beschrijving')}
            name='beschrijving'
            rules={[
              () => ({
                validator (rule, value) {
                  if (value && value.length >= 400) {
                    return Promise.reject('Opmerking mag maximaal 400 characters lang zijn')
                  } else if (!showAllUsers && (!value || value.length <= 10)) {
                    return Promise.reject($t('Voeg een duidelijke opmerking toe, minimaal 10 characters.'))
                  }
                  return Promise.resolve()
                }
              })
            ]}
          >
            <Input.TextArea rows={3} placeholder={$t('Voeg een duidelijke beschrijving toe.')} />
          </Form.Item>

        </Form>}

    </Modal>
  )
}
