import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, Radio, Tag, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { $t } from '~/i18n.js'

export const SectionStartmethode = ({
  readOnly, flight, saveFlight, connectOrCreateSleepFlight, selectedKist,
  size = 'medium', confirmSelection = false, extra_methods
}) => {
  // createNewSleepFlight: only for club tijdschrijven, not for private admin, if sleep selected it will generate a second new flight for the sleepvliegtuig
  const [start_methode, setStartMethod] = useState(flight.start_methode)
  const [loading, setLoading] = useState(false)
  const [alreadyFired, setAlreadyFired] = useState(false)

  let methods = selectedKist && selectedKist.category === 'sleep' ? ['tmg', 'tmg-a'] : ['lier', 'sleep', 'zelf', 'tmg']
  // $t('lier')
  // $t('sleep')
  // $t('zelf')
  // $t('tmg')
  // $t('tmg-a')
  if (extra_methods) methods = methods.concat(extra_methods)

  useEffect(() => {
    setStartMethod(flight.start_methode)
    setAlreadyFired(false)
    setLoading(false)
  }, [flight])

  const setStartMethode = async (event) => {
    // debounce function
    if (alreadyFired) {
      message.error($t('Methode word nog gewijzigt, een moment geduld'))
      return null
    }
    setAlreadyFired(true)

    // debounce only if sleepFlight should be connected
    setTimeout(() => setAlreadyFired(false), connectOrCreateSleepFlight ? 2000 : 100)

    setLoading(true)
    const new_method = event.target.value
    setStartMethod(new_method)

    let sleep_uuid = null
    if (connectOrCreateSleepFlight) {
      sleep_uuid = await connectOrCreateSleepFlight({ flight, new_method })
    }

    await saveFlight({ start_methode: new_method, sleep_uuid })
    setLoading(false)
  }

  const triggerStartMethode = (event) => {
    if (confirmSelection) {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: <p>
          {$t('Zeker weten?')} <br />
          {$t('Deze aanpassing in startmethode zal direct zichtbaar worden in de startadministratie.')}
        </p>,
        onOk () {
          setStartMethode(event)
        },
        onCancel () {
        }
      })
    } else {
      setStartMethode(event)
    }
  }

  return (
    <div className='column' style={{ alignItems: 'flex-start', marginTop: 12 }}>

      {start_methode === 'tmg-a' && !flight.sleep_uuid && <p style={{ marginBottom: 12 }}><Tag color='red'>Let op</Tag> Vlucht is aTMG aerotow zonder gekoppelde zweefvlucht. Wijzig startmethode.</p>}

      <div className='row spacer' style={{ flexWrap: 'wrap' }}>
        <Radio.Group
          size={size} buttonStyle='solid'
          disabled={readOnly || loading}
          value={start_methode}
          onChange={!loading && triggerStartMethode}
        >
          {methods.map((method, index) => <Radio.Button key={index} value={method}>{$t(method)}</Radio.Button>)}
        </Radio.Group>
      </div>

    </div>
  )
}
