import { AimOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, List, Modal, Popconfirm, Select } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { $t } from "~/i18n.js";
import { StartIcon } from "~/components/icon/icon.jsx";
import { generate_uuid } from "~/lib/helpers.js";
import { updateFlight } from "~/redux/flights/flights.actions.js";
import { sortClubKisten } from "~/redux/materiaal/materiaal.normalizers.js";

export const SleepClaimModal = ({ allFlights, readOnly, activeDay, openFlight }) => {
  const [modalVisible, setShowModal] = useState(false);
  const [pendingSave, setPendingSave] = useState(false);

  const dispatch = useDispatch();

  const claimFlight = async (flight) => {
    setPendingSave(true);
    let res = null;

    if (flight.sleep_uuid) {
      // Sleep was already created, as such just update this flight and we'll be good
      res = await dispatch(
        updateFlight({
          uuid: flight.sleep_uuid,
          sleep_uuid: flight.uuid,
          start_methode: "tmg-a",
          is_deleted: false,
          start_tijd: flight.start_tijd,
          datum: flight.datum,

          // ensures that claimed flight arrives ontop for usability of the sleepvlieger
          start_tijd_int: 99999,
        })
      );
    } else {
      // Create a sleep flight for this, unknown kist and times
      const new_sleep_uuid = generate_uuid();

      // update the flight itself
      await dispatch(
        updateFlight({
          uuid: flight.uuid,
          start_methode: "sleep",
          sleep_uuid: new_sleep_uuid,
        })
      );

      // create a new tmg sleep flight, note no kist, must select this as sleepvlieger
      res = await dispatch(
        updateFlight({
          uuid: new_sleep_uuid,
          start_methode: "tmg-a",
          sleep_uuid: flight.uuid,

          dag_id: flight.dag_id,
          datum: flight.datum,
          index: allFlights.length,

          // note sleep will return by default to starting field
          vertrek_vliegveld: flight.vertrek_vliegveld,
          aankomst_vliegveld: flight.vertrek_vliegveld,
        })
      );

      if (res) onCancel();
      setPendingSave(false);
    }
  };

  const addFlight = async () => {
    setPendingSave(true);
    const new_zweef_uuid = generate_uuid();
    const new_sleep_uuid = generate_uuid();
    const baseData = {
      dag_id: activeDay.dag_id,
      datum: activeDay.datum,
      index: allFlights.length,
      vertrek_vliegveld: activeDay.vertrek_vliegveld,
      aankomst_vliegveld: activeDay.vertrek_vliegveld
    }
    await dispatch(
      updateFlight({
        uuid: new_zweef_uuid,
        sleep_uuid: new_sleep_uuid,
        start_methode: "sleep",
        ...baseData
      })
    );
    let flight = await dispatch(
      updateFlight({
        uuid: new_sleep_uuid,
        sleep_uuid: new_zweef_uuid,
        start_methode: "tmg-a",
        ...baseData
      })
    );
    
    if(flight) {
      openFlight(flight)
      onCancel();
    }
    setPendingSave(false);
  };

  const [registratieFilter, setRegistratieFilter] = useState(null);
  let kistenLookup = {};
  let kisten = [];
  const flights = [];
  if (modalVisible) {
    kistenLookup = allFlights.reduce((all, flight) => {
      if (["tmg", "tmg-a"].indexOf(flight.start_methode) < 0) {
        all[flight.registratie] = {
          kist_id: flight.kist_id,
          registratie: flight.registratie,
          callsign: flight.callsign,
          type: flight.type,
        };

        if (
          registratieFilter &&
          flight.registratie === registratieFilter &&
          flight.start_tijd
        ) {
          flights.push({
            ...flight,
            sortby: parseInt(flight.start_tijd.replace(/\D/g, "")),
          });
        }
      }
      return all;
    }, {});
    kisten = sortClubKisten(
      Object.keys(kistenLookup).map((registratie) => kistenLookup[registratie])
    );
    flights.sort((a, b) => b.sortby - a.sortby);
  }

  const onCancel = () => {
    setShowModal(false);
    setRegistratieFilter(null);
  };

  return (
    <>
      {modalVisible && (
        <Modal
          title={$t("Voeg sleep toe")}
          open={modalVisible}
          confirmLoading={pendingSave}
          onCancel={onCancel}
          footer={[<AnnuleerButton key="annuleren" onClick={onCancel} />]}
        >
          <div style={{ padding: "0 12px 42px", minHeight: 330 }}>
            <p className="small">
              {$t(
                "Voeg een lege sleep toe"
              )}
            </p>
            <Button
                style={{margin: '12px 0 32px'}}
                disabled={readOnly}
                type="default"
                size="small"
                icon={<PlusOutlined />}
                loading={pendingSave}
                onClick={addFlight}
              >
                {$t("Voeg toe")}
              </Button>


            <p className="small">
              {$t(
                "Als een vlucht niet als sleep is aangemerkt kan je dat hier wijzigen. Nieuwe vluchten kan je alleen via de start administratie toevoegen."
              )}
            </p>

            <div className="row" style={{ padding: "24px 0" }}>
              <p>{$t("Selecteer kist")}:</p>
              <Select
                showSearch
                style={{ width: 200, marginLeft: 12 }}
                onChange={(v) => setRegistratieFilter(v.split("||")[1])}
                filterOption={(input, option) => {
                  return (
                    option &&
                    option.value &&
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {kisten &&
                  kisten.map((kist, key) => (
                    <Select.Option
                      key={key}
                      value={`${kist.callsign}||${kist.registratie}`}
                    >
                      {kist.callsign ? kist.callsign + " - " : ""}{" "}
                      {kist.registratie}
                    </Select.Option>
                  ))}
              </Select>
            </div>

            {flights && flights.length > 0 && (
              <List
                style={{ maxHeight: 230, overflowY: "auto" }}
                itemLayout="horizontal"
                dataSource={flights}
                renderItem={(flight) => (
                  <List.Item
                    actions={[
                      <Popconfirm
                        key="1"
                        placement="bottom"
                        title={$t(
                          "Zeker weten dat je de vlucht wilt omzetten naar een Sleepstart?"
                        )}
                        onConfirm={() => claimFlight(flight)}
                        disabled={readOnly}
                        okText={$t("Ja")}
                        cancelText={$t("Nee")}
                      >
                        <Button
                          disabled={readOnly}
                          type="primary"
                          size="small"
                          loading={pendingSave}
                        >
                          {$t("zet om")}
                        </Button>
                      </Popconfirm>,
                    ]}
                  >
                    <List.Item.Meta
                      title={
                        <div className="row">
                          <p style={{ width: 80 }}>
                            <StartIcon color="lightgray" />{" "}
                            {flight.start_tijd || ""}
                          </p>
                          <p style={{ width: 80 }}>
                            {$t(flight.start_methode)}
                          </p>
                          <p>{flight.gezagvoerder_naam || $t("onbekend")}</p>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            )}
          </div>
        </Modal>
      )}

      <Button
        size="small"
        icon={<AimOutlined />}
        style={{ marginLeft: 6 }}
        onClick={() => setShowModal(true)}
      >
        {$t("Voeg toe")}
      </Button>
    </>
  );
};
