import { $t } from '~/i18n.js'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { AddMeldingModal } from './addMeldingModal.jsx'
import { addMelding, getMaterialData } from '~/redux/materiaal/materiaal.actions.js'
import { updateIfNeeded } from '~/lib/helpers.js'

function successNewMeldingAdded (onOk) {
  Modal.success({
    title: $t('Dank!'),
    content: $t('We hebben de technici een mailtje gestuurd met de melding, die gaan het nu beoordelen. Mocht je nog een toelichting willen geven, laat dan een notitie achter.'),
    onOk
  })
}

export const AddMeldingButton = ({ history, currentMaterial, currentWorkorderID, propagateNewMelding, key=1 }) => {
  const { persist, materiaal } = useSelector((state) => state)
  const dispatch = useDispatch()

  const [modalOpen, setmodalOpen] = useState(false)

  // if modal opens, check if technici can be downloaded
  useEffect(() => {
    modalOpen && !currentWorkorderID && updateIfNeeded(materiaal.lastUpdated, () => dispatch(getMaterialData()))
  }, [modalOpen])

  const handleAddNew = async (data) => {
    const melding = await dispatch(addMelding(data))
    if (melding?.id && !currentWorkorderID) {
      successNewMeldingAdded(() => history && history.push('/report/' + melding.id))
    }
    if (melding?.id && propagateNewMelding) propagateNewMelding(melding)
    return melding
  }

  return (
    <div key={key}>
      {persist.profile.vliegt && modalOpen && (
        <AddMeldingModal
          visible={modalOpen}
          onSave={handleAddNew}
          onClose={() => setmodalOpen(false)}
          activeMelding={null}
          materiaal={materiaal}
          currentMaterial={currentMaterial}
          currentWorkorderID={currentWorkorderID}
        />
      )}

      {addMelding && persist.profile.vliegt && (
        <Button type='primary' icon={<PlusOutlined />} onClick={() => setmodalOpen(true)}>
          {$t('Nieuwe melding')}
        </Button>
      )}

    </div>
  )
}
