import { $t } from '~/i18n.js'
import React from 'react'
import { Tabs } from 'antd'
import { Notities } from "~/components/profile/tabs/notities.jsx";
import { BevoegdhedenClusterTab } from "~/components/bevoegdheden/BevoegdhedenClusterTab.jsx";
import { DTOtrainingTab } from "~/components/profile/tabs/dtoTraining.jsx";

export const CardInstructeursboek = ({
  notities,
  pilot,
  behaalde_bevoegdheden,
  bevoegdheden,
  dto_training,
}) => {
  return (
    <Tabs defaultActiveKey="vliegers">
      <Tabs.TabPane tab={$t("Notities")} key="notities">
        <Notities notities={notities || []} alleenPublic />
      </Tabs.TabPane>

      {bevoegdheden &&
        bevoegdheden.map(
          (cluster) =>
            // always show if cluster is not dto, or otherwise only if dto training has started
            (!cluster.is_dto ||
              (dto_training &&
                dto_training[cluster.id] &&
                dto_training[cluster.id].status === "started")) && (
              <Tabs.TabPane tab={cluster.name} key={cluster.name}>
                <BevoegdhedenClusterTab
                  pilot={pilot}
                  behaalde_bevoegdheden={behaalde_bevoegdheden}
                  cluster={cluster}
                />
              </Tabs.TabPane>
            )
        )}

      <Tabs.TabPane tab={$t("DTO")} key="DTO">
        <DTOtrainingTab
          canWijzigDTO={false}
          dto_training={dto_training}
          bevoegdheden={bevoegdheden}
          pilot={pilot}
        />
      </Tabs.TabPane>
    </Tabs>
  );
};
