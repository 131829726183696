import { Collapse } from "antd";
import React, { useState } from "react";

import { BevoegdheidModal } from "./components/BevoegdheidModal.jsx";
import { Category } from "./components/Category.jsx";

import "./bevoegdheden.css";

export const BevoegdhedenClusterTab = ({
  pilot,
  setBevoegdheid,
  behaalde_bevoegdheden,
  cluster,
}) => {
  const [bevoegheid, openBevoegheid] = useState(null);
    
  // Determine which tabs must be default open, and also get a count of achieved bevoegheden
  let defaultActiveKeys = [];

  for (let i = 0; i < cluster.category.length; i++) {
    cluster.category[i].count = 0;
    cluster.category[i].nearly_invalid = false;
    cluster.category[i].bevoegdheid.forEach((bevoegheid) => {
      bevoegheid.behaald = behaalde_bevoegdheden[bevoegheid.id] || {};
      if (bevoegheid.behaald.assigned) cluster.category[i].count += 1;
      if (bevoegheid.behaald.nearly_invalid) cluster.category[i].nearly_invalid = true;
    });

    // add to default open if items are open and none is already opened
    if (
      defaultActiveKeys.length < 1 &&
      cluster.category[i].count < cluster.category[i].bevoegdheid.length
    ) {
      defaultActiveKeys = [i];
    }
  }

  return (
    <>
      <BevoegdheidModal
        bevoegdheid={bevoegheid}
        user_id={pilot.id}
        closeModal={() => openBevoegheid(null)}
        setBevoegdheid={setBevoegdheid}
        cluster={cluster}
      />

      {cluster.description && (
        <p className="userInput" style={{ color: "darkgray" }}>
          {cluster.description}{" "}
          <span style={{ marginLeft: 4 }} className="gray">
            {cluster.curriculum_versie}
          </span>
        </p>
      )}

      <Collapse
        bordered={false}
        className="BevoegdheidCollapsible"
        defaultActiveKey={defaultActiveKeys}
        style={{ maxHeight: 400, overflowY: "auto", overflowX: "hidden" }}
      >
        {cluster &&
          cluster.category.map((category, key) =>
            Category(
              key,
              category,
              pilot.id,
              cluster,
              setBevoegdheid,
              openBevoegheid
            )
          )}
      </Collapse>
    </>
  );
};
