import React, { useState } from "react";
import { useSelector } from "react-redux";
import { SelectedFlightModal } from "~/components/selectedFlight/SelectedFlightModal.jsx";
import { api } from "~/lib/api.js";

export const AdminFlightEditModal = ({ flight, history, updateFlight }) => {
  const { kisten, sleep_kisten, vliegers } = useSelector(
    (state) => state.flights
  );
  const { profile } = useSelector((state) => state.persist);

  if (!flight) return null;

  const [saving, setSaving] = useState(false);
  const saveFlight = async (payload) => {
    setSaving(true);
    const { response, data } = await api.post2(
      "flights/save_flight.json",
      payload
    );

    if (response.ok && data) updateFlight(data);
    setSaving(false);
    return data;
  };

  return (
    <SelectedFlightModal
      readOnly={false}
      currentUser={profile}
      visible={!!flight?.uuid}
      onClose={() => {
        history.replace("/admin/flights");
      }}
      flight={flight}
      isPending={saving}
      updateFlight={(data) => saveFlight({ ...data })}
      openConnectedFlight={true}
      kisten={kisten}
      sleep_kisten={sleep_kisten}
      vliegers={vliegers}
      aanmeldingen={[]}
    />
  );
};
