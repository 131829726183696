import { sortClubKisten } from '../materiaal/materiaal.normalizers.js'
import { normalizeVliegers } from '../persist/persist.normalizers.js'
import { getFlightOrder, getKistStatus, normalizeFlights } from './flights.normalizers.js'
import { flightsState } from './flights.state.js'

export const flightsReducer = (state = flightsState, action) => {
  switch (action.type) {
    case 'FLIGHTS_PENDING':
      return {
        ...state,
        isPending: true
      }

    case 'FLIGHT_STATS_PENDING':
      return {
        ...state,
        isPending: true
      }

    case 'FLIGHTS_ERROR':
      return {
        ...state,
        isPending: false
      }

    case 'SET_BASE_DATA': {


      // kisten is sorted along club and non-club. However we must sort club along in the proper way
      const club_kisten = sortClubKisten(action.kisten.filter((kist) => kist.category === 'club' && kist.is_visible))
      const sleep_kisten = sortClubKisten(action.kisten.filter((kist) => kist.category === 'sleep'))

      // in case sleep is used as tmg kist, we add in this kist
      const all_club_kisten = club_kisten.concat(sleep_kisten)
      const otherKisten = all_club_kisten.concat(action.kisten.filter((kist) => (kist.category !== 'club' && kist.category !== 'sleep')))

      const all_group_keys = Object.keys(action.group_name_lookup || {}).sort()

      return {
        ...state,
        isPending: false,
        lastUpdatedBaseData: new Date(),
        vliegers: normalizeVliegers(action.vliegers, action.default_vliegers),
        club_kisten: all_club_kisten,
        sleep_kisten,
        kisten: otherKisten,
        bevoegdheden: action.bevoegdheden,
        group_name_lookup: action.group_name_lookup,
        all_group_keys
      }
    }

    case 'UPDATE_VLIEGER_IN_LIST': {
      // replace the vlieger in the list of users with the latest profile data
      const vliegers = state.vliegers.map((vlieger) => {
        if (vlieger.id === action.id) {
          return action.vlieger_data
        }
        return vlieger
      })
      return {
        ...state,
        vliegers
      }
    }

    case 'ADD_KIST_TO_DATA':
      // replace the kist in the list of users with the latest profile data, first filter out the existing kist if you save it after correcting type
      return {
        ...state,
        kisten: state.kisten.filter((kist) => kist.id !== action.kist.id).concat(action.kist)
      }

    case 'FLIGHTS_SUCCESS': {
      const normalizedFlights = normalizeFlights(action.allFlights).sort((a, b) => b.order - a.order)
      return {
        ...state,
        isPending: false,
        allFlights: normalizedFlights,
        kistStatus: getKistStatus(state.club_kisten, normalizedFlights)
      }
    }

    case 'FLIGHT_STATS_SUCCESS':
      const myNormalizedFlights = normalizeFlights(action.flights)
      return {
        ...state,
        isPending: false,
        lastUpdatedFlightData: new Date(),
        allFlights: myNormalizedFlights,
        totals: {
          total_starts: action.total_starts,
          total_pic_starts: action.total_pic_starts,
          total_dbo_starts: action.total_dbo_starts,
          total_time: action.total_time,
          total_dbo_time: action.total_dbo_time,
          total_pic_time: action.total_pic_time
        }
      }

    case 'NEW_FLIGHT':
      const addedFlights = [action.flightData].concat(state.allFlights)
      return {
        ...state,
        allFlights: addedFlights,
        kistStatus: getKistStatus(state.club_kisten, addedFlights)
      }

    case 'UPDATE_FLIGHT':
      // insert flight with new object through map function for deep copy
      let found = false
      let newAllFlights = state.allFlights.map((flight) => {
        // either return existing flight, or updated flight normalized with order
        if (flight.uuid === action.flightData.uuid) {
          found = true
          return { ...flight, ...action.flightData, order: getFlightOrder(action.flightData) }
        }
        return flight
      })

      // if we are updating a flight not found in the list, can be for deleted or new ogn flights, add them manually
      if (!found) newAllFlights = newAllFlights.concat({ ...action.flightData, order: getFlightOrder(action.flightData) })

      return {
        ...state,
        allFlights: newAllFlights.sort((a, b) => b.order - a.order),
        kistStatus: getKistStatus(state.club_kisten, newAllFlights)
      }

    case 'UPDATE_FLIGHT_FROM_WEBSOCKET':
      // Remove flight from existing array, pop to front and normalize
      const allFlights = [{ ...action.flight, order: getFlightOrder(action.flight) }]
        .concat(state.allFlights.filter((f) => f.uuid !== action.flight.uuid))
        .sort((a, b) => b.order - a.order)

      return {
        ...state,
        allFlights,
        events: [action.event].concat(state.events),
        kistStatus: getKistStatus(state.club_kisten, allFlights)
      }

    case 'DELETE_FLIGHT':
      const all = normalizeFlights(
        state.allFlights.filter((flight) => flight.uuid !== action.flightData.uuid)
      )
      return {
        ...state,
        allFlights: all,
        kistStatus: getKistStatus(state.club_kisten, all)
      }

    case 'ONLINE':
      return {
        ...state,
        network_online: true
      }

    case 'OFFLINE':
      return {
        ...state,
        network_online: false
      }

    case 'ADD_TO_QUEUE':
      const new_queue = { ...state.flight_queue }
      // add flight to queue, update existing records with new data
      new_queue[action.data.uuid] = { ...new_queue[action.data.uuid], ...action.data, _t: new Date() }
      return {
        ...state,
        flight_queue: new_queue
      }

    case 'REMOVE_FROM_QUEUE':
      if (state.flight_queue[action.uuid]) {
        const removed_queue = { ...state.flight_queue }
        // remove uuid from queue
        delete removed_queue[action.uuid]
        return {
          ...state,
          flight_queue: removed_queue
        }
      } else {
        return state
      }

    case 'UPDATE_OGN_MODE':
      return {
        ...state,
        ogn_mode: action.ogn_mode ? action.ogn_mode : state.ogn_mode,
        default_flight_cat: action.default_flight_cat,
        default_sleep_cat: action.default_sleep_cat,
        default_tmg_cat: action.default_tmg_cat,
        suggesties: []
      }

    case 'UPDATE_FLIGHTLOG':
      return {
        ...state,
        flightlogs: action.flightlogs || []
      }
    case 'ADD_FLIGHTLOG': {
      // Update and remove logs
      // if log contains flight_uuid then the log is already connected.
      const filtered_logs = [...state.flightlogs.filter((log) => log.id !== action.flightlog.id)];
      return {
        ...state,
        // only add to logs if flightLog does not have a UUID (e.g. not synced to logs)
        flightlogs: action.flightlog.flight_uuid ? filtered_logs : [action.flightlog].concat(filtered_logs)
      };
    }

    case 'RESET_FLIGHTS_STATE':
      return {
        ...flightsState
      }

    default:
      return state
  }
}
