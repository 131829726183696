import { Button, Cascader, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { updateIfNeeded } from "~/lib/helpers.js";
import {
  getMaterialData,
  saveMaterialTab,
} from "~/redux/materiaal/materiaal.actions.js";
import { normalizePartsListForCascade } from "~/redux/materiaal/materiaal.normalizers.js";

export const MovePartModal = ({
  visible,
  part,
  currentMaterial,
  onSave,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const { materiaal } = useSelector((state) => state);

  // if modal opens, check if material overview needs to be downloaded
  useEffect(() => {
    visible &&
      part &&
      updateIfNeeded(materiaal.lastUpdated, () => dispatch(getMaterialData()));
  }, [visible, part]);

  const [newMaterialID, setNewMaterialID] = useState(null);
  const [newPartID, setNewPartID] = useState([]);
  useEffect(() => {
    if (visible) {
      setNewMaterialID(currentMaterial.id);
      setNewPartID([]);
    }
  }, [visible, part, currentMaterial]);

  const [loadingParts, setLoadingParts] = useState(null);
  const [partsList, setPartsList] = useState(null);
  useEffect(() => {
    // fire async function off, and make sure that you only mount if active
    const getData = async () => {
      setLoadingParts(true);
      setNewPartID(null);
      const res = await api.get(`materiaal/${newMaterialID}/tab_parts.json`);

      // check if function still active, waiting for loading closing modal is a ratrace condition
      if (active) {
        // reduce parts list to format for
        const normalize = (part) => ({
          value: part.id,
          label: part.name,
          children: part.parts.map((p) => normalize(p)),
        });
        setPartsList(normalizePartsListForCascade(res?.data?.parts));
      }
      setLoadingParts(false);
    };
    if (!newMaterialID || loadingParts) return;
    let active = true;
    getData();
    return () => (active = false);
  }, [newMaterialID]);

  const [saving, setSaving] = useState(false);
  const movePart = async () => {
    setSaving(true);
    const payload = {
      action: "move",
      id: part.id,
      material_id: newMaterialID,
      parent_part_id:
        newPartID?.length > 0 ? newPartID[newPartID.length - 1] : null,
    };
    const res = dispatch(
      saveMaterialTab({
        activeTab: "parts",
        materialID: currentMaterial.id,
        payload,
      })
    );
    if (res) {
      closeModal();
      onSave();
    }
    setSaving(false);
  };

  return (
    <Modal
      title={$t("Verhuis onderdeel")}
      width={400}
      confirmLoading={saving}
      open={!!part}
      onCancel={closeModal}
      footer={[
        <AnnuleerButton key="annuleren" onClick={closeModal} />,
        <Button
          key="opslaan"
          disabled={!newMaterialID && newPartID?.length === 0}
          onClick={movePart}
          type="primary"
          loading={saving}
        >
          {$t("Opslaan")}
        </Button>,
      ]}
    >
      <p className="spacer">
        {$t("Verhuis onderdeel ${partname} naar:", {
          partname: part.name,
        })}
      </p>

      <Select
        style={{ width: 200, marginBottom: 42 }}
        value={newMaterialID}
        onChange={(id) => setNewMaterialID(id)}
      >
        {materiaal?.material?.map((mat, key) => (
          <Select.Option key={key} value={mat.id}>
            {mat.name}
          </Select.Option>
        ))}
      </Select>

      <p className="spacer">{$t("Indien nodig, onder")}:</p>
      <Cascader
        disabled={loadingParts}
        style={{ width: 200 }}
        placeholder=""
        options={partsList}
        changeOnSelect
        value={newPartID}
        onChange={(id) => setNewPartID(id)}
      />
    </Modal>
  );
};
