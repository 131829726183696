import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Switch
} from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { $t } from '~/i18n.js'
import { toLocaleDate } from "~/lib/localize.js";

export const SelectTypeDag = ({ setItem, setName, name, items }) => (
  <Form.Item
    label={$t('Soort dag')}
    name='type_dag'
    rules={[{ required: true, message: $t('Voeg een dag soort toe!') }]}
  >
    <Select
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input
              style={{ flex: 'auto' }}
              value={name}
              onChange={(event) =>
                setName(
                  event.target.value.replace(/^\w/, (c) => c.toUpperCase())
                )}
            />
            <Button
              type='primary'
              style={{ marginLeft: 4 }}
              onClick={() => {
                if (name.length > 0) {
                  setItem(items.concat(name))
                  setName('')
                }
              }}
            >
              <PlusOutlined /> {$t('Andere')}
            </Button>
          </div>
        </div>
      )}
    >
      {items &&
        items.length > 0 &&
        items.map((type, key) => (
          <Select.Option key={key} value={type}>
            {type}
          </Select.Option>
        ))}
    </Select>
  </Form.Item>
)

export const AddDayModal = ({
  activeDay,
  club,
  visible,
  onCancel,
  onOk,
  confirmLoading,
  link_to
}) => {
  if (!visible) return null
  const [form] = Form.useForm()

  const [name, setName] = useState('')
  const [items, setItem] = useState(club && club.dag_types?.split(','))
  const [atThuisveld, setAtThuisVeld] = useState(true)
  const [vliegend, setVliegend] = useState(true)

  const vliegveld = !club
    ? ''
    : club.vliegveld_icao
      ? club.vliegveld_icao
      : club.vliegveld

  useEffect(() => {
    setAtThuisVeld(
      !activeDay || !club || activeDay.vertrek_vliegveld === vliegveld
    )
    setVliegend(!activeDay ? true : activeDay?.is_vliegend)
  }, [activeDay, visible])

  return (
    <Modal
      title={
        activeDay
          ? toLocaleDate(new Date(activeDay.datum))
          : $t('Voeg nieuwe dag toe')
      }
      open={visible}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      okText={$t('Opslaan')}
      cancelText={$t('Annuleren')}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()

            const vertrek_vliegveld =
              atThuisveld || !values.locatie ? vliegveld : values.locatie
            onOk({
              ...activeDay,
              ...values,
              datum: activeDay
                ? activeDay.datum
                : values.datum.format("YYYY-MM-DD"),
              briefing_tijd: values.briefing_tijd,
              aankomst_vliegveld: vertrek_vliegveld || "-",
              vertrek_vliegveld: vertrek_vliegveld || "-",
              notify_users: !!activeDay,
            });
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name='addDay'
        preserve={false}
        requiredMark={false}
        initialValues={{
          type_dag:
            club && club.dag_types?.length > 0
              ? club.dag_types?.split(',')[0].trim()
              : '',
          start_methode: 'lier',
          ...activeDay,
          datum: activeDay ? dayjs(activeDay.datum) : dayjs(),
          briefing_tijd: activeDay ? activeDay.briefing_tijd : '09:00',
          locatie: activeDay ? activeDay.vertrek_vliegveld : vliegveld
        }}
      >
        {link_to && (
          <Form.Item
            label={$t('Datum')}
            name='datum'
            rules={[{ required: true, message: $t('Voeg datum toe!') }]}
          >
            <DatePicker
              format={localStorage.localeFormat}
              disabledDate={(current) => {
                // for start overview, add days in past
                if (link_to === 'start') return current && current > dayjs().startOf('day')

                // for register overview, only add days in future
                return current && current < dayjs().startOf('day')
              }}
            />
          </Form.Item>
        )}

        <SelectTypeDag
          setItem={setItem}
          setName={setName}
          name={name}
          items={items}
        />

        <Form.Item
          label={$t('Beschrijving')}
          name='titel'
          rules={[
            {
              required: false,
              message: $t('Maximaal ${c} characters', { c: 100 }),
              max: 110
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={$t('Vliegend')} name='is_vliegend'>
          <Switch
            onChange={(checked) => setVliegend(checked)}
            checked={vliegend}
            checkedChildren={$t('Ja')}
            unCheckedChildren={$t('Nee')}
          />
        </Form.Item>

        {vliegend && (
          <div>
            <Form.Item
              label={$t('Thuisveld (${vliegveld})', {
                vliegveld: vliegveld || ' - '
              })}
            >
              <Switch
                onChange={(checked) => setAtThuisVeld(checked)}
                checked={atThuisveld}
                checkedChildren={$t('Ja')}
                unCheckedChildren={$t('Nee')}
              />
            </Form.Item>

            {!atThuisveld && (
              <div>
                <Form.Item label={$t('Locatie')} name='locatie'>
                  <Input placeholder={$t('voeg de ICAO code toe.')} />
                </Form.Item>

              </div>
            )}
          </div>
        )}

        {!vliegend && (
          <Form.Item label={$t('Locatie')} name='locatie'>
            <Input />
          </Form.Item>
        )}

        {vliegend && (
          <Form.Item label={$t('Start methode')} name='start_methode'>
            <Select>
              {['lier', 'sleep', 'zelf', 'tmg'].map(
                // $t('lier') $t('sleep') $t('zelf') $t('tmg')
                (start, idx) => (
                  <Select.Option key={idx} value={start}>
                    {$t(start)}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label={$t('Briefing tijd')}
          name='briefing_tijd'
          rules={[{ required: true, message: $t('Voeg tijd toe!') }]}
        >
          <Input type='time' style={{ width: 120 }} placeholder='hh:mm' />
        </Form.Item>

        {activeDay && (
          <Form.Item
            label={$t('Gaat door')}
            name='gaat_door'
            valuePropName='checked'
          >
            <Switch checkedChildren={$t('Ja')} unCheckedChildren={$t('Nee')} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}
