import { PlusOutlined, ToolOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { $t } from "~/i18n.js";

import { updateIfNeeded } from "~/lib/helpers.js";
import { toLocaleDate } from "~/lib/localize.js";
import { materiaalActions } from "~/redux/actions.js";
import { AddMeldingModal } from "./sections/addMeldingModal.jsx";

import {
  InFlightService,
  MeldingCategoryLine,
  MeldingStatusTag,
} from "../materialTags.jsx";
import { helpDocs } from "./sections/help.jsx";

import { AddBerichtModal } from "./sections/addBerichtModal.jsx";
import { MeldingStateButtons } from "./sections/MeldingStateButtons.jsx";

import { Bericht } from "~/components/bericht/bericht.jsx";
import { HelpButton } from "~/components/help/help.jsx";
import { BreadcrumbHeader } from "~/components/layout/breadcrumbHeader.jsx";
import { MainLayout } from "~/components/layout/layout.jsx";

const MeldingPageComponent = ({
  history,
  match,
  materiaal,
  activeMelding,
  profile,
  getMelding,
  updateMelding,
  addMessage,
  getMaterialData,
}) => {
  const [loading, setLoading] = useState(true);
  const [pendingAction, setPendingAction] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await getMelding(match.params.id);
      setLoading(false);
    };
    fetchData();
  }, [match.params.id, getMelding, setLoading]);

  // only if modal opens get base data for technici
  useEffect(() => {
    pendingAction === "edit" &&
      updateIfNeeded(materiaal.lastUpdated, getMaterialData);
  }, [pendingAction]);

  const handleSave = async (data) => {
    const result = await updateMelding(data);
    if (result) setPendingAction(null);
    return result;
  };

  const handleAddMessage = async (data) => {
    const message = await addMessage({
      bericht: data.bericht,
      only_for_technicians: data.only_for_technicians,
      files: data.files,
      melding_id: activeMelding.id,
      transitie: pendingAction !== "nieuw_bericht" ? pendingAction : "",
    });
    setPendingAction(null);
    return message;
  };

  const handlePendingAction = (newAction) => {
    // only update if no other action present
    if (pendingAction) return null;
    setPendingAction(newAction);
  };

  const is_technicus = profile.group_keys?.includes(
    activeMelding?.material?.category === "kist"
      ? "technicus_vliegend"
      : "technicus_rollend"
  );

  const docs = helpDocs();
  return (
    <MainLayout
      history={history}
      isLoading={loading || materiaal.isPending || !activeMelding.id}
    >
      {pendingAction && pendingAction !== "edit" && (
        <AddBerichtModal
          is_technicus={is_technicus}
          saveExternalWorkorder={
            pendingAction === "afgerond" &&
            activeMelding?.category === "defect" &&
            activeMelding?.material?.category === "kist"
          }
          pendingAction={pendingAction}
          onClose={() => {
            setPendingAction(null);
          }}
          onSave={handleAddMessage}
        />
      )}

      <AddMeldingModal
        visible={pendingAction === "edit"}
        onSave={handleSave}
        onClose={() => setPendingAction(null)}
        activeMelding={activeMelding}
        materiaal={materiaal}
      />

      <BreadcrumbHeader
        breadcrumbs={[
          <Link to="/melding" className="title" key="1">
            {$t("Overzicht meldingen")}
          </Link>,
          activeMelding.material && (
            <Link
              key="2"
              className="title"
              to={`/materiaal/${activeMelding.material.id}`}
            >
              {activeMelding.material.name}
            </Link>
          ),
          `${$t("Melding")} #${activeMelding.id}`,
        ]}
        buttons={[<HelpButton helpDocs={docs} key='3' />]}
      />

      {
        // melding aanpassen kan als je de overall permissie hebt, of een rollen/vliegend indien het ook over rollend/vliegend gaat
        (profile.wijzig_melding ||
          (profile.wijzig_melding_rollend &&
            !activeMelding.material?.kist_id) ||
          (profile.wijzig_melding_vliegend &&
            activeMelding.material?.kist_id)) && (
          <MeldingStateButtons
            melding={activeMelding}
            saveMelding={updateMelding}
            setPendingAction={handlePendingAction}
          />
        )
      }

      <Card
        title={
          <div>
            <p className="bold">
              <MeldingStatusTag
                status={activeMelding.status}
                workorder_id={activeMelding.workorder_id}
              />{" "}
              {activeMelding.titel}
            </p>
            {activeMelding.workorder_id && (
              <Button
                size="small"
                style={{ marginTop: 12 }}
                icon={<ToolOutlined />}
                onClick={() =>
                  history.push(
                    `/material/${activeMelding.material_id}/workorders/${activeMelding.workorder_id}/view`
                  )
                }
              >
                {$t("Ga naar workorder")}
              </Button>
            )}
          </div>
        }
        style={{ marginTop: 24 }}
      >
        {activeMelding.kist && (
          <p style={{ marginBottom: 6 }}>
            <Tag color="blue">{$t("Vliegend")}</Tag>{" "}
            {activeMelding.kist.call_sign}: {activeMelding.kist.registratie}
          </p>
        )}

        {activeMelding.rollend && (
          <p style={{ marginBottom: 6 }}>
            <Tag color="blue">{$t("Rollend")}</Tag> {activeMelding.rollend.type}
            : {activeMelding.rollend.naam}
          </p>
        )}

        <MeldingCategoryLine
          isVliegend={activeMelding?.material?.category === "kist"}
          category={activeMelding.category}
        />

        <Divider style={{ margin: "12px 0" }} />

        <p style={{ marginBottom: 6 }}>
          {$t("Gemeld door")}:{" "}
          {activeMelding.user ? activeMelding.user.name : ""}
        </p>
        <p>
          {$t("Toegewezen technicus")}:{" "}
          {activeMelding.technicus ? (
            activeMelding.technicus.name
          ) : (
            <span className="alert bold">{$t("Géén")}</span>
          )}
        </p>

        <Divider style={{ margin: "12px 0" }} />

        <p style={{ marginBottom: 6 }}>
          <span>
            {$t("Datum aangemeld")}{": "}
            {toLocaleDate(new Date(activeMelding.date_created))}
          </span>
        </p>
        <InFlightService element={activeMelding} />
        {activeMelding.date_verwacht_afgehandeld && (
          <span>
            {$t("Datum verwachtte afhandeling")}{": "}
            {toLocaleDate(new Date(activeMelding.date_created))}
          </span>
        )}
      </Card>

      <div style={{ padding: 24 }}>
        {profile.vliegt && (
          <div
            className="header row clickable"
            onClick={() => handlePendingAction("nieuw_bericht")}
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              size="small"
              shape="circle"
              style={{ marginRight: 10 }}
            />
            <div
              className="row"
              style={{ alignItems: "center", marginBottom: 6 }}
            >
              {$t("Notities")}
            </div>
          </div>
        )}

        {activeMelding.berichten.map((bericht, index) => (
          <Bericht
            key={index}
            not_visible={bericht.only_for_technicians}
            date_created={bericht.date_created}
            user={bericht.user}
            message={bericht.bericht}
            transition={bericht.transitie}
            files={bericht.bestanden}
          />
        ))}
      </div>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  profile: state.persist.profile,
  activeMelding: state.materiaal.activeMelding,
  materiaal: state.materiaal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getMaterialData: (d) => dispatch(materiaalActions.getMaterialData(d)),
    getMelding: (d) => dispatch(materiaalActions.getMelding(d)),
    updateMelding: (d) => dispatch(materiaalActions.updateMelding(d)),
    addMessage: (d) => dispatch(materiaalActions.addMessage(d)),
  };
};

export const MeldingPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(MeldingPageComponent);
