import React, { useEffect, useState } from 'react'
import { MainLayout } from '../../components/layout/layout'
import { $t } from '~/i18n.js'
import { updateIfNeeded } from '../../lib/helpers'

import { useDispatch, useSelector } from 'react-redux'
import { getPeriod, savePeriod } from '../../redux/onderhoud/onderhoud.actions'

import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Select } from 'antd'

import { BreadcrumbHeader } from '../../components/layout/breadcrumbHeader'
import { CurrentPeriodSection } from './currentPeriod'
import { PeriodForm } from './period.form'

export const OnderhoudsUrenPagina = ({ history }) => {
  const { onderhoud, persist } = useSelector((state) => state)
  const dispatch = useDispatch()

  useEffect(() => {
    updateIfNeeded(onderhoud.lastUpdated, () => dispatch(getPeriod({ id: 'current' })))
  }, [onderhoud.lastUpdated, dispatch])

  const [editPeriod, setEditPeriod] = useState(null)

  return (
    <>

      {editPeriod && <PeriodForm
        activePeriod={editPeriod}
        savePeriod={(payload) => dispatch(savePeriod(payload))}
        closeModal={() => setEditPeriod(null)}
                     />}

      <MainLayout history={history} isLoading={onderhoud.isPending}>

        <BreadcrumbHeader breadcrumbs={[$t('Onderhoudsuren')]} />

        <div className='row' style={{ justifyContent: 'flex-start', flexWrap: 'wrap', minHeight: 35, alignItems: 'center' }}>

          <div>
            <Select
              value={onderhoud.current_period && onderhoud.current_period.id} style={{ width: 470, maxWidth: '90vw', marginBottom: 6 }}
              onChange={(id) => {
                dispatch(getPeriod({ id }))
              }}
            >
              {onderhoud.periods.map((period, id) =>
                <Select.Option key={id} value={period.id}>{period.naam} <span className='gray'>({period.status} van {period.date_from} tot {period.date_to})</span> </Select.Option>
              )}
            </Select>
          </div>

          {persist.profile.wijzig_winteruren && <Button
            disabled={!onderhoud.current_period}
            style={{ marginLeft: 12 }}
            onClick={() => setEditPeriod(onderhoud.current_period)}
            icon={<EditOutlined />}
                                                >
            {$t('Wijzig')}
          </Button>}

          {persist.profile.wijzig_winteruren && <Button
            type='primary' icon={<PlusOutlined />}
            style={{ marginLeft: 12 }}
            onClick={() => setEditPeriod({
              naam: `${$t('Onderhoud winter')} ${new Date().getFullYear()}/${new Date().getYear() - 100 + 1}`,
              status: 'concept',
              date_from: `${new Date().getFullYear()}-01-01`,
              date_to: `${new Date().getFullYear()}-12-31`,
              factor: 1,
              minimum_uren: 6,
              exclude_groups: ['bestuur', 'instructeur']
            })}
                                                >
            {$t('Voeg toe')}
          </Button>}

        </div>

        {onderhoud.current_period &&
          <CurrentPeriodSection
            period={onderhoud.current_period}
            wijzig_winteruren={persist.profile.wijzig_winteruren}
            savePeriod={(d) => dispatch(savePeriod(d))}
          />}

      </MainLayout>
    </>
  )
}
