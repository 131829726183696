export const defaultTableConfig = {
  defaultPageSize: 40,
  size: "default",
  showSizeChanger: false,
  position: ["bottomLeft"],
  hideOnSinglePage: true,
};

export const defaultTableConfigWithSizing = {
  ...defaultTableConfig,
  showSizeChanger: true,
  pageSizeOptions: [25, 50, 100, 250, 500],
};
