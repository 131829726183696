import { LockFilled, UnlockOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { testDateIsToday, toLocaleDate } from "~/lib/localize.js";
import {
  aanmeldingenActions,
  daysActions,
  flightsActions,
} from "~/redux/actions.js";

import { CloseDay } from "~/components/closeDay/closeDay.jsx";
import { MainLayout } from "~/components/layout/layout.jsx";
import { SelectedSleepModal } from "~/components/selectedFlight/selectedSleep.modal.jsx";
import { updateIfNeeded } from "~/lib/helpers.js";
import { FlightQueueComponent } from "../start/flightQueueComponent.jsx";
import { LiveFlightConnection } from "../start/liveFlightConnection.jsx";
import { SleepClaimModal } from "./sleep.claim.modal.jsx";
import { SleepTable } from "./sleep.table.jsx";

export const SleepAdministratieComponent = ({
  match,
  persist,
  flights,
  days,
  aanmeldingen,
  getAanmeldingen,
  openDay,
  updateFlight,
  getFlights,
  getAllDays,
  getBaseData,
  updateStatus,
}) => {
  const [loading, setLoading] = useState(true);
  const [activeSleep, setActiveSleep] = useState(null);

  const readOnly = days.activeDay.status === "locked";

  const syncData = async () => {
    await getBaseData()
    await openDay(match.params.id)
    await getAanmeldingen({
      dag_id: days.activeDay.dag_id,
      normalizeAsVliegers: true,
    });
  }

  useEffect(() => {
    updateIfNeeded(flights.lastUpdatedBaseData, getBaseData, null, 3600000); // 1 hour
  }, [flights.lastUpdatedBaseData]);
  useEffect(() => {
    updateIfNeeded(
      days.lastUpdated,
      getAllDays,
      () => days.lastUpdated && openDay(match.params.id),
      3600000
    ); // 1 hour
  }, [days.lastUpdated, match.params.id]);
  useEffect(() => {
    if (days.lastUpdated && days.activeDay.dag_id) {
      days.activeDay.can_aanmeld &&
        days.activeDay.dag_id &&
        navigator.onLine &&
        getAanmeldingen({
          dag_id: days.activeDay.dag_id,
          normalizeAsVliegers: true,
        });
    }
  }, [days.lastUpdated, days.activeDay.dag_id]);
  useEffect(() => {
    const fetchData = async () => {
      await getFlights({ dag_id: days.activeDay.dag_id });
      setLoading(false);
    };
    if (
      flights.lastUpdatedBaseData &&
      navigator.onLine &&
      days.lastUpdated &&
      days.activeDay.dag_id
    ) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [
    days.lastUpdated,
    days.activeDay.dag_id,
    getFlights,
    flights.lastUpdatedBaseData,
  ]);

  return (
    <MainLayout isLoading={loading} renderHeadless>
      <SelectedSleepModal
        visible={!!activeSleep}
        onClose={() => setActiveSleep(null)}
        readOnly={readOnly}
        sleepFlight={activeSleep}
        allFlights={flights.allFlights}
        updateFlight={(data) => updateFlight(data, false)}
        kisten={flights.kisten}
        sleep_kisten={flights.sleep_kisten}
        vliegers={flights.vliegers}
        aanmeldingen={aanmeldingen.aanmeldingen}
        currentUser={persist.profile}
        sleep_cat={persist.club && persist.club.sleep_cat}
      />

      <div
        className="row"
        style={{
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
          marginRight: 45,
        }}
      >
        <div className="row">
          {days.activeDay.status === "locked" && (
            <LockFilled style={{ color: "black" }} />
          )}
          {days.activeDay.status === "closed" && (
            <UnlockOutlined style={{ color: "black" }} />
          )}
          <span style={{ marginLeft: 6 }}>
            {toLocaleDate(new Date(days.activeDay.datum))} -{" "}
            {days.activeDay.type_dag}
          </span>
        </div>

        <div className="row">
          {days.activeDay.is_today && persist.club && <FlightQueueComponent syncData={syncData} />}

          {days.activeDay.is_today && persist.club && (
            <LiveFlightConnection
              club={persist.club}
              dayId={days.activeDay.dag_id}
              muteSound
            />
          )}

          <SleepClaimModal
            activeDay={days.activeDay}
            allFlights={flights.allFlights}
            readOnly={readOnly}
            openFlight={setActiveSleep}
          />

          <CloseDay
            dag_id={match.params.id}
            canClose={days.activeDay.status === "open"}
            canOpen={
              days.activeDay.status === "closed" &&
              testDateIsToday(days.activeDay.datum)
            }
            updateStatus={updateStatus}
            getFlights={getFlights}
          />
        </div>
      </div>

      <SleepTable
        sleep_kisten={flights.sleep_kisten}
        allFlights={flights.allFlights}
        vliegers={flights.vliegers}
        sleep_cat={persist.club && persist.club.sleep_cat}
        setActiveSleep={setActiveSleep}
      />
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  flights: state.flights,
  aanmeldingen: state.aanmeldingen,
  persist: state.persist,
  days: state.days,
  profile: state.persist.profile,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getFlights: (d) => dispatch(flightsActions.getFlights(d)),
    updateFlight: (d, saveToQueueWhenOffline) =>
      dispatch(flightsActions.updateFlight(d, saveToQueueWhenOffline)),
    openDay: (d) => dispatch(daysActions.openDay(d)),
    updateStatus: (d) => dispatch(daysActions.updateStatus(d)),
    getAanmeldingen: (d) => dispatch(aanmeldingenActions.getAanmeldingen(d)),
    getAllDays: (d) => dispatch(daysActions.getAllDays(d)),
    getBaseData: (d) => dispatch(flightsActions.getBaseData(d)),
  };
};

export const SleepAdministratiePagina = connect(
  mapStateToProps,
  mapDispatchToProps
)(SleepAdministratieComponent);
