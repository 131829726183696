import { api } from '../../lib/api'

export const getPeriod = (payload) => async (dispatch) => {
  dispatch({
    type: 'ONDERHOUD_PENDING'
  })
  const data = await api.post('onderhoud/get_period.json', payload)
  data && dispatch({
    type: 'ONDERHOUD_SET_PERIOD',
    periods: data.periods,
    current_period: data.current_period
  })
}

export const savePeriod = (payload) => async (dispatch) => {
  dispatch({
    type: 'ONDERHOUD_PENDING'
  })
  const data = await api.post('onderhoud/save_period.json', payload)
  data && dispatch({
    type: 'ONDERHOUD_SET_PERIOD',
    periods: data.periods,
    current_period: data.current_period
  })
  return data
}

export const getMijnOnderhoud = () => async (dispatch) => {
  dispatch({
    type: 'ONDERHOUD_PENDING'
  })
  const data = await api.get('onderhoud/mijn_uren.json')
  data && data.period
    ? dispatch({
      type: 'SET_MIJN_ONDERHOUD',
      period: data.period,
      verplichting: data.verplichting.length > 0 ? data.verplichting[0] : null,
      registraties: data.registraties
    })
    : dispatch({
      type: 'ONDERHOUD_DONE'
    })
  return data
}

export const registreerUren = (payload) => async (dispatch) => {
  const data = await api.post('onderhoud/registreer_uren.json', payload)
  data && dispatch({
    type: 'SET_ONDERHOUD_REGISTRATIES',
    // registreer uren voor either yourself or others in main overview
    is_current: !!payload.user_id,
    registraties: data.registraties
  })
  return data
}
