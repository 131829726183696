import { $t } from "~/i18n.js";

// Add in comment so that code is run during translation code scan
export const permissions = [
    'app_vliegt', // $t('app_vliegt')
    'app_zichzelf_aanmelden', // $t('app_zichzelf_aanmelden')
    'app_iedereen_aanmelden', // $t('app_iedereen_aanmelden')
    'app_wijzig_page_message', // $t('app_wijzig_page_message')
    'app_wijzig_start', // $t('app_wijzig_start')
    'app_wijzig_sleep', // $t('app_wijzig_sleep')
    'app_wijzig_rooster', // $t('app_wijzig_rooster')
    'app_wijzig_reservering', // $t('app_wijzig_reservering')
    'app_wijzig_melding', // $t('app_wijzig_melding')
    'app_wijzig_melding_vliegend', // $t('app_wijzig_melding_vliegend')
    'app_wijzig_melding_rollend', // $t('app_wijzig_melding_rollend')
    'app_wijzig_winteruren', // $t('app_wijzig_winteruren')
    'app_wijzig_material', // $t('app_wijzig_material')
    'app_goedkeuren_uren', // $t('app_goedkeuren_uren')
    'app_wijzig_documenten', // $t('app_wijzig_documenten')
    'app_wijzig_leden', // $t('app_wijzig_leden')
    'app_wijzig_dto', // $t('app_wijzig_dto')
    'app_ziet_dto', // $t('app_ziet_dto')
    'app_ziet_alle_data', // $t('app_ziet_alle_data')
    'app_uitgifte_bevoegheid', // $t('app_uitgifte_bevoegheid')
    'app_ziet_staff', // $t('app_ziet_staff')
    'app_ziet_emaillijst', // $t('app_ziet_emaillijst')
    'app_ziet_alle_email', // $t('app_ziet_alle_email')
    'app_wijzig_vluchten', // $t('app_wijzig_vluchten')
    'app_wijzig_findings', // $t('app_wijzig_findings')
]

// Translate on the fly
export const translateUserPermission = (permission) => $t(permission)