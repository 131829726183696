import { CloseOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { $t, i18n } from "~/i18n.js";

export const SleepHoogteEdit = ({ currentHeight, saveHeight, readOnly }) => {
  const [showModal, setShowModal] = useState(false);
  const [loadingAction, setLoadingAction] = useState("");

  const [newHeight, setNewHeight] = useState(currentHeight);
  useEffect(() => setNewHeight(currentHeight), [currentHeight]);

  const triggerSave = async (action) => {
    setLoadingAction(action);
    if (newHeight !== currentHeight) await saveHeight(newHeight);
    resetAndClose();
  };

  const resetAndClose = () => {
    setLoadingAction("");
    setNewHeight(null);
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <Modal
          width={340}
          title={$t("Wijzig sleephoogte")}
          open={showModal}
          onCancel={resetAndClose}
          footer={[
            <Button
              loading={loadingAction === "opslaan"}
              style={{ width: 120 }}
              key="slaan"
              type="primary"
              onClick={() => triggerSave("opslaan")}
              icon={<SaveOutlined />}
            >
              {$t("Opslaan")}
            </Button>,
            <AnnuleerButton  key="annuleren" onClick={resetAndClose} />,
          ]}
        >
          <div style={{ padding: 12 }}>
            <div className="row">
              <Button
                loading={loadingAction === "wis"}
                style={{ width: 150 }}
                onClick={() => setNewHeight(0)}
                icon={<CloseOutlined />}
              >
                {$t("Wis hoogte")}
              </Button>
            </div>

            <p style={{ marginTop: 12 }}>{$t("Wijzig hoogte naar")}: </p>

            <div className="row">
              <Input
                type="number"
                allowClear={false}
                style={{
                  width: 120,
                  padding: "2px 4px",
                  marginRight: 6,
                  display: "flex",
                }}
                value={Math.round(i18n.convertHeightToLocale(newHeight))}
                onChange={(element) => {
                  setNewHeight(
                    i18n.convertLocaleHeightToMetric(element.target.value || 0)
                  );
                }}
                onPressEnter={() => triggerSave("opslaan")}
              />
            </div>
          </div>
        </Modal>
      )}

      <div
        className="row"
        style={{
          margin: "2px 0 6px",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <p style={{ marginRight: 6 }}>
          {$t("Sleephoogte")} {i18n.unit === "m" ? "(m)" : "(ft)"}:{" "}
        </p>
        <Button
          type="default"
          onClick={() => !readOnly && setShowModal(true)}
          disabled={readOnly}
        >
          {Math.round(i18n.convertHeightToLocale(currentHeight))}{" "}
          <EditOutlined />
        </Button>
      </div>
    </>
  );
};
