import { Button } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

export const SluitButton = (args) => {
  return (
    <Button {...args}>
      {$t("Sluiten")}
    </Button>
  );
};
