import { Select } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { $t, i18n, locales } from '~/i18n.js'
import { profileAction } from '../../redux/persist/persist.actions'

export const SelectLanguage = ({ naked=false, forceReload=false }) => {
  const [language, setLanguage] = useState(localStorage.language)
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector((state) => state.persist)

  const onChange = (language) => {
    i18n.changeLanguage(language)

    if (!isAuthenticated || forceReload) {
      // bug in some of the cases where the language is pushed when not logged in
      location.reload()
    } else {
      // for logged in users, we save to profile
      dispatch(profileAction({ profile: { language } }))
    }
    setLanguage(language)
  }

  return (

    <div className='row' style={{ gap: 12 }}>
      {!naked && <p style={{ color: 'inherit' }}>{$t('Taal: ')}</p>}

      <Select value={language} onChange={onChange} className={naked ? 'naked' : ''} style={{ width: 180 }}>
        {i18n.languages.map((lng) => (
          <Select.Option key={lng} value={lng}>
            {locales[lng]}
          </Select.Option>
        ))}
      </Select>

    </div>
  )
}
