import { $t } from '~/i18n.js'
import { EyeInvisibleOutlined, PhoneOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import { deserializePhone } from '../../lib/localize'

export const Phone = ({ number, private_mode }) => {
  return (
    <div className='row'>

      <Button
        type='primary' icon={<PhoneOutlined style={{ margin: '3px 0 0 -1px' }} />} size='small' shape='circle' disabled={!number || number === -1}
        target='_blank' href={number ? `tel:${number}` : null}
      />
      {private_mode && <EyeInvisibleOutlined style={{ margin: '1px 2px 0 12px' }} />}
      <p style={{ marginLeft: 6, marginRight: 18 }}>{number ? deserializePhone(number) : $t('onbekend')}</p>

    </div>
  )
}
