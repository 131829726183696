export const colors = {

  // Brand colors
  primary: '#7f7fba',
  primary_light: '#1890ff',
  secondary: '#C8283C',

  // Colors
  blue: '#7f7fba',
  darkblue: '#0a1e2e',

  // currency
  groen_dark: '#3a7a3a',
  groen: '#79DE79',
  geel: '#FCFC99',
  rood: '#FB6962',
  nvt: '#F8F9FA',

  green: '#79DE79',
  yellow: '#FCFC99',
  orange: '#EAB256',
  red: '#FB6962',

  gray: 'rgba(0, 0, 0, 0.25)',
  gray_light: '#E4E9EE',
  gray_lighter: '#EFF2F5',
  gray_lightest: '#F8F9FA',

  darkgray: '#444444',
  darkgray_light: '#7f7f7f',
  darkgray_lighter: '#8e8e8e',

  black: '#0A0927',

  white_off: '#EBF7FF',
  white: '#fff',
  transparent: 'rgba(255, 255, 255, 0.1)'

}