import { $t } from "~/i18n.js";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BreadcrumbHeader } from "../../components/layout/breadcrumbHeader";
import { MainLayout } from "../../components/layout/layout";
import { persistActions } from "../../redux/actions";
import { CardContact } from "./cards/card.contact";
import { CardEmergency } from "./cards/card.emergency";
import { CardPersonalia } from "./cards/card.personalia";
import { CardSecurity } from "./cards/card.security";
import { DataCardList } from "../../components/dataCards/dataCardList";
import { CardBestanden } from "./cards/card.bestanden";
import { CardBrevet } from "./cards/card.brevet";
import { CardLidmaatschap } from "./cards/card.lidmaatschap";
import { CardMedical } from "./cards/card.medical";
import { CardPrivacy } from "./cards/card.privacy";
import { Layout, Form } from "antd";

export const MyDataPagina = ({ history }) => {
  const persist = useSelector((state) => state.persist);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  function profileAction(d) {
    dispatch(persistActions.profileAction(d));
  }

  return (
    <MainLayout
      history={history}
      isLoading={persist.isPending || !persist.lastUpdated}
    >
      <BreadcrumbHeader breadcrumbs={[$t("Mijn gegevens")]} />

      <div className="MyData">
        <CardPersonalia profile={persist.profile} saveData={profileAction} />
        <CardBrevet
          profile={persist.profile}
          gegevens={persist.gegevens}
          saveData={profileAction}
          club={persist.club}
        />
        <CardMedical
          profile={persist.profile}
          gegevens={persist.gegevens}
          saveData={profileAction}
        />
        <CardBestanden
          profile={persist.profile}
          bestanden={persist.bestanden}
        />
        <CardContact
          profile={persist.profile}
          gegevens={persist.gegevens}
          saveData={profileAction}
        />
        <CardEmergency gegevens={persist.gegevens} saveData={profileAction} />
        {!persist.profile.group_keys?.includes("gastvlieger") && (
          <CardPrivacy
            profile={persist.profile}
            club={persist.club}
            saveData={profileAction}
          />
        )}
        <CardLidmaatschap
          profile={persist.profile}
          club={persist.club}
          saveData={profileAction}
        />
        <CardSecurity profile={persist.profile} />
      </div>
    </MainLayout>
  );
};
