import { $t } from '~/i18n.js'
import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Tooltip } from 'antd'
import { Notities } from '../../../../components/profile/tabs/notities'

export const CardNotities = ({ setNotitie, setShowNotitieModal, dagNotities, profile }) => {
  return (
    <Card
      size='small' className='topCard' extra={
        <Tooltip placement='bottom' title={$t('Voeg dagnotitie toe')}>
          <Button
            type='primary' icon={<PlusOutlined />} size='small' shape='circle'
            onClick={() => {
              setNotitie({ id: null, bericht: '', is_kritiek: false, is_prive: false })
              setShowNotitieModal(true)
            }}
          />
        </Tooltip>
    }
      title={$t('Dag notities')}
    >

      <div style={{ maxHeight: 290, overflowY: 'auto' }}>
        <Notities
          notities={dagNotities} dagNotificatie
          currentUserID={profile.id}
          openNotitie={(notitie) => {
            setNotitie(notitie)
            setShowNotitieModal(true)
          }}
        />
      </div>

    </Card>
  )
}
