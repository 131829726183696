import { Card } from "antd";
import React from "react";

export const SettingCard = ({ title = "", children = [] }) => {
  return (
    <Card size="small" title={title}>
      {children}
    </Card>
  );
};
