export const adminState = {

  isPending: false,

  // Email Campaigns
  campaignsUpdated: null,
  campaigns: [],

  // documents
  documentsUpdated: null,
  tabs: [],

  // findings
  findingsUpdated: null,
  findings: []
}
