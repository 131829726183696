import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import useMedia from "use-media";
import { $t } from "~/i18n.js";

import { Flex, Tabs } from "antd";
import { MainLayout } from "../../components/layout/layout.jsx";

import { DataCard } from "../../components/dataCards/dataCard.jsx";
import { BreadcrumbHeader } from "../../components/layout/breadcrumbHeader.jsx";
import { FlightStats } from "../../components/profile/flightStats.jsx";
import { VliegerStats } from "../../components/profile/vliegerStats.jsx";
import { updateIfNeeded } from "../../lib/helpers.js";
import { persistActions } from "../../redux/actions.js";
import { CardInstructeursboek } from "./cards/card.instructeursboek.jsx";
import { CardTypeTotals } from "./cards/card.typeTotals.jsx";

const MyStartsComponent = ({
  history,
  persist,
  bevoegdheden,
  getVliegerData,
}) => {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  useEffect(() => {
    updateIfNeeded(
      persist.lastUpdatedData,
      () => getVliegerData({ year }),
      null
    );
  }, [persist.lastUpdatedData, getVliegerData, year]);

  const isWide = useMedia({ minWidth: "1350px" }, true);
  const vlieger_data = persist.vlieger_data
    ? persist.vlieger_data
    : { gegevens: {} };

  return (
    <MainLayout
      history={history}
      isLoading={persist.isPending || !persist.lastUpdatedData}
    >
      <BreadcrumbHeader breadcrumbs={[$t("Mijn starts")]} />

      {isWide ? (
        <Flex gap={24}>
          <DataCard title={$t("Overzicht")} type="card" width={350}>
            <VliegerStats
              pilot={vlieger_data}
              gegevens={vlieger_data.gegevens ? vlieger_data.gegevens : {}}
              showPhone={false}
              isInstructorOrPilot
            />
          </DataCard>

          <DataCard title={$t("Recency")} type="card" width={350}>
            <CardTypeTotals
              recency={vlieger_data.recency}
              gezagvoerder={vlieger_data.gezagvoerder}
              tweede={vlieger_data.tweede}
            />
          </DataCard>

          <DataCard title={$t("Instructeurs boek")} type="card" width={350}>
            <CardInstructeursboek
              notities={vlieger_data.notities}
              pilot={persist.profile}
              behaalde_bevoegdheden={vlieger_data.behaalde_bevoegdheden}
              dto_training={vlieger_data.dto_training}
              bevoegdheden={bevoegdheden}
            />
          </DataCard>
        </Flex>
      ) : (
        <div style={{ minHeight: 380 }}>
          <Tabs
            style={{ background: "white", paddingLeft: 6 }}
            defaultActiveKey="overzicht"
            items={[
              {
                label: $t("Overzicht"),
                key: "overzicht",
                children: (
                  <VliegerStats
                    pilot={vlieger_data}
                    gegevens={
                      vlieger_data.gegevens ? vlieger_data.gegevens : {}
                    }
                    isInstructorOrPilot
                  />
                ),
              },
              {
                label: $t("Recency"),
                key: "recency",
                children: (
                  <CardTypeTotals
                    recency={vlieger_data.recency}
                    gezagvoerder={vlieger_data.gezagvoerder}
                    tweede={vlieger_data.tweede}
                  />
                ),
              },
              {
                label: $t("Instructeurs boek"),
                key: "Inst",
                children: (
                  <CardInstructeursboek
                    notities={vlieger_data.notities}
                    pilot={persist.profile}
                    behaalde_bevoegdheden={vlieger_data.behaalde_bevoegdheden}
                    dto_training={vlieger_data.dto_training}
                    bevoegdheden={bevoegdheden}
                  />
                ),
              },
            ]}
          />
        </div>
      )}

      <div>
        <p className="bold">{$t("Startoverzicht")}</p>

        <FlightStats />
      </div>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  persist: state.persist,
  bevoegdheden: state.flights.bevoegdheden,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getVliegerData: (d) => dispatch(persistActions.getVliegerData(d)),
  };
};

export const MyStartsPagina = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyStartsComponent);
