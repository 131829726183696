import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

import { parseMinutes } from '../../lib/localize'

const calcDuration = (start_tijd) => {
  const seconds = parseInt((dayjs() - dayjs(start_tijd, 'HH:mm')) / 60000)
  if (seconds < 0) return ''
  return parseMinutes(seconds)
}

export const ActualFlightTime = ({ flight }) => {
  const [time, setTime] = useState('')

  useEffect(() => {
    // setup interval, return cleanup which will be fired after we close element to clear out the interval
    let mounted = true

    const execTimeout = () => {
      if (mounted) {
        setTime(calcDuration(flight.start_tijd))
        setTimeout(execTimeout, 5000)
      }
    }

    // first time fireoff
    if (!flight.is_deleted && !flight.is_locked) {
      execTimeout()
    }

    // return that we remove interval
    return () => {
      mounted = false
    }
  }, [flight])

  return (
    <span className='gray'>
      {(time) || ''}
    </span>
  )
}
