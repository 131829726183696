import React from "react";
import { config } from "~/config.js";
import "./logo.css";

export const Logo = ({ url, width, height }) => {
  if (!url) return null;
  return (
    <div
      className="Logo"
      style={{
        width: width || 70,
        height: height || 40,
        backgroundImage: `url('${config.media_prefix}${url}')`,
      }}
    />
  );
};
