import { Button, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { ModalLayout } from "~/components/layout/modalLayout.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";

const successWachtwoordReset = () => {
  Modal.success({
    title: $t("Check je email"),
    content: $t(
      "Indien dit emailadres bekend is in de Zweef.App, dan hebben we hebben je nét een link gestuurd om je wachtwoord aan te passen."
    ),
  });
};

export const requestPasswordReset = async (email) => {
  const res = await api.post("auth/send_password_reset_email.json", { email });
  if (res) successWachtwoordReset();
};

export const PasswordForgetPage = ({ history }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    // fire off login function of Redux
    setLoading(true);
    await requestPasswordReset(values.email);
    setLoading(false);
  };

  return (
    <ModalLayout width="500px">
      <h1>{$t("Wachtwoord kwijt")}</h1>

      <p className="spacer">
        {$t(
          "Kan gebeuren, geen probleem. Voeg je email toe (dat bekend is in de Zweef.App) en we sturen je een mailtje om je wachtwoord te resetten."
        )}
      </p>

      <Form
        layout="vertical"
        requiredMark={false}
        name="basic"
        onFinish={onFinish}
        preserve={false}
      >
        <Form.Item
          autoFocus
          name="email"
          rules={[{ required: true, type: "email" }]}
        >
          <Input type="email" placeholder={$t("Emailadres")} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {$t("Reset wachtwoord")}
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            type="link"
            onClick={() => history.push("/login")}
            style={{ paddingLeft: 0 }}
          >
            {$t("Terug naar login")}
          </Button>
        </Form.Item>
      </Form>
    </ModalLayout>
  );
};
