import { registerSW } from 'virtual:pwa-register'

// https://github.com/antfu/vite-plugin-pwa/blob/main/src/client/build/register.ts
const updateSW = registerSW({

  onOfflineReady () {
    console.log('[SW] You are ready to work offline')
  },
  onRegistered () {
    console.log('[SW] onRegistered')
  },
  onRegisterError (e) {
    console.log('[SW] onRegisterError', e)
  }
})
