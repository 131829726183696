import { Drawer } from 'antd'
import React from 'react'
import { $t } from '~/i18n.js'
import { Markdown } from '../markdown/markdown'

export const HelpDrawer = ({ title, subtitle, setShowHelp, showHelp, helpDocs }) => (
  <Drawer
    title={$t('Help')}
    placement='right'
    closable
    onClose={() => setShowHelp(false)}
    open={showHelp}
  >

    <div className='markDown'>

      {title && <h2>{title}</h2>}
      {subtitle && <p>{subtitle}</p>}

      <Markdown text={helpDocs} />

    </div>

  </Drawer>
)
