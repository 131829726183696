import { CheckOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Card } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'
import './dataCard.css'

export const DataCard = ({
  title = '',
  loading = false, 
  readOnly = false, 
  onSave = () => {}, 
  type = 'card', 
  children = [], 
  buttonTitle = '', 
  width = null 
}) => {
  const [canSave, setCanSave] = useState(false)
  const [hasSaved, setHasSaved] = useState(false)

  if (type === 'check') {
    return (
      <div className='dataCard withFooter'>
        {children}

        <div className='row' style={{ justifyContent: 'flex-end' }}>
          <Button size='small' type='primary' loading={loading} onClick={onSave}>
            {buttonTitle || $t('Volgende')}
          </Button>
        </div>
      </div>
    )
  }

  // all others return regular card
  return (
    <Card
      size='small' style={width ? { width } : {}} className={`dataCard ${!readOnly && onSave ? ' withFooter' : ''}`}
      title={title} onClick={() => !canSave && setCanSave(true)}
    >

      {children}

      {!readOnly && onSave && (
        <div className={`footer ${canSave ? 'open' : 'closed'}`}>
          <Button
            size='small' icon={hasSaved ? <CheckOutlined /> : <SaveOutlined />} type={hasSaved ? 'default' : 'primary'}
            loading={loading} onClick={async () => {
              const res = await onSave()
              if (res) {
                setHasSaved(true)
                setTimeout(() => setHasSaved(false), 4000)
              }
            }}
          >
            {
              hasSaved ? $t('Opgeslagen') : $t('Opslaan')
            }
          </Button>
        </div>
      )}

    </Card>
  )
}
