import React, { useEffect, useState } from 'react'

const baseOptions = {
  chart: {
    type: 'line',
    zoomType: 'x'
  },
  title: {
    text: null
  },
  xAxis: {},
  yAxis: {},
  tooltip: {},
  plotOptions: {},
  credits: false
}

class ChartWrapper {
  async $mount (el, options) {
    const Highcharts = await import('highcharts')

    const chart = Highcharts.chart(el, options)
    this.chart = chart
    this.el = el
    return this
  }

  update (options) {
    this.chart && this.chart.update(options)
  }
}

export const Chart = ({ chartOptions, activePointCallback }) => {
  const chartRef = React.createRef()

  const [chart, setChart] = useState(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const load = async () => {
      if (!chartRef.current || loaded) return
    if (!chartOptions) return

    const options = { ...baseOptions, ...chartOptions }

    // if callback required, overrule event
    if (activePointCallback) {
      options.plotOptions = {
        series: {
          point: {
            events: {
              mouseOver: function () {
                // returning this as point
                activePointCallback(this.options)
              }
            }
          }
        }
      }
    }

    const el = chartRef.current

    // initiate the chart with the new options, must be onto a constant otherwise it doesn't work
    const chartEl = await new ChartWrapper().$mount(el, options)
    setChart(chartEl)

    setLoaded(true)
    }
    load()
  }, [chartRef, loaded, setLoaded])

  useEffect(() => {
    
    const loadData = async () => {
      if (chart) {
        // Trick to force re-draw of the series, as they are not removed properly 
        while (chart.chart.series.length > 0) {
          chart.chart.series[0].remove(true)
        }
        Object.entries(chartOptions.series).forEach(item => {
          chart.chart.addSeries(item[1], false)
        })
  
        chart.update(chartOptions)
      }
    }
    loadData()

  }, [chartOptions])

  return (
    <div style={{ width: '100%', height: '100%' }} ref={chartRef} />
  )
}
