import { MailOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { toLocaleDate } from "~/lib/localize.js";

export const EmailComponent = () => {
  const [fileLoading, setFileLoading] = useState(false);

  const { group_name_lookup, all_group_keys } = useSelector(
    (state) => state.flights
  );

  const menu = (
    <Menu>
      {all_group_keys.map((group, key) => (
        <Menu.Item key={key}>
          <Button
            style={{ marginLeft: 12, marginTop: 6 }}
            type="text"
            loading={fileLoading === group}
            onClick={async () => {
              setFileLoading(group);
              await api.open_file(
                `auth/${group}/export_email.json`,
                `email ${toLocaleDate(new Date())}.xlsx`
              );
              setFileLoading(null);
            }}
          >
            {group_name_lookup[group] || group}
          </Button>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomLeft">
      <Button style={{ marginRight: 6 }} icon={<MailOutlined />}>
        {$t("Mailinglijst")}
      </Button>
    </Dropdown>
  );
};
