import { SwapOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { $t } from "~/i18n.js";
import { selectElementByID } from "../../lib/helpers.js";
import { SelectPilot } from "../profile/selectPilot.jsx";
import { FlightDetailsModal } from "./sections/flightDetailsModal.jsx";
import { SectionKistStatus } from "./sections/kistStatus.jsx";
import { SectionButtons } from "./sections/sectionButtons.jsx";
import { SectionChecks } from "./sections/sectionChecks.jsx";
import { SectionKist } from "./sections/sectionKist.jsx";
import { SectionStartmethode } from "./sections/sectionStartmethode.jsx";
import "./SelectedFlightBar.css";

export const SelectedFlightBar = ({
  isPending,
  readOnlyDay,
  flight,
  updateFlight,
  sleepFlight,
  connectOrCreateSleepFlight,
  kisten,
  sleep_kisten,
  vliegers,
  aanmeldingen,
  kistStatus,
  openKist,
  setSelectedPilot,
  activeDay,
}) => {
  // check if selectedKist already exists, required for kist and tweede_inzittende registration
  const selectedKist = selectElementByID(
    kisten,
    "registratie",
    flight && flight.registratie
  );
  const selectedSleepKist = selectElementByID(
    sleep_kisten,
    "registratie",
    sleepFlight && sleepFlight.registratie
  );

  const gezagVoerder = selectElementByID(
    vliegers,
    "id",
    flight && flight.gezagvoerder_id
  );
  const tweedeInzittende = selectElementByID(
    vliegers,
    "id",
    flight && flight.tweede_inzittende_id
  );
  const sleepVlieger = selectElementByID(
    vliegers,
    "id",
    sleepFlight && sleepFlight.gezagvoerder_id
  );

  const readOnly =
    readOnlyDay || (flight && (flight.is_deleted || flight.is_locked));

  const [flightModalVisible, setFlightModal] = useState(false);

  const saveFlight = async (data) => {
    // saving betalendlid to sleepvlucht if in data
    if ("betalend_lid_id" in data && selectedSleepKist)
      await updateFlight({
        betalend_lid_id: data.betalend_lid_id,
        uuid: sleepFlight.uuid,
      });

    // saving full element only if there is no ID
    const result = await updateFlight(
      flight.id ? { ...data, uuid: flight.uuid } : { ...flight, ...data }
    );
    setFlightModal(false);
    return result;
  };

  const saveSleepFlight = async (data) => {
    // function to save data, e.g. times, but only if there is a sleepVlucht (makes call easier)
    if (sleepFlight) {
      return await updateFlight({ ...data, uuid: sleepFlight.uuid });
    }
  };

  return (
    <div className="selectedFlight">
      <FlightDetailsModal
        vliegers={vliegers}
        visible={flightModalVisible}
        onClose={() => setFlightModal(false)}
        saveFlight={saveFlight}
        saveSleepFlight={saveSleepFlight}
        flight={flight}
        sleepFlight={sleepFlight}
        readOnly={readOnly}
      />

      <div className="row">
        {!readOnlyDay && (
          <SectionKistStatus
            is_today={activeDay.is_today}
            activeFlightUUID={flight ? flight.uuid : null}
            kistStatus={kistStatus}
            openKist={openKist}
          />
        )}
      </div>

      <div
        className="row content"
        style={{ alignItems: "flex-start", marginTop: 6 }}
      >
        <div className="column section">
          <p className="small gray" style={{ marginBottom: 3 }}>
            {$t("Zweefkist")}:
          </p>
          <SectionKist
            selectedKist={selectedKist}
            kisten={kisten}
            flight={flight}
            saveFlight={saveFlight}
            readOnly={readOnly}
            is_today={activeDay.is_today}
          />

          <SectionStartmethode
            selectedKist={selectedKist}
            connectOrCreateSleepFlight={connectOrCreateSleepFlight}
            flight={flight}
            saveFlight={saveFlight}
            readOnly={readOnly}
          />

          {sleepFlight && (
            <div>
              <p className="small gray" style={{ marginBottom: 3 }}>
                {$t("Sleepkist")}:
              </p>

              <SectionKist
                selectedKist={selectedSleepKist}
                kisten={sleep_kisten}
                flight={sleepFlight}
                saveFlight={saveSleepFlight}
                readOnly={readOnly}
              />
            </div>
          )}
        </div>

        <div
          className="section column"
          style={{ justifyContent: "space-between", alignItems: "flex-start" }}
        >
          <SelectPilot
            dag_id={activeDay.dag_id}
            readOnly={readOnly || !flight.registratie}
            field_id="gezagvoerder_id"
            field_name="gezagvoerder_naam"
            title={
              selectedKist && selectedKist.pilots === 2
                ? $t("PIC/instructeur")
                : $t("PIC")
            }
            pilot={gezagVoerder}
            otherPilot={tweedeInzittende}
            setBetalendLid={flight.start_methode !== "tmg-a"}
            vliegers={aanmeldingen}
            alle_vliegers={vliegers}
            flight={flight}
            saveFlight={saveFlight}
            openPilot={() => setSelectedPilot(gezagVoerder)}
          />

          {(!selectedKist ||
            selectedKist.pilots === 2 ||
            flight.tweede_inzittende_naam) && (
            <Button
              disabled={!flight.tweede_inzittende_id || !flight.gezagvoerder_id}
              shape="circle"
              icon={<SwapOutlined />}
              size="small"
              type="text"
              style={{ position: "absolute", top: 70, left: -6 }}
              onClick={() => {
                // determine if tweede is instructeur, in which case it is a fis flight
                saveFlight({
                  betalend_lid_id: flight.gezagvoerder_id,
                  gezagvoerder_id: flight.tweede_inzittende_id,
                  gezagvoerder_naam: flight.tweede_inzittende_naam,
                  tweede_inzittende_id: flight.gezagvoerder_id,
                  tweede_inzittende_naam: flight.gezagvoerder_naam,
                  is_fis: tweedeInzittende && tweedeInzittende.is_instructeur,
                  is_training: false,
                  is_examen: false,
                  is_profcheck: false,
                });
              }}
            />
          )}

          {(!selectedKist ||
            selectedKist.pilots === 2 ||
            flight.tweede_inzittende_naam) && (
            <SelectPilot
              dag_id={activeDay.dag_id}
              readOnly={readOnly || !flight.registratie}
              field_id="tweede_inzittende_id"
              field_name="tweede_inzittende_naam"
              title={$t("Pax/DBO/2e")}
              pilot={tweedeInzittende}
              otherPilot={gezagVoerder}
              gezagVoerder={gezagVoerder}
              setBetalendLid={flight.start_methode !== "tmg-a"}
              vliegers={aanmeldingen}
              alle_vliegers={vliegers}
              flight={flight}
              saveFlight={saveFlight}
              openPilot={() => setSelectedPilot(tweedeInzittende)}
            />
          )}

          {sleepFlight && (
            <div className="row" style={{ marginTop: 4 }}>
              <p>Sleepvlieger:</p>
              <SelectPilot
                dag_id={activeDay.dag_id}
                hideAvatar
                readOnly={readOnly}
                field_id="gezagvoerder_id"
                field_name="gezagvoerder_naam"
                title={$t("Sleepvlieger")}
                pilot={sleepVlieger}
                otherPilot={null}
                // vliegers gezagvoerder only non-pax
                vliegers={aanmeldingen.filter(
                  (aanmelding) =>
                    aanmelding.id > 0 && aanmelding.as_sleepvlieger
                )}
                alle_vliegers={vliegers}
                flight={sleepFlight}
                saveFlight={saveSleepFlight}
                openPilot={() => setSelectedPilot(sleepVlieger)}
              />
            </div>
          )}
        </div>

        <div className="section" style={{ maxWidth: 200 }}>
          <SectionChecks
            readOnly={readOnly || !flight.registratie}
            flight={flight}
            saveFlight={saveFlight}
            sleepFlight={sleepFlight}
            saveSleepFlight={saveSleepFlight}
            gezagvoerder={gezagVoerder}
            tweedeInzittende={tweedeInzittende}
          />
        </div>

        <div className="column section buttons">
          <SectionButtons
            readOnly={readOnly}
            sleepFlight={sleepFlight}
            flight={flight}
            isPending={isPending}
            saveFlight={saveFlight}
            saveSleepFlight={saveSleepFlight}
            showFlightEditModal={() => setFlightModal(true)}
          />
        </div>
      </div>
    </div>
  );
};
