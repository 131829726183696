import { Form } from "antd";
import React from "react";
import { SettingCard } from "~/components/dataCards/SettingCard.jsx";
import {
  LengthValidator,
  ValidateEmailListValidator,
} from "~/components/form/formHelpers.js";
import { ListSelectToString } from "~/components/form/ListSelectToString.jsx";
import { $t } from "~/i18n.js";

export const ContactList = () => {
  const contactValidator = [
    LengthValidator(500, false),
    ValidateEmailListValidator,
  ];

  return (
    <SettingCard title={$t("Contact lijsten")}>
      <p style={{ marginBottom: 12 }}>
        {$t("Lijsten mogen meerdere mail adressen bevatten")}:
      </p>
      <Form.Item
        label={$t("Leden administratie")}
        name="contact_leden"
        rules={contactValidator}
      >
        <ListSelectToString allowAddNew={true} />
      </Form.Item>

      <Form.Item
        label={$t("Reserveringen")}
        name="contact_reserveringen"
        rules={contactValidator}
      >
        <ListSelectToString allowAddNew={true} />
      </Form.Item>

      <Form.Item
        label={$t("Instructie")}
        name="contact_instructie"
        rules={contactValidator}
      >
        <ListSelectToString allowAddNew={true} />
      </Form.Item>

      <Form.Item
        label={$t("Vliegende technici")}
        name="contact_vliegend"
        rules={contactValidator}
      >
        <ListSelectToString allowAddNew={true} />
      </Form.Item>

      <Form.Item
        label={$t("Rollende technici")}
        name="contact_rollend"
        rules={contactValidator}
      >
        <ListSelectToString allowAddNew={true} />
      </Form.Item>
    </SettingCard>
  );
};
