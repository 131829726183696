import { createTotal, normalizeDays } from './days.normalizers'
import { daysState, newActiveDay } from './days.state'

export const daysReducer = (state = daysState, action) => {
  switch (action.type) {
    case 'DAYS_PENDING':
      return {
        ...state,
        isPending: true
      }

    case 'DAYS_ERROR':
      return {
        ...state,
        isPending: false
      }

    case 'NEW_DAY':
      // add new day in front of days of record
      const newAllDays = state.allDays.filter((day) => day.dag_id !== parseInt(action.newDay.dag_id))
      const newDays = normalizeDays([action.newDay])
      return {
        ...state,
        isPending: false,
        activeDay: newDays[0],
        allDays: newDays.concat(newAllDays)
      }

    case 'DAYS_SUCCESS':
      // add new day in front of days of record

      return {
        ...state,
        isPending: false,
        lastUpdated: new Date(),
        allDays: normalizeDays(action.allDays),
        totals: createTotal(action),
        mijn_diensten: action.mijn_diensten.reduce((all, dienst) => {
          if (all[dienst.dag_id]) all[dienst.dag_id].push(dienst)
          else all[dienst.dag_id] = [dienst]
          return all
        }, {}),
        mijn_aanmeldingen: action.mijn_aanmeldingen.reduce((all, dag_id) => {
          all[dag_id] = 1
          return all
        }, {})
      }

    case 'OPEN_DAY':
      // add new day in front of days of record
      let activeDay = []
      if (action.dag_id === 'today') {
        // get first day that can be started
        activeDay = state.allDays.filter((day) => day.can_start)
      } else {
        // get id
        activeDay = state.allDays.filter((day) => day.dag_id === parseInt(action.dag_id))
      }

      return {
        ...state,
        activeDay: activeDay.length > 0 ? activeDay[0] : newActiveDay
      }

    case 'ROOSTER_SUCCES':
      return {
        ...state,
        lastUpdatedRoosters: new Date(),
        roosters: action.data?.roosters,
        users: action.data?.users
      }

    case 'ROOSTER_UPDATE':
      return {
        ...state,
        roosters: [action.rooster].concat(state.roosters.filter(r => r.id !== action.rooster.id))
      }

    case 'UPDATE_MIJN_AANMELDINGEN':
      // mijn_aanmeldingen is dictionary of {...dag_id: 0/1 voor aangemeld}. As such update this dictionary after aanmeldingen
      const mijn_aanmeldingen = { ...state.mijn_aanmeldingen }
      mijn_aanmeldingen[action.dag_id] = action.aanmelding
      return {
        ...state,
        mijn_aanmeldingen
      }

    case 'RESET_DAYS_STATE':
      return {
        ...daysState
      }

    default:
      return state
  }
}
