import { CheckCircleFilled, ClockCircleFilled } from "@ant-design/icons";
import { Collapse } from "antd";
import React from "react";
import { colors } from "~/theme/colors.js";
import { Bevoegdheid } from "./Bevoegdheid.jsx";

export const Category = (
  key,
  category,
  user_id,
  cluster,
  setBevoegdheid,
  openBevoegheid
) => {
  return (
    <Collapse.Panel
      header={category.label || category.name}
      key={key}
      extra={
        category.nearly_invalid ? (
          <ClockCircleFilled style={{ color: colors.orange }} />
        ) : category.count === category.bevoegdheid.length ? (
          <CheckCircleFilled style={{ color: colors.groen_dark }} />
        ) : (
          <p>
            {category.count}/{category.bevoegdheid.length}
          </p>
        )
      }
    >
      <div className="column category">
        {category.description && (
          <p
            className="userInput"
            style={{ marginLeft: 10, color: "darkgray" }}
          >
            {category.description}
          </p>
        )}

        {category.bevoegdheid.map((bevoegdheid, idx) => (
          <Bevoegdheid
            key={idx}
            bevoegdheid={bevoegdheid}
            user_id={user_id}
            cluster={cluster}
            setBevoegdheid={setBevoegdheid}
            openBevoegheid={openBevoegheid}
          />
        ))}
      </div>
    </Collapse.Panel>
  );
};
