import { CloseOutlined, CloudDownloadOutlined, PlusOutlined, SendOutlined, ToolOutlined } from '@ant-design/icons'
import { Button, Modal, Popconfirm } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'
import { api } from '../../../lib/api'
import { toLocaleDate } from '../../../lib/localize'
import { RegistratieForm } from '../registratie.form'

export const PeriodButtons = ({ period, savePeriod, wijzig_winteruren }) => {
  const [fileLoading, setFileLoading] = useState(false)
  const [showRegistratieForm, setShowRegistratieForm] = useState(false)

  return (
    <div className='row' style={{ alignItems: 'center', flexWrap: 'wrap' }}>

      {showRegistratieForm && <RegistratieForm
        period={period}
        visible={showRegistratieForm}
        registratie={{}}
        showAllUsers
        closeModal={() => setShowRegistratieForm(false)}
                              />}

      {wijzig_winteruren && period.status !== 'actief' &&
        <Popconfirm
          placement='bottom' title={$t('Zeker weten? Hiermee maak je de onderhoudsuren zichtbaar voor alle leden.')}
          onConfirm={() => savePeriod({ id: period.id, status: 'actief' })}
          okText={$t('Ja')} cancelText={$t('Nee')}
        >
          <Button style={{ margin: '0 6px 8px 0' }} type='primary'>
            {$t('Activeer periode')}
          </Button>
        </Popconfirm>}

      {wijzig_winteruren && period.status !== 'actief' &&
        <Popconfirm
          placement='bottom'
          title={$t('Zeker weten? Op basis van de laatste vluchten worden de uren-verplichting opnieuw berekend.')}
          onConfirm={() => savePeriod({ id: period.id, recalculate_verplichting: true })}
          okText={$t('Ja')} cancelText={$t('Nee')}
        >
          <Button size='small' style={{ margin: '0 6px 8px 0' }} icon={<ToolOutlined />}>
            {$t('Her-bereken uren')}
          </Button>
        </Popconfirm>}

      {wijzig_winteruren && period.status === 'actief' &&
        <Popconfirm
          placement='bottom'
          title={$t('Zeker weten? Hiermee sluit je de periode af en kunnen leden geen uren meer schrijven.')}
          onConfirm={() => savePeriod({ id: period.id, status: 'gesloten' })}
          okText={$t('Ja')} cancelText={$t('Nee')}
        >
          <Button size='small' style={{ margin: '0 6px 8px 0' }} danger icon={<CloseOutlined />}>
            {$t('Sluit periode')}
          </Button>
        </Popconfirm>}

      {wijzig_winteruren && period.status === 'actief' &&
        <Popconfirm
          placement='bottom'
          title={$t('Zeker weten? Hiermee verstuur je alle leden een update email.')}
          onConfirm={async () => {
            const data = await savePeriod({ id: period.id, send_update: true })
            data && Modal.success({
              title: $t('Update gestart'),
              content: $t('Binnen het aankomend uur ontvangen alle leden een update van hun onderhoudsuren.')
            })
          }}
          okText={$t('Ja')} cancelText={$t('Nee')}
        >
          <Button size='small' style={{ margin: '0 6px 8px 0' }} icon={<SendOutlined />}>
            {$t('Stuur update')}
          </Button>
        </Popconfirm>}

      {period.status !== 'gesloten' &&
        <Button
          size='small' style={{ margin: '0 6px 8px 0' }} icon={<PlusOutlined />}
          onClick={() => setShowRegistratieForm(true)}
        >
          {$t('Voeg uren toe')}
        </Button>}

      <Button
        style={{ margin: '0 6px 8px 0' }} size='small'
        icon={<CloudDownloadOutlined />}
        loading={fileLoading}
        onClick={async () => {
          setFileLoading(true)
          await api.open_file(`onderhoud/${period.id}/export_period.json`, `${period.naam} - ${toLocaleDate(new Date())}.xlsx`)
          setFileLoading(false)
        }}
      >
        {$t('Exporteer')}
      </Button>

    </div>

  )
}
