import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { $t } from "~/i18n.js";

import { ModalLayout } from "~/components/layout/modalLayout.jsx";
import { persistActions } from "~/redux/actions.js";

const successEmailReset = (new_email) => {
  Modal.success({
    title: $t("Je email is nu veranderd naar ${new_email}", { new_email }),
    content: $t("Vanaf nu is dit je nieuwe email in ons systeem."),
  });
};

export const ActivateEmailPage = ({
  match,
  history,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const updateEmail = async () => {
    const payload = {
      email_code: match.params.email_code,
      history,
    }
    const profile = await dispatch(persistActions.login_with_email_activation_link(payload))

    if (profile) {
      successEmailReset(profile.email);
    } else {
      setLoading(false);
    }
  };

  return (
    <ModalLayout width="500px">
      <h1>{$t("Wijzig e-mailadres")}</h1>

      <p>{$t("Bevestig dat je hiermee je e-mailadres wijzigt.")}</p>

      <Button
        style={{ marginTop: 24 }}
        loading={loading}
        onClick={updateEmail}
        type="primary"
      >
        {$t("Bevestig & login")}
      </Button>
    </ModalLayout>
  );
};
