import { DeleteOutlined, ExclamationCircleTwoTone, EyeInvisibleOutlined, InboxOutlined, LinkOutlined, PaperClipOutlined, RollbackOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Tooltip } from 'antd'
import { Comment } from '@ant-design/compatible'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { config } from '../../config'
import { toLocaleDate } from '../../lib/localize'
import { getUserAvatar, getUserAvatarError } from '../profile/selectPilot'
import { UserModal } from '../profile/userModal'

import './bericht.css'

export const normalizeFileName = (file) => {
  // normalizing file <uuid>_<name>.jpg becomes <name>
  if (!file) return ''
  const split = file.split('/')
  let value = split[split.length - 1]
  if (value.indexOf('___') > 0) {
    value = value.split('___')[1]
  }
  if (value.indexOf('_') > 0) {
    value = value.split('_').slice(1).join(' ')
  }
  return value
}

const normalizeURL = (url) => {
  url = url.replace('https://', '').replace('www.', '').replace('http://', '')
  return url
}

const BerichtThumbnail = ({ file, width }) => {
  if (file.is_deleted) {
    return <div className='thumb_nail' style={{ width: width || 150 }}>-</div>
  } else if (file.url) {
    return (
      <a
        target='_blank' rel='noopener noreferrer' title='file' href={file.url}
        className='thumb_nail clickable' style={{ width: width || 150 }}
      >
        <span style={{ marginRight: 2 }}>
          {file.url_valid
            ? <LinkOutlined />
            : <Tooltip placement='top' title={$t('De link is foutief, vraag de admin om hem opnieuw toe te voegen.')}>
              <ExclamationCircleTwoTone twoToneColor='red' />
              </Tooltip>}
        </span>
        {normalizeURL(file.url)}
      </a>
    )
  } else {
    if (!file.file) {
      // temporary file
      return <p><PaperClipOutlined />{file.name}</p>
    }
    const url = (config.media_prefix + file.file)
    const check_file = file.file.toLowerCase()
    if (check_file.endsWith('png') || check_file.endsWith('jpg') || check_file.endsWith('jpeg')) {
      return (
        <a
          target='_blank' rel='noopener noreferrer' title='image'
          href={url} className='thumb_nail clickable' style={{ height: width || 150, width: width || 150, backgroundImage: `url(${url})` }}
        >.
        </a>
      )
    } else {
      return (
        <a
          target='_blank' rel='noopener noreferrer' title='file'
          href={url} className='thumb_nail clickable'
        >
          <PaperClipOutlined />
          {normalizeFileName(file.file)}
        </a>
      )
    }
  }
}

const DeleteFile = ({ file, deleteFile }) => {
  if (file.is_deleted) return <div className='thumb_nail'>-</div>
  return (
    <div className='thumb_nail'>
      {file.url ? normalizeURL(file.url) : normalizeFileName(file.file || file.name)}

      <Popconfirm
        key='remove'
        placement='bottom' title={$t('Zeker weten dat je dit bestand wilt ${action}?', { action: file.is_archived ? $t('verwijderen') : $t('archiveren') })}
        onConfirm={() => deleteFile(file)}
        okText={$t('Ja')} cancelText={$t('Nee')}
      >
        {
                              file.is_archived
                                ? <DeleteOutlined className='clickable' style={{ marginLeft: 6 }} />
                                : <Button style={{ marginLeft: 6 }} type='primary' shape='circle' size='small' icon={<InboxOutlined />} />
                            }
      </Popconfirm>

      {file.is_archived &&
        <Popconfirm
          key='herstel'
          placement='bottom' title={$t('Herstel bestand terug uit archief')}
          onConfirm={() => deleteFile(file, true)}
          okText={$t('Ja')} cancelText={$t('Nee')}
        >
          <RollbackOutlined className='clickable' style={{ marginLeft: 6 }}>{$t('herstel')}</RollbackOutlined>
        </Popconfirm>}
    </div>
  )
}

export const BerichtFileThumbnails = ({ files, deleteFile, width }) => {
  return (
    <div className='thumb_nails_wrapper'>
      {!deleteFile && files?.map((file, key) => <BerichtThumbnail key={key} file={file} width={width} />)}
      {deleteFile && files?.map((file, key) => <DeleteFile key={key} file={file} deleteFile={deleteFile} />)}
    </div>
  )
}

export const Bericht = ({ user, date_created, message, transition, files, not_visible }) => {
  const date = new Date(date_created)
  const [openUser, setOpenUser] = useState(null)

  return (
    <div className='row' style={{ marginTop: 24, padding: 12, background: 'white' }}>

      {openUser && <UserModal
        visible={openUser && openUser.id}
        onClose={() => setOpenUser(null)}
        pilot={openUser}
                   />}

      <Comment
        author={user ? user.name : $t('onbekende gebruiker')}
        avatar={
          <div className='clickable' onClick={() => setOpenUser(user)}>
            <img
              alt={user?.name}
              width='100px' height='100px'
              onError={getUserAvatarError}
              src={getUserAvatar(user, true)}
            />
          </div>
        }
        content={
          <div>
            {transition && <p className='bold'>{transition}</p>}
            <div className='row'>
              {not_visible && <span style={{ marginRight: 6 }}><EyeInvisibleOutlined /></span>}
              <p>{message}</p>
            </div>
            <BerichtFileThumbnails files={files} />
          </div>
        }
        datetime={toLocaleDate(date) + ', ' + date.toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit' })}
      />
    </div>
  )
}
