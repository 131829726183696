import { Input } from "antd";
import React from "react";

const toHour = (value, _default) =>
  value >= 0 ? parseInt(value / 60) : _default;
const toMinute = (value, _default) =>
  value >= 0 ? parseInt(value % 60) : _default;

export const TimeInput = ({ value, onChange, readOnly, allowClear }) => {
  const triggerHourChange = (e) => {
    onChange?.(parseInt(e.target.value || 0) * 60 + toMinute(value, 0));
  };
  const triggerMinuteChange = (e) => {
    onChange?.(toHour(value, 0) * 60 + parseInt(e.target.value || 0));
  };

  return (
    <div className="row">
      <Input
        type="number"
        disabled={readOnly}
        value={toHour(value, null)}
        onChange={triggerHourChange}
        allowClear={allowClear}
        style={{
          width: 80,
        }}
      />
      <span style={{ margin: "0 9px 0 3px" }}>uur</span>
      <Input
        type="number"
        disabled={readOnly}
        value={toMinute(value, null)}
        onChange={triggerMinuteChange}
        allowClear={allowClear}
        style={{
          width: 66,
        }}
      />
      <span style={{ margin: "0 9px 0 3px" }}>min</span>
    </div>
  );
};
