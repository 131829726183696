import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { BreadcrumbHeader } from '../../components/layout/breadcrumbHeader'
import { MainLayout } from '../../components/layout/layout'
import { config } from '../../config'
import { $t } from '~/i18n.js'

let app = null
async function createCachedApp () {
  // if (app) return app
  const zReservation = await import('z-reservation/main.js')
  app = zReservation.create({ config })
  return app
}

const ReservationPageComponent = ({ history }) => {
  const inputRef = useRef(null)

  useEffect(() => {
    async function mount () {
      const app = await createCachedApp()
      app.mount(inputRef.current)
    }
    mount()
  }, [])

  return (
    <MainLayout history={history}>
      <div className='row' style={{ flexWrap: 'wrap', gap: 6, justifyContent: 'space-between' }}>
        <BreadcrumbHeader
          breadcrumbs={[$t('Reserveren')]}
          buttons={[]}
        />
        <Button type='primary' icon={<PlusOutlined />} href='#/add'>
          {$t('Voeg toe')}
        </Button>

      </div>

      <div id='app' ref={inputRef}>...</div>
    </MainLayout>
  )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => {
  return {}
}

export const ReservationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationPageComponent)
