import { StopOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Popconfirm, Select } from "antd";
import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";

import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { FlightDetails } from "./flightDetails.jsx";

export const FlightDetailsModal = ({
  visible,
  onClose,
  saveFlight,
  saveSleepFlight,
  readOnly,
  flight,
  vliegers,
}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const triggerDelete = async () => {
    setLoading(true);
    saveSleepFlight && (await saveSleepFlight({ action: "delete" }));
    await saveFlight({ action: "delete" });
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        vertrek_vliegveld: flight.vertrek_vliegveld,
        aankomst_vliegveld: flight.aankomst_vliegveld,
        bijzonderheden: flight.bijzonderheden,
        betalend_lid_id: flight.betalend_lid_id,
      });
    }
  }, [visible, flight]);

  const onSubmitData = async () => {
    // submit data
    setLoading(true);
    try {
      const values = await form.validateFields();
      if (saveSleepFlight) {
        // NOTE: flight can be outlanded, but sleep never in the start administration (yes in sleep)
        // as such overwrite aankomst as vertrek and save
        saveSleepFlight({
          vertrek_vliegveld: values.vertrek_vliegveld,
          aankomst_vliegveld: values.vertrek_vliegveld,
          bijzonderheden: values.bijzonderheden,
          betalend_lid_id: flight.betalend_lid_id,
        });
      }

      // save all values to flight
      await saveFlight({ save: true, ...values });
      onClose();
      setLoading(false);
    } catch (error) {
      console.log("Validation error: ", error);
      setLoading(false);
    }
  };

  if (flight) {
    return (
      <Modal
        width={550}
        title={$t("Vlucht ${l} details", { l: flight.volg_nummer || "" })}
        open={visible}
        onCancel={onClose}
        onOk={onSubmitData}
        confirmLoading={loading}
        okText={$t("Opslaan")}
        cancelText={$t("Sluiten")}
        footer={[
          <Popconfirm
            placement="bottom"
            title={$t("Zeker weten dat je de vlucht wilt verwijderen")}
            onConfirm={triggerDelete}
            key="delete"
            okText={$t("Ja")}
            cancelText={$t("Nee")}
          >
            <Button
              icon={<StopOutlined />}
              danger
              style={{ marginRight: 42 }}
              loading={loading}
              disabled={readOnly}
            >
              {$t("Verwijder")}
            </Button>
          </Popconfirm>,
          <SluitButton key="sluit" onClick={onClose} />,
          <Button
            key="opslaan"
            onClick={onSubmitData}
            type="primary"
            disabled={readOnly}
          >
            {$t("Opslaan")}
          </Button>,
        ]}
      >
        <Form
          form={form}
          preserve={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 13 }}
          layout="horizontal"
          name="details"
          size="small"
          initialValues={{
            afstand: flight.afstand,
            vertrek_vliegveld: flight.vertrek_vliegveld,
            aankomst_vliegveld: flight.aankomst_vliegveld,
            bijzonderheden: flight.bijzonderheden,
            betalend_lid_id: flight.betalend_lid_id,
          }}
          requiredMark={false}
        >
          <Form.Item
            label={$t("Vertrek")}
            name="vertrek_vliegveld"
            rules={[
              {
                required: true,
                message: $t("Voeg vertrek vliegveld toe!"),
              },
            ]}
          >
            <Input prefix={$t("van")} disabled={readOnly} />
          </Form.Item>

          <Form.Item label={$t("Aankomst")} name="aankomst_vliegveld">
            <Input prefix={$t("naar")} disabled={readOnly} />
          </Form.Item>

          {flight.is_overland && (
            <Form.Item label={$t("Afstand (km)")} name="afstand">
              <Input disabled={readOnly} type="number" />
            </Form.Item>
          )}

          {(flight?.start_methode?.startsWith("tmg") ||
            flight?.blocktime > 0) && (
            <Form.Item label={$t("Bloktijd (min)")} name="blocktime">
              <Input disabled={readOnly} type="number" />
            </Form.Item>
          )}

          <Form.Item label={$t("Betalend lid")} name="betalend_lid_id">
            <Select
              showSearch
              disabled={readOnly}
              style={{ width: 200 }}
              filterOption={(input, option) =>
                option &&
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {vliegers &&
                vliegers.map((user, key) => (
                  <Select.Option key={key} value={user.id}>
                    {user.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={$t("Bijzonderheden")}
            name="bijzonderheden"
            rules={[
              {
                required: false,
                message: $t("Maximaal ${c} characters", { c: 500 }),
                max: 500,
              },
            ]}
          >
            <Input.TextArea rows={4} disabled={readOnly} />
          </Form.Item>
        </Form>

        <FlightDetails flight={flight} />

        <p className="small gray center" style={{ margin: "24px 0 0 0" }}>
          <span style={{ margin: "0 4px" }}>{flight.uuid}</span>
          {!flight.id && <span style={{ margin: "0 4px" }}>new</span>}
        </p>
      </Modal>
    );
  }
  return null;
};
