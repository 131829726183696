import React from "react";
import { WorkorderCard } from "../tabs/workorders/workorderCard.jsx";

export const WorkorderOverviewTab = ({ workorders, search, searchTag }) => {
  const list_workorders = workorders?.filter((row) => {
    const inCategory =
      searchTag.length === 0 ||
      searchTag.some(
        (t) => row.kist_category === t || row.material_category === t
      );
    if (search) {
      return (
        inCategory && row.material_name?.toLowerCase().indexOf(search) > -1
      );
    }
    return inCategory;
  });

  return (
    <div className="column" style={{ gap: 24, alignItems: "flex-start" }}>
      {list_workorders.map((workorder, key) => (
        <WorkorderCard key={key} showAsOverview workorder={workorder} />
      ))}
    </div>
  );
};
