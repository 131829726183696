import { $t } from '~/i18n.js'
import React from 'react'
import { Button } from 'antd'
import './404_not_found.css'

export function NotFound404({ history }) {
  return (
    <div className='not_found'>
      <h2>404.</h2>
      <p>{$t('Pagina niet gevonden.')}</p>
      <Button className='homeButton' type='primary' onClick={() => history.push('/')}>
        {$t('Terug')}
      </Button>
    </div>
  );
}
