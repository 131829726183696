import { SendOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

import { formatMessage } from "~/lib/helpers.js";
import { _parseDate } from "~/lib/localize.js";

const _dictionaryToTextArray = (lookup, filterTMGa) => {
  // export dictionary to text array suited for export
  if (!lookup) return "";
  let keys = Object.keys(lookup).sort();
  if (filterTMGa) keys = keys.filter((key) => key.indexOf("tmg-a") === -1);
  return keys.reduce(
    (all, key) => all + `${all === "" ? "" : "\n"}${key}: ${lookup[key]}`,
    ""
  );
};

export const EmailDagVerslag = ({
  emailSettings,
  profile,
  history,
  flights,
  activeDay,
  club,
  notes,
}) => {
  // only send out if settings are populated with email and permissions are correct
  if (!profile.ziet_emaillijst) return null;
  if (!emailSettings.verslag_email) return null;

  const constructEmail = () => {
    const filteredFlights = (flights || []).filter((f) => !f.is_deleted);

    // reduce the flights per method and category
    const method_lookup = {};
    const category_lookup = {};
    const method_category_lookup = {};
    const start_method = {};

    const limit = new Date("2000-01-01T19:00:00.000Z");
    let day_count = 0;
    let evening_count = 0;
    let flight_count = 0;

    filteredFlights.forEach((f) => {
      if (f.start_methode !== "tmg-a") flight_count += 1;

      if (!method_lookup[f.start_methode]) method_lookup[f.start_methode] = 0;
      method_lookup[f.start_methode] += 1;

      if (!category_lookup[f.category]) category_lookup[f.category] = 0;
      category_lookup[f.category] += 1;

      if (!start_method[f.start_methode]) start_method[f.start_methode] = {};
      if (!start_method[f.start_methode][f.category])
        start_method[f.start_methode][f.category] = 0;
      start_method[f.start_methode][f.category] += 1;

      const cat_method = f.start_methode + "   " + f.category;
      if (!method_category_lookup[cat_method])
        method_category_lookup[cat_method] = 0;
      method_category_lookup[cat_method] += 1;

      // CHECK time
      if (f.start_methode === "lier" && f.category === "20") {
        if (new Date(`2000-01-01T${f.start_tijd}:00.000Z`) > limit) {
          evening_count += 1;
        } else {
          day_count += 1;
        }
      }
    });

    const data = {
      date: _parseDate(activeDay.datum),
      created_by: profile.name,
      number_flights: flight_count,
      flights_per_method: _dictionaryToTextArray(method_lookup, true),
      flights_per_category: _dictionaryToTextArray(category_lookup, true),
      flights_per_method_category: _dictionaryToTextArray(
        method_category_lookup,
        true
      ),
      lier_day_count: Math.ceil(day_count / 2),
      lier_evening_count: Math.ceil(evening_count / 2),

      lier_per_category: _dictionaryToTextArray(start_method.lier, false),
      sleep_per_category: _dictionaryToTextArray(start_method.sleep, false),
      tmg_per_category: _dictionaryToTextArray(start_method.tmg, false),
      tmga_per_category: _dictionaryToTextArray(start_method["tmg-a"], false),
      zelf_per_category: _dictionaryToTextArray(start_method.zelf, false),
      overig_per_category: _dictionaryToTextArray(start_method.overig, false),

      daily_notes: notes.reduce(
        (all, note) =>
          all +
          `${all === "" ? "" : "\n"}- ${note.bericht} (${
            note.instructeur_naam
          })`,
        ""
      ),
    };

    // navigate to the page with email content
    const groups = emailSettings.verslag_email
      .split(",")
      .map((g) => g.toLowerCase().trim());

    const email = {
      groups,
      reply_to_name: emailSettings.verslag_reply_to ? club.name : profile.name,
      reply_to: emailSettings.verslag_reply_to || profile.email,
      subject: formatMessage(emailSettings.verslag_subject, data),
      content: formatMessage(emailSettings.verslag_template, data),
      help: emailSettings.verslag_help,
    };
    history.push("/email", { email });
  };

  return (
    <Button onClick={constructEmail} type="primary" icon={<SendOutlined />}>
      Verstuur
    </Button>
  );
};
