import { Checkbox, Descriptions, message } from "antd";
import React, { useState } from "react";
import { $t } from "~/i18n.js";
import { TermsVersionEN } from "../../components/layout/terms/text.terms.en";
import { TermsVersionNL } from "../../components/layout/terms/text.terms.nl";
import { CardBase } from "../../components/timeLine/cardBase";
import { config } from "../../config";
import { api } from "../../lib/api";

export const CardBevestig = ({ data, saveAndNext, prev }) => {
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);

  const handleSave = async () => {
    if (!agree) {
      message.error($t("Ga akkoord met de algemene voorwaarden"));
      return;
    }

    setLoading(true);
    const payload = {
      ...data,
      schema_name: data.name.toLowerCase(),
      language: localStorage.language,
      terms_version: localStorage.language.startsWith("nl")
        ? TermsVersionNL
        : TermsVersionEN,
      terms_name: data.first_name + " " + data.last_name,
    };
    if (payload.logo === null) delete payload.logo;
    const result = await api.post_form(
      "",
      payload,
      `${config.base_backend_url}/all/register.json`
    );
    setLoading(false);
    if (result) saveAndNext(result);
  };

  return (
    <CardBase
      prev={prev}
      next={handleSave}
      title={$t("Opslaan")}
      loading={loading}
    >
      <Descriptions title={$t("Bevestig")} size="small" bordered column={1}>
        <Descriptions.Item label={$t("Club")}>
          {data.full_name}
        </Descriptions.Item>

        <Descriptions.Item label={$t("Afkorting")}>
          {data.name}
        </Descriptions.Item>

        <Descriptions.Item label={$t("Admin")}>
          {data.first_name} {data.last_name}
        </Descriptions.Item>

        <Descriptions.Item label={$t("Email")}>{data.email}</Descriptions.Item>

        <Descriptions.Item label={$t("Timezone")}>
          {data.timezone}
        </Descriptions.Item>

        <Descriptions.Item label={$t("Land code")}>
          {data.country}
        </Descriptions.Item>
      </Descriptions>

      <Checkbox
        style={{ marginTop: 12 }}
        checked={agree}
        onChange={(e) => setAgree(e.target.checked)}
      >
        {$t(
          "Ik, als vertegenwoordiger van ${fullname}, ga akkoord dat met de",
          { fullname: data.full_name }
        )}{" "}
        <a href="https://app.zweef.app/terms" target="_blank" rel="noreferrer">
          {$t("algemene voorwaarden")}
        </a>
        .
      </Checkbox>

      <p style={{ margin: "12px 6px" }}>
        {$t("Na bevestiging ontvang je een email met vervolginstructies.")}
      </p>
    </CardBase>
  );
};
