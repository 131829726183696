import { $t } from '~/i18n.js'
import React, { useState } from 'react'
import { Button, Card, Divider } from 'antd'
import { EditOutlined, MailOutlined, PlusOutlined } from '@ant-design/icons'
import { _parseDate } from '../../../lib/localize'
import { MessageModal } from './messageModal'
import { colors } from '../../../theme/colors'

export const MessageCard = ({ messages, currentUserID, canEditMessage }) => {
  const [activeMessage, setActiveMessage] = useState(null)

  return (
    <>
      <MessageModal
        visible={activeMessage !== null}
        message={activeMessage}
        onClose={() => setActiveMessage(null)}
      />
      <Card
        size='small'
        className='topCard'
        title={$t('Berichten')}
        extra={canEditMessage && <Button
          type='primary' icon={<PlusOutlined />} size='small' shape='circle'
          onClick={() => setActiveMessage({ id: null })}
                                 />}
      >

        <div style={{ maxHeight: 240, overflowY: 'auto', overflowX: 'hidden' }}>

          {messages?.length > 0
            ? messages.map((message, key) => {
              const canEdit = canEditMessage && message.user_id === currentUserID
              return (
                <div key={key} className='spacer'>

                  <p className='small' style={{ color: colors.darkgray_light }}>
                    {_parseDate(message.date_created, { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
                  </p>

                  <div className={`row ${canEdit && 'clickable'}`} onClick={() => canEdit && setActiveMessage(message)} style={{ marginTop: 4 }}>

                    {canEdit &&
                      <EditOutlined style={{ marginRight: 4, fontSize: 12 }} />}

                    <p className='bold small'>
                      {message.user_name}
                    </p>

                  </div>
                  <p>
                    {message.as_email && <MailOutlined style={{ marginRight: 4, fontSize: 12, color: colors.primary }} />}
                    {message.message}
                  </p>

                  <Divider style={{ margin: '2px 0' }} />

                </div>
              )
            })
            : <p style={{ marginLeft: 36 }} />}

        </div>
      </Card>
    </>
  )
}
