export const PrivacyVersionEN = "Versie 4, 17 feb 2023";

export const PrivacyTextEN = `
When you log in to the Gliding.App or visit the website, we record your personal data. We believe it is important to respect and protect your privacy as much as possible. That is why we handle your personal data with care and are transparent about its use.

### 1. Who is who?

If you read 'you', 'you' or 'your(w)' in this privacy statement, we mean you as a glider pilot or visitor of the website Gliding.app or glidingapp.com (from hereon known as Gliding.App).

If you read 'we', 'us' or 'our', we mean the Gliding.App (also known as Zweef.App and registered with the Chamber of Commerce under number 88819051).

By “club” we mean the gliding club of which you are a member, which is legally responsible for processing personal data.

The relationship between us, you and the club is a triangle, and along every relationship there is an agreement:

- You have an agreement with your club in the form of a membership at your club. As part of this, your club processes your personal data.
 
- Your club has chosen to use the Gliding.App to keep track of the starting administration, DTO administration and/or other club administration. Your club is legally responsible for the processing of personal data and the Gliding.app is the (sub)processor of this (personal) data. Your club and the Gliding.app have agreed on a processing agreement as prescribed by the GDPR, which, among other things, stipulates the processing, rights, confidentiality and security of data.
 
- As an individual member, you also have the option to log in to the Gliding.App yourself. You can view your data, enter additional data and the Gliding.app can process additional personal data about you (for example when you last logged in). The Gliding.app is responsible for the data that Gliding.app additionally processes from you. This privacy notice explains the processing of that data.


The Gliding.App also offers links, for example with specific login systems. For the sake of completeness, we hereby note that we are not legally responsible for the processing of personal data via the relevant websites. The person responsible for this is the relevant party.

### 2. What data do we keep and why?

We process data on behalf of your club with the aim of taking care of your club's digital gliding administration. Consider matters such as the starting administration, EASA Declared Training Organization (DTO) administration, authorities, or currency of members.

Which personal data we record and the reason for this depends on the situation. Below we describe per situation what we record and why. In addition, there may be more situations in which your data has been used.


#### 2.1 Data that we process on behalf of your club

The data that we process on behalf of your gliding club is described in the processing agreement that your club has with the Gliding.app. In the privacy statement of your gliding club you can read which personal data your club processes about you. In general, the Gliding.app processes the following data on behalf of the clubs, but the situation may differ per club:

When you register to fly and when you fly, we store this information in our administration. It is recorded, among other things, who you flew with, on which aircraft, type of flight (for example EASA training, exam or cross-country flights), where the flight was and what time and duration it flew. The operator of an airport is obliged under EASA legislation to register who the captain is. This information will be stored during the flight.

Within the EASA Declared Training Organization (DTO) there is also a requirement to store data. For example, for the student tracking system that can be consulted by instructors. We also ask for other information, such as (emergency) contact details, a profile photo, the validity date of medical.

This data is also used to calculate your current currency (according to the currency Barometer) and whether you meet the EASA recency requirements. What is good to know is that your currency and recency can be viewed by all members to create a collective awareness of security.

In addition, your licence number, KNVvL number (insurance), medical validity, and authorizations within the club are stored so that the club can see whether you meet the legal requirements or conditions set by the club.

Finally, if you give permission for this and your club uses this functionality, you can enter your bank account number and we will share your bank account number with your gliding club to ensure that automatic debit can be made for, for example, your membership, towvlu

Finally, if you give permission for this and your club uses this functionality, you can enter your bank account number and we will share your bank account number with your gliding club to ensure that automatic debits can be made for, for example, your membership, towing flights and other club-related costs.


#### 2.2 Data that the Gliding.app processes itself

We collect information about visitors to our website, for example how many visitors have been and from which page they were referred. The data we record depends on what information your browser sends. This may, for example, be information about your device, your operating system or the time at which you visited our website.

When you log in to the Gliding.App, you must provide your name and email address. We store this data in the administration that your club manages. This information is necessary to ensure that you can log in, your password can be reset and to send service messages. Without this information we cannot let you use the Gliding.App.


### 3. How have we secured your data?

We have taken appropriate technical and organizational measures to protect your personal data. We have based the choice of these measures on the available technology, the implementation costs, the type of personal data we process about you and the risks associated with it. For example, our website has an SSL certificate, we use a firewall to prevent abuse and we have encryption on certain critical data. In addition, all our servers are within the European Union (European Economic Area) and hosted in locations where data cannot be shared with the US (think of the EU-US Privacy Shield).

### 4. With whom do we share your data?

#### 4.1 Data that we process on behalf of your club

The data about you that is processed in the Gliding.App on behalf of your club can be (partly) shared with other members or specific groups of members within your club (for example instructors). This is necessary for your club, for example, to comply with (EASA and DTO) legislation, to organize a safe flying company and to meet the conditions of, for example, the insurance of aircraft and vehicles. We carry out this on behalf of your club and your club is responsible for this. This is recorded in a processing agreement between us and your club. More information about which data your club processes about you can be found in the privacy statement of your club.

Within the Gliding.App, every user can choose to turn on the privacy mode. With this mode, privacy-sensitive data (such as address details and flight information) will be shielded from other members. However, system administrators, instructors and tow pilots (only for tow flights) from your own club will still be allowed to view your data in that case.

If you give permission for this, we will share your bank account number with your club to ensure that automatic debits can be made for, for example, your membership and towing flights.

We only share your data with other parties if this is legally required, such as safety investigations by the Human Environment and Transport Inspectorate (ILT). In that case, we only share the personal data that we are required to provide by law for such an investigation. Communication about this will go through your club as they are responsible for processing your data.

Finally, we will never use, sell, exchange or rent your personal information and flight details for our own purposes.

#### 4.2 Data that the Gliding.app itself processes

We will never transfer your personal information to a third party unless we are legally obliged to do so.


### 5. How long do we keep your data?

We do not store your personal data for longer than is necessary for the purpose for which we recorded it. This applies to both data that we process on behalf of your club and data that the Gliding.App processes itself.

If you have created a user account, your data will be retained. After you have canceled your account, we will permanently delete the data within 36 months in accordance with the instructions received from your club. This is in accordance with the EASA DTO legislation DTO.GEN.135 c).

We do store the data necessary to comply with EASA legislation as an airport operator, namely the name of the captain for every flight you have made.

We continuously make backups of the data you store with us. We store backups for a maximum of 60 days. This means that deleted data can be retained in such a backup for a maximum of 60 days.

### 6. What rights do you have when it comes to your privacy?

According to privacy legislation, you have a number of rights, such as viewing, receiving, correcting and deleting your data:

Via the portal you can view the personal data and flight data that we process about you.

- You can correct or delete personal data that is factually incorrect there yourself.
- You can limit the visibility of your personal data by switching on the privacy mode in the Gliding.App.
- We can transfer your personal data to you digitally (data portability). In that case, we will make an export of your related personal data and flight data. If you want to use this, you can find the export of your data in the __my_data__ tab of the Gliding.App.

Do you have other questions or requests about viewing, correcting or deleting your data? Please contact your club.


### 7. What can you do if you have a privacy complaint?

If you have a complaint about how we handle personal data as we do on behalf of your club, we ask you to first share this complaint with your club. Your club will then look for a solution together with you, and if necessary with us.

In other cases, email us at [contact@Gliding.app](mailto:contact@Gliding.app). You can do this, for example, if you suspect that your data has been misused or that there has been a data breach.


(This is an English translation. In case that the Dutch and English tekst differ, the Dutch text prevails. In case of translation errors, please let us know. Thanks)
`;
