import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  PlusOutlined
} from "@ant-design/icons";
import { Button, Flex, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min.js";
import { ImportButton } from "~/components/buttons/ImportButton.jsx";
import { RefreshBaseData } from "~/components/buttons/RefreshBaseData.jsx";
import { BreadcrumbHeader } from "~/components/layout/breadcrumbHeader.jsx";
import { defaultTableConfig } from "~/components/layout/table.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { selectElementByID } from "~/lib/helpers.js";
import { colors } from "~/theme/colors.js";
import { ClusterEditModal } from "./components/ClusterEditModal.jsx";
import { ImportDialogDTO } from "./components/ImportDialogDTO.jsx";

export function AdminDTO({ history }) {
  const [clusters, setClusters] = useState([]);

  const columns = [
    { title: $t("Volgorde"), dataIndex: "order" },
    { title: $t("Naam"), dataIndex: "name" },
    { title: $t("Curriculum versie"), dataIndex: "curriculum_versie" },
    {
      title: $t("Is DTO"),
      dataIndex: "is_dto",
      render: (_, dto) => (
        <p>
          {dto.is_dto ? (
            <CheckCircleTwoTone twoToneColor={colors.green} />
          ) : (
            <CloseCircleTwoTone twoToneColor={colors.red} />
          )}
        </p>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const [loading, setLoading] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const data = await api.get("admin/dto.json");
    if (data) setClusters(data);
    setLoading(false);
  };

  const renderClusterForm = ({ history, match }) => {
    let selectedCluster = {
      id: null,
      is_default: false,
      isNew: true,
      order: 0,
    };
    if (match.params.clusterId !== "new") {
      selectedCluster = selectElementByID(
        clusters,
        "id",
        Number(match.params.clusterId)
      );
    }
    return (
      <ClusterEditModal
        history={history}
        cluster={selectedCluster}
        refreshData={fetchData}
      />
    );
  };

  return (
    <Flex gap="middle" vertical>
      <Switch>
        {clusters.length && (
          <Route
            path="/admin/dto_settings/:clusterId"
            component={renderClusterForm}
          />
        )}
      </Switch>

      <Flex align="center" gap="middle" justify="space-between">
        <BreadcrumbHeader breadcrumbs={[$t("DTO settings")]} />

        <ImportDialogDTO
          refreshAfterImport={fetchData}
          isModal={showImportModal}
          closeModal={() => setShowImportModal(false)}
        />

        <Flex gap={12}>
          <RefreshBaseData />
          <ImportButton onClick={() => setShowImportModal(true)} />

          <Button
            onClick={() => history.push("/admin/dto_settings/new")}
            icon={<PlusOutlined />}
            size="small"
          >
            {$t("Voeg toe")}
          </Button>
        </Flex>
      </Flex>

      <Table
        loading={loading}
        style={{
          marginTop: 12,
          width: "95vw",
          overflowX: "auto",
          cursor: "pointer",
        }}
        pagination={{ ...defaultTableConfig, defaultPageSize: 100 }}
        size="small"
        rowKey="id"
        dataSource={clusters}
        columns={columns}
        onRow={(cluster) => {
          return {
            onClick: () => history.push(`/admin/dto_settings/${cluster.id}`),
          };
        }}
      />
    </Flex>
  );
}
