import { ArrowDownOutlined, ArrowUpOutlined, EditOutlined, EyeOutlined, FileExcelOutlined, PlusOutlined, RetweetOutlined, ToolOutlined, VerticalAlignTopOutlined } from '@ant-design/icons'
import { Button, Collapse, Empty, Switch, Tag } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { $t } from '~/i18n.js'
import { HelpBubble } from '../../../../components/help/help.bubble'
import { api } from '../../../../lib/api'
import { selectElementByID } from '../../../../lib/helpers'
import { saveMaterialTab } from '../../../../redux/materiaal/materiaal.actions'
import { MaintenanceStatusTag } from '../../materialTags'
import { AddMaintenanceModal } from './addMaintenance'
import { MaintenanceCard } from './maintenanceCard'
import { ModalEditMaintenance } from './modalEditMaintenance'
import { ModalViewMaintenance } from './modalViewMaintenance'

export const MaterialMaintenance = ({ currentMaterial, isAuthenticated, maintenance, active_maintenance_id, tasks, readOnly, action, history, hasAccess }) => {
  const [viewAddModal, setViewAddModal] = useState(false)
  const [readOnlyMode, setReadOnlyMode] = useState(true)

  const [fileLoading, setFileLoading] = useState(false)
  const downloadFile = async () => {
    if (fileLoading) return
    setFileLoading(true)
    await api.open_file(`materiaal/${currentMaterial.id}/maintenance_export.json`, `Maintenance Programma ${currentMaterial.name}.xlsx`)
    setFileLoading(false)
  }

  const openMaintenanceProgram = active_maintenance_id ? selectElementByID(maintenance, 'id', parseInt(active_maintenance_id)) : null

  const canShowEditModal = action === 'edit' && !readOnly
  const openModal = (id, action) => history.push(`/${hasAccess ? 'material' : 'status/material'}/${currentMaterial.id}/maintenance/${id}/${action || ''}`)
  const closeModal = () => history.push(`/${hasAccess ? 'material' : 'status/material'}/${currentMaterial.id}/maintenance/`)
  const openWorkorder = (id) => history.push(`/${hasAccess ? 'material' : 'status/material'}/${currentMaterial.id}/workorders/${id}/view`)

  const dispatch = useDispatch()
  const saveMultipleMaintenance = async (payload) => {
    await dispatch(saveMaterialTab({ activeTab: 'maintenance', materialID: currentMaterial.id, payload: { objects: payload } }))
  }

  const [moving, setMoving] = useState(false)
  const move_up = async (maintenance_program, key) => {
    if (!moving && key > 0) {
      setMoving(true)
      const prev = maintenance[key - 1]

      // in case orders are same, bump up previous
      const delta = prev.order === maintenance_program.order ? 1 : 0
      await saveMultipleMaintenance([
        { id: prev.id, order: maintenance_program.order + delta, t: prev.name, skip_checks: true },
        { id: maintenance_program.id, order: prev.order, t: maintenance_program.name, skip_checks: true }])
      setMoving(false)
    }
  }

  const move_down = async (maintenance_program, key) => {
    if (!moving && key < (maintenance.length - 1)) {
      setMoving(true)
      const next = maintenance[key + 1]

      // in case orders are same, bump up previous
      const delta = maintenance_program.order === next.order ? 1 : 0
      await saveMultipleMaintenance([
        { id: maintenance_program.id, order: next.order + delta, t: maintenance_program.name, skip_checks: true },
        { id: next.id, order: next.order, t: next.name, skip_checks: true }])
      setMoving(false)
    }
  }

  return (
    <div className='column' style={{ gap: 24, alignItems: 'flex-start' }}>

      {canShowEditModal && openMaintenanceProgram &&
        <ModalEditMaintenance maintenanceProgram={openMaintenanceProgram} visible tasks={tasks} currentMaterial={currentMaterial} closeModal={closeModal} />}
      {!canShowEditModal && openMaintenanceProgram && <ModalViewMaintenance maintenanceProgram={openMaintenanceProgram} visible tasks={tasks} closeModal={closeModal} />}

      {viewAddModal && <AddMaintenanceModal
        visible={viewAddModal}
        currentMaterial={currentMaterial}
        closeModal={() => setViewAddModal(false)}
                       />}

      <div className='row' style={{ justifyContent: 'space-between', gap: 12, width: '100%', flexWrap: 'wrap' }}>
        {currentMaterial.maintenance_status && <div className='row'>
          {$t('Algemene status')} <HelpBubble content={$t('Status word elke nacht bijgewerkt')} /><span style={{ marginRight: 12 }}>:</span> <MaintenanceStatusTag
            maintenance_status={currentMaterial.maintenance_status}
            default_value='nvt'
                                                                                                                                />
        </div>}

        <div className='row' style={{ gap: 12 }}>

          {!readOnly &&
            <p className='row'>{$t('Wijzig')}:
              <Switch
                size='small' style={{ marginLeft: 4 }}
                onChange={(checked) => setReadOnlyMode(!checked)}
                checked={!readOnlyMode}
              />
            </p>}
          {!readOnly && maintenance.length > 0 && <Button onClick={() => setViewAddModal(true)} size='small' icon={<PlusOutlined />}>
            {$t('Voeg toe')}
                                                  </Button>}
          {maintenance.length > 0 && <Button icon={<FileExcelOutlined />} size='small' loading={fileLoading} onClick={downloadFile}>
            {$t('Download')}
                                     </Button>}
        </div>
      </div>

      {!readOnly && !maintenance.length && <div>
        <Button type='primary' onClick={() => setViewAddModal(true)}>{$t('Voeg maintenance task toe')}</Button>
                                           </div>}
      {readOnly && !maintenance.length && <div className='row' style={{ justifyContent: 'center', width: '100%', marginTop: 42 }}>
        <Empty description='Geen taken' />
                                          </div>}

      <Collapse style={{ marginTop: 42, width: '100%', background: 'white' }}>
        {maintenance.map((maintenance, key) =>
          <Collapse.Panel
            key={key}
            header={<div className='row' style={{ gap: 12, alignItems: 'flex-start' }}>
              <MaintenanceStatusTag maintenance_status={maintenance.status} hideTag />
              <p>{maintenance.schedule === 'recurring' ? <RetweetOutlined /> : <VerticalAlignTopOutlined style={{ transform: 'rotate(90deg)' }} />}
                <Tag>{maintenance.part_name}</Tag> {maintenance.name}
              </p>

              {isAuthenticated && openWorkorder && maintenance.workorder_id && <ToolOutlined style={{ marginRight: 6 }} />}

              {!maintenance.part_is_installed && <Tag color='red'>niet actief</Tag>}
            </div>}
            extra={[
              readOnlyMode && <Button
                key='open' shape='circle' size='small' icon={<EyeOutlined />} onClick={(e) => {
                  e.stopPropagation()
                  openModal(maintenance.id, '')
                }}
                              />,

              !readOnlyMode && <Button
                disabled={moving} onClick={(e) => {
                  e.stopPropagation()
                  move_up(maintenance, key)
                }}
                key='up' shape='circle' size='small' icon={<ArrowUpOutlined />}
                               />,

              !readOnlyMode && <Button
                disabled={moving} onClick={(e) => {
                  e.stopPropagation()
                  move_down(maintenance, key)
                }}
                key='down' shape='circle' size='small' icon={<ArrowDownOutlined />}
                               />,

              !readOnlyMode && <Button
                key='edit' shape='circle' size='small' icon={<EditOutlined />} style={{ marginLeft: 6 }} onClick={(e) => {
                  e.stopPropagation()
                  openModal(maintenance.id, 'edit')
                }}
                               />
            ]}
          >

            <MaintenanceCard openWorkorder={isAuthenticated && openWorkorder} maintenance={maintenance} />
          </Collapse.Panel>
        )}
      </Collapse>

    </div>
  )
}
