import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Card, Tag } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BerichtFileThumbnails } from "~/components/bericht/bericht.jsx";
import { api } from "~/lib/api.js";
import { _parseDateTime } from "~/lib/localize.js";

const Document = ({ hideTag, document, readOnly }) => {
  // in case files have been modified after save, we retrieve them from temporary lookup in redux
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.materiaal?.currentMaterial);

  const deleteFile = async (file, herstel) => {
    await api.post_form("materiaal/save_document_file.json", {
      id: file.id,
      action: herstel ? "herstel" : "delete",
    });

    const res = await api.get(`materiaal/${id}/tab_documents.json`);
    res &&
      dispatch({
        type: "MATERIAAL_SET_TAB",
        obj_id: id,
        material: res.material,
        activeTab: "documents",
        tab_data: res.data,
      });
  };

  const archived = document.files?.filter((f) => f.is_archived);

  return (
    <div>
      <div className="row" style={{ gap: 12, flexWrap: "wrap" }}>
        {!hideTag && document.is_archived && <Tag color="default">archief</Tag>}
        {!hideTag && <Tag color="cyan">{document.tag}</Tag>}
        <p className="gray">
          {document.user_name} {_parseDateTime(document.date_created)}
        </p>
      </div>

      <BerichtFileThumbnails
        files={document.files?.filter((f) => !f.is_archived)}
        deleteFile={!readOnly && deleteFile}
      />

      {!readOnly && archived.length > 0 && (
        <div style={{ marginTop: 6 }}>
          <p className="bold spacer">Archief:</p>
          <BerichtFileThumbnails
            files={archived}
            deleteFile={!readOnly && deleteFile}
          />
        </div>
      )}
    </div>
  );
};

export const DocumentList = ({
  hideTag,
  documents,
  saveDocuments,
  editDocument,
  readOnly,
}) => {
  if (documents?.length === 0) return;

  const [moving, setMoving] = useState(false);
  const move_up = async (document, key) => {
    if (!moving && key > 0) {
      setMoving(true);
      const prev_doc = documents[key - 1];

      // in case orders are same, bump up previous
      const delta = prev_doc.order === document.order ? 1 : 0;
      await saveDocuments([
        { id: prev_doc.id, order: document.order + delta, t: prev_doc.name },
        { id: document.id, order: prev_doc.order, t: document.name },
      ]);
      setMoving(false);
    }
  };

  const move_down = async (document, key) => {
    if (!moving && key < documents.length - 1) {
      setMoving(true);
      const next_doc = documents[key + 1];

      // in case orders are same, bump up previous
      const delta = document.order === next_doc.order ? 1 : 0;
      await saveDocuments([
        { id: document.id, order: next_doc.order + delta, t: document.name },
        { id: next_doc.id, order: next_doc.order, t: next_doc.name },
      ]);
      setMoving(false);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {documents.map((document, key) => {
        // Connect path to next level
        return (
          <Card
            key={key}
            style={{ width: "100%", marginBottom: 12 }}
            size="small"
            title={
              <p>
                {document.name}{" "}
                <span className="gray" style={{ marginLeft: 12 }}>
                  {document.version}
                </span>
              </p>
            }
            extra={[
              !readOnly && saveDocuments && (
                <Button
                  disabled={moving}
                  onClick={() => move_up(document, key)}
                  key="up"
                  shape="circle"
                  size="small"
                  icon={<ArrowUpOutlined />}
                />
              ),

              !readOnly && saveDocuments && (
                <Button
                  disabled={moving}
                  onClick={() => move_down(document, key)}
                  key="down"
                  shape="circle"
                  size="small"
                  icon={<ArrowDownOutlined />}
                />
              ),

              !readOnly && (
                <Button
                  style={{ marginLeft: 6 }}
                  onClick={() => editDocument(document)}
                  key="alter"
                  shape="circle"
                  size="small"
                  icon={<EditOutlined />}
                />
              ),
            ]}
          >
            <Document
              hideTag={hideTag}
              document={document}
              editDocument={editDocument}
              readOnly={readOnly}
            />
          </Card>
        );
      })}
    </div>
  );
};
