import { $t } from '~/i18n.js'
import React from 'react'
import { Button } from 'antd'
import { CalendarOutlined, CheckOutlined, CloseOutlined, EditOutlined, SmileOutlined } from '@ant-design/icons'

export const MeldingStateButtons = ({ melding, pendingSave, pendingAction, setPendingAction }) => {
  return (
    <div className='row' style={{ flexWrap: 'wrap' }}>

      <div className='row spacer'>
        <Button
          loading={pendingSave && pendingAction === 'edit'}
          onClick={() => setPendingAction('edit')}
          style={{ marginRight: 6 }}
          icon={<EditOutlined />}
        >
          {$t('Wijzigen')}
        </Button>

      </div>

      <div className='row spacer'>
        {melding.status && melding.status === 'aangemeld' &&
          (melding.category === 'defect' || melding.category === 'klacht') &&
            <Button
              loading={pendingSave && pendingAction === 'uitgesteld'}
              onClick={() => setPendingAction('uitgesteld')}
              style={{ marginRight: 6 }} icon={<CalendarOutlined />}
            >
              {$t('Uitstellen')}
            </Button>}

        {['aangemeld', 'uitgesteld'].includes(melding.status) &&
          <Button
            loading={pendingSave && pendingAction === 'afgerond'}
            onClick={() => setPendingAction('afgerond')}
            style={{ marginRight: 6 }} icon={<SmileOutlined />}
          >
            {$t('Oplossen')}
          </Button>}

        {melding.status && melding.status !== 'afgerond' &&
          <Button
            loading={pendingSave && pendingAction === 'afgewezen'}
            onClick={() => setPendingAction('afgewezen')}
            color='danger' style={{ marginRight: 6 }} icon={<CloseOutlined />}
          >
            {$t('Afwijzen')}
          </Button>}

        {['afgerond', 'afgewezen'].includes(melding.status) &&
          <Button
            loading={pendingSave && pendingAction === 'aangemeld'}
            onClick={() => setPendingAction('aangemeld')}
            style={{ marginRight: 6 }} icon={<CheckOutlined />}
          >
            {$t('Her-open melding')}
          </Button>}
      </div>

    </div>
  )
}
