import PropTypes from 'prop-types'
import React from 'react'

import './loader.css'

export class Loader extends React.Component {
  static propTypes = {
    size: PropTypes.number, // number, string, any
    topColor: PropTypes.string,
    style: PropTypes.any
  }

  static defaultProps = {
    size: 50,
    topColor: '#3498db',
    style: {}
  }

  constructor (props, context) {
    super(props, context)

    this.state = {}
  }

  render () {
    return (
      <div className='loaderWrapper' style={this.props.style}>

        <div
          className='loader' style={{
            width: this.props.size,
            height: this.props.size,
            borderWidth: (this.props.size / 6),
            borderTopColor: this.props.topColor,
            background: 'white'
          }}
        />

      </div>
    )
  }
}
