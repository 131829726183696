export const normalizeDays = (data) => {
  const output = []
  const today = (new Date()).setHours(0, 0, 0, 0)

  data.map((single) => {
    return output.push(normalizeDay(single, today))
  })

  return output
}

export const normalizeDay = (day, today) => {
  const date = (new Date(day.datum)).setHours(0, 0, 0, 0)
  return {
    ...day,
    date,
    can_aanmeld: date >= today,
    can_start: date <= today,
    is_today: date === today
  }
}

export const createTotal = (action) => {
  // for totals we reduce list of [{dag_id: 1, start_method: 'lier', start: 2, minutes: 24}] to {1: {lief: {start: 2, minutes: 24}}
  // this so we can quickly lookup data in the tables
  const totals = action.totals && action.totals.reduce((all, day_start) => {
    // fill out day and start method
    if (!all[day_start.dag_id]) all[day_start.dag_id] = { starts: 0, minutes: 0 }

    // add in total, duration and per start method
    if (day_start.start_methode !== 'tmg-a') {
      all[day_start.dag_id].starts += day_start.starts
      all[day_start.dag_id].minutes += day_start.minutes
    }
    all[day_start.dag_id][day_start.start_methode] = day_start.starts

    // add in notitie and sleeps for table
    all[day_start.dag_id].notitie = action.notities[day_start.dag_id] || ''

    return all
  }, {})

  // then add in aanmeldingen
  action.aanmeldingen && action.aanmeldingen.reduce((all, dag) => {
    if (!all[dag.dag_id]) all[dag.dag_id] = { aanmeldingen: 0 }
    all[dag.dag_id].aanmeldingen = dag.ct
    return all
  }, totals)

  return totals
}

export const normalizeRooster = (rooster, users, currentUserID) => {
  const has_social_option = rooster.roosters.filter(r => r.autofill === 'social').length > 0
  const output = { ...rooster, saveAvailability: 0, canSave: 0, has_social_option, user_count: {} }

  // add a counter for all users in the diensten
  const rooster_lookup = rooster.roosters.reduce((all, r) => {
    all[r.id] = 1
    return all
  }, {})

  // filter all users to the groups found in the rooster which allows you to select the user
  rooster.roosters?.forEach((rooster) => {
    rooster.users = [{ name: '' }].concat(users && users.filter((u) => u.group_keys?.includes(rooster.group_key)))
  })

  const all_user_lookup = {}

  rooster?.days?.sort((a, b) => a.datum.localeCompare(b.datum))
  rooster?.days?.forEach((day) => {
    day.date = new Date(day.datum)
    day.dienst_lookup = {}
    day.diensten.forEach((dienst) => {
      // Day also has other roosters from other periods, ignore them
      if (!rooster_lookup[dienst.rooster_id]) return

      if (dienst.user_id) {
        if (!output.user_count[dienst.user_id]) output.user_count[dienst.user_id] = 0
        output.user_count[dienst.user_id] += 1
      }

      // Add own availability and remark if found
      // only show preferences of others, otherwise you have to fix counter on save which is more difficult
      dienst.preference_lookup = {}
      dienst.preferences = dienst.preferences.filter(p => {
        all_user_lookup[p.user_id] = 1
        dienst.preference_lookup[p.user_id] = p

        // save own availability
        if (p.user_id === currentUserID) {
          dienst.availability = p.availability
          dienst.remark = p.remark

          // also save to general day remark
          day.remark = p.remark
          return false
        }
        return true
      })
      day.dienst_lookup[dienst.rooster_id] = dienst
    })
  })

  output.n_users_with_preference = Object.keys(all_user_lookup).length

  return output
}
