import { Button, Checkbox, Divider, Input, Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { Markdown } from "../../markdown/markdown.jsx";

import { PricingTextNL, PricingVersionNL } from "./text.pricing.nl.jsx";
import { PrivacyTextNL, PrivacyVersionNL } from "./text.privacy.nl.jsx";
import { TermsTextNL, TermsVersionNL } from "./text.terms.nl.jsx";

import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { PricingTextEN, PricingVersionEN } from "./text.pricing.en.jsx";
import { PrivacyTextEN, PrivacyVersionEN } from "./text.privacy.en.jsx";
import { TermsTextEN, TermsVersionEN } from "./text.terms.en.jsx";

const isNL = localStorage.language
  ? localStorage.language.startsWith("nl")
  : window.location.host.indexOf("gliding") === -1;

const Termsmarkdown = (content) => {
  let element = "";
  let version = "";

  // simple, just 2 language support

  if (content === "privacy") {
    element = isNL ? PrivacyTextNL : PrivacyTextEN;
    version = isNL ? PrivacyVersionNL : PrivacyVersionEN;
  }
  if (content === "pricing") {
    element = isNL ? PricingTextNL : PricingTextEN;
    version = isNL ? PricingVersionNL : PricingVersionEN;
  }
  if (content === "terms") {
    element = isNL ? TermsTextNL : TermsTextEN;
    version = isNL ? TermsVersionNL : TermsVersionEN;
  }
  return (
    <div className="markDown" style={{ padding: 12, overflowY: "scroll" }}>
      <Markdown text={element} />
      <p style={{ fontStyle: "italic", marginTop: 24 }}>{version}</p>
    </div>
  );
};

export const TermsmodalReadOnly = () =>
  Modal.success({
    title: $t("Privacy mededeling"),
    width: 800,
    content: Termsmarkdown("terms"),
  });

export const Termspage = ({ header, content }) => {
  return (
    <div style={{ margin: "auto", maxWidth: 900 }}>
      <h1 style={{ padding: 12 }}>
        {isNL ? "Zweef.App" : "GlidingApp"} {header}
      </h1>

      {Termsmarkdown(content)}
    </div>
  );
};

export const Termsmodal = ({ profile, profileAction }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true);

  const confirmTerms = async () => {
    setLoading(true);
    await profileAction({
      profile: {
        date_gdpr_confirmed: new Date(),
      },
    });
    setLoading(false);
    setVisible(false);
  };

  // show modal if not confirmed or for testing check search bar
  const isVisible =
    visible &&
    ((profile.vliegt && !profile.date_gdpr_confirmed) ||
      window.location.search.includes("privacy"));

  return (
    <Modal
      width="800px"
      title={`${isNL ? "Zweef.App" : "GlidingApp"} ${$t("Privacy mededeling")}`}
      open={isVisible}
      confirmLoading={loading}
      closable={true}
      onCancel={confirmTerms}
      footer={null}
    >
      {Termsmarkdown("privacy")}

      <Divider />

      <div className="column">
        <SluitButton key="sluit"
          type="primary"
          loading={loading}
          onClick={confirmTerms}
          style={{ marginTop: 12 }}
        />
      </div>
    </Modal>
  );
};

function onOKmessage(message, onOk) {
  Modal.success({
    title: message,
    onOk,
  });
}

export const ClubTermsPage = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [agree, setAgree] = useState(false);
  const [name, setName] = useState("");

  const { persist } = useSelector((state) => state);

  const confirmTerms = async () => {
    if (!name) {
      setError($t("Vul je naam in"));
      return;
    }
    if (!agree) {
      setError($t("Ga akkoord met de voorwaarden"));

      return;
    }

    setError(null);
    setLoading(true);

    const res = await api.post("auth/sign_new_club_terms.json", {
      terms_version: TermsVersionNL,
      terms_name: name,
      terms_token: match.params?.token,
    });
    setLoading(false);
    setAgree(null);
    setName("");
    if (res) onOKmessage(res?.message, () => history.push("/"));
  };

  return (
    <div style={{ margin: "auto", maxWidth: 900 }}>
      <h1 style={{ padding: 12 }}>
        {isNL ? "Zweef.App" : "GlidingApp"} {$t("Algemene Voorwaarden")}
      </h1>

      <div
        style={{
          maxHeight: "calc(100vh - 200px)",
          overflowY: "auto",
          marginBottom: 120,
        }}
      >
        {Termsmarkdown("terms")}
      </div>

      <div
        style={{
          borderTop: "solid 1px black",
          backgroundColor: "White",
          width: "100%",
          paddingTop: 5,
          height: 90,
          position: "absolute",
          bottom: 0,
          left: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p className="small alert" style={{ height: 15, color: "red" }}>
          {errorMessage}
        </p>

        <Checkbox
          style={{ marginTop: 4 }}
          checked={agree}
          onChange={(e) => setAgree(e.target.checked)}
        >
          {$t(
            "Ik, als vertegenwoordiger ${persist}, ga akkoord dat met de algemene voorwaarden.",
            {
              persist:
                persist?.club?.name ||
                window.location.hostname.split(".")[0].toUpperCase(),
            }
          )}
        </Checkbox>

        <div className="row">
          <p>{$t("Naam")}: </p>
          <Input
            type="text"
            style={{ width: 250, margin: "6px 18px" }}
            placeholder=""
            value={name}
            onChange={(elm) => setName(elm.target.value)}
          />
          <Button type="primary" loading={loading} onClick={confirmTerms}>
            {$t("Ik ga akkoord")}
          </Button>
        </div>
      </div>
    </div>
  );
};
