import { config as DEV } from './config/development'
import { config as PROD } from './config/production'

class Config {
  mode = 'unknown'
  ws_url = ''
  debugRedux = false
  media_prefix = ''
  client_secret = ''
  sentry_key = ''
  test_users = []
  tenant = ''
  base_backend_url = ''

  constructor() {
    // determine which configuration must be used
    this.isZweef = window.location.href.indexOf('zweef') > -1

    const config = {
      ...this.isLocal ? DEV : PROD,

      // overwrite option if you want to from window
      ...window.zweef || {}
    }

    this.tenant = this.getTenant(window.location)

    // load in defaults on self for direct access
    Object.assign(this, config)

    // determine backend url based on local or production zweef.app/gliding.app
    if (this.isLocal) {
      this.base_backend_url = window.localStorage.base_backend_url || 'http://localhost:2001'
      this.ws_url = 'ws://localhost:2001/websock'
    } else {
      // backend determines on url
      if (this.isZweef) {
        this.base_backend_url = 'https://admin.zweef.app'
        this.ws_url = 'wss://admin.zweef.app/websock'
      } else {
        this.base_backend_url = 'https://admin.gliding.app'
        this.ws_url = 'wss://admin.gliding.app/websock'
      }
    }
  }

  get isLocal() {
    // shortcut, dev.localhost -> production settings on jelzc club
    if (window.location.hostname.startsWith('jelzc.localhost')) return false
    return window.location.hostname.endsWith('localhost')
  }

  get backend_url() {
    return `${this.base_backend_url}/club/${this.tenant}`
  }

  /**
   * add in an avatar prefix so that we can generate the URL based from the user's UUID
   */
  get avatar_prefix() {
    return `${this.media_prefix}${this.tenant}/gegevens/`
  }

  getTenant(location = window.location) {
    const tenant_split = location.hostname.split('.')
    if (tenant_split.length > 1 && tenant_split[0] !== 'localhost') {
      return location.hostname.split('.')[0]
    }
    // Fallback to marketing site
    return 'www'
  }
}

export const config = new Config()
