import { Button, Tag } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'
import { _parseDate, parseMinutes, toLocaleDate } from '../../../lib/localize'
import { ViewFlightModal } from '../../selectedFlight/viewFlight.modal'
import { FISRecencyTable, RecencyTable, TMGRecencyTable } from '../recency'
import { FileExcelOutlined } from '@ant-design/icons'
import { api } from '../../../lib/api'
import './overviewFlights.css'
import { FESRecencyTable } from '../recency.jsx'

export const TotalTypeTable = ({ data, title }) => {
  if (!data) return null

  // generate a lookup for the types and methods, so that we can create a
  // cross table with types per row, and date/methods per start methode
  const type_lookup = {}
  const method_lookup = {}

  data.forEach((row) => {
    // fill out lookups with blank dates
    const desc = row.type || $t('Anders')

    const date = dayjs(row.laatste_vlucht)

    if (!type_lookup[desc]) type_lookup[desc] = { date }
    if (!method_lookup[row.start_methode]) method_lookup[row.start_methode] = date

    // check to see if this date is later then prepopulated, if so overrule
    if (date.isAfter(type_lookup[desc].date)) type_lookup[desc].date = date
    if (date.isAfter(method_lookup[row.start_methode])) method_lookup[row.start_methode] = date

    // add in the number of flights to create a cross table lookup
    type_lookup[desc][row.start_methode] = row.vluchten
  })

  // and now order the methods and type lookups
  const types = Object.keys(type_lookup).sort()
  const methods = Object.keys(method_lookup).sort()

  return (
    <div style={{ marginBottom: 24 }}>
      <p>{title}</p>

      {(!data || data.length === 0 || !types || types.length === 0)
        ? <p className='alert'>{$t('Geen vluchten')}</p>
        : <table className='dataBlock'>
          <tbody>
            <tr style={{ backgroundColor: '#F8F9FA' }}>
              <th>{$t('Kist')}</th>
              <th>{$t('Datum')}</th>
              {methods.map((method, idx) => <th key={idx}>{$t(method)}</th>)}
            </tr>

            <tr style={{ borderBottom: '1pt solid gray' }}>
              <th />
              <th />
              {methods.map((method, idx) => <th style={{ fontWeight: 500 }} key={idx}>{method_lookup[method].format('DD-MM')}</th>)}
            </tr>

            {types && types.map((type, key) => <tr key={key} className='bodyRow'>
              <td className='col'>{type}</td>
              <td className='col'>{type_lookup[type] && type_lookup[type].date ? type_lookup[type].date.format('DD-MM') : ''}</td>

              {methods.map((method, idx) =>
                <td className='col' key={idx}>{type_lookup[type] && type_lookup[type][method] ? type_lookup[type][method] : ''}</td>
              )}
            </tr>)}
          </tbody>
        </table>}
    </div>
  )
}

export const Recency = ({ recency, gezagvoerder, tweede }) => {
  return (
    <div className='overviewFlightTab'>

      {recency && recency.starts >= 0 && <RecencyTable recency={recency} />}

      {recency && recency.tmg_starts >= 0 && <TMGRecencyTable recency={recency} />}

      {recency && recency.fis && <FISRecencyTable recency={recency} />}

      {recency && recency.is_fes && <FESRecencyTable recency={recency} />}

      <TotalTypeTable
        data={gezagvoerder} title={$t('Currency starts laatste 6 maanden, als gezagvoerder:')}
      />
      <TotalTypeTable
        data={tweede} title={$t('Currency starts laatste 6 maanden, als tweede inzittende:')}
      />

    </div>
  )
}

export const LastFlights = ({ flights, pilot }) => {
  const [flight, openFlight] = useState(null)
  const [fileLoading, setFileLoading] = useState(false)
  const downloadFile = async () => {
    setFileLoading(true)
    await api.open_file('flights/export_history.json?format=xlsx&user_id=' + pilot.id, `${$t('vluchten')} ${pilot.name} - ${toLocaleDate(new Date())}.xlsx`)
    setFileLoading(false)
  }

  return (
    <div className='overviewFlightTab'>

      <ViewFlightModal
        onClose={() => openFlight(null)}
        flight={flight}
        currentUser={pilot}
      />

      <Button icon={<FileExcelOutlined />} size='small' loading={fileLoading} onClick={downloadFile}>
        {$t('Download')}
      </Button>

      <table className='dataBlock'>
        <tbody>
          {(!flights || flights === -1)
            ? <tr style={{ marginLeft: 36 }}>
              <td>-</td>
            </tr>
            : flights.map((row, key) => {
              const tag = row.gezagvoerder_id === pilot.id
                ? 'PIC'
                : (
                  <span>
                    {row.gezagvoerder_naam}
                    {row.is_training ? ' (Training)' : ''}
                    {row.is_profcheck ? ' (Prof)' : ''}
                    {row.is_examen ? ' (Examen)' : ''}
                  </span>
                  )
              return (
                <tr className='clickable' key={`${key}`} onClick={() => openFlight(row)}>
                  <td className='col'>{_parseDate(row.datum)}</td>
                  <td className='col onlyWide'>{row.callsign ? row.callsign : row.registratie}</td>
                  <td className='col'>{row.type}</td>
                  <td className='col center'>{tag}</td>
                  <td className={'col center ' + row.start_methode}>{$t(row.start_methode)}</td>
                  <td className='col'>
                    {parseMinutes(row.vluchtduur)}{row.starts > 1 ? ` ${row.starts} starts` : ''}
                    {row.is_overland && <Tag color='green'>{row.afstand > 0 ? (row.afstand + 'km') : $t('Ovl')}</Tag>}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}
