import { QrcodeOutlined } from '@ant-design/icons'
import { Button, Form, Input, Radio, Select } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n'
import { DataCard } from '~/components/dataCards/dataCard'
import { HelpBubble } from '~/components/help/help.bubble'
import { CopyIcon } from '~/components/icon/copy'
import { api } from '~/lib/api'
import { phValidator } from '~/lib/localize'

export const CardGeneral = ({ currentMaterial, saveData, readOnly, kist }) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const saveForm = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      // manipulate values for save
      const payload = {
        ...values,
        kist: kist && {
          category: values?.kist_category,
          pilots: values?.pilots,
          type: values?.type,
        }
      }
      const res = await saveData(payload)
      setLoading(false)
      return res
    } catch (info) {
      console.log('Validate Failed:', info)
      setLoading(false)
    }
  }

  const [downloading, setDownloading] = useState(false)
  const downloadCode = async () => {
    if (downloading) return
    setDownloading(true)
    await api.open_file(`materiaal/qrcode?id=${currentMaterial.id}&label=${currentMaterial.name}`, `QR ${currentMaterial.name}.png`)
    setDownloading(false)
  }

  return (
    <DataCard title={$t('Algemeen')} readOnly={readOnly} onSave={saveForm} loading={loading}>

      <Form
        form={form}
        name='general'
        initialValues={{ ...kist, ...currentMaterial, kist_category: kist?.category }}
        requiredMark={false}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 16 }}
      >

        <Form.Item
          label={$t('Naam')}
          name='name'
          rules={[{ required: true, message: $t('Maximaal ${c} characters', { c: 200 }), max: 200 }]}
        >
          <Input placeholder={$t('Naam van materiaal')} disabled={readOnly} />
        </Form.Item>

        {kist && <Form.Item
          label={$t('Registratie')}
          name='registratie'
          rules={[phValidator]}
                 >
          <Input
            placeholder={$t('registratie nummer')}
            disabled={readOnly}
          />
        </Form.Item>}

        <Form.Item
          label={<p>{$t('Type')} {kist && <HelpBubble content={$t('Onder deze beschrijving zal hij selecteerbaar zijn onder de aanmeldingen en dagelijkse startadministratie.')} />}</p>}
          name='type'
          rules={[{ required: true, message: $t('Maximaal ${c} characters', { c: 200 }), max: 200 }]}
        >
          <Input
            placeholder={$t('Type materiaal')}
            disabled={readOnly}
          />
        </Form.Item>

        {!kist && <Form.Item
          label={$t('Categorie')}
          name='category'
                  >
          <Select disabled={readOnly}>
            <Select.Option key='rollend' value='rollend'>{$t('Rollend')}</Select.Option>
            <Select.Option key='overig' value='overig'>{$t('Overig')}</Select.Option>
          </Select>
        </Form.Item>}

        {kist && <Form.Item
          label={$t('Zitplaatsen')}
          name='pilots'
                 >
          {readOnly
            ? <Input disabled />
            : <Radio.Group disabled={readOnly}>
              <Radio.Button style={{ width: 30 }} value={1}>1</Radio.Button>
              <Radio.Button style={{ width: 30 }} value={2}>2</Radio.Button>
            </Radio.Group>}
        </Form.Item>}

        {kist &&
          <Form.Item
            label={<p>{$t('Beschikbaar')} <HelpBubble content={<div>
              <p>{$t('Bepaald inzetbaarheid door clubleden')}:</p>
              <p><span className='bold'>{$t('Club')}</span>: {$t('Door clubleden worden ingezien en ingezet.')}</p>
              <p><span className='bold'>{$t('Sleep')}</span>: {$t('Door club worden ingezet als sleepkist.')}</p>
              <p><span className='bold'>{$t('Prive')}</span>: {$t('Alleen toegewezen leden hebben toegang.')}</p>
            </div>}
                                          />
            </p>}
            name='kist_category'
            rules={[{ required: true, message: $t('Selecteer beschikbaarheid') }]}
          >
            <Select disabled={readOnly}>
              <Select.Option value='club'>{$t('Club')}</Select.Option>
              <Select.Option value='sleep'>{$t('Sleep')}</Select.Option>
              <Select.Option value='prive'>{$t('Prive')}</Select.Option>
            </Select>
          </Form.Item>}

        <Form.Item
          label={<p>{$t('Reserveerbaar')} <HelpBubble content={$t('Kan door leden gereserveerd worden in de app')} /></p>}
          name='is_reservable'
          rules={[{ required: true, message: $t('Selecteer waarde') }]}
        >
          <Select disabled={readOnly}>
            <Select.Option value>{$t('Ja')}</Select.Option>
            <Select.Option value={false}>{$t('Nee')}</Select.Option>
          </Select>
        </Form.Item>

        {!readOnly && <Form.Item
          label={<p>{$t('Opmerking')} <HelpBubble content={$t('Bericht zichtbaar voor alle leden.')} /></p>}
          name='remark'
          rules={[{ required: false, max: 700, message: $t('Maximaal ${c} characters', { c: 700 }) }]}
                      >
          <Input.TextArea rows={3} disabled={readOnly} />
        </Form.Item>}

        {!readOnly && <Form.Item label={<p>{$t('QR code')} <HelpBubble content={$t('De QR code is een unieke code waarmee je direct de actuele status van het materiaal kan inzien. Ook vliegers die geen toegang hebben tot de app kunnen hiermee de status van de kist inspecteren voordat ze ermee gaan vliegen.')} /></p>}>
          <Button
            icon={<QrcodeOutlined />} size='small' style={{ marginRight: 12 }}
            loading={downloading} onClick={downloadCode}
          >{$t('Download')}
          </Button>
          <div className='small row' style={{ gap: 6, marginTop: 6 }}>{$t('Kopieer')} link: <CopyIcon text={`https://${window.location.host}/status/material/${currentMaterial.uuid}`} /></div>

        </Form.Item>}

      </Form>

    </DataCard>
  )
}
