import { $t } from '~/i18n.js'
import React from 'react'
import { Badge, Button, List, Modal } from 'antd'
import { CheckCircleTwoTone, CheckOutlined, CloseCircleTwoTone, CloseOutlined, StarOutlined, StarTwoTone, UnorderedListOutlined } from '@ant-design/icons'
import { colors } from '../../../theme/colors'

export const RenderAvailibility = ({ availability }) => {
  if (availability === 'empty') return null
  if (availability === 'star') return <StarTwoTone twoToneColor={colors.primary_light} />
  if (availability === 'z_no') return <CloseCircleTwoTone twoToneColor={colors.rood} />
  return <CheckCircleTwoTone twoToneColor={colors.groen} />
}

const RenderUserPreference = ({ preference }) => {
  return (
    <div className='row' style={{ gap: 4, alignItems: 'flex-start' }}>

      <RenderAvailibility availability={preference.availability} />

      <div>
        <p>{preference.user_name}

        </p>
        {preference.remark && <p className='small'>{preference.remark}</p>}
      </div>
    </div>
  )
}

const showPreferences = (preferences) => {
  return Modal.info({
    title: $t('Opgegeven voorkeuren'),
    content: (
      <div style={{ maxHeight: 240, overflowY: 'auto' }}>
        <List
          size='small'
          dataSource={preferences}
          renderItem={preference => <List.Item><RenderUserPreference preference={preference} /></List.Item>}
        />
      </div>
    ),
    onOk () {
    }
  })
}

export const SocialSelect = ({ data, saveAvailability }) => {
  const SocialBadge = ({ data, icon, availability }) => {
    return (
      <Badge size='small' color={colors.gray} count={data ? data.preferences.filter(f => f.availability === availability).length : 0}>
        <Button
          type={data?.availability === availability ? 'primary' : 'default'}
          size='small' shape='circle' icon={icon} onClick={() => saveAvailability(availability)}
        />
      </Badge>
    )
  }

  return (
    <div className='row' style={{ gap: 4 }}>

      <SocialBadge
        availability='star'
        data={data}
        icon={<StarOutlined />}
      />
      <SocialBadge
        availability='yes'
        data={data}
        icon={<CheckOutlined />}
      />
      <SocialBadge
        availability='z_no'
        data={data}
        icon={<CloseOutlined />}
      />
      {data !== null && <Button
        type='link'
        disabled={data.preferences.length === 0}
        size='small' shape='circle' icon={<UnorderedListOutlined />}
        onClick={() => showPreferences(data.preferences)}
                        />}

    </div>
  )
}
