import { Flex, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";
import { HelpBubble } from "../help/help.bubble.jsx";
import { SwitchYesNo } from "./SwitchYesNo.jsx";

const otherString = ",other";

export function SelectCustomCategoryList({
  title,
  hasOther,
  listValues,
  onChange,
}) {
  const [listItems, setListItems] = useState([]);
  useEffect(() => {
    setListItems(listValues);
  }, []);

  return (
    <Flex gap={6} vertical={true}>
      <Flex gap={12} align="center">
        <p>{$t("Naam")}:</p>
        <Input
          value={title}
          onChange={(e) => onChange(e.target.value, listValues, hasOther)}
        />
      </Flex>
      <Flex gap={12} align="center">
        <p>{$t("Waarden")}:</p>

        <Select
          mode="tags"
          value={listValues}
          onChange={(newList) => onChange(title, newList, hasOther)}
        >
          {listItems?.map((item, idx) => (
            <Select.Option key={idx} value={item.trim()}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Flex>

      <Flex gap={12} align="center">
        <p>
          {$t("Bevat anders")}{" "}
          <HelpBubble
            content={$t("Gebruiker kan een eigen andere waarde intypen")}
          />
          :
        </p>
        <SwitchYesNo
          value={hasOther}
          onChange={(newHasOther) => onChange(title, listValues, newHasOther)}
        />
      </Flex>
    </Flex>
  );
}

export function SelectCustomCategoryListFromCSV({
  id = "",
  value = "",
  onChange = (e) => {},
}) {
  const selectedValue = value ? value.split(",") : [];

  const hasOther = value && value.endsWith(otherString);
  const title = selectedValue.length > 0 ? selectedValue[0] : "";
  let listValues =
    selectedValue.length <= 1
      ? []
      : selectedValue.slice(1, selectedValue.length - (hasOther ? 1 : 0));

  // console.log({ value, selectedValue, hasOther, title, listValues });

  const save = (title, values, hasOther) => {
    let newValue = title || "";

    if (values.length > 0) {
      newValue += "," + values.join(",");
    }
    if (hasOther) {
      newValue += ",other";
    }
    onChange(newValue);
  };

  return (
    <SelectCustomCategoryList
      title={title}
      hasOther={hasOther}
      listValues={listValues}
      onChange={save}
    />
  );
}
