import { $t } from '~/i18n.js'
import React from 'react'
import { useDispatch } from 'react-redux'

import { DataCard } from '../../../components/dataCards/dataCard'
import { Bestanden } from '../../../components/profile/tabs/bestanden'

export const CardBestanden = ({ profile, bestanden }) => {
  const dispatch = useDispatch()

  return (
    <DataCard title={$t('Bestanden')} readOnly={true}>

      <Bestanden
        bestanden={bestanden}
        pilot={profile}
        onAddBestand={(bestanden) => dispatch({
          type: 'SET_PROFILE_FILES',
          bestanden
        })}
      />

    </DataCard>
  )
}
