
export function parseCsv (text = '', seperator = ',') {
    // to json format, list of dictionaries
    const rows = text.split(/\r\n|\n/) || []
    const headers = rows[0].split(seperator)
    // remove first array
    rows.shift()
    const body = rows
    const lines = []
  
    if (!body) {
      return []
    }
  
    body.forEach((line) => {
      const data = line.split(seperator)
  
      if (data.length === headers.length) {
        // default csv import with uuid field, will overwrite if it contains in csv
        // reason is for backend sentry parsing of unclear data format errors
        const row = {
          _meta: {
            headers,
            line
          }
        }
        
        headers.forEach((header, j) => {
          row[header] = data[j]
        })
        lines.push(row)
      }
    })
    return lines
  }
  