import { Button } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

export const AnnuleerButton = (args) => {
  return (
    <Button {...args}>
      {$t("Annuleren")}
    </Button>
  );
};
