import { MessageOutlined, ToolOutlined } from '@ant-design/icons'
import { Button, Tag } from 'antd'
import React from 'react'
import { $t } from '~/i18n.js'
import { MaintenanceStatusTag } from '../../materialTags'

const Interval = ({ maintenance, label, unit, units, next_unit, field_prefix }) => {
  if (!maintenance[field_prefix + '_status']) return null
  return (
    <div>
      <div className='row' style={{ gap: 3 }}>
        <p>{label}:</p>
        <MaintenanceStatusTag maintenance_status={maintenance[field_prefix + '_status']} hideTag />
        <p>{maintenance[field_prefix + '_remain']} {maintenance[field_prefix + '_remain'] === 1 ? unit : units} {$t('over')}</p>
      </div>
      <p>{$t('Volgende')} {field_prefix === 'date' ? $t('op') : $t('bij')}: {maintenance[field_prefix + '_next']} {next_unit || ''}</p>
    </div>
  )
}

export const MaintenanceCard = ({ maintenance, openWorkorder }) => {
  return (
    <div style={{ padding: 12 }}>
      <p>{maintenance.category === 'arc' && <Tag>ARC</Tag>} {maintenance.description}</p>
      {maintenance.remark && <p><MessageOutlined /> {maintenance.remark}</p>}

      {openWorkorder && maintenance.workorder_id && <p className='clickable' onClick={() => openWorkorder(maintenance.workorder_id)} style={{ marginTop: 12 }}>
        <ToolOutlined style={{ marginRight: 6 }} />
        {$t('Workorder actief')}:
        <Button size='small' style={{ marginLeft: 6 }}>{$t('open')}</Button>
                                                    </p>}

      <div className='row' style={{ gap: 32, marginTop: 12 }}>
        <Interval
          maintenance={maintenance}
          label={$t('Datum')}
          unit={$t('dag')}
          units={$t('dagen')}
          field_prefix='date'
        />
        <Interval
          maintenance={maintenance}
          label={$t('Starts')}
          unit={$t('start')}
          units={$t('starts')}
          next_unit={$t('starts')}
          field_prefix='starts'
        />
        <Interval
          maintenance={maintenance}
          label={$t('Uren')}
          unit={$t('uur')}
          units={$t('uren')}
          next_unit={$t('uren')}
          field_prefix='hours'
        />
        <Interval
          maintenance={maintenance}
          label={$t('Motoruren')}
          unit={$t('motoruur')}
          units={$t('motoruren')}
          next_unit={$t('motoruren')}
          field_prefix='motor_hours'
        />
      </div>
    </div>
  )
}
