import { DeleteFilled, DeleteOutlined } from '@ant-design/icons'
import { Button, Input, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { $t } from '~/i18n.js'

import { useSelector } from 'react-redux'
import { defaultTableConfig } from '../../../../components/layout/table'
import { CardBase } from '../../../../components/timeLine/cardBase'

export const CardRooster = ({ users, data, saveAndNext, prev }) => {
  const [roosters, setRoosters] = useState([])
  useEffect(() => {
    setRoosters(data.roosters)
  }, [data.roosters, setRoosters])

  const { group_name_lookup, all_group_keys } = useSelector((state) => state.flights)

  const handleSave = () => {
    saveAndNext({ roosters })
  }
  console.log('... rooster: ', roosters)
  return (
    <CardBase prev={prev} next={handleSave}>

      <Button
        type='primary' style={{ width: 180 }} className='spacer'
        onClick={() => {
          // give roosters a unique negative value, must be new otherwise key is same
          setRoosters(roosters.concat([{ id: -1 * Date.now(), description: '', group_key: 'instructeur', autofill: 'blank' }]))
        }}
      >
        {$t('Voeg dienst toe')}
      </Button>

      <Table
        dataSource={roosters}
        rowKey='id'
        style={{ width: '100%', overflowX: 'auto' }}
        pagination={defaultTableConfig}
        size='small'
        columns={[
          {
            title: $t('Beschrijving'),
            dataIndex: 'description',
            render: (value, row, index) =>
              <Input
                value={value}
                disabled={row.delete}
                onChange={(event) => {
                  const newRoosters = [...roosters]
                  newRoosters[index].description = event.target.value
                  setRoosters(newRoosters)
                }}
              />
          },
          {
            title: $t('Groep'),
            dataIndex: 'group_key',
            width: 200,
            render: (value, row, index) =>
              <Select
                disabled={row.delete}
                value={value} style={{ width: '100%' }} onChange={(value) => {
                  const newRoosters = [...roosters]
                  newRoosters[index].group_key = value
                  setRoosters(newRoosters)
                }}
              >
                {all_group_keys.map((group, idx) => <Select.Option key={idx} value={group}>{group_name_lookup[group] || group}</Select.Option>)}
              </Select>
          },

          {
            title: $t('Uitsluiten'),
            dataIndex: 'exclude_users',
            width: 80,
            render: (value, row, index) =>

              <Select
                mode='multiple'
                disabled={row.delete}
                filterOption={(input, option) => {
                  return option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
                value={value} style={{ width: '100%' }} onChange={(newID) => {
                  const newRoosters = [...roosters]
                  newRoosters[index].exclude_users = newID
                  setRoosters(newRoosters)
                }}
              >
                {users?.filter(u => u.group_keys.indexOf(row.group_key) > -1).map((user, key) => <Select.Option value={user.id} key={key}>{user.name}</Select.Option>)}
              </Select>
          },

          {
            title: $t('Autofill'),
            dataIndex: 'autofill',
            width: 80,
            render: (value, row, index) =>

              <Select
                disabled={row.delete}
                value={value} style={{ width: '100%' }} onChange={(value) => {
                  const newRoosters = [...roosters]
                  newRoosters[index].autofill = value
                  setRoosters(newRoosters)
                }}
              >
                <Select.Option key={0} value='blank'>{$t('Leeg')}</Select.Option>
                <Select.Option key={1} value='autofill'>{$t('Auto')}</Select.Option>
                <Select.Option key={2} value='social'> {$t('Sociaal')}</Select.Option>
              </Select>
          },

          {
            render: (_, row, index) =>
              <Button
                shape='circle' onClick={() => {
                  if (row.id > 0) {
                    const newRoosters = [...roosters]
                    newRoosters[index].delete = !row.delete
                    setRoosters(newRoosters)
                  } else {
                    setRoosters(roosters.filter((d) => d.id !== row.id))
                  }
                }}
              >
                {row.delete ? <DeleteFilled /> : <DeleteOutlined />}
              </Button>
          }
        ]}
      />

      <p className='small'><span className='bold'>{$t('Groep')}: </span> {$t('De groep leden die deze dienst mogen invullen/ruilen.')} </p>

      <p className='small' style={{ marginTop: 6 }}>{$t('Methodes om diensten te vullen:')}</p>
      <p className='small'><span className='bold'>{$t('Leeg')} </span> {$t('Laat de dienst leeg, handmatig invullen.')}</p>
      <p className='small'><span className='bold'>{$t('Auto')} </span> {$t('Automatisch invullen, verdeel de leden zo goed mogelijk over de diensten.')}</p>
      <p className='small'><span className='bold'>{$t('Sociaal')} </span> {$t('Vraag leden voor hun voorkeur, en verdeel de diensten zo goed mogelijk over de voorkeuren.')}</p>

    </CardBase>
  )
}
