import { StopOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Popconfirm } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'

export const CloseDay = ({ dag_id, canOpen, canClose, updateStatus, getFlights }) => {
  const [savingStatus, setSavingStatus] = useState(false)

  const [showConfirm, setConfirm] = useState(false)
  const [confirmText, setConfirmText] = useState('')

  const sluitMsg = $t('sluit dag')

  const forceClose = (invalidFlights, confirm) => {
    Modal.confirm({
      title: $t('Er zijn ongeldige vluchten gevonden'),
      icon: <StopOutlined />,
      content: <div>
        <p>{$t('De volgende vluchten bevatten fouten: ')}</p>
        <div style={{ maxHeight: 150, overflowY: 'auto' }}>
          {invalidFlights.map((t, idx) => <p key={idx}>{t}</p>)}
        </div>
      </div>,
      okText: $t('Sluit dag'),
      okType: 'danger',
      cancelText: $t('Terug'),
      onCancel () {
        return getFlights({ dag_id })
      },
      onOk () {
        return confirm()
      }
    })
  }

  const triggerUpdateStatus = async (data) => {
    setSavingStatus(true)
    const newDay = await updateStatus({ ...data, dag_id })

    if (newDay && newDay.invalidFlights) {
      forceClose(newDay.invalidFlights, () => setConfirm(true))
    } else {
      await getFlights({ dag_id })
    }

    setSavingStatus(false)
  }

  return (
    <>

      <Modal
        title={$t('Bevestig')}
        open={showConfirm}
        confirmLoading={savingStatus}
        onCancel={() => {
          setConfirm(false)
          setConfirmText('')
        }}
        onOk={
             async () => {
               await triggerUpdateStatus({ action: 'closed', force: true })
               setConfirm(false)
               setConfirmText('')
               await getFlights({ dag_id })
             }
           }
        okButtonProps={{ disabled: confirmText !== sluitMsg }}
      >

        <div className='column'>

          <p><span className='bold'>{$t('Let op')}</span>, {$t('je gaat de dag afsluiten terwijl er ongeldige vluchten zijn gevonden')}.</p>

          <p>{$t('Om te bevestigen dat je dit bewust doet, schrijf')} <span className='bold'>{sluitMsg}</span> {$t('in het input hieronder')}.</p>

          <Input
            type='text' style={{ width: 150, margin: '10px auto' }} placeholder={sluitMsg}
            value={confirmText}
            onChange={(elm) => setConfirmText(elm.target.value)}
          />

        </div>
      </Modal>

      {canClose && (
        <Popconfirm
          placement='bottom' title={$t('Zeker weten dat je de dag wilt afsluiten?')}
          onConfirm={() => triggerUpdateStatus({ action: 'closed', force: false })}
          okText={$t('Ja')} cancelText={$t('Nee')}
        >
          <Button size='small' icon={<StopOutlined />} loading={savingStatus} style={{ marginLeft: 6 }}>
            {$t('Afsluiten')}
          </Button>
        </Popconfirm>
      )}

      {canOpen && (
        <Popconfirm
          placement='bottom' title={$t('Zeker weten dat je de dag wilt openen?')}
          onConfirm={() => triggerUpdateStatus({ action: 'open' })}
          okText={$t('Ja')} cancelText={$t('Nee')}
        >
          <Button size='small' loading={savingStatus} style={{ marginLeft: 6 }}>
            {$t('Heropen')}
          </Button>
        </Popconfirm>
      )}
    </>
  )
}
