import { $t } from '~/i18n.js'
import React, { useState } from 'react'
import { api } from '../../../lib/api'
import { colors } from '../../../theme/colors'
import { Button, Popconfirm, Tag, Tooltip } from 'antd'
import { CheckOutlined, EditOutlined, StopOutlined } from '@ant-design/icons'

export const RegistratieStatus = ({ periodState, registratie, openRegistratie, magGoedkeuren }) => {
  const [loading, setLoading] = useState(null)
  const [newState, setNewState] = useState(null)

  const saveStatus = async (newStatus) => {
    setLoading(newStatus)

    const payload = { id: registratie.id, status: newStatus }
    const res = await api.post('onderhoud/beroordeel_registratie.json', payload)
    if (res) {
      setNewState(res.status)
    }
    setLoading(false)
  }
  const state = newState || registratie.status

  return (
    <div className='row'>
      {registratie.beoordelaar_name
        ? <Tooltip placement='top' title={$t('${registername} op ${registerdate}', { registername: registratie.beoordelaar_name, registerdate: registratie.date_beoordeeld && registratie.date_beoordeeld.split('T')[0] })}>
          <Tag>{state}</Tag>
        </Tooltip>
        : <Tag>{state}</Tag>}

      {periodState !== 'gesloten' && <>
        {openRegistratie ? (
          <>
            {state === 'in_aanvraag' && (
              <Button
                type='primary' size='small'
                style={{ marginLeft: 4 }}
                onClick={() => openRegistratie(registratie)}
                icon={<EditOutlined />}
              >
                {$t('Wijzig')}
              </Button>
            )}
          </>
        )
          : (
            <>
              {/* If no openRegistratie found then you can accept the hours */}
              {state !== 'geaccepteerd' && magGoedkeuren && (
                <Button
                  type='primary' size='small'
                  style={{ marginLeft: 4 }}
                  onClick={() => saveStatus('geaccepteerd')}
                  loading={loading === 'geaccepteerd'}
                  icon={<CheckOutlined />}
                />
              )}

              {state !== 'afgewezen' && magGoedkeuren && (
                <Popconfirm
                  placement='bottom' title={$t('Zeker weten dat je deze uren wilt afwijzen?')}
                  onConfirm={() => saveStatus('afgewezen')}
                  okText={$t('Ja')} cancelText={$t('Nee')}
                >
                  <Button
                    danger ghost shape='circle' className='revokeButton'
                    style={{ marginLeft: 4 }}
                    loading={loading === 'afgewezen'}
                    icon={<StopOutlined style={{ color: colors.rood }} />} size='small'
                  />
                </Popconfirm>
              )}
            </>
            )}
      </>}
    </div>
  )
}
