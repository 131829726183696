import { AutoComplete } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

export const CountryCodeSelect = (props) => (
  <AutoComplete
    {...props}
    options={[
      { value: "NL", label: <>NL ({$t("Nederland")})</> },
      { value: "BE", label: <>BE ({$t("België")})</> },
      { value: "FR", label: <>FR ({$t("Frankrijk")})</> },
      { value: "UK", label: <>UK ({$t("Engeland")})</> },
      { value: "DE", label: <>DE ({$t("Duitsland")})</> },
    ]}
  />
);
