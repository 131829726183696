import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { $t } from '~/i18n.js'

import { PlusOutlined } from '@ant-design/icons'
import { Button, Select, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { defaultTableConfig } from '../../../../components/layout/table'
import { selectElementByID, updateIfNeeded } from '../../../../lib/helpers'
import { getPeriod } from '../../../../redux/onderhoud/onderhoud.actions'
import { RegistratieForm } from '../../../onderhoud/registratie.form'
import { registratieKolommen } from '../../../onderhoud/section/columns.onderhoud.uren'

export const MaterialHours = ({ history, currentMaterial, currentPeriodID, readOnly }) => {
  const dispatch = useDispatch()
  const { onderhoud, persist } = useSelector((state) => state)
  const magGoedkeuren = ['technician', 'arc_technician', 'admin', 'manager'].indexOf(currentMaterial.current_role) > -1

  useEffect(() => {
    updateIfNeeded(onderhoud.lastUpdated, () => dispatch(getPeriod()))
  }, [onderhoud.lastUpdated, dispatch])

  if (!currentPeriodID) {
    currentPeriodID = onderhoud.periods?.filter(p => p.status === 'actief')[0]?.id
  }
  const currentPeriod = selectElementByID(onderhoud.periods, 'id', parseInt(currentPeriodID))

  const [activeRegistratie, setActiveRegistratie] = useState(null)

  return (
    <div>

      {activeRegistratie && <RegistratieForm
        open={!!activeRegistratie}
        registratie={activeRegistratie}
        showAllUsers={false}
        closeModal={() => setActiveRegistratie(null)}
                            />}

      <div className='row' style={{ justifyContent: 'space-between', flexWrap: 'wrap', gap: 12 }}>

        <div className='row' style={{ gap: 12 }}>
          <span>{$t('Periode')}:</span>

          <Select
            mode='single' size='small' value={currentPeriodID ? parseInt(currentPeriodID) : null}
            onChange={value => history.push(`/material/${currentMaterial.id}/hours/${value}`)}
          >
            {onderhoud.periods?.map((period, key) => <Select.Option value={period.id} key={key}>{period.naam} ({period.status})</Select.Option>)}
          </Select>
        </div>

        <Button
          icon={<PlusOutlined />} onClick={() => setActiveRegistratie({
            material_id: currentMaterial.id,
            date: dayjs().format('YYYY-MM-DD')
          })} size='small' type='default'
        >
          {$t('Voeg toe')}
        </Button>

      </div>

      <Table
        style={{ marginTop: 12, width: '97vw', cursor: 'pointer' }}
        pagination={defaultTableConfig}
        size='small'
        rowKey='id'
        dataSource={currentMaterial.hours?.hours}
        columns={registratieKolommen(currentPeriod?.status, null, true, magGoedkeuren)}
        summary={rows => {
          if (rows.length === 0) return

          let totalHours = 0
          rows.forEach(r => totalHours += r.uren)

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={2}>{$t('Totaal')}:</Table.Summary.Cell>
              <Table.Summary.Cell colSpan={1}>
                {totalHours}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )
        }}
      />

    </div>
  )
}
