import { Descriptions } from 'antd'
import React from 'react'
import { $t } from '~/i18n.js'
import { _parseDate } from '../../../lib/localize'

export const PeriodStats = ({ period }) => {
  return (
    <>
      {period.remark && <p>{period.remark}</p>}
      <Descriptions size='small' row={1}>
        <Descriptions.Item label={$t('Status')}>{period.status}</Descriptions.Item>
        <Descriptions.Item label={$t('Totaal uren')}>{period.uren_budget}</Descriptions.Item>
        <Descriptions.Item label={$t('Gemaakte uren')}>{parseInt(period.uren_gemaakt)} ({period.uren_gemaakt <=0 || period.uren_budget <=0 ? '0' : parseInt(period.uren_gemaakt / period.uren_budget * 100)}%)</Descriptions.Item>
      </Descriptions>
      {period.updated_date && <p className='small spacer'>{(`${$t('Laatste wijziging')}: ${period.updated_name} op ${_parseDate(period.updated_date.split('T')[0])}`)}</p>}
    </>
  )
}
