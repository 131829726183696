import { PlusOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import useMedia from 'use-media'
import { $t } from '~/i18n.js'

import { MainLayout } from '~/components/layout/layout.jsx'
import { updateIfNeeded } from '~/lib/helpers.js'
import { daysActions, flightsActions } from '~/redux/actions.js'

import { defaultTableConfig } from '~/components/layout/table.jsx'
import { AddDayModal } from '../addDay/addDayModal.jsx'
import { dagenColumns, dagenMobileColumns } from './dagen.columns.jsx'
import { FlightOperationsModal } from './flightOperationsModal.jsx'
import { StartwagenWachtwoord } from './startwagen.wachtwoord.jsx'

const DagOverzichtComponent = ({ persist, history, days, flights, getAllDays, getBaseData, saveDay, title, link_to }) => {
  const [modalOpen, setmodalOpen] = useState(false)
  const isWide = useMedia({ minWidth: '940px' }, true)

  useEffect(() => {
    updateIfNeeded(days.lastUpdated, getAllDays)
  }, [days.lastUpdated, getAllDays])

  useEffect(() => {
    updateIfNeeded(flights.lastUpdatedBaseData, getBaseData)
  }, [flights.lastUpdatedBaseData, getBaseData])

  const today = days.allDays.filter(d => d.is_today)

  const toggleModal = () => {
    setmodalOpen(!modalOpen)
  }

  const handleAddNew = async (data) => {
    setmodalOpen(false)
    const dag = await saveDay(data)
    dag && history.push(`/${link_to}/${dag.dag_id}`)
  }

  const columns = isWide ? dagenColumns(days.totals, days.mijn_aanmeldingen, days.mijn_diensten, link_to) : dagenMobileColumns(days.totals, days.mijn_aanmeldingen, days.mijn_diensten, link_to)

  return (
    <MainLayout history={history}>

      <AddDayModal
        link_to={link_to}
        visible={modalOpen}
        onOk={handleAddNew}
        onCancel={toggleModal}
        confirmLoading={days.isPending}
        club={persist.club}
      />

      <div className='row' style={{ justifyContent: 'space-between', minHeight: 35 }}>
        <p className='title'>{$t(title)}</p>

        <div className='row'>
          {link_to === 'start' && persist.club && persist.club.schema_name === 'acvz' && <FlightOperationsModal />}

          {link_to === 'start' && persist.profile.ziet_staff && <StartwagenWachtwoord />}

          {today.length > 0 && link_to === 'tow' && <Button style={{ marginRight: 4 }} onClick={() => history.push('/tow/today')}>
            {$t('naar vandaag')}
          </Button>}

          {(link_to === 'register' || link_to == 'start') && <Button type='primary' icon={<PlusOutlined />} onClick={toggleModal}>
            {$t('Nieuwe dag')}
          </Button>}

        </div>

      </div>

      <Table
        loading={days.isPending}
        style={{ marginTop: 12, width: '100%', overflowX: 'auto' }}
        rowKey='dag_id'
        pagination={defaultTableConfig}
        dataSource={link_to === 'register'
          ? days.allDays.filter((day) => day.can_aanmeld).sort((a, b) => a.date - b.date)
          : days.allDays.filter((day) => day.can_start && (link_to === 'dailyreport' ? true : day.is_vliegend))}
        className='table--clickable'
        size='small'
        columns={columns}
        onRow={(row) => {
          return {
            onClick: () => history.push(`/${link_to}/${row.dag_id}`)
          }
        }}
      />

    </MainLayout>
  )
}

const mapStateToProps = (state) => ({
  flights: state.flights,
  registratie: state.registratie,
  days: state.days,
  persist: state.persist
})

const mapDispatchToProps = (dispatch) => {
  return {
    saveDay: (d) => dispatch(daysActions.saveDay(d)),
    getAllDays: (d) => dispatch(daysActions.getAllDays(d)),
    getBaseData: (d) => dispatch(flightsActions.getBaseData(d))
  }
}

export const DagOverzichtPagina = connect(mapStateToProps, mapDispatchToProps)(DagOverzichtComponent)
