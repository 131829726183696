import {
  AppstoreAddOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
  PrinterOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Button, Card, Popconfirm, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BreadcrumbHeader } from "~/components/layout/breadcrumbHeader.jsx";
import { MainLayout } from "~/components/layout/layout.jsx";
import { defaultTableConfig } from "~/components/layout/table.jsx";
import { UserModal } from "~/components/profile/userModal.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { updateIfNeeded } from "~/lib/helpers.js";
import { _parseDateTime, toLocaleDate } from "~/lib/localize.js";
import {
  aanmeldingenActions,
  daysActions,
  flightsActions,
} from "~/redux/actions.js";
import { AddDayModal } from "../addDay/addDayModal.jsx";
import { AanmeldForm } from "./aanmeld.form.jsx";
import { AanmeldTableColumns } from "./aanmeldLijst.columns.jsx";
import "./aanmeldLijst.css";
import { CardKisten } from "./card.kisten.jsx";
import { CardRollen } from "./card.rollen.jsx";
import { CardRooster } from "./card.rooster.jsx";
import { MessageCard } from "./messageCard.jsx";

export const AanmeldLijstComponent = ({
  history,
  match,
  kist_aanmelding,
  club,
  profile,
  aanmeldingen,
  flights,
  days,
  saveDay,
  getBaseData,
  getAanmeldingen,
  saveAanmelding,
  getAllDays,
  openDay,
}) => {
  // setup base data
  useEffect(() => {
    updateIfNeeded(flights.lastUpdatedBaseData, getBaseData);
  }, [flights.lastUpdatedBaseData, getBaseData]);
  useEffect(() => {
    updateIfNeeded(
      days.lastUpdated,
      getAllDays,
      () => days.lastUpdated && openDay(match.params.id)
    );
  }, [days.lastUpdated, getAllDays, match.params.id, openDay]);
  useEffect(() => {
    const fetchData = async () => {
      const resp = await getAanmeldingen({
        dag_id: match.params.id,
        normalizeAsVliegers: false,
      });
      if (!resp) history.push("/register");
    };
    fetchData();
  }, [match.params.id, getAanmeldingen, history]);

  // load in data
  const [loading, setLoading] = useState(false);
  const [openedPilot, setOpenPilot] = useState(null);
  const [openModal, setmodalOpen] = useState();
  const [activeAanmelding, setActiveAanmelding] = useState(-1);

  const editAanmelding = (aanmelding) => {
    setActiveAanmelding(aanmelding);
    setmodalOpen("aanmeld");
  };

  const handleSaveDay = (data) => {
    setmodalOpen(null);
    saveDay(data);
  };

  const afmelden = async (lid) => {
    setLoading(true);
    await saveAanmelding({
      action: "meld_af",
      dag_id: days.activeDay.dag_id,
      lid,
    });
    setLoading(false);
  };

  // get current aanmelding and aanmelding of user above for quick reference
  let aanmeldingenLijst = [];
  let currentAanmelding =
    aanmeldingen.aanmeldingen &&
    aanmeldingen.aanmeldingen.filter(
      (aanmelding) => aanmelding.vlieger && aanmelding.vlieger.id === profile.id
    );
  if (currentAanmelding && currentAanmelding.length > 0) {
    currentAanmelding = currentAanmelding[0];
    aanmeldingenLijst = [currentAanmelding].concat(
      aanmeldingen.aanmeldingen.filter(
        (aanmelding) =>
          aanmelding.vlieger && aanmelding.vlieger.id !== profile.id
      )
    );
  } else {
    currentAanmelding = null;
    aanmeldingenLijst = aanmeldingen.aanmeldingen;
  }

  // concat all unique types
  const types = flights.club_kisten
    .reduce((all, kist) => {
      const value = kist.type_group || kist.type;
      value && !all.includes(value) && all.push(value);
      return all;
    }, [])
    .sort();

  return (
    <MainLayout
      history={history}
      isLoading={days.isPending || aanmeldingen.isPending}
    >
      <AddDayModal
        link_to={null}
        visible={openModal === "day"}
        onOk={handleSaveDay}
        onCancel={() => setmodalOpen(null)}
        confirmLoading={days.isPending}
        activeDay={days.activeDay}
        club={club}
      />

      {activeAanmelding !== -1 && (
        <AanmeldForm
          visible={openModal === "aanmeld"}
          activeAanmelding={activeAanmelding}
          closeModal={() => {
            setmodalOpen(null);
            setActiveAanmelding(-1);
          }}
          saveAanmelding={saveAanmelding}
          profile={profile}
          activeDay={days.activeDay}
          types={types}
          vliegers={flights.vliegers}
          kist_aanmelding={profile.vliegt && kist_aanmelding}
        />
      )}

      <UserModal
        visible={openedPilot && openedPilot.id}
        onClose={() => setOpenPilot(null)}
        pilot={openedPilot}
      />

      <div
        className="row"
        style={{
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignContent: "center",
        }}
      >
        <BreadcrumbHeader
          breadcrumbs={[
            <Link key='1' to="/aanmelden" className="title">
              {$t("Aanmeldlijst")}
            </Link>,
            <p key='2' style={{ display: "flex" }}>
              {toLocaleDate(new Date(days.activeDay.datum))}
            </p>,
          ]}
        />

        <div className="row" style={{ flexWrap: "wrap", gap: 6 }}>
          {profile.zichzelf_aanmelden && (
            <>
              {currentAanmelding && currentAanmelding.aangemeld ? (
                <>
                  <Tooltip placement="top" title={$t("Pas aan")}>
                    <Button
                      type="primary"
                      onClick={() => editAanmelding(currentAanmelding)}
                      icon={<EditOutlined />}
                    >
                      {$t("Edit")}
                    </Button>
                  </Tooltip>
                  <Popconfirm
                    placement="bottom"
                    title={$t("Zeker weten dat je wilt afmelden?")}
                    onConfirm={() => afmelden()}
                    okText={$t("Ja")}
                    cancelText={$t("Nee")}
                  >
                    <Button
                      loading={loading}
                      danger
                      icon={<CloseOutlined />}
                      onClick={() => {}}
                    >
                      {$t("Meld af")}
                    </Button>
                  </Popconfirm>
                </>
              ) : (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => editAanmelding(currentAanmelding || {})}
                >
                  {$t("Meld aan")}
                </Button>
              )}
            </>
          )}
          {profile.iedereen_aanmelden && (
            <Button icon={<PlusOutlined />} onClick={() => editAanmelding({})}>
              {$t("Meld lid aan")}
            </Button>
          )}
          {days.activeDay.can_start && (
            <>
              <Button
                icon={<PrinterOutlined />}
                href={`/aanmelden/print/${match.params.id}`}
                target="_blank"
              >
                {$t("print")}
              </Button>

              {club.schema_name === "acvz" && (
                <Button
                  icon={<AppstoreAddOutlined />}
                  href={`https://startleider.app/load/zweefapp/${
                    days.activeDay.dag_id
                  }?club=${club.schema_name}&token=${api.getToken()}`}
                  target="_blank"
                >
                  Startleider.app
                </Button>
              )}
            </>
          )}
          <Button onClick={() => setmodalOpen("day")} icon={<EditOutlined />}>
            {$t("Dag aanpassen")}
          </Button>
        </div>
      </div>

      <div
        className="column"
        style={{ marginTop: 6, alignItems: "flex-start" }}
      >
        {!days.activeDay.gaat_door && (
          <Tag style={{ marginRight: 6 }} color="red">
            {$t("Geannuleerd")}
          </Tag>
        )}
        <p className="title">
          {days.activeDay.type_dag}
          {days.activeDay.titel &&
            days.activeDay.titel.length > 0 &&
            ": " + days.activeDay.titel}
          {!days.activeDay.is_vliegend && (
            <span style={{ marginLeft: 12 }}>
              <TeamOutlined /> {$t("Niet vliegend")}
            </span>
          )}
        </p>
        <p>
          {days.activeDay.gaat_door && (
            <span>
              {$t("briefing om")} {days.activeDay.briefing_tijd || "??"},{" "}
              {days.activeDay.vertrek_vliegveld}
            </span>
          )}
        </p>
        {days.activeDay.updated_name && days.activeDay.updated_date && (
          <p className="small">
            {$t("aangepast door")} {days.activeDay.updated_name},{" "}
            {_parseDateTime(days.activeDay.updated_date)}
          </p>
        )}
      </div>

      <div className="dagVerslag">
        {days.activeDay.is_vliegend && (
          <Card size="small" className="topCard" title={$t("Rollen")}>
            <CardRollen
              aanmeldingen={aanmeldingen}
              groups_aanmeld={club.groups_aanmeld}
              group_name_lookup={flights.group_name_lookup}
            />
          </Card>
        )}

        {days.activeDay.is_vliegend && (
          <Card size="small" className="topCard" title={$t("Kisten")}>
            <CardKisten aanmeldingen={aanmeldingen} day={days.activeDay} />
          </Card>
        )}

        {aanmeldingen.diensten && aanmeldingen.diensten.length > 0 && (
          <Card size="small" className="topCard" title={$t("Diensten")}>
            <CardRooster
              aanmeldingen={aanmeldingen.aanmeldingen}
              group_name_lookup={flights.group_name_lookup}
              diensten={aanmeldingen.diensten}
              user={profile}
              openAanmelding={() => !currentAanmelding && editAanmelding({})}
            />
          </Card>
        )}

        <MessageCard
          messages={aanmeldingen.messages}
          canEditMessage
          currentUserID={profile.id}
        />
      </div>

      <div style={{ marginTop: 12 }}>
        <Table
          style={{ marginTop: 12, width: "98%", overflowX: "auto" }}
          pagination={defaultTableConfig}
          size="small"
          rowKey="id"
          dataSource={aanmeldingenLijst}
          columns={AanmeldTableColumns(
            setOpenPilot,
            profile.is_instructeur && aanmeldingen.notities,
            profile,
            editAanmelding,
            afmelden,
            club.groups_aanmeld,
            types,
            days.activeDay.is_vliegend,
            flights.group_name_lookup
          )}
        />
      </div>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  aanmeldingen: state.aanmeldingen,
  days: state.days,
  flights: state.flights,
  club: state.persist.club,
  profile: state.persist.profile,
  kist_aanmelding: state.persist.kist_aanmelding,
  lastUpdated: state.materiaal.lastUpdated,
});

const mapDispatchToProps = (dispatch) => {
  return {
    saveDay: (d) => dispatch(daysActions.saveDay(d)),
    getBaseData: (d) => dispatch(flightsActions.getBaseData(d)),
    openDay: (d) => dispatch(daysActions.openDay(d)),
    getAanmeldingen: (d) => dispatch(aanmeldingenActions.getAanmeldingen(d)),
    saveAanmelding: (d) => dispatch(aanmeldingenActions.saveAanmelding(d)),
    getAllDays: (d) => dispatch(daysActions.getAllDays(d)),
  };
};

export const AanmeldLijstPagina = connect(
  mapStateToProps,
  mapDispatchToProps
)(AanmeldLijstComponent);
