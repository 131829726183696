import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Space, Tag } from 'antd'
import React from 'react'
import { $t } from '~/i18n.js'
import { RecencyCurrencyBarometer } from '~/components/profile/recency.jsx'
import { getUserAvatar, getUserAvatarError } from '~/components/profile/selectPilot.jsx'
import { ReadMore } from '~/components/readmore/readMore.jsx'

export const AanmeldTableColumns = (setOpenPilot, laatsteNotities, profile, setActiveAanmelding, afmelden, groups_aanmeld, types, vliegend = true, group_name_lookup) => {
  const rollen_filters = [
    { text: $t('dbo'), value: 'dbo' },
    { text: $t('solist'), value: 'solist' },
    { text: $t('brevet'), value: 'brevet' },
    { text: $t('instructeur'), value: 'instructeur' },
    { text: $t('sleepvlieger'), value: 'sleepvlieger' }
  ]
  if (groups_aanmeld && groups_aanmeld.length > 1) {
    groups_aanmeld.split(',').forEach(g => rollen_filters.push({ text: g.replace('_', ' '), value: g }))
  }

  const cols = [
    {
      title: '',
      render: (text, row) => <>
        {row.vlieger.id === profile.id && <Space>
          <Button type='primary' icon={<EditOutlined />} size='small' shape='circle' onClick={() => setActiveAanmelding(row)} />
        </Space>}
        {profile.iedereen_aanmelden && row.aangemeld && <Space>
          <Popconfirm
            placement='bottom' title={$t('Zeker weten dat je de aanmelding wilt annuleren?')}
            onConfirm={() => afmelden(row.vlieger.id)}
            okText={$t('Ja')} cancelText={$t('Nee')}
          >
            <Button type='text' icon={<CloseOutlined />} size='small' shape='circle' />
          </Popconfirm>
        </Space>}
      </>
    },
    {
      title: $t('Vlieger'),
      dataIndex: 'name',
      sorter: (a, b) => ('' + a.vlieger.name).localeCompare(b.vlieger.name),
      filters: rollen_filters,
      onFilter: (value, record) => {
        // instructeur/sleepvlieger optioneel per aanmelding
        if (value === 'instructeur') return record.as_instructeur
        if (value === 'sleepvlieger') return record.as_sleepvlieger
        // else filter on group aanmelding
        return record.vlieger.group_keys?.includes(value)
      },
      render: (_, d) => <div className='row clickable' onClick={() => setOpenPilot(d.vlieger)}>

        <img
          className='avatarLedenLijst' alt=''
          src={getUserAvatar(d.vlieger, true)}
          onError={getUserAvatarError}
        />

        <div>
          <p className='bold'>{d.vlieger.name}</p>

          {d.aangemeld
            ? <>
              {d.vlieger && d.vlieger.tag && <>

                {d.as_sleepvlieger && <Tag>{$t('sleepvlieger')}</Tag>}
                {d.as_instructeur && <Tag>{$t('instructeur')}</Tag>}

                {d.nieuw_lid && <Tag color='green'>{$t('nieuw lid!')}</Tag>}

                {d.vlieger.tag === 'dbo' && <Tag>{$t('dbo')}</Tag>}
                {d.vlieger.tag === 'solist' && <Tag>{$t('solist')}</Tag>}

                {d.vlieger.group_keys && groups_aanmeld && groups_aanmeld.length > 1 && groups_aanmeld.split(',').map(
                  (group, idx) => d.vlieger.group_keys?.includes(group) && <Tag key={idx}>{group_name_lookup[group] || group}</Tag>
                )}
                                             </>}
            </>
            : <Tag color='volcano'>{$t('Afgemeld')}</Tag>}
        </div>

      </div>
    },

    vliegend && {
      title: $t('Currency'),
      render: (text, d) => d.vlieger && d.aangemeld && <RecencyCurrencyBarometer pilot={d.vlieger} />
    },

    vliegend && {
      title: $t('Kisten'),
      filters: [
        { value: 'check', text: $t('Trainingsvlucht') },
        { value: 'ovl', text: $t('Overland') },
        ...types.map(v => {
          return { value: v, text: v }
        })
      ],
      onFilter: (value, record) => {
        // instructeur/sleepvlieger optioneel per aanmelding
        if (value === 'check') return record.checkstart
        if (value === 'ovl') return record.overland
        // else filter on group aanmelding
        return record.kist.includes(value)
      },
      render: (text, v, index) => {
        if (v.aangemeld) {
          return (
            <>
              {v.checkstart && <Tag color='gold'>{$t('Trainingsvlucht')}</Tag>}
              {v.overland && <Tag color='gold'>{$t('Ovl')}</Tag>}
              {v.kist && v.kist.map((kist, key) => {
                return <Tag key={key} color={key === 0 ? 'green' : 'default'}>{kist}</Tag>
              })}
            </>
          )
        }
      }
    },

    vliegend && profile.is_instructeur && {
      title: $t('Laatste trainings vlucht'),
      render: (text, d) => {
        if (d.aangemeld) {
          return <p>{d.vlieger && d.vlieger.last_check_start ? d.vlieger.last_check_start.split(', ')[0] : ''}</p>
        }
      }
    },

    { title: $t('Van'), dataIndex: 'van', render: (text, d) => d.aangemeld && text, sorter: (a, b) => ('' + a.van).localeCompare(b.van) },
    { title: $t('Tot'), dataIndex: 'tot', render: (text, d) => d.aangemeld && text, sorter: (a, b) => ('' + a.tot).localeCompare(b.tot) },
    { title: $t('Opmerking'), dataIndex: 'opmerking', render: (text, d) => <ReadMore text={text} /> }
  ]

  if (laatsteNotities) {
    cols.push({
      title: $t('Laatste notitie'),
      render: (text, d, index) => {
        const notitie = laatsteNotities[d.vlieger && d.vlieger.id]
        return (
          <>
            {notitie && <ReadMore text={`${notitie.bericht}, - "${notitie.instructeur_naam}" (${notitie.datum})`} />}
          </>
        )
      }
    })
  }

  return cols.filter(c => c)
}
