import { Cascader, Input, Modal, Radio, Select, Timeline } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { $t } from '~/i18n.js'
import { api } from '../../../../lib/api'
import { selectElementByID } from '../../../../lib/helpers'
import { getMaterialTab, saveMaterialTab } from '../../../../redux/materiaal/materiaal.actions'

export const AddMaintenanceModal = ({ visible, currentMaterial, closeModal }) => {
  const dispatch = useDispatch()

  const [part_id, selectPartID] = useState(null)
  const [partsList, setPartsList] = useState(null)

  const [listTemplates, setListTemplates] = useState([])
  useEffect(() => {
    const loadParts = async () => {
      // load in parts
      const res = await dispatch(getMaterialTab({ materialID: currentMaterial.id, activeTab: 'parts' }))
      const normalize = (part) => ({ value: part.id, label: part.name, children: part.parts.map(p => normalize(p)) })
      const parts = res?.data?.parts?.map(part => normalize(part))
      setPartsList(parts)

      // and load in templates
      const _r = await api.get(`materiaal/${currentMaterial.id}/maintenance_templates.json`)
      if (_r?.templates) setListTemplates(_r.templates)
    }
    if (!visible) return
    loadParts()
  }, [visible])

  const [action, setAction] = useState('')
  const [template_id, setTemplate] = useState(null)
  const [name, setName] = useState('')

  const selectedTemplate = selectElementByID(listTemplates, 'id', template_id)

  const [saving, setSaving] = useState(false)
  const addProgram = async () => {
    setSaving(true)
    // partID is cascade, as such is an array of the nest, take last
    const payload = { action, template_id, name, part_id: part_id[part_id.length - 1] }
    const res = await dispatch(saveMaterialTab({ activeTab: 'maintenance', materialID: currentMaterial.id, payload }))
    if (res) closeModal()
    setSaving(false)
  }
  return (
    <Modal
      title={$t('Voeg maintenance task toe')}
      confirmLoading={saving}
      open={visible}
      onCancel={closeModal}
      okButtonProps={{ disabled: !part_id || !action || (!template_id && !name) }}
      onOk={addProgram}
    >

      <Timeline>

        <Timeline.Item color={part_id ? 'green' : 'blue'}>
          <p className='spacer'>{$t('Selecteer onderdeel')}:</p>
          <Cascader
            disabled={!partsList} style={{ width: 300 }}
            placeholder='' options={partsList} changeOnSelect
            value={part_id} onChange={id => selectPartID(id)}
          />
        </Timeline.Item>

        <Timeline.Item color={action ? 'green' : (part_id ? 'blue' : 'gray')}>
          <p className='spacer'>{$t('Selecteer sjabloon')}:</p>
          <Radio.Group
            disabled={!part_id} value={action}
            onChange={(event) => {
              setTemplate(null)
              setAction(event.target.value)
              setName('')
            }}
          >
            {currentMaterial.category === 'kist' && <Radio.Button style={{ minWidth: 70 }} value='new_from_template'>{$t('Algemeen')}</Radio.Button>}
            <Radio.Button style={{ minWidth: 70 }} value='new_from_material'>{$t('Club')}</Radio.Button>
            <Radio.Button style={{ minWidth: 70 }} value='new_from_blank'>{$t('Leeg')}</Radio.Button>
          </Radio.Group>
        </Timeline.Item>

        <Timeline.Item color={(template_id || name) ? 'green' : (action ? 'blue' : 'gray')}>
          {action === 'new_from_template' && <>
            <p className='spacer'>{$t('Selecteer algemeen sjabloon')}:</p>
            <Select
              style={{ width: 300 }}
              value={template_id} onChange={id => setTemplate(id)}
            >
              {listTemplates.map((t, key) =>
                t.template === 'easa' && <Select.Option key={key} value={t.id}>{t.category.toUpperCase()}: {t.name}</Select.Option>)}
            </Select>
            {selectedTemplate && <p className='small' style={{ marginTop: 6 }}>{selectedTemplate.description}</p>}
                                             </>}

          {action === 'new_from_material' && <>
            <p className='spacer'>{$t('Selecteer sjabloon van ander materiaal')}:</p>
            <Select
              style={{ width: '100%' }} showSearch
              filterOption={(input, option) => option?.children?.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={template_id} onChange={id => setTemplate(id)}
            >
              {listTemplates.map((t, key) =>
                t.template === 'club' && <Select.Option key={key} value={t.id}>{t.material}: {t.name}</Select.Option>)}
            </Select>
            {selectedTemplate && <p className='small' style={{ marginTop: 6 }}>{selectedTemplate.description} ({selectedTemplate.material})</p>}
                                             </>}

          {action === 'new_from_blank' && <>
            <p className='spacer'>{$t('Naam van onderhoudsprogramma:')}</p>
            <Input
              style={{ width: 300 }} value={name}
              onChange={(event) => setName(event.target.value.substring(0, 149))}
            />
                                          </>}
        </Timeline.Item>

      </Timeline>

    </Modal>
  )
}
