import { CloseOutlined } from "@ant-design/icons";
import { Button, Flex, Input, Modal, Popconfirm, Table } from "antd";
import React, { useState } from "react";
import { AddButton } from "~/components/buttons/AddButton";
import { SettingCard } from "~/components/dataCards/SettingCard.jsx";
import { defaultTableConfig } from "~/components/layout/table";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { _parseDate } from "~/lib/localize";

export const APItokens = () => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [tokens, setTokens] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const getTokens = async () => {
    setIsLoading(true);
    let data = await api.get("admin/api_tokens.json");
    setTokens(data);
    setHasLoaded(true);
    setIsLoading(false);
  };

  const tokenAction = async (method = "DELETE", payload = {}) => {
    setIsLoading(true);

    const { data } = await api._call_method(
      method,
      "admin/api_tokens.json",
      payload
    );
    if (data) setTokens(data);
    setIsLoading(false);
  };

  const [name, setName] = useState("");
  const [showModal, setShowModal] = useState(false);

  return (
    <SettingCard title="API tokens">
      <Modal
        title={$t("Voeg toe")}
        open={showModal}
        confirmLoading={isLoading}
        onCancel={() => {
          setName("");
          setShowModal(false);
        }}
        okText={$t("Opslaan")}
        cancelText={$t("Annuleren")}
        okButtonProps={{
          disabled: name === ''
        }}
        onOk={async () => {
          await tokenAction("POST", { name: name });
          setName("");
          setShowModal(false);
          setHasLoaded(true);
        }}
      >
        <p>{$t("Naam")}: </p>{" "}
        <Input
          type="string"
          value={name}
          onChange={(e) => setName(e.target.value.substring(0, 49))}
        />
      </Modal>

      <Flex gap={24} justify="space-between">
        <p style={{ marginBottom: 12 }}>
          {$t(
            "Automatische verbinding naar de ZweefApp via de API, uitleg staat op: "
          )}
          <a
            href="https://documenter.getpostman.com/view/25434528/2s8ZDX5PRi"
            target="_blank"
          >
            {$t("Link")}
          </a>
        </p>

        <AddButton onClick={() => setShowModal(true)} />
      </Flex>

      {!hasLoaded && (
        <Button onClick={getTokens} loading={isLoading}>
          {$t("Haal tokens op")}
        </Button>
      )}

      {hasLoaded && (
        <div>
          <Table
            dataSource={tokens || []}
            rowKey="id"
            style={{ width: "100%", overflowX: "auto", marginTop: 12 }}
            size="small"
            pagination={defaultTableConfig}
            columns={[
              { title: $t("Naam"), dataIndex: "name" },
              {
                title: $t("Gemaakt op"),
                dataIndex: "date_created",
                render: (d) => _parseDate(d),
              },
              { title: "token", dataIndex: "render_token" },
              {
                render: (_, token) => (
                  <div className="row" style={{ width: 65 }}>
                    <Popconfirm
                      placement="bottom"
                      title={$t("Zeker weten?")}
                      onConfirm={() => {
                        tokenAction('DELETE', {id: token.id})
                      }}
                      okText={$t("Ja")}
                      cancelText={$t("Nee")}
                    >
                      <Button
                        loading={isLoading}
                        style={{ marginRight: 6 }}
                        shape="circle"
                        size="small"
                        icon={<CloseOutlined />}
                      />
                    </Popconfirm>
                  </div>
                ),
              },
            ]}
          />
        </div>
      )}
    </SettingCard>
  );
};
