import { adminState } from './admin.state'

export const adminReducer = (state = adminState, action) => {
  switch (action.type) {
    case 'ADMIN_PENDING':
      return {
        ...state,
        isPending: true
      }
    case 'ADMIN_ERROR':
      return {
        ...state,
        isPending: false
      }
    case 'SET_DOCUMENTS':
      return {
        ...state,
        isPending: false,
        documentsUpdated: new Date(),
        tabs: action.tabs ? action.tabs : []
      }

    case 'SET_CAMPAIGNS':
      return {
        ...state,
        isPending: false,
        campaigns: action.campaigns ? action.campaigns : [],
        campaignsUpdated: new Date()
      }

    case 'SAVE_CAMPAIGN':
      return {
        ...state,
        isPending: false,
        campaigns: [action.campaign].concat(state.campaigns.filter(c => c.id !== action.campaign.id))
      }
    case 'DELETE_CAMPAIGN':
      return {
        ...state,
        isPending: false,
        campaigns: state.campaigns.filter(c => c.id !== action.campaign_id)
      }

    case 'ADD_CAMPAIGN_FILE':
      const newCampaigns = [...state.campaigns]
      newCampaigns.forEach(c => {
        if (c.id === action.campaign_id) {
          if (action.action === 'delete') {
            c.attachments = c.attachments.filter(f => f.id !== action.file.id)
          } else {
            c.attachments.push(action.file)
          }
        }
      })
      return {
        ...state,
        campaigns: newCampaigns
      }

    case 'SET_FINDINGS':
      return {
        ...state,
        isPending: false,
        findings: action.findings ? action.findings : [],
        findingsUpdated: new Date()
      }

    case 'SAVE_FINDING':
      return {
        ...state,
        isPending: false,
        findings: [action.finding].concat(state.findings.filter(c => c.id !== action.finding.id))
      }

    case 'RESET_ADMIN_STATE':
      return {
        ...adminState
      }

    default:
      return state
  }
}
