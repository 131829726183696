import { QuestionOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React from 'react'
import { $t } from '~/i18n.js'

import './help.css'

function setHelpDialog (title, content) {
  Modal.success({
    title: title || $t('Uitleg'),
    icon: <QuestionOutlined />,
    content
  })
}

export const HelpBubble = ({ title = '', content = null, children = null }) => {
  return (
    <>
      <QuestionOutlined
        style={{
          marginRight: children ? 6 : 0,
          cursor: content ? 'pointer' : 'default',
          border: 'solid 1px #d9d9d9',
          boxShadow: '0 2px 0 rgb(0 0 0 / 2%)',
          borderRadius: '50%',
          padding: 2,
          fontSize: 11
        }}
        onClick={(e) => {
          if (content) {
            e.stopPropagation()
            setHelpDialog(title, content)
          }
        }}
        className={content ? 'clickable' : ''}
      />
      {children}
    </>
  )
}
