import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { _parseDate } from "~/lib/localize.js";

export const DayNavigator = ({ history }) => {
  const { allDays, activeDay } = useSelector((state) => state.days);

  const [prev, setPrev] = useState(null);
  const [next, setNext] = useState(null);

  useEffect(() => {
    // find index and set previous and next
    if (activeDay && allDays) {
      let newPrev = null;
      let found = false;
      allDays.some((day) => {
        if (found) {
          setNext(day);
          return true;
        }

        // iterate towards next day
        if (day.dag_id === activeDay.dag_id) {
          found = true;
        } else {
          newPrev = day;
        }
      });
      setPrev(newPrev);
    }
  }, [allDays, activeDay]);

  const button = (day, icon) =>
    day && (
      <Button
        icon={icon}
        onClick={() => history.push("/dailyreport/" + day.dag_id)}
      >
        {_parseDate(day.datum, { month: "2-digit", day: "2-digit" })}
      </Button>
    );

  if (!activeDay) return null;
  if (!allDays) return null;

  return (
    <div className="row" style={{ gap: 4, marginRight: 6 }}>
      {button(prev, <DoubleLeftOutlined />)}
      {button(next, <DoubleRightOutlined />)}
    </div>
  );
};
