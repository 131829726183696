import { ToolOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { $t } from '~/i18n.js'
import { getMaterialTab } from '../../../../redux/materiaal/materiaal.actions'

export const SetRelatedMelding = ({ visible, currentMaterial, meldingen, setMeldingen, list_meldingen }) => {
  const dispatch = useDispatch()

  // normalize meldingen to array of integers
  meldingen = meldingen?.map(m => m.id)
  list_meldingen = list_meldingen?.filter(m => !m.workorder_id && m.status === 'aangemeld')

  useEffect(() => {
    if (!visible) return
    if (currentMaterial && !currentMaterial.meldingen) dispatch(getMaterialTab({ materialID: currentMaterial.id, activeTab: 'meldingen' }))
  }, [visible, currentMaterial])

  return (
    <div className='row' style={{ gap: 12, marginTop: 9, marginLeft: 12 }}>
      <p style={{ width: 120 }}>{$t('Meldingen')}:</p>
      <Select
        style={{ width: '100%' }} showSearch mode='multiple' disabled={!list_meldingen}
        filterOption={(input, option) => option?.children?.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0}
        value={meldingen} onChange={ids => setMeldingen(ids.map(i => {
          return { id: i }
        }))}
      >
        {list_meldingen?.map((m, key) =>
          <Select.Option key={key} value={m.id}>{m.titel}
            {m.workorder_id && <ToolOutlined style={{ marginLeft: 6 }} />}
          </Select.Option>)}
      </Select>
    </div>
  )
}
