export const config = {
  mode: 'production',

  media_prefix: 'https://zweefapp.s3.amazonaws.com/media/',

  client_secret: 'LmwtuTeToDALLZpzDAEG',
  sentry_key: 'https://a93139cbe37d435fabb7502446015294@o457592.ingest.sentry.io/5453791' // leave blank to disable
}

export default config
