import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Alert, Button, Form, Modal, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { InputFileSingle } from "~/components/form/InputFileSingle.jsx";
import { HelpBubble } from "~/components/help/help.bubble.jsx";
import { defaultTableConfig } from "~/components/layout/table.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { parseCsv } from "~/lib/parsers.js";

const mappingTableLookup = {
  email: "email",
  first_name: "first_name",
  last_name: "last_name",
  membership: "lidmaatschap",
  member_number: "lid_nummer",
  remark: "remark",
};

export function ImportDialogUsers({ isModal, closeModal, refreshAfterImport }) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState({ success: 0, fail: 0 });
  const [status, setStatus] = useState({});

  const [items, setItems] = useState([]);
  const [value, setValue] = useState("");

  useEffect(() => {
    console.log("reset");
    form.resetFields();

    setLoading(false);
    setItems([]);
    setValue("");
    setLoadingStatus(null);
    setStatus({});
  }, [isModal]);

  useEffect(() => {
    // Also update deps
    const _items = parseCsv(value);
    _items.map((item) => {
      Object.keys(mappingTableLookup).forEach((key) => {
        item[mappingTableLookup[key]] = item[key];
      });
    });
    setItems(_items);
  }, [value]);

  const updateStatus = (email, newStatus, currentStatus) => {
    let newStatusLookup = currentStatus || status;
    newStatusLookup[email] = newStatus;
    setStatus({ ...status });
    return newStatusLookup;
  };

  const startImport = async () => {
    let success = 0;
    let fail = 0;
    let statusLookup = { ...status };
    setLoading(true);

    for (const idx in items) {
      let user = items[idx];
      if (status[user.email]) {
        statusLookup = updateStatus(user.email, "skipped");
        continue;
      }

      statusLookup = updateStatus(user.email, "loading");
      const { response } = await api.post2("admin/users.json", user);
      if (!response.ok) {
        statusLookup = updateStatus(user.email, "fail", statusLookup);
        fail += 1;
      } else {
        statusLookup = updateStatus(user.email, "success", statusLookup);
        success += 1;
      }
    }
    refreshAfterImport();
    setLoadingStatus({ success, fail });
    setLoading(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setValue(e.target.result.toString());
        setStatus({});
      };
      reader.readAsText(file);
    }
  };

  return (
    <>
      <Modal
        title={$t("Importeer gebruikers")}
        open={isModal}
        onCancel={!loading && closeModal}
        width={1000}
        footer={[
          <Button
            key="opslaan"
            disabled={!value || items.length == 0}
            onClick={startImport}
            type="primary"
            loading={loading}
          >
            {$t("Importeer")}
          </Button>,
          <SluitButton key="sluit" disabled={loading} onClick={closeModal} />,
        ]}
      >
        <p className="bold">
          1. {$t("Selecteer bestand")}:
          <HelpBubble
            content={
              <div>
                <p>
                  {$t(
                    "Bestand moet comma-seperated geformateerd zijn, zoals de export vanuit de ZweefApp."
                  )}
                </p>
                <p>{$t("Bekijk de volgende template")}:</p>
                <p>
                  <a href="https://zweefapp-ui.s3.eu-central-1.amazonaws.com/template_import_users.csv">
                    {$t("Link naar template")}
                  </a>
                </p>
              </div>
            }
          />
        </p>

        <Form form={form}>
          <Form.Item
            name="file"
            rules={[{ required: false }]}
            onChange={handleFileChange}
          >
            <InputFileSingle accept=".csv" />
          </Form.Item>
        </Form>

        {/* <input type="file" accept=".csv" onChange={handleFileChange} /> */}

        <p className="bold">2. {$t("Bekijk de data")}</p>

        {items.length > 0 && (
          <p style={{ marginBottom: 12 }}>
            {$t("Import bevat ${number} gebruiker(s)", {
              number: items.length,
            })}
          </p>
        )}

        <Table
          style={{
            overflowX: "auto",
            cursor: "pointer",
          }}
          size="small"
          pagination={{ ...defaultTableConfig, defaultPageSize: 25 }}
          rowKey="email"
          dataSource={items}
          columns={[
            {
              width: 80,
              render: (_, user) => {
                return (
                  <div>
                    {status[user.email] === "skipped" && (
                      <Tag color="orange">{$t("Overgeslagen")}</Tag>
                    )}
                    {status[user.email] === "loading" && (
                      <Tag icon={<SyncOutlined spin />} color="processing" />
                    )}
                    {status[user.email] === "success" && (
                      <Tag icon={<CheckCircleOutlined />} color="success" />
                    )}
                    {status[user.email]?.startsWith("fail") && (
                      <Tag icon={<ExclamationCircleOutlined />} color="warning">
                        {status[user.email]}
                      </Tag>
                    )}
                  </div>
                );
              },
            },
            { title: "email", dataIndex: "email" },
            { title: "first_name", dataIndex: "first_name" },
            { title: "last_name", dataIndex: "last_name" },
            { title: "membership", dataIndex: "lidmaatschap" },
            { title: "member_number", dataIndex: "lid_nummer" },
            { title: "remark", dataIndex: "remark" },
          ]}
        />

        {(loadingStatus?.success > 0 || loadingStatus?.fail > 0) && (
          <Alert
            type="warning"
            message={$t(
              "Import: ${success} gelukt, ${fail} mislukt",
              loadingStatus
            )}
          />
        )}
      </Modal>
    </>
  );
}
