import { api } from '../../lib/api'

export const saveDay = (data) => async (dispatch) => {
  dispatch({
    type: 'DAYS_PENDING'
  })

  const newDay = await api.post('days.json', data)
  dispatch({
    type: newDay ? 'NEW_DAY' : 'DAYS_ERROR',
    newDay
  })

  return newDay
}

export const getAllDays = () => async (dispatch) => {
  dispatch({
    type: 'DAYS_PENDING'
  })
  const data = await api.get('days.json')
  data && dispatch({
    type: 'DAYS_SUCCESS',
    allDays: data.days,
    totals: data.totals,
    notities: data.notities,
    aanmeldingen: data.aanmeldingen,
    sleeps: data.sleeps,
    mijn_aanmeldingen: data.mijn_aanmeldingen ? data.mijn_aanmeldingen : [],
    mijn_diensten: data.mijn_diensten ? data.mijn_diensten : []
  })
  return data
}

export const openDay = (dag_id) => async (dispatch) => {
  dispatch({
    type: 'OPEN_DAY',
    dag_id
  })
}

export const updateStatus = (data) => async (dispatch) => {
  const newDay = await api.post('days/update_status.json', data)
  newDay && !newDay.invalidFlights && dispatch({
    type: 'NEW_DAY',
    newDay
  })

  return newDay
}

export const getRoosters = () => async (dispatch) => {
  const data = await api.get('rooster.json')
  data && dispatch({
    type: 'ROOSTER_SUCCES',
    data
  })
  return data?.roosters
}

export const saveRooster = (payload) => async (dispatch) => {
  const data = await api.post('rooster/save_rooster.json', payload)
  data && dispatch({
    type: 'ROOSTER_UPDATE',
    rooster: data.rooster
  })
  return data?.rooster
}
