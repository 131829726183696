import { DeleteFilled, DeleteOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Input, Row, Table } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { $t } from '~/i18n.js'

import { defaultTableConfig } from '../../../../components/layout/table'
import { CardBase } from '../../../../components/timeLine/cardBase'
import { toLocaleDate } from '../../../../lib/localize'

export const CardDay = ({ data, saveAndNext, prev }) => {
  const [date_from, setDate_from] = useState(dayjs())
  const [date_to, setDate_to] = useState(null)
  const [invalid, setInvalid] = useState(false)
  const [days, setDays] = useState([])
  useEffect(() => {
    if (data.date_from) setDate_from(dayjs(data.date_from))
    if (data.date_to) setDate_to(dayjs(data.date_to))
    setDays(data.days)
  }, [data.days, data.date_from, data.date_to, setDate_from, setDate_to, setDays])

  const addDay = (oldDays, date) => {
    // filter the date from the existing list, add with new data, sort and store.
    const datum = date.format('YYYY-MM-DD')
    const newDays = oldDays.filter((d) => d.datum !== datum).concat([{ datum, titel: '' }])
    if (newDays) newDays?.sort((a, b) => a.datum.localeCompare(b.datum))
    return newDays
  }

  const addMultipleDays = (allDays) => {
    // loop through dates
    let newDays = [...days]
    let current = date_from
    const end = date_to.add(1, 'days')
    while (current.isBefore(end, 'day')) {
      if (allDays || current.day() === 0 || current.day() === 6) {
        newDays = addDay(newDays, current)
      }
      current = current.add(1, 'days')
    }
    setDays(newDays)
  }

  const handleSave = () => {
    if (days.length === 0) {
      setInvalid(true)
    } else {
      setInvalid(false)
      saveAndNext({ days, date_from: days[0].datum, date_to: days[days.length - 1].datum })
    }
  }

  return (
    <CardBase prev={prev} next={handleSave}>

      <Row justify='space-between'>

        <Col span={12}>

          <p>{$t('Voeg meerdere data toe')}: </p>
          {invalid && <p className='alert'>{$t('Voeg 1 of meerdere data toe!')}</p>}
          <p className='small' style={{ marginBottom: 24 }}>{$t('Dagen die al in het systeem aangemaakt zijn (datum & dagtype) worden niet opnieuw aangemaakt.')}</p>

          <Row>
            <Col span={12}>
              <p>{$t('Vanaf')}: </p>
              <DatePicker
                format={localStorage.localeFormat}
                value={date_from}
                onChange={(value) => setDate_from(value)}
                disabledDate={(current) => current && current < dayjs().startOf('day')}
              />
            </Col>

            <Col span={12}>
              <p>{$t('Tot')}: </p>
              <DatePicker
                format={localStorage.localeFormat}
                value={date_to}
                onChange={(value) => setDate_to(value)}
                disabledDate={(current) => date_from && current && current < date_from.startOf('day')}
              />
            </Col>
          </Row>

          <div className='column' style={{ marginTop: 24, alignItems: 'flex-start', height: 110, justifyContent: 'space-between' }}>
            <Button
              type='primary' style={{ width: 180 }} disabled={!date_from}
              onClick={() => setDays(addDay(days, date_from))}
            >
              {$t('Voeg toe')}: {date_from ? date_from.format('DD-MM-YYYY') : ' datum '}
            </Button>
            <Button
              type='primary' style={{ width: 180 }}
              onClick={() => addMultipleDays(true)}
            >
              {$t('Voeg alle data toe')}
            </Button>
            <Button
              type='primary' style={{ width: 180 }}
              onClick={() => addMultipleDays(false)}
            >
              {$t('Voeg weekend toe')}
            </Button>
          </div>

        </Col>

        <Col span={12}>

          {days.length > 0 &&
            <Table
              dataSource={days}
              rowKey='datum'
              style={{ width: '100%', overflowX: 'auto' }}
              pagination={defaultTableConfig}
              size='small'
              columns={[
                {
                  title: $t('Datum'),
                  dataIndex: 'datum',
                  render: (value, day) => <p className={day.delete ? 'gray' : ''}>{toLocaleDate(new Date(value), { weekday: 'short', month: '2-digit', day: '2-digit' })}</p>
                },
                {
                  title: $t('Beschrijving (optioneel)'),
                  dataIndex: 'titel',
                  render: (value, day) => <Input
                    value={value} disabled={day.delete}
                    onChange={(event) => {
                      const newDays = days.map(d => {
                        if (d.datum === day.datum) d.titel = event.target.value
                        return d
                      })
                      setDays(newDays)
                    }}
                                          />
                },

                {
                  render: (_, day) =>
                    <Button
                      shape='circle' onClick={() => {
                        if (day.dag_id > 0) {
                          const newDays = days.map(d => {
                            if (d.datum === day.datum) d.delete = !d.delete
                            return d
                          })
                          setDays(newDays)
                        } else {
                          setDays(days.filter((d) => d.datum !== day.datum))
                        }
                      }}
                    >
                      {day.delete ? <DeleteFilled /> : <DeleteOutlined />}
                    </Button>
                }
              ]}
            />}

        </Col>

      </Row>

    </CardBase>
  )
}
