import { WarningFilled, WarningOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'
import { $t } from '~/i18n.js'

import { flarm, Icon, LandingIcon, StartIcon } from '../icon/icon'

export const OGNwarningText = ({ ogn_indicator, defaultIcon }) => {
  if (ogn_indicator < 1) return <span>{defaultIcon} {$t('Tijd handmatig ingevoerd')}.</span>
  if (ogn_indicator === 1) return <span><Icon icon={flarm} color='green' style={{ marginRight: 6 }} viewBox='0 100 1250 1024' /> {$t('Tijd ingevoerd via Flarm')}.</span>
  if (ogn_indicator > 1) {
    return (
      <span>
        <WarningFilled style={{ color: 'red' }} /> {$t('Tijd via Flarm is niet accuraat')},
        {ogn_indicator > 990
          ? $t('een zeer grote afwijking is mogelijk.')
          : <span> {$t('verwacht een afwijking van')} +/- {parseInt(ogn_indicator / 60 / 2)} min.</span>}
      </span>
    )
  }
  return null
}

export const OGNtimeIcon = ({ ogn_indicator, defaultIcon }) => {
  if (!ogn_indicator) return defaultIcon || null
  return (
    <Tooltip placement='top' title={<OGNwarningText ogn_indicator={ogn_indicator} defaultIcon={defaultIcon} />}>
      {ogn_indicator < 1 && defaultIcon}
      {ogn_indicator === 1 && <Icon icon={flarm} color='green' viewBox='0 100 1250 1024' />}
      {ogn_indicator > 1 && <WarningOutlined style={{ color: 'red' }} />}
    </Tooltip>
  )
}

export const OGNstartTijd = ({ ogn_indicator, time, default_value = '' }) => (
  <span style={{ display: 'flex' }}>
    <OGNtimeIcon ogn_indicator={ogn_indicator} defaultIcon={<StartIcon color='lightgray' />} />
    {time || default_value}
  </span>
)

export const OGNlandingsTijd = ({ ogn_indicator, time, default_value = '', marginLeft = 4 }) => (
  <span style={{ display: 'flex', marginLeft }}>
    <OGNtimeIcon ogn_indicator={ogn_indicator} defaultIcon={<LandingIcon color='lightgray' />} />
    {time || default_value}
  </span>
)
