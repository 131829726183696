import { PaperClipOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input } from "antd";
import React from "react";
import { $t } from "~/i18n.js";
import { config } from "~/config.js";
import { AddButton } from "../buttons/AddButton.jsx";

export const FileInput = ({ value = [], onChange = (v) => {} } = {}) => {
  // save files one by one
  let files = value || [];

  const saveFile = (index, fileObject) => {
    if (!fileObject) return null;

    const newFiles = [...files];
    newFiles[index].content_type = fileObject.type;
    newFiles[index].size = fileObject.size;
    newFiles[index].originFileObj = fileObject;
    onChange(newFiles);
  };

  const deleteFile = (file, index, checked) => {
    if (file.id) {
      file.action = checked ? $t("delete") : $t("update");
    } else {
      files = files.filter((_, idx) => idx !== index);
    }
    onChange?.([...files]);
  };

  const addNew = () => {
    onChange(
      files.concat({ file_id: null, size: 0, action: "new", file: null })
    );
  };

  return (
    <div>
      {files?.map((file, index) => {
        const split = file?.file?.split("___");
        return (
          <div className="row" style={{ gap: 12 }} key={index}>
            {file.id && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                title="file"
                href={config.media_prefix + file.file}
              >
                <PaperClipOutlined />
                {split[split.length - 1].substr(0, 60)}
              </a>
            )}

            {!file.id && (
              <Input
                type="file"
                multiple={false}
                onChange={(e) => saveFile(index, e.target.files[0])}
              />
            )}

            {file.id ? (
              <Checkbox
                onChange={(e) => deleteFile(file, index, e.target.checked)}
              >
                {$t("verwijder")}
              </Checkbox>
            ) : (
              <Button
                danger
                type="text"
                size="small"
                onClick={() => deleteFile(file, index)}
              >
                {$t("verwijder")}
              </Button>
            )}
          </div>
        );
      })}

      <AddButton style={{ margin: "12px 0" }} onClick={addNew} />
    </div>
  );
};

export const FileValidator = () => ({
  validator(_, files) {
    if (!files?.length) return Promise.resolve();

    // if attachments, must save first
    const valid_types = [
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
      "text/csv",
      "image/jpeg",
      "image/png",
      "image/gif",
    ];
    let size = 0;
    const invalid_files = [];
    const invalid_names = [];
    files.forEach((f) => {
      if (f?.action !== "delete") {
        if (f.size) {
          console.log("adding size: ", f);
          size += f.size;
        }

        if (
          f.content_type &&
          !valid_types.some((v) => f.content_type.indexOf(v) > -1)
        ) {
          invalid_files.push(f.originFileObj?.name);
        }

        if (f.originFileObj?.name?.length > 70) {
          invalid_names.push(f.originFileObj?.name?.substr(0, 20) + "...");
        }
      }
    });

    // check invalid types
    if (invalid_files.length > 0) {
      return Promise.reject(
        `"${invalid_files.join(", ")}" ` +
          $t(
            "kan je niet toevoegen, alleen pdf's, plaatjes, documenten en excel bestanden."
          )
      );
    }

    // check invalid types:
    if (invalid_names.length > 0) {
      return Promise.reject(
        `"${invalid_names.join(", ")}", ` +
          $t("namen zijn te lang, mogen maximaal 70 characters bevatten.")
      );
    }

    // check total size
    const maxSize = 4;
    if (size > maxSize * 1e6) {
      return Promise.reject(
        $t(
          'Je mag maximaal ${size} uploaden. Sla je bijlage anders op onder "documenten" en voeg de link toe in je email.',
          { size: `${maxSize}MB` }
        )
      );
    }

    return Promise.resolve();
  },
});
