
export const PrivacyVersionNL = "Versie 4, 17 feb 2023";

export const PrivacyTextNL = `
Als jij inlogt bij de Zweef.App of de website bezoekt leggen wij persoonsgegevens van je vast. Wij vinden het belangrijk om jouw privacy daarbij zo veel mogelijk te respecteren en te beschermen. Daarom gaan we zorgvuldig om met je persoonsgegevens en zijn we transparant over het gebruik hiervan. 

### 1. Wie is wie?

Als je in deze privacyverklaring 'jij', 'je' of 'jou(w)' leest, bedoelen we jou als zweefvlieger of bezoeker van de Zweef.App.

Lees je 'wij', 'we', 'ons', of 'onze', dan bedoelen we de Zweef.App (ingeschreven bij de Kamer van Koophandel onder nummer 88819051).

Met “club” bedoelen we de zweefvliegvereniging waar jij lid van bent, die zijn juridisch gezien de verantwoordelijke voor de verwerking persoonsgegevens. 

De relatie tussen ons, jij en de club is een driehoek, en langs elke relatie ligt er een afspraak:

- Jij hebt een overeenkomst met jouw club in de vorm van een lidmaatschap bij jouw club. Jouw club verwerkt als onderdeel daarvan persoonsgegevens van jou.
 
- Jouw club heeft ervoor gekozen om voor het bijhouden van de startadministratie, DTO administratie en/of andere clubadministratie gebruik te maken van de Zweef.App. Jouw club is juridisch gezien de verantwoordelijke voor de verwerking persoonsgegevens en de Zweef.app is daarin de (sub)verwerker van deze (persoons)gegevens. Jouw club en de Zweef.app zijn hiervoor een verwerkingsovereenkomst overeengekomen zoals voorgeschreven door de AVG waarin onder andere de verwerking, rechten, geheimhouding, en beveiliging van gegevens zijn vastgelegd.
 
- Verder heb jij als individueel lid ook de mogelijkheid om zelf in te loggen op de Zweef.App. Hierbij kun jij je gegevens inzien, aanvullende gegevens invoeren en kan de Zweef.app aanvullende persoonsgegevens van jou verwerken (bijvoorbeeld wanneer jij voor het laatst bent ingelogd). Voor de gegevens de die Zweef.app aanvullend van jou verwerkt is de Zweef.app verwerkingsverantwoordelijke. In deze privacymededeling wordt de verwerking van die gegevens uitgelegd.


In de Zweef.App worden ook koppelingen aangeboden, bijvoorbeeld met specifieke inlog systemen. Voor de volledigheid leggen we hierbij vast dat wij juridisch gezien niet de verantwoordelijke zijn voor de verwerking van persoonsgegevens via betreffende websites. De verantwoordelijke hiervoor is de betreffende partij.

### 2. Welke gegevens houden we bij en waarom?

Wij verwerken gegevens in opdracht van jouw club met als doel de digitale zweefvliegadministratie van jouw club te verzorgen. Denk daarbij aan zaken als de startadministratie, EASA Declared Training Organisation (DTO) administratie, bevoegdheden, of currency van leden. 

Welke persoonsgegevens we vastleggen en wat de reden hiervan is, hangt af van de situatie. Hieronder beschrijven we per situatie wat we vastleggen en waarom. Daarnaast kan het zo zijn dat er meer situaties zijn waarin je gegevens gebruikt zijn.


#### 2.1 Gegevens die wij verwerken in opdracht van jouw club

De gegevens die wij van jouw verwerken in opdracht van jouw zweefvliegvereniging zijn beschreven in de verwerkingsovereenkomst die jouw club heeft met de Zweef.app. In de privacyverklaring van jouw zweefvliegclub kun je teruglezen welke persoonsgegevens jouw club van jou verwerkt. In zijn algemeenheid verwerkt de Zweef.app de volgende gegevens in opdracht van de clubs, maar de situatie kan per club verschillen:

Als je je aanmeldt om te gaan vliegen en als je gaat vliegen dan slaan we deze gegevens op in onze administratie. Er wordt, onder andere, geregistreerd met wie je vloog, op welke kist, type vlucht (bijvoorbeeld EASA trainings-, examen- of overlandvluchten), waar de vlucht was en hoe laat en lang gevlogen is. De exploitant van een luchthaven is op grond van de EASA wetgeving verplicht te registreren wie de gezagvoerder is. Dit gegeven zal worden opgeslagen bij de vlucht.

Binnen de EASA Declared Training Organisation (DTO) is er ook een eis om gegevens op te slaan. Bijvoorbeeld voor het leerling volg systeem dat door instructeurs is te raadplegen. Ook vragen we hiervoor andere gegevens, zoals (nood)contactgegevens, een profielfoto, de geldigheidsdatum van medical. 

Deze gegevens worden tevens gebruikt voor het berekenen van je actuele currency (volgens de currency Barometer) en of je voldoet aan de EASA recency eisen. Wat goed is om te weten is dat jouw currency en recency door alle leden in gezien kan worden om hiermee een collectief bewustzijn van veiligheid te creëren.

Daarnaast worden jouw brevetnummer, KNVvL nummer (verzekering), geldigheid medical, en bevoegdheden binnen de club opgeslagen zodat de club kan zien of je aan de wettelijke eisen of door de club gestelde voorwaarden voldoet.

Tot slot, als jij toestemming hiervoor geeft en jouw club gebruik maakt van deze functionaliteit, dan kun jij jouw bankrekeningnummer invullen en delen we jouw bankrekeningnummer met jouw zweefvliegclub om te zorgen dat er automatisch geïncasseerd kan worden voor, bijvoorbeeld, jouw lidmaatschap, sleepvluchten en andere clubgerelateerde kosten. 


#### 2.2 Gegevens die de Zweef.app zelf verwerkt

We verzamelen gegevens over de bezoekers van onze website, bijvoorbeeld hoeveel bezoekers er geweest zijn en vanaf welke pagina ze zijn doorverwezen. Welke gegevens we vastleggen, hangt af welke informatie je browser meestuurt. Dit kan bijvoorbeeld informatie zijn over je apparaat, je besturingssysteem of het tijdstip waarop je onze website hebt bezocht.

Als je inlogt bij de Zweef.App, moet je je naam en e-mailadres opgeven. Deze gegevens slaan we op in de administratie die je club beheert. Deze gegevens zijn nodig om te kunnen zorgen dat je kunt inloggen, je wachtwoord gereset kan worden en om serviceberichten te kunnen sturen.  Zonder deze gegevens kunnen we je geen gebruik laten maken van de Zweef.App.


### 3. Hoe hebben we jouw gegevens beveiligd?

Om je persoonsgegevens te beveiligen hebben we passende technische en organisatorische maatregelen genomen. De keuze van deze maatregelen hebben we gebaseerd op de beschikbare technologie, de uitvoeringskosten, het type persoonsgegevens dat we van jou verwerken en de risico's die daaraan verbonden zijn. Zo heeft onze website bijvoorbeeld een SSL-certificaat, maken we gebruik van een firewall om misbruik te voorkomen en hebben we encryptie op bepaalde kritieke data. Daarnaast staan al onze servers binnen Europese Unie (Europese Economische Ruimte) en gehost op locaties waarvan data ook niet gedeeld kan worden met de VS (denk aan de EU-US Privacy Shield).

### 4. Met wie delen we je gegevens?

#### 4.1 Gegevens die wij verwerken in opdracht van jouw club

De gegevens die van jou in de Zweef.App worden verwerkt in opdracht van jouw club kunnen (deels) gedeeld worden met andere leden of specifieke groepen leden binnen jouw club (bijvoorbeeld instructeurs). Dat is voor jouw club bijvoorbeeld nodig om te kunnen voldoen aan (EASA en DTO) wetgeving, een veilig vliegbedrijf te kunnen organiseren en om te kunnen voldoen aan voorwaarden van bijvoorbeeld de verzekering van vliegtuigen en voertuigen. Wij voeren dit uit in opdracht van jouw club en jouw club is hiervoor verwerkingsverantwoordelijke. Dit is vastgelegd in een verwerkingsovereenkomst tussen ons en jouw club. Meer informatie over welke gegevens jouw club van jou verwerkt kun je vinden in de privacy mededeling van jouw club. 

Binnen de Zweef.App kan iedere gebruiker kiezen om de privacy modus aan te zetten. Met deze modus zal privacygevoelige data (zoals adresgegevens en vluchtinformatie) afgeschermd worden van andere leden. Echter, systeem beheerders, instructeurs en sleepvliegers (alleen voor sleepvluchten) van je eigen club zullen in dat geval nog wel jouw data in mogen zien. 

Indien jij toestemming hiervoor geeft delen we jouw bankrekening nummer met jouw club om ervoor te zorgen dat er automatisch geïncasseerd kan worden voor, bijvoorbeeld, jouw lidmaatschap en sleepvluchten.

Met andere partijen delen we je gegevens alleen als dit wettelijk verplicht is, denk bijvoorbeeld aan veiligheidsonderzoeken vanuit de Inspectie Leefomgeving en Transport (ILT). In dat geval delen we alleen de persoonsgegevens die we volgens de wet voor een dergelijk onderzoek moeten verstrekken. De  communicatie hierover zal via je club verlopen aangezien die verantwoordelijk zijn voor de verwerking van je gegevens.

Als laatste, we zullen je persoonlijke gegevens en vluchtgegevens nooit voor eigen doeleinden gebruiken, verkopen, uitruilen of verhuren. 

#### 4.2 Gegevens die de Zweef.app zelf verwerkt

Wij zullen je persoonlijke gegevens nooit aan een derde partij overhandigen tenzij wij daartoe wettelijk verplicht zijn.


### 5. Hoe lang bewaren we je gegevens?

We bewaren je persoonsgegevens niet langer dan noodzakelijk is voor het doel waarvoor we ze hebben vastgelegd. Dit geld voor zowel gegevens die wij verwerken in opdracht van jouw club, als gegevens die de Zweef.App zelf verwerkt.

Als je een gebruikersaccount hebt aangemaakt, blijven je gegevens bewaard. Nadat je je account hebt opgezegd, verwijderen we de gegevens binnen 36 maanden definitief volgens de opdracht gekregen vanuit je club. Dit is conform de EASA DTO wetgeving DTO.GEN.135 c).

We bewaren wel de data dat nodig is om als exploitant van de luchthaven te voldoen aan EASA wetgeving, namelijk de naam van de gezagvoerder bij elke vlucht die jij hebt gemaakt.

We maken continu backups van de gegevens die je bij ons opslaat. Backups slaan we voor maximaal 60 dagen op. Dit betekent dat verwijderde gegevens nog maximaal 60 dagen in zo'n back-up bewaard kunnen blijven.


### 6. Welke rechten heb je als het gaat om jouw privacy?

Volgens de privacywetgeving heb je een aantal rechten, zoals het inzien, ontvangen, corrigeren en (laten) verwijderen van jouw gegevens:

Via de portal kun je inzage krijgen in de persoonsgegevens en vluchtdata die we van jou verwerken.

- Persoonsgegevens die feitelijk onjuist zijn kun je daar zelf corrigeren of verwijderen.
- De zichtbaarheid van je persoonsgegevens kan je beperken door de privacy modus aan te schakelen in de Zweef.App.
- Je persoonsgegevens kunnen we digitaal aan jou over te dragen (dataportabiliteit). Wij maken maken in dat geval een export van jouw gerelateerde persoonsgegevens en vluchtdata. Mocht je hier gebruik van willen maken, de export van je data vind je in de __mijn_gegevens__ tab van de Zweef.App.

Heb je overige vragen of verzoeken over het inzien, corrigeren of verwijderen van jouw  gegevens? Neem dan contact op met jouw club.


### 7. Wat kun je doen als je een privacyklacht hebt?

Heb je een klacht over hoe we met de persoonsgegevens omgaan zoals we dat doen in opdracht van jouw club, dan vragen we je om deze klacht eerst met je club te delen. Je club zoekt dan samen met jou, en indien nodig samen met ons, naar een oplossing.

In overige gevallen, mail ons op [contact@zweef.app](mailto:contact@zweef.app). Dit kun je bijvoorbeeld doen als je vermoedt dat er misbruik van je gegevens is gemaakt, of dat er een datalek is geweest.

`;
