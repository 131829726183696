import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { $t } from '~/i18n.js'

import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Modal, Upload } from 'antd'

import { normFileDuringFormUpload } from '../../lib/helpers'
import { adminActions } from '../../redux/actions'

export const AddFindingMessageModal = ({ visible, closeModal, activeFinding, transition, onSave }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (!visible) {
      form.resetFields()
      setLoading(false)
    }
  }, [visible])

  const dispatch = useDispatch()

  const saveFiles = async (message_id, files) => {
    files?.length && files.forEach(file => {
      dispatch(adminActions.saveFindingFile({ message_id, file: file.originFileObj }))
    })
  }

  const saveForm = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)
      const payload = {
        finding_id: activeFinding.id,
        transition,
        message: values.message
      }
      const data = await dispatch(adminActions.saveFindingMessage(payload))

      setLoading(false)
      if (!data.message_id || !data.finding) return

      // custom add shortcut files so it is directly visible
      if (values.files) {
        data.finding.messages.forEach(m => {
          if (m.id === data.message_id) m.files = [...values.files]
        })
      }

      onSave(data.finding)
      closeModal()

      // directly save files, no await
      saveFiles(data.message_id, values.files)
    } catch (info) {
      message.error(`Data is incorrect, ${info?.errorFields?.length} fouten gemeld`)
      console.log('error saving message: ', info)
      setLoading(false)
    }
  }

  return (
    <Modal
      title={transition === '' ? $t('Voeg nieuw bericht toe') : $t('Bevestig actie')}
      open={visible}
      confirmLoading={loading}
      okText={$t('Opslaan')}
      cancelText={$t('Annuleren')}
      onCancel={closeModal}
      onOk={saveForm}
    >

      <Form
        form={form}
        layout='vertical'
        name='bericht'
        preserve={false}
      >

        {transition !== '' && (
          <p className='bold spacer'>{$t('Bevestig')}: {$t(transition)}</p>
        )}

        <Form.Item
          label={$t('Bericht')}
          name='message'
          rules={[
            { required: false, max: 900, message: $t('Maximaal ${c} characters', { c: 900 }) }
          ]}
        >
          <Input.TextArea rows={4} autoFocus />
        </Form.Item>

        <Form.Item
          name='files' label={$t('Bijlage')} valuePropName='fileList'
          getValueFromEvent={normFileDuringFormUpload}
        >
          <Upload
            name='file' multiple
            beforeUpload={(file) => false}
            showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
          >
            <Button icon={<UploadOutlined />} />
            {$t('Selecteer 1 of meerdere bestanden')} bestanden
          </Upload>
        </Form.Item>

      </Form>

    </Modal>
  )
}
