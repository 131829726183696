import { Button, message } from 'antd'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Loader } from '../../components/loader/loader'
import { $t } from '~/i18n.js'
import { getURLparams } from '../../lib/helpers'
import { persistActions } from '../../redux/actions'

const LoginWithAccessToken = ({ history, login_with_accesstoken }) => {
  // data is found in url parameters
  const params = getURLparams()

  // show error or login with acess token
  if (params.error) {
    message.error(params.error)
    history.push('/login')
  }
  if (params.token) login_with_accesstoken({ token: params.token, history })

  const [showHome, setshowHome] = useState(false)
  setTimeout(() => setshowHome(true), 3000)

  return (
    <div style={{ minWidth: '95vw', marginTop: '20vh' }} className='column'>
      <Loader />

      {showHome && <div style={{ marginTop: 42 }} className='column'>
        <p>{$t('Is er iets fout gegaan?')}</p>
        <Button type='primary' onClick={() => history.push('/login')}>
          {$t('Terug naar inlog scherm')}
        </Button>
      </div>}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    login_with_accesstoken: (data) => dispatch(persistActions.login_with_accesstoken(data))
  }
}

export const LoginWithAccessTokenPage = connect(null, mapDispatchToProps)(LoginWithAccessToken)
