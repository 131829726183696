import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { message, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { getUserAvatar } from '../../../components/profile/selectPilot'

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error($t('Je kan alleen een JPG/PNG bestand uploaden'))
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error($t('Het bestand moet kleiner zijn dan 2MB'))
  }
  return isJpgOrPng && isLt2M
}

export const AvatarUpload = ({ profile, saveData }) => {
  const [loading, setLoading] = useState(false)

  const uploadAvatar = async (data) => {
    // save avatar as original file
    setLoading(true)
    await saveData({ avatar: data.file, remain: true })
    setLoading(false)
  }

  return (
    <ImgCrop cropShape='round'>
      <Upload
        name='avatar'
        listType='picture-card'
        className='avatar-uploader'
        showUploadList={false}
        multiple={false}
        beforeUpload={beforeUpload}
        customRequest={uploadAvatar}
      >

        <div className='uploadAvatar' style={{ backgroundImage: `url("${getUserAvatar(profile)}")` }}>
          <div className={`overlay ${loading ? 'loading' : ''}`}>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>{loading ? $t('Laden') : $t('Voeg toe')}</div>
          </div>
        </div>

      </Upload>
    </ImgCrop>
  )
}
