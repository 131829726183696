import { PlusOutlined } from "@ant-design/icons";
import { Button, Collapse } from "antd";
import React from "react";
import { $t } from "~/i18n.js";
import { generate_uuid } from "~/lib/helpers.js";
import { CategoryCollapse } from './CategoryCollapse.jsx';
import { getNextOrderInList, OrderDeleteButtons } from './OrderDeleteButtons.jsx';
import { Flex } from "antd";
import {DifCheckTag} from './DifCheckTag.jsx'

export const ClusterChecklist = ({ 
  categories = [], 
  saveCategories = c => {},
  readOnly= false
}) => {

  const saveCategory = (newCategory) => {
    let newCategories = categories.map(c => {
      if (c.id === newCategory.id) return {...c, ...newCategory}
      return c
    })
    saveCategories(newCategories)
  }

  const addNewCategory = () => {
    let newCategories = categories.concat([
      {
        order: getNextOrderInList(categories),
        id: generate_uuid(),
        action: 'add',
        name: '',
        label: '',
        description: '',
        bevoegdheid: []
      }
    ])
    saveCategories(newCategories)
  }

  let sortedCategories = categories.sort((a, b) => a.order - b.order)
  const items = sortedCategories.map((category, index) => {
    return {
      category: category,
      key: index,
      label: <p>{category.label || category.name} <DifCheckTag action={category.action}/> </p>,
      extra: <OrderDeleteButtons task={category} task_index={index} tasks={sortedCategories} saveTasks={saveCategories} />,
      children: <CategoryCollapse category={category} saveCategory={saveCategory}/>,
    }
  })

  return <Flex vertical={true} gap={12}>
          <Collapse items={items}/>
          <Button style={{ width: 120 }} onClick={addNewCategory} size='small' icon={<PlusOutlined />}>{$t('Voeg toe')} </Button>
        </Flex>
};
