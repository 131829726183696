import { $t } from '~/i18n.js'
import React, { useEffect } from 'react'
import { Select } from 'antd'
import { useDispatch } from 'react-redux'
import { getMaterialTab } from '../../../../redux/materiaal/materiaal.actions'

export const SetRelatedDocument = ({ visible, currentMaterial, documents_used, setDocumentsUsed, list_documents }) => {
  const dispatch = useDispatch()

  // normalize documents_used to array of integers
  documents_used = documents_used.map(m => m.id)

  useEffect(() => {
    if (!visible) return
    if (currentMaterial && !currentMaterial.documents) dispatch(getMaterialTab({ materialID: currentMaterial.id, activeTab: 'documents' }))
  }, [visible, currentMaterial])

  return (
    <div className='row' style={{ gap: 12, marginTop: 9, marginLeft: 12 }}>
      <p style={{ width: 120 }}>Documenten:</p>
      <Select
        style={{ width: '100%' }} showSearch mode='multiple' disabled={!list_documents}
        filterOption={(input, option) => option?.children?.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0}
        value={documents_used} onChange={ids => setDocumentsUsed(ids.map(i => {
          return { id: i }
        }))}
      >
        {list_documents?.map((d, key) => !d.workorder_name &&
          <Select.Option key={key} value={d.id}>
            {d.name} {d.version && <span className='gray'>({d.version})</span>}
          </Select.Option>)}
      </Select>
    </div>
  )
}
