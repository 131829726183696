import { Button, Divider } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $t } from "~/i18n.js";
import { SelectLanguage } from "~/components/profile/selectLanguage.jsx";
import { config } from "~/config.js";
import { updateIfNeeded } from "~/lib/helpers.js";
import { getAllClubs } from "~/redux/persist/persist.actions.js";
import { colors } from "~/theme/colors.js";
import "./www.css";

export const WwwHomePage = ({ history }) => {
  const { all_clubs, lastUpdatedClubs } = useSelector((state) => state.persist);

  const dispatch = useDispatch();

  useEffect(() => {
    updateIfNeeded(lastUpdatedClubs, () => dispatch(getAllClubs()));
  }, [lastUpdatedClubs, dispatch, getAllClubs]);

  const url = config.isZweef ? "zweef.app" : "gliding.app";
  
  return (
    <div className="modalLayout">
      <div
        className="modalContent"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          className="modal"
          style={{ maxWidth: 700, margin: "20vh auto 120px auto" }}
        >
          <div
            className="column"
            style={{
              width: "100%",
              minHeight: 100,
              backgroundColor: colors.white_off,
            }}
          >
            <div
              className={`homeLogo ${
                window.isZweef() ? "ZweefLogo" : "GlideLogo"
              } `}
            />
            <p style={{ marginBottom: 24 }}>
              {$t("Compleet")} | {$t("Eenvoudig")} | {$t("EASA-proof")}
            </p>
          </div>

          <div
            className="column"
            style={{ width: "100%", padding: 24, textAlign: "center" }}
          >
            <SelectLanguage />

            <p style={{ marginTop: 12 }}>
              {$t("Het administratiesysteem voor zweefvliegclubs")}
            </p>

            <Button
              type="link"
              style={{ marginTop: 12 }}
              href={
                config.isZweef
                  ? "https://bit.ly/ZweefApp"
                  : "https://bit.ly/GlidingApp"
              }
              target="blank"
              size="small"
            >
              {$t("Bekijk introductie presentatie")}
            </Button>

            <Divider />

            <div className="clubs row" style={{ justifyContent: "center" }}>
              {all_clubs &&
                all_clubs.map(
                  (club, key) =>
                    club.logo &&
                    club.on_homepage && (
                      <a
                        className="club row clickable"
                        key={key}
                        href={`https://${club.schema_name}.${url}`}
                      >
                        <div
                          className="clublogo"
                          style={{
                            backgroundImage: `url('${config.media_prefix}${club.logo}')`,
                          }}
                        />
                        <p>{club.full_name}</p>
                      </a>
                    )
                )}
            </div>

            <Divider />

            <p style={{ marginBottom: 12 }}>
              {$t(
                "Wil je vrijblijvend de Zweef.App uitproberen voor je eigen club?"
              )}
            </p>

            <Button
              type="primary"
              onClick={() => history.push("/registration")}
            >
              {$t("Registreer je club")}
            </Button>
          </div>
        </div>
      </div>

      <div className="centerBackground fadeIn" />

      <www_footer>
        <a href={`//app.${url}/terms`}>
          <span>{$t("Voorwaarden")}</span>
        </a>
        |
        <a href={`//app.${url}/privacy`}>
          <span>{$t("Privacy")}</span>
        </a>
        |
        <a href={`//app.${url}/pricing`}>
          <span>{$t("Prijsplan")}</span>
        </a>
        |
        <SelectLanguage naked />
      </www_footer>
    </div>
  );
};
