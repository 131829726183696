import { $t } from '~/i18n.js'
import React from 'react'
import { Button } from 'antd'
import './404_not_found.css'

export class NotFound extends React.Component {
  render ({ history } = this.props) {
    return (
      <div className='not_found'>

        <h2>404.</h2>
        <p>{$t('Pagina niet gevonden.')}</p>

        <Button className='homeButton' type='primary' onClick={() => this.props.history.push('/')}>
          {$t('Terug')}
        </Button>

      </div>
    )
  }
}
