import { Divider, Modal, Result, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";

import { SluitButton } from "~/components/buttons/SluitButton.jsx";
import { CardConfirm } from "./card.confirm.jsx";
import { CardData } from "./card.data.jsx";
import { CardDay } from "./card.day.jsx";
import { CardRooster } from "./card.rooster.jsx";

export const AddRoosterModal = ({
  modalVisible,
  closeModal,
  saveRooster,
  editRoosterData,
  users,
}) => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState(null);

  useEffect(() => {
    setStep(0);
    setData(
      editRoosterData?.id
        ? editRoosterData
        : {
            date_from: null,
            date_to: null,
            day: {},
            days: [],
            roosters: [],
            users: [],
            is_vliegend: true,
          }
    );
  }, [modalVisible, editRoosterData]);

  const saveAndNext = (newData) => {
    setData({ ...data, ...newData });
    setStep(step + 1);
  };
  if (data === null) return null;

  const prev = () => setStep(step - 1);

  return (
    <Modal
      title={$t("Voeg rooster toe")}
      open={modalVisible}
      onCancel={closeModal}
      footer={null}
      width="750px"
    >
      <div style={{ padding: 24 }}>
        <Steps size="small" current={step}>
          <Steps.Step title={$t("Beschrijving")} />
          <Steps.Step title={$t("Dagen")} />
          <Steps.Step title={$t("Roosters")} />
          <Steps.Step title={$t("Bevestig")} />
        </Steps>

        <Divider />

        <div className="myDataCards row" style={{ justifyContent: "center" }}>
          {step === 0 && <CardData data={data} saveAndNext={saveAndNext} />}
          {step === 1 && (
            <CardDay data={data} saveAndNext={saveAndNext} prev={prev} />
          )}
          {step === 2 && (
            <CardRooster
              users={users}
              data={data}
              saveAndNext={saveAndNext}
              prev={prev}
            />
          )}
          {step === 3 && (
            <CardConfirm
              data={data}
              saveAndNext={saveAndNext}
              prev={prev}
              saveData={saveRooster}
            />
          )}
        </div>

        {step > 3 && (
          <Result
            status="success"
            title={$t("Rooster opgeslagen")}
            subTitle={$t(
              "Het rooster is opgeslagen, je kan nu de diensten invullen."
            )}
            extra={[<SluitButton key="sluit" onClick={closeModal} />]}
          />
        )}
      </div>
    </Modal>
  );
};
