import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";

import { EditOutlined } from "@ant-design/icons";
import { Button, DatePicker, Modal } from "antd";
import { api } from "../../lib/api.js";
import { HelpBubble } from "../help/help.bubble.jsx";

export const InstructorRefresher = ({ pilot, recency, setRecency }) => {
  const [showModal, setShowModal] = useState(false);

  const [dates, setDates] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setDates({
      fis_date_refresher_course: recency.fis_date_refresher_course
        ? dayjs(recency.fis_date_refresher_course)
        : null,
      fis_date_training_flight: recency.fis_date_training_flight
        ? dayjs(recency.fis_date_training_flight)
        : null,
      fis_date_exam: recency.fis_date_exam
        ? dayjs(recency.fis_date_exam)
        : null,
      fes_refresher: recency.fes_refresher
        ? dayjs(recency.fes_refresher)
        : null,
    });
    setLoading(false);
  }, [recency]);

  const triggerSave = async () => {
    setLoading(true);
    const res = await api.post("gegevens/fis_dates.json", {
      user_id: pilot.id,
      fis_date_refresher_course: dates.fis_date_refresher_course
        ? dates.fis_date_refresher_course.format("YYYY-MM-DD")
        : null,
      fis_date_training_flight: dates.fis_date_training_flight
        ? dates.fis_date_training_flight.format("YYYY-MM-DD")
        : null,
      fis_date_exam: dates.fis_date_exam
        ? dates.fis_date_exam.format("YYYY-MM-DD")
        : null,
      fes_refresher: dates.fes_refresher
        ? dates.fes_refresher.format("YYYY-MM-DD")
        : null,
    });
    if (res) {
      setRecency(res?.currency?.recency);
      setShowModal(false);
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        size="small"
        type="link"
        icon={<EditOutlined />}
        onClick={() => setShowModal(true)}
      >
        {$t("Wijzig datum refresher, Instr. flt en examen")}
      </Button>

      <Modal
        title={$t("Wijzig datum refresher course en Instr. flt")}
        open={showModal}
        okText={$t("Opslaan")}
        cancelText={$t("Annuleren")}
        confirmLoading={loading}
        onCancel={() => setShowModal(false)}
        onOk={triggerSave}
      >
        <p />
        {$t(
          'Voor instructeurs gelden de EASA FI(s) recencyeisen, waaronder in de afgelopen 3 jaar een Instructor Refresher Training, en in de afgelopen 9 jaar een "Instruction flight under supervision".'
        )}

        <div style={{ marginTop: 12 }}>
          <span>{$t("Instructor Refresher Training")}</span>
          <br />
          <DatePicker
            value={dates.fis_date_refresher_course}
            style={{ marginLeft: 12, width: 200 }}
            format={localStorage.localeFormat}
            onChange={(v) =>
              setDates({ ...dates, fis_date_refresher_course: v })
            }
            disabledDate={(current) =>
              current && current > dayjs().endOf("day")
            }
          />
        </div>

        <div style={{ marginTop: 12 }}>
          <span>{$t("Instruction flight under supervision")}</span>
          <br />
          <DatePicker
            value={dates.fis_date_training_flight}
            style={{ marginLeft: 12, width: 200 }}
            format={localStorage.localeFormat}
            onChange={(v) =>
              setDates({ ...dates, fis_date_training_flight: v })
            }
            disabledDate={(current) =>
              current && current > dayjs().endOf("day")
            }
          />
        </div>

        <div style={{ marginTop: 12 }}>
          <span>
            {$t("Datum examen, wanneer restrictie eraf is gegaan")}
            <HelpBubble
              content={$t(
                "Na deze datum gaat de datum in waarop de vlieger aan starts en uren recency moet voldoen"
              )}
            />
            :
          </span>
          <br />

          <DatePicker
            value={dates.fis_date_exam}
            style={{ marginLeft: 12, width: 200 }}
            format={localStorage.localeFormat}
            onChange={(v) => setDates({ ...dates, fis_date_exam: v })}
            disabledDate={(current) =>
              current && current > dayjs().endOf("day")
            }
          />
        </div>

        {recency.is_fes && (
          <>
            <div style={{ marginTop: 12 }}>
              <span>
                {$t(
                  'Recency voor examinatoren die elke 5 jaar een "FE(S) refresher course" moeten volgen.'
                )}
              </span>
              <br />
              <DatePicker
                value={dates.fes_refresher}
                style={{ marginLeft: 12, width: 200 }}
                format={localStorage.localeFormat}
                onChange={(v) => setDates({ ...dates, fes_refresher: v })}
                disabledDate={(current) =>
                  current && current > dayjs().endOf("day")
                }
              />
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
