import { i18n } from '~/i18n'
import en from './docs/en.md?raw'
import nl from './docs/nl.md?raw'

const lookup = {
  nl,
  en
}

export const helpDocs = () => lookup[i18n.language] || en
