import { Layout } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import useMedia from "use-media";
import { Logo } from "../logo";
import { OnlineIndicator } from "../onlineIndicator";
import { MobileMenu } from "./MobileMenu";
import { ProfileMenu } from "./ProfileMenu";

export function AppHeader({ triggerShowCheckModal }) {
  const persist = useSelector((state) => state.persist);
  const isNarrow = useMedia({ maxWidth: "1000px" }, true);
  const showMobileMenu = isNarrow;

  return (
    <Layout.Header
      style={{
        position: "fixed",
        top: 0,
        zIndex: 500,
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {showMobileMenu && (
        <MobileMenu
          showCheckDataButton={persist.profile.check_required}
          triggerShowCheckModal={triggerShowCheckModal}
          profile={persist.profile}
          club={persist.club}
        />
      )}

      {/* <LargeMenu mode="vertical" /> */}

      <a className="row" href={"/"}>
        {persist.club && persist.club.logo && <Logo url={persist.club.logo} />}

        {persist.club && (
          <p
            className="white"
            style={{ fontWeight: 700, fontSize: 15 }}
          >
            {isNarrow ? persist.club.name : persist.club.full_name}
          </p>
        )}
      </a>

      <div style={{ flexGrow: 1 }} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <OnlineIndicator />

        {(persist.profile.vliegt ||
          persist.profile.group_keys?.includes("niet_vliegend")) && (
          <ProfileMenu persist={persist} />
        )}
      </div>
    </Layout.Header>
  );
}
