export const normalizePeriod = (period) => {
  // normalizes event data for frontend
  if (!period) return null

  // filter out open registraties
  period.open_registraties = period.registraties.filter((r) => r.status === 'in_aanvraag' && !r.is_prive)

  // in verplichtingen, get all gemaakte uren per lid
  const uren_gemaakt = period.registraties.reduce((all, r) => {
    // check if lid already in dictionary
    if (!all[r.user_id]) all[r.user_id] = 0

    // add in total gemaakte uren
    if (r.status !== 'afgewezen' && !r.is_prive) all[r.user_id] += r.uren

    return all
  }, {})

  // then use this dictionary to fill out verplichtingen
  period.verplichtingen.forEach(v => {
    v.uren_gemaakt = uren_gemaakt[v.user_id] || 0
    v.uren_open = v.uren_gemaakt > v.uren_verplichting ? 0 : (v.uren_verplichting - v.uren_gemaakt)
  })

  return period
}
