export const TermsVersionNL = "Versie 2, 17 feb 2023";

export const TermsTextNL = `

  Als jij een account aanmaakt voor jouw Zweefvliegclub bij de Zweef.App gaan wij samen afspraken aan. Wij vinden het belangrijk om transparant te zijn. In deze verklaring leggen wij vast hoe we met elkaar omgaan en we veilig en privacyvriendelijk met elkaar's data omgaan.

  Heb je vragen over de Zweef.App, over deze voorwaardes? Mail ons op [contact@zweef.app](mailto:contact@zweef.app). 
  
  
  ### 1. Wie is wie?
  
  Als je in deze voorwaardes 'jij', 'je' of 'jou(w)' leest, bedoelen we jou als admin gebruiker van de Zweef.App en “Vertegenwoordiger” van jouw club. Met “club” bedoelen we de zweefvliegclub waar jij ofwel een bestuurder van bent (zoals ingeschreven in de KvK) of de bevoegdheid vanuit het bestuur hebben gegeven om die te vertegenwoordigen en zelfstandige handelingen in de Zweef.App te verrichten. We hoeven dit niet na te gaan. Voor zover nodig geef je hierbij een onherroepelijke volmacht aan iedere Vertegenwoordiger om jouw club zelfstandig te vertegenwoordigen. 
  
  Lees je 'wij', 'we', 'ons', of 'onze', dan bedoelen we de Zweef.App (ingeschreven bij de Kamer van Koophandel onder nummer 88819051).
  
  
  ### 2. Wanneer gelden deze voorwaarden? 
  
  Deze overeenkomst komt tot stand als je voor het eerst gebruikmaakt van de Zweef.App, waar je expliciet akkoord geeft op de overeenkomst voordat het account van je club wordt aangemaakt. We werken altijd op basis van deze algemene voorwaarden. We wijken hier alleen van af als we dit expliciet per e-mail zijn overeengekomen.
  
  
  
  ### 3. De Zweef.App: wat kan je wel en niet van ons verwachten? 
  
  Wij willen graag dat je Zweef.App met veel plezier gebruikt. Wij doen daarom ons best om Zweef.App altijd goed te laten werken en constant te verbeteren.
  
  We kunnen echter niet garanderen dat Zweef.App altijd en volledig beschikbaar is en zonder storingen werkt. Ook kunnen we niet garanderen dat Zweef.App op elk besturingssysteem, elke browser, tablet of elk (mobiel) apparaat te gebruiken is.
  
  Daarnaast kunnen we Zweef.App soms tijdelijk buiten gebruik stellen om onderhoud te plegen, aanpassingen te doen of de werking te verbeteren. Dit proberen we tijdens de winter of anders als nodig 's nachts of doordeweeks te doen als we niet vliegen, zodat je er zo min mogelijk last van hebt.
  
  We zijn vrij om onderdelen van Zweef.App aan te passen, uit te breiden, te schrappen of te wijzigen. Dit kan betekenen dat bepaalde functionaliteiten of diensten op een bepaald moment niet meer beschikbaar zijn, of dat de Zweef.App niet meer werkt op bepaalde besturingssystemen of (mobiele) apparaten. We zorgen er in ieder geval voor dat er niet meer of op een aanvullende manier persoonsgegevens verwerkt worden dan beschreven in artikel 9.
  
  We doen er alles aan om ervoor te zorgen dat Zweef.App actueel, accuraat en foutloos is, maar we kunnen hier niet honderd procent voor instaan. Zo kan het gebeuren dat berekeningen, overzichten of rapportages die jij in Zweef.App maakt, fouten bevatten. Dit kan het gevolg zijn van een systeemfout in Zweef.App, maar ook van verkeerde informatie of gegevens die jij zelf hebt ingevoerd. 
  
  ### 4. Wat zijn jouw verantwoordelijkheden? 
  
  Je bent verantwoordelijk voor het goed gebruik van de Zweef.App voor jouw club en de handelingen die met jouw gebruikersaccount worden verricht. Je bent verantwoordelijk om de correcte informatie over jouw club in te voeren. Ook ben je verantwoordelijk als iemand anders jouw wachtwoord heeft gebruikt of doordat je anderen gebruik laat maken van jouw account, wij aanvaarden daar geen aansprakelijkheid voor. 
  
  Vermoed je dat er met jouw gebruikersaccount iets vreemds of ongebruikelijks aan de hand is? Laat het ons weten via [contact@zweef.app](mailto:contact@zweef.app) en dan kunnen we je wachtwoord resetten of account (tijdelijk) deactiveren. 
  
  
  ### 5. Welke prijzen gelden er voor het gebruik van de Zweef.App?
  
  Het aanmaken van een testaccount is gratis en vrijblijvend. Met dit testaccount kun je maximaal 6 gebruikers toevoegen om gedurende een periode van 12 maanden de Zweef.App gratis en vrijblijvend uit te proberen. Tijdens deze periode bieden wij de mogelijkheid om de data van jouw club te importeren.
  
  Vanaf het moment dat je meer dan 6 gebruikers gaat activeren gaan wij ervanuit dat je de Zweef.App gaat inzetten voor je club, en verandert de status van je account naar een betaald account. 
  
  Op dit moment bieden we een betaald account dat uitgaat van het aantal actieve leden die je hebt ingeladen in de groep “Zweefvlieger”. Dit kan in de toekomst veranderen. Op onze website vind je meer informatie hierover.
  
  Indien je niet binnen 12 maanden overgaat naar een betaald account, dan behouden we ons het recht om je testaccount af te sluiten. We zullen dit met jou overleggen, het kan zijn dat je meer tijd nodig hebt om je club voor te bereiden voor het gebruik van de Zweef.App. 
  
  
  ### 6. Hoe werkt de betaling?
  
  Op het moment dat je testaccount omgezet wordt naar een betaald account, moet je geldige betaalgegevens opgeven. Wij streven ernaar om je jaarlijks in Januari een factuur te sturen voor het totaal bedrag van dat jaar. Mocht je later in het jaar omgegaan zijn naar een betaald account, dan brengen we pro rata voor het resterende deel van het jaar de kosten in rekening. 
  
  Zorg dat je het bedrag binnen de betaaltermijn van 4 weken betaalt, en vermeld daarbij het referentienummer. Betaal je te laat, dan kunnen we wettelijke handels rente en incassokosten in rekening brengen. Ook kunnen we jou en je club tijdelijk de toegang tot de Zweef.App ontzeggen, totdat je de openstaande facturen hebt betaald. Daarnaast hebben we het recht om de overeenkomst te ontbinden, onze dienstverlening aan jou te stoppen en je Zweef.App account zelfs te verwijderen. We zijn in dat geval niet aansprakelijk voor eventuele schade die je lijdt. Deze clausule hebben we erin staan wegens juridische redenen, we gaan ervanuit gezien de vriendelijke en hobbymatig karakter van onze sport dat dit nooit nodig zal zijn.
  
  
  ### 7. Kunnen wij de prijzen wijzigen? En wat kun jij dan doen? 
  
  Prijzen kunnen jaarlijks aangepast worden op basis van de inflatiecorrectie vanuit CBS. Hiervoor geldt wel een motivatieplicht vanuit ons, we kunnen niet zomaar onze prijzen wijzigen. Het kan wel zijn dat we in de toekomst extra functionaliteiten aanbieden waarvoor een andere prijs geldt. In beide gevallen, als we de prijzen wijzigen, melden we je dit altijd ten minste voor 1 oktober. Ben je het niet met de wijziging eens, dan kan je onze overeenkomst kosteloos opzeggen aan het einde van het jaar. Blijf je na ingang van de prijswijziging in het nieuwe jaar van de Zweef.App gebruik maken, dan geef je daarmee aan dat je akkoord bent met de wijziging.
  
  
  ### 8. Opzeggen
  
  Jouw relatie met ons heeft geen einddatum. Wil je deze toch opzeggen? Je kan dit jaarlijks doen door voor 28 december een opzeggings e-mail naar [contact@zweef.app](mailto:contact@zweef.app) te sturen. Na het opzeggen ontvang je al je club gegevens in een een volledige datadump, inclusief backups van bestanden. Wij deactiveren jouw account en slaan een backup van je data op voor 12 maanden na het deactiveren. Na deze periode verwijderen we alle gegevens van jouw en je club. 
  
  Wij kunnen de relatie ook opzeggen, en zullen dat doen met een opzegtermijn van 3 maanden. We kunnen dit onder meer doen als er - bijvoorbeeld - vermoeden is dat er onrechtmatig gebruik wordt gemaakt van onze dienst, als blijkt dat opgegeven informatie door jouw als vertegenwoordiger van jouw club niet blijkt te kloppen, als de voortzetting van onze relatie met jou resulteert in aansprakelijkheidsrisico voor ons, als je niet op tijd wilt betalen. 
  
  Mochten wij de relatie opzeggen omdat wij als Zweef.App stoppen met het aanbieden van onze dienst, dan zullen we doen met een opzegtermijn van 12 maanden en zullen we alle aangesloten clubs toegang geven tot de broncode via een publiek toegankelijke GIT repository, en de rechten overdragen om de broncode onder Creative Commons Share-Alike (Gelijk delen) licentie. Tezamen met de datadump van clubs, kan je deze periode gebruiken om een nieuwe partij te zoeken die de dienst te continueren of de benodigde stappen ondernemen om zelf de dienst te continueren. 
  
  We zijn na opzegging van de relatie met jou niet aansprakelijk voor schade die jij lijdt of kosten die jij maakt, voor bijvoorbeeld het niet op tijd downloaden van je club gegevens. 
  
  
  ### 9. Privacy en persoonsgegevens
  
  In de Zweef.App kan je persoonsgegevens van derden (de leden van jouw club) vastleggen, denk aan e-mailadressen. Hierdoor moeten wij beiden voldoen aan de Algemene verordening gegevensbescherming (AVG). Juridisch gezien wordt de club aangemerkt als verantwoordelijke voor de verwerking van de genoemde persoonsgegevens. Wij zelf worden aangemerkt als de verwerker. We beschouwen deze bepaling als de verwerkersovereenkomst die we op grond van de AVG moeten afsluiten.
  
  Ter info, voor gebruikers die zelf inloggen op de Zweef.App hebben we een aparte privacy mededeling, deze kan je lezen in de privacyverklaring op onze website onder [app.zweef.app/privacy](https://app.zweef.app/privacy).
  
  
  #### 9.1 Algemene beschrijving
  
  Wij verwerken gegevens in opdracht van de club met als doel de digitale zweefvliegadministratie van de club te faciliteren. Denk daarbij aan zaken als de startadministratie, EASA Declared Training Organisation (DTO) administratie, bevoegdheden, of currency van leden. Hierbij worden de gegevens van leden van de club verwerkt zoals hieronder genoemd:
  
  - Naam
  - Contactgegevens
  - Noodcontactgegevens
  - Geldigheid medical
  - Brevet-, KNVvL-, AML-nummer
  - Gemaakte starts waaronder datum, tijdstip, registratienummer vliegtuig en rol.
  - Voortgang en aantekeningen DTO
  - Bevoegdheden binnen de club
  - Aanmeldingen, diensten en/of reserveringen
  
  Deze gegevens worden verwerkt en toegankelijk gemaakt aan andere leden van de club dan wel een specifieke groep leden van de club (bijvoorbeeld instructeurs, bestuur of adminleden), op basis van door de club geconfigureerde groepen om te kunnen voldoen aan de wettelijke verplichtingen van de zweefvliegclub volgend uit de EASA en DTO regels, een vliegbedrijf te kunnen organiseren en te voldoen aan voorwaarden van bijvoorbeeld de verzekering van vliegtuigen en materieel. Wij zorgen ervoor dat we voldoen aan de vereisten van de wet- en regelgeving rondom de verwerking van persoonsgegevens.
   
  #### 9.2 Instructies rondom verwerking
  
  Wij verwerken de gegevens zoals benoemd in artikel 9.1. We gebruiken de persoonsgegevens niet voor andere doeleinden of op andere wijze dan hierin beschreven is.
   
  #### 9.3 Geheimhouding
  
  Wij zorgen er beiden (jij als Vertegenwoordiger van je club en wij als de Zweef.App) voor dat alle personen die toegang hebben tot de genoemde persoonsgegevens, deze niet met anderen zullen delen en geheimhouden. Alleen als wij daar vanwege een wettelijk voorschrift toe verplicht zijn zullen we hiertoe overgaan, en zullen we de club zo snel mogelijk hierover informeren.
   
  #### 9.4 Beveiliging
  
  Om de persoonsgegevens te beveiligen hebben we passende technische en organisatorische maatregelen genomen. De keuze van deze maatregelen hebben we gebaseerd op de beschikbare technologie, de uitvoeringskosten, het type persoonsgegevens dat we verwerken en de risico's die daaraan verbonden zijn. Zo heeft onze website bijvoorbeeld een SSL-certificaat, maken we gebruik van een firewall om misbruik te voorkomen en hebben we encryptie op bepaalde kritieke data. Daarnaast staan al onze servers binnen Europese Unie (Europese Economische Ruimte) en gehost op locaties waarvan data ook niet gedeeld kan worden met de VS (denk aan de EU-US Privacy Shield).
   
  #### 9.5 Subverwerkers
  
  Wij hosten onze dienst op Amazon binnen de Europese Unie (Europese Economische Ruimte), die bij ons als bij ons als subverwerker optreed, waar we zowel onze servers als databases hebben draaien.
   
  #### 9.6 Privacyrechten
  
  De gegevens die jij of je clubgenoten invoeren in Zweef.App, zijn altijd van jou. We zullen dus nooit het eigendom claimen van gegevens die je zelf hebt ingevoerd. 
  
  Als verantwoordelijke voor de verwerking van persoonsgegevens, ben jij bevoegd om alle data van jouw club in te zien, te wijzigen of te verwijderen als dat nodig blijkt te zijn.
  
  Een klacht of verzoek van een betrokkene met betrekking tot de verwerking van persoonsgegevens, zoals beschreven in artikel 9.1, wordt door ons doorgestuurd naar jouw club, die verantwoordelijk is voor de afhandeling van het verzoek. Wij zullen volledig meewerken om te zorgen dat jullie - binnen de wettelijke termijnen - hieraan kunnen voldoen.
   
  #### 9.7 Melding bij een datalek
  
  Zodra wij merken dat er een datalek, een onrechtmatige verwerking van persoonsgegevens of een inbreuk op onze systeem is gebeurd zullen we dit direct melden zodat we het incident kunnen beoordelen en passende vervolgstappen te kunnen zetten. Uiteraard zullen we beide het het incident beoordelen, en samen bepalen welke maatregelen we kunnen treffen om de impact te beperken en dit in de toekomst te voorkomen. 
  
  #### 9.8 Opslag termijn en Gegevens verwijderen
  
  Gegevens van individuele leden die door jou als club worden ingevoerd worden bewaard zolang als het lid als actief lid wordt aangemerkt door de club. Na verwijdering van het lid worden de gegevens nog 36 maanden bewaard om te kunnen voldoen aan EASA en DTO bewaartermijnen.
  
  Wij zijn verplicht om bij beëindiging van onze relatie de verwerkte persoonsgegevens te verwijderen, en zullen dit doen zoals we beschreven hebben in artikel 8.
   
  We maken continu backups van de gegevens die je bij ons opslaat. Backups slaan we voor maximaal 60 dagen op. Dit betekent dat verwijderde gegevens nog maximaal 60 dagen in zo'n back-up bewaard kunnen blijven.
   
  #### 9.9 Audits
  
  Mocht je een audit willen doen om te zien of wij aan alle wettelijke verplichtingen voldoen, dan werken wij hieraan mee. Na overleg mag jij een onafhankelijke auditor vragen, en op jullie kosten mag deze de audit uitvoeren. Wij verlenen hierbij alle medewerking.
  
  ### 10. Kunnen we deze voorwaarden wijzigen? En wat kan jij dan doen? 
  
  We kunnen deze voorwaarden af en toe wijzigen. We laten je dit altijd ruim van tevoren per e-mail weten, en vragen we je expliciet om opnieuw akkoord te gaan met de voorwaardes. Ben je het niet met de wijziging eens, dan kan je per direct onze overeenkomst opzeggen. 
  
  
  ### 11. Geschillen: hoe lossen we die op? 
  
  Dit is ook een clausule die juridisch gezien in onze voorwaarden staat, maar hopelijk nooit nodig zal zijn. Als we een geschil hebben, doen we namelijk ons best om samen met jou tot een oplossing te komen. Laat het daarom zo snel mogelijk weten als je ergens niet tevreden over bent. Lukt het niet om samen een oplossing te vinden, dan leggen we ons geschil voor aan de bevoegde rechter in Utrecht. Voor onze overeenkomst geldt het Nederlandse recht.
`;
