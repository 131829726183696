import { DatePicker, Form, Input, InputNumber } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'
import { DataCard } from '../../../components/dataCards/dataCard'

export const CardBrevet = ({ profile, gegevens, club, saveData, type }) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const saveForm = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()
      const date_brevet_afgifte = values.date_brevet_afgifte ? values.date_brevet_afgifte.format('YYYY-MM-DD') : null

      // for now fis dates are setup by backend
      // let fis_date_refresher_course = values.fis_date_refresher_course ? values.fis_date_refresher_course.format('YYYY-MM-DD') : null;
      // let fis_date_training_flight = values.fis_date_training_flight ? values.fis_date_training_flight.format('YYYY-MM-DD') : null;
      const fis_initial_hours = values.fis_initial_hours
      const knvvl = values.knvvl
      const brevet_nummer = values.brevet_nummer
      const aml_nummer = values.aml_nummer

      // manipulate values for save
      const res = await saveData({
        gegevens: { date_brevet_afgifte, fis_initial_hours, knvvl, brevet_nummer, aml_nummer }
      })
      setLoading(false)
      return res
    } catch (info) {
      console.log('Validate Failed:', info)
      setLoading(false)
    }
  }

  const is_instructeur = profile.is_instructeur
  const is_brevet = profile.group_keys?.indexOf('brevet') > -1
  const is_technicus = profile.group_keys?.includes('technicus_vliegend') || profile.group_keys?.includes('technicus_arc')
  const is_nl = club?.country === 'NL'

  return (
    <DataCard title={$t('Brevet')} onSave={saveForm} loading={loading} type={type}>

      {(is_brevet || is_instructeur || is_nl || is_technicus) && <Form
        form={form}
        name='Brevet'
        layout='vertical'
        initialValues={{
          date_brevet_afgifte: gegevens.date_brevet_afgifte && dayjs(gegevens.date_brevet_afgifte, 'YYYY-MM-DD'),

          // updated in backend for now
          // fis_date_refresher_course: gegevens.fis_date_refresher_course && dayjs(gegevens.fis_date_refresher_course, 'YYYY-MM-DD'),
          // fis_date_training_flight: gegevens.fis_date_training_flight && dayjs(gegevens.fis_date_training_flight, 'YYYY-MM-DD'),
          fis_initial_hours: gegevens.fis_initial_hours,
          knvvl: gegevens.knvvl,
          brevet_nummer: gegevens.brevet_nummer,
          aml_nummer: gegevens.aml_nummer
        }}
        requiredMark={false}
      >

        {(is_instructeur || is_brevet) && <>

          <p>{$t('(SPL) Brevet nummer & datum uitgifte')}:</p>
          <Form.Item name='brevet_nummer'>
            <Input placeholder={$t('Brevet nummer')} />
          </Form.Item>

          <p className='small'>{$t('Staat op je brevet onder punt X, vanaf deze datum berekent het systeem je rolling recency.')}</p>
          <Form.Item name='date_brevet_afgifte'>
            <DatePicker format={localStorage.localeFormat} />
          </Form.Item>
        </>}

        {(is_technicus) && <>
          <p>{$t('Technicus licentie (AML) nummer:')}</p>
          <Form.Item name='aml_nummer'>
            <Input placeholder={$t('AML nummer')} />
          </Form.Item>

        </>}

        {is_nl && <>
          <p>{$t('KNVvL lidnummer')}</p>
          <Form.Item
            name='knvvl'
          >
            <Input />
          </Form.Item>
        </>}

        {is_instructeur && <>
          <p>{$t('Start uren instructie FI(s)')}</p>
          <p className='small' style={{}}>{$t('Nodig om je totaal FI(s) uur bij te werken met vluchten gemaakt in het verleden.')}</p>
          <Form.Item
            name='fis_initial_hours'
            rules={[{ required: false, message: $t('Vul in') }]}
          >
            <InputNumber
              min={0}
              formatter={value => `${value} ${$t('uren')}`}
              parser={value => value.replace(/\D/g, '')}
            />
          </Form.Item>

        </>}

      </Form>}

    </DataCard>
  )
}
