import { Button, Divider, Input, message, Modal } from 'antd'
import React, { useState } from 'react'
import { $t } from '~/i18n.js'

import { DataCard } from '../../../components/dataCards/dataCard'
import { api } from '../../../lib/api'
import { requestPasswordReset } from '../../login/password_forget'

const successEmailCheck = () => {
  Modal.success({
    title: $t('Check je email'),
    content: $t('We hebben je nét een email gestuurd met een link erin om je nieuwe email address te bevestigen.')
  })
}

export const CardSecurity = ({ profile }) => {
  const [savingAction, setSavingAction] = useState('')
  const [newEmail, setNewEmail] = useState('')

  const fireRequestPasswordReset = async () => {
    setSavingAction('password')
    await requestPasswordReset(profile.email)
    setSavingAction()
  }

  const fireNewEmail = async () => {
    if (!newEmail || newEmail.indexOf('@') === -1) {
      message.error('Voeg een valide email toe')
    } else {
      setSavingAction('email')
      const res = await api.post('auth/send_new_email_verification.json', { new_email: newEmail })
      if (res) successEmailCheck()
      setSavingAction()
      setNewEmail('')
    }
  }

  return (
    <DataCard title={$t('Inloggegevens')} type='card' readOnly={true}>

      <div className='row spacer'>

        <Input
          style={{ width: 210, marginRight: 24 }} onChange={(event) => setNewEmail(event.target.value)}
          placeholder={$t('Nieuw emailadres')}
          type='email' value={newEmail}
        />
        <Button loading={savingAction === 'email'} onClick={fireNewEmail}>
          {$t('Wijzig email')}
        </Button>
      </div>

      <p className='small' style={{  }}>{$t('Je email is nu')} <span className='bold'>{profile.email}</span>. {$t('Als je het wilt wijzigen versturen we je een bevestiging naar je nieuwe email adres')}.
      </p>

      <Divider />

      <Button loading={savingAction === 'password'} onClick={fireRequestPasswordReset}>
        {$t('Wijzig wachtwoord')}
      </Button>

      <p className='small' style={{ marginTop: 12, marginBottom: 6  }}>{$t('Wachtwoord wijzigen? We sturen je een email met vervolginstructies.')}</p>

    </DataCard>
  )
}
