import { Button, Card, Form, Layout, Skeleton, Anchor, Row, Col, Flex } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BreadcrumbHeader } from "~/components/layout/breadcrumbHeader.jsx";
import { $t } from "~/i18n.js";
import { api } from "~/lib/api.js";
import { Airport } from "./cards/Airport.jsx";
import { Basics } from "./cards/Basics.jsx";
import { ContactList } from "./cards/ContactList.jsx";
import { Financial } from "./cards/Financial.jsx";
import { NameLogo } from "./cards/NameLogo.jsx";
import { PlanTerms } from "./cards/PlanTerms.jsx";
import { Export } from "./cards/Export.jsx";
import { SelectableList } from "./cards/SelectableList.jsx";
import { VerslagList } from "./cards/VerslagList.jsx";
import { APItokens } from "./cards/APItokens.jsx";
import { RefreshBaseData } from "~/components/buttons/RefreshBaseData.jsx";

export const AdminSettings = () => {
  // to save club data to persist
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [clubData, setClubData] = useState({});
  const [loading, setLoading] = useState(false);
  
  const fetchData = async () => {
    setLoading(true);
    const data = await api.get('admin/club_settings.json');
    if (data) {
      setClubData(data);
      form.setFieldValue(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [saving, setSaving] = useState(false);
  const saveData = async () => {
    let values = await form.validateFields();
    setSaving(true);

    if (!values.logo?.size) {
      delete values["logo"];
    }

    const data = await api.post_form(`admin/club_settings.json`, values);
    if (data) {
      setClubData(data);
      form.setFieldValue(data);

      // match to persist redux state
      dispatch({
        type: "SET_CLUB",
        club: data,
      });
    }
    setSaving(false);
  };

  return (
    <div>


      <Flex  align="center" gap="middle" justify="space-between">
        <BreadcrumbHeader breadcrumbs={[$t("Club gegevens")]} />

        <RefreshBaseData/>
      </Flex>

      {loading && (
        <Card>
          <Skeleton />
        </Card>
      )}

      {!loading && (
        <Row>
          <Col span={16}>
            <Form
              scrollToFirstError={{
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
              }}
              onFinish={saveData}
              initialValues={clubData}
              form={form}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              requiredMark={false}
            >
              <Flex className="MyData" gap={15}>
                <div id='name'>
                  <NameLogo />
                </div>
                <div id='basics'>
                  <Basics />
                </div>
                <div id='contact'>
                  <ContactList />
                </div>
                <div id='selects'>
                  <SelectableList />
                </div>
                <div id='verslag'>
                  <VerslagList />
                </div>
                <div id='airport'>
                  <Airport form={form} />
                </div>

                <div id='financial'>
                  <Financial />
                </div>

                <div id='terms'>
                  <PlanTerms clubData={clubData} />
                </div>

                <div id='tokens'>
                  <APItokens />
                </div>

                <div id='export'>
                  <Export clubData={clubData}/>
                </div>

              </Flex>

              <Layout.Footer
                style={{
                  width: "100%",
                  background: "white",
                  position: "fixed",
                  bottom: "0",
                  display: 'flex',
                  marginLeft: -10,
                  alignItems: 'center',
                  height: "40px",
                  borderTop: "1px solid #e8e8e8",
                }}
              >
                <Button
                  disabled={loading}
                  loading={saving}
                  type="primary"
                  htmlType="submit"
                >
                  {$t("Opslaan")}
                </Button>
              </Layout.Footer>
            </Form>
          </Col>
          <Col span={8}>
            <Anchor
              offsetTop={64}
              affix={{
                offsetTop: 64,
              }}
              items={[
                {
                  key: 'name',
                  href: '#name',
                  title: $t('Naam en logo'),
                },
                {
                  key: 'basics',
                  href: '#basics',
                  title: $t('Basis'),
                },
                {
                  key: 'contact',
                  href: '#contact',
                  title: $t('Contact lijsten'),
                },
                
                {
                  key: 'selects',
                  href: '#selects',
                  title: $t('Club inrichting'),
                },
                {
                  key: 'verslag',
                  href: '#verslag',
                  title: $t('Dagverslag'),
                },
                {
                  key: 'airport',
                  href: '#airport',
                  title: $t('Thuisveld'),
                },
                {
                  key: 'financial',
                  href: '#financial',
                  title: $t('Financieel'),
                },
                {
                  key: 'terms',
                  href: '#terms',
                  title: $t('Plan en voorwaarden'),
                },
                {
                  key: 'tokens',
                  href: '#tokens',
                  title: 'API tokens',
                },
                {
                  key: 'export',
                  href: '#export',
                  title: $t('Exporteer data'),
                },
              ]}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
