export const baseCurrentMaterial = {
  id: null,

  // role manager, member or None (but can still be visible for club kisten)
  current_role: null,
  kist_category: 'club', // or "sleep" or "prive"

  // includes base data
  name: null,
  type: null,
  category: null,
  kist: null,
  status: '',
  maintenance_status: '',

  // per tab base data is kept, and if currentMaterial base is retrieved, tabs are reset
  meldingen: null,
  logbook: null,
  data: null,
  parts: null,
  documents: null,
  maintenance: null,
  workorders: null,
  directives: null,
  hours: null
}

export const materiaalState = {

  isPending: true,

  // Tab of current material
  currentMaterial: { ...baseCurrentMaterial },

  // Base data with overviews
  lastUpdated: null,
  status: [],
  meldingen: [],
  inspections: [],
  technici: [],
  material: null,
  parts: null,
  workorders: null,
  teams: null,

  activeMelding: {
    id: 2,
    date_created: null,
    date_updated: null,
    date_verwacht_afgehandeld: null,
    status: null,
    technicus: null,
    user: {
      id: null
    },
    beschrijving: null,
    category: null,
    material: null,
    berichten: []
  }

}
