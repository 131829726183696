import React, { useState } from "react";
import { useSelector } from "react-redux";
import { $t } from "~/i18n.js";

import {
  EyeInvisibleOutlined,
  LockFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, List } from "antd";
import { config } from "~/config.js";
import { api } from "~/lib/api.js";
import { _parseDate } from "~/lib/localize.js";
import { HelpBubble } from "../../help/help.bubble.jsx";
import { BestandenAddModal } from "./bestanden.AddModal.jsx";

export const Bestanden = ({ currentUser, pilot, bestanden, onAddBestand }) => {
  const [openFile, setActiveFile] = useState(null);
  const [pendingSave, setPendingSave] = useState(false);

  const { profile } = useSelector((state) => state.persist);

  const onSaveFile = async (data) => {
    setPendingSave(true);
    data = { user_id: pilot && pilot.id, ...data };
    const res = await api.post_form("gegevens/file.json", data);
    if (res && onAddBestand) {
      let files = bestanden || [];
      // add, update or remove from files list
      if (!data.id) {
        files = [res].concat(files);
      } else if (data.delete) {
        files = files.filter((f) => f.id !== data.id);
      } else {
        files = files.map((file) => (file.id === res.id ? res : file));
      }
      onAddBestand(files);
    }
    setPendingSave(false);
    // close of modal if result returned
    res && setActiveFile(null);
  };

  return (
    <div>
      <BestandenAddModal
        currentUser={currentUser}
        openFile={openFile}
        pendingSave={pendingSave}
        onCancel={() => setActiveFile(null)}
        onSave={onSaveFile}
      />

      <div className="row">
        <Button
          style={{ padding: 0, marginRight: 6 }}
          size="small"
          type="link"
          icon={<PlusOutlined />}
          onClick={() => setActiveFile({ action: "new", is_visible: true })}
        >
          {$t("Voeg bestand toe")}
        </Button>
        <HelpBubble
          content={$t(
            "Alleen jijzelf of instructeurs kunnen bestanden inzien en toevoegen."
          )}
        />
      </div>

      <List
        style={{ maxHeight: 280, overflowY: "auto", overflowX: "hidden" }}
        itemLayout="horizontal"
        dataSource={bestanden}
        size="small"
        renderItem={(item) => (
          <List.Item
            actions={[
              <div key="2">
                {item.read_only && (
                  <span style={{ marginRight: 6 }}>
                    <LockFilled />
                  </span>
                )}

                {(profile.wijzig_dto ||
                  profile.id === item.uploaded_by_id ||
                  (!item.read_only && profile.id === item.user_id)) && (
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    danger
                    onClick={() => setActiveFile(item)}
                  >
                    {$t("Wijzig")}
                  </Button>
                )}
              </div>,
              <a
                key="1"
                target="_blank"
                rel="noopener noreferrer"
                title="file"
                href={config.media_prefix + item.file}
              >
                {$t("Open")}
              </a>,
            ]}
          >
            <List.Item.Meta
              title={
                <span>
                  {!item.is_visible && <EyeInvisibleOutlined />} {item.name}
                </span>
              }
              description={_parseDate(item.date_created)}
            />
          </List.Item>
        )}
      />
    </div>
  );
};
