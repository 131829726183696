import {
  PlusOutlined
} from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { $t } from "~/i18n.js";

export const AddButton = (args) => {
  return (
    <Button size="small" {...args} icon={<PlusOutlined />}>
      {$t("Voeg toe")}
    </Button>
  );
};
