import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { $t } from '~/i18n.js'

import { PlusOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Divider, Tag, Tooltip } from 'antd'

import { _parseDateTime } from '../../../lib/localize.js'

import { i18n } from '~/i18n.js'
import { api } from '../../../lib/api.js'
import { HelpBubble } from '../../help/help.bubble.jsx'
import { NotitieModal } from '../../profile/notitieModal.jsx'
import { Notities } from '../../profile/tabs/notities.jsx'
import './flightDetails.css'

const _showValue = (value, field) => {
  if (field === 'status') return $t(value) // $t('zichtbaar') $t('verwijderd')
  if (field === 'height') return i18n.parseLocaleHeightString(value)
  if (typeof value === 'boolean') {
    return value ? 'ja' : 'nee'
  }
  return (value === null || value === '') ? '(leeg)' : value
}

const NotitiesTable = ({ flight, profile, notities, openNotitieModal, title }) => {
  // only show notities if relevant
  if (!flight.gezagvoerder_id) return null
  if (!profile.is_instructeur && flight.gezagvoerder_id !== profile.id && flight.tweede_inzittende_id !== profile.id) return null
  return (
    <div>

      <Tooltip placement='top' title={$t('Als instructeur kan je per vlucht een notitie achterlaten, en zelf kiezen of hij zichtbaar is voor de vlieger.')}>
        <Divider orientation='left' plain>
          {title} {$t('Instructie notities')}

          {profile.is_instructeur && flight.gezagvoerder_id &&
            <Button style={{ marginLeft: 12 }} type='primary' icon={<PlusOutlined />} size='small' onClick={() => openNotitieModal(null)}>
              {$t('Voeg toe')}
            </Button>}
        </Divider>
      </Tooltip>

      <Notities
        flightNote
        notities={notities}
        currentUserID={profile.id}
        alleenPublic={false}
        openNotitie={(notitie) => openNotitieModal(notitie)}
      />

    </div>
  )
}

const ChangesTable = ({ changes, created, title, flight }) => {
  if (!created || !changes) return null
  
  // possible fields for translation
  // $t('vertrek vliegveld'),
  // $t('aankomst vliegveld'),
  // $t('Callsign'),
  // $t('Registratie'),
  // $t('Type'),
  // $t('Gezagvoerder'),
  // $t('Tweede inzittende'),
  // $t('examen'),
  // $t('Profcheck'),
  // $t('Overland'),
  // $t('category'),
  // $t('Start methode'),
  // $t('Trainingsvlucht'),
  // $t('start tijd'),
  // $t('landings tijd'),
  // $t('height')

  return (
    <div>

      <Divider orientation='left' plain>
        {title || <span>{$t('Wijzigingen')} <HelpBubble content={$t('Na landing worden wijzigingen op belangrijke velden bijgehouden. Bijvoorbeeld voor velden zoals tijd, vlieger en startmethode.')} />:</span>}
      </Divider>

      <div className='flightChanges'>
        <table style={{ width: '100%' }}>
          <tbody>

            {changes?.map((record, key) => {
              return record?.updates?.map((update, idx) => {
                if (!update) return null
                
                const field = $t(update.field);

                // alert if field is ... for training, examen and profcheck and currently true
                const alert = update.field.includes('is') && update.to === true && flight[update.field] === true

                return (!update.field.includes('id') &&
                  <tr key={`${key}-${idx}`}>
                    <td className='truncate' style={{ marginRight: 6 }}>{_parseDateTime(record.date_created, { month: '2-digit', day: '2-digit' })}</td>
                    <td className='truncate' style={{ marginRight: 6 }}>{record.user_name}</td>

                    <td className='truncate' style={{ marginRight: 6 }}>
                      {alert
                        ? <Tag color='red'><WarningOutlined style={{ fontSize: 12, marginRight: 6 }} /> {field}</Tag>
                        : field}
                    </td>

                    <td className='truncate' style={{ marginRight: 6 }}>{_showValue(update.from, update.field)}</td>
                    <td>➤</td>
                    <td className='truncate'>{_showValue(update.to, update.field)}</td>
                  </tr>)
              }
              )
            })}

            {created?.date_created && (
              <tr>
                <td className='truncate' style={{ marginRight: 6 }}>{_parseDateTime(created.date_created, { month: '2-digit', day: '2-digit' })}</td>
                <td className='truncate' style={{ marginRight: 6 }}>{created.created || 'Automatisch'}</td>
                <td className='truncate'>{$t('aangemaakt')}</td>
              </tr>
            )}

          </tbody>
        </table>
      </div>
    </div>
  )
}

export const FlightDetails = ({ flight, title, hideNotes }) => {
  const { profile } = useSelector((state) => state.persist)
  const dispatch = useDispatch()

  // for updates we retrieve it for each flight
  const [changes, setChanges] = useState([])
  const [created, setCreated] = useState({})
  const [notities, setNotities] = useState([])
  useEffect(() => {
    if (flight && flight.id) {
      api.get(`flights/${flight.id}/get_details.json`)
        .then((res) => {
          setChanges(res?.changes)
          setCreated(res?.created)
          setNotities(res?.notities)
        })
    }
  }, [flight])

  const [modalOpen, setModalOpen] = useState(false)
  const [notitie, setOpenNotitie] = useState(null)
  const openNotitieModal = (notitie) => {
    setOpenNotitie(notitie || { id: null, bericht: '', is_kritiek: false, is_prive: false })
    setModalOpen(true)
  }

  const onAddNotitie = (newNote) => {
    setNotities([newNote].concat(notities.filter(n => newNote.id !== n.id)))
    // TODO: set to vliegerdata redux
    setModalOpen(false)
    setOpenNotitie(null)
    dispatch({
      type: 'ADD_NOTITIE_TO_USER_PROFILE',
      notitie: newNote
    })
  }

  return (
    <div>

      {!hideNotes && <NotitieModal
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={onAddNotitie}
        activeDay={{ dag_id: flight.dag_id, datum: flight.datum }}
        notitie={notitie}
        flight_uuid={flight.uuid}
        pilot={flight.is_fis && flight.tweede_inzittende_id
          ? { id: flight.tweede_inzittende_id, name: flight.tweede_inzittende_naam }
          : { id: flight.gezagvoerder_id, name: flight.gezagvoerder_naam }}
                     />}

      {!hideNotes && <NotitiesTable
        notities={notities} openNotitieModal={openNotitieModal} title={title}
        flight={flight} profile={profile}
                     />}

      <ChangesTable changes={changes} created={created} title={title} flight={flight} />
    </div>
  )
}
