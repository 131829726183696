import { StopOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { $t } from "~/i18n.js";

import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import {
  FileInput,
  FileValidator,
} from "../../components/fileInput/fileInput.jsx";
import { HelpBubble } from "../../components/help/help.bubble.jsx";
import { validateEmail } from "../../lib/helpers.js";
import { _parseDate } from "../../lib/localize.js";
import { List } from "antd";
import { PilotLink } from "~/components/selectedFlight/PilotLink.jsx";

const SAVE = "save";
const DELETE = "delete";

export const EmailForm = ({
  visible,
  groups,
  activeEmail,
  closeModal,
  saveEmail,
  group_name_lookup,
}) => {
  if (!activeEmail) return null;
  
  const [loadingAction, setLoading] = useState("");

  const [form] = Form.useForm();

  const readOnly = activeEmail?.date_send;

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const saveForm = async (action) => {
    try {
      const values = await form.validateFields();
      setLoading(action);

      const result = await saveEmail({
        campaign: {
          action,
          id: activeEmail.id,
          reply_to_name: values.reply_to_name,
          reply_to: values.reply_to,
          groups: values.groups,
          recipients: values.recipients,
          subject: values.subject,
          content: values.content,
        },
        files: values.attachments?.filter((f) => !!f.action),
      });

      if (result) {
        closeModal();
      }
      setLoading("");
    } catch (info) {
      console.error("error: ", { info });
      message.error(
        $t("Data is incorrect, ${e} fouten gemeld", {
          e: info?.errorFields?.length,
        })
      );
      setLoading("");
    }
  };

  const triggerDelete = async () => {
    setLoading(DELETE);
    await saveEmail({ campaign: { id: activeEmail.id, action: DELETE } });
    closeModal();
    setLoading("");
  };

  return (
    <Modal
      title={activeEmail?.id ? $t("Wijzig email") : $t("Voeg nieuw email toe")}
      open={visible}
      confirmLoading={!!loadingAction}
      onCancel={closeModal}
      footer={[
        activeEmail?.id && !activeEmail?.date_send && (
          <Popconfirm
            key="remove"
            placement="bottom"
            title={$t("Zeker weten dat je dit wilt verwijderen?")}
            onConfirm={triggerDelete}
            okText="Ja"
            cancelText="Nee"
          >
            <Button
              loading={loadingAction === DELETE}
              danger
              icon={<StopOutlined />}
            >
              {$t("verwijder")}
            </Button>
          </Popconfirm>
        ),
        <AnnuleerButton  key="annuleren" onClick={closeModal} />,

        <Button
          type={activeEmail?.id ? "default" : "primary"}
          key="o"
          onClick={() => saveForm(SAVE)}
          loading={loadingAction === SAVE}
        >
          {$t("Opslaan")}
        </Button>,
      ]}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        name="addEmail"
        layout="vertical"
        preserve={false}
        initialValues={activeEmail || {}}
        requiredMark={false}
      >
        {activeEmail && activeEmail.date_send ? (
          <>
            <Tag color="green">{$t("Verstuurd")}</Tag>
            {_parseDate(activeEmail.date_send)}
          </>
        ) : (
          <>
            <Tag color="blue">{$t("Concept")}</Tag>
          </>
        )}

        <Form.Item
          style={{ marginTop: 12 }}
          label={
            <p>
              {$t("Ontvangers")}
              <HelpBubble
                content={$t(
                  "Selecteer hier de groepen gebruikers die je wilt mailen. In de club settings kan je selecteren welke groepen zichtbaar zijn, onder het veld 'Groepen voor filter ledenlijst'. Je kan ook een los emailadres toevoegen."
                )}
              />
            </p>
          }
          name="groups"
          rules={[
            { required: false },
            () => ({
              validator(_, values) {
                if (!values) {
                  return Promise.reject($t("Voeg een groep of email toe."));
                }

                // existing group or correct email should validate properly
                const incorrectValues = [];
                values.map((v) => {
                  if (
                    v !== "zweefvlieger" &&
                    groups.indexOf(v) === -1 &&
                    !validateEmail(v)
                  ) {
                    incorrectValues.push(v);
                  }
                });
                if (incorrectValues.length > 0) {
                  return Promise.reject(
                    `"${incorrectValues.join(", ")}" ${$t(
                      "is incorrect, voeg groepen of valide emailaddressen toe."
                    )} `
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select mode="tags" disabled={readOnly}>
            <Select.Option key={-1} value="zweefvlieger">
              {$t("alle zweefvliegers")}
            </Select.Option>
            {groups &&
              groups.split(",").map((group, idx) => (
                <Select.Option key={idx} value={group.replace(" ", "").trim()}>
                  {group_name_lookup[group] || group}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <p>
              {$t("Naam verzender")}
              <HelpBubble
                content={$t(
                  "Dit is de naam van waaruit de email verstuurd wordt."
                )}
              />
            </p>
          }
          name="reply_to_name"
          rules={[{ required: false }]}
        >
          <Input type="text" disabled={readOnly} />
        </Form.Item>

        <Form.Item
          label={
            <p>
              {$t("Antwoord aan email")}
              <HelpBubble
                content={$t(
                  "Dit is het emailadres van waaruit de mail verstuurd wordt, en waarop de ontvangers replyen."
                )}
              />
            </p>
          }
          name="reply_to"
          rules={[{ required: true, type: "email" }]}
        >
          <Input type="email" disabled={readOnly} />
        </Form.Item>

        <Form.Item
          label={$t("Onderwerp")}
          name="subject"
          rules={[
            {
              required: true,
              message: $t("Maximaal ${c} characters", { c: 120 }),
              max: 1000,
            },
          ]}
        >
          <Input type="text" disabled={readOnly} />
        </Form.Item>

        <Form.Item>
          {activeEmail?.help && (
            <p className="small" style={{ marginTop: -6, marginBottom: 4 }}>
              {activeEmail.help}
            </p>
          )}
          <Form.Item
            label={$t("Tekst")}
            name="content"
            rules={[
              {
                required: true,
                message: $t("Voeg text toe, max 5000 characters"),
                max: 5000,
              },
            ]}
          >
            <Input.TextArea rows={8} disabled={readOnly} />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="attachments"
          label={$t("Bijlage(s)")}
          rules={[{ required: false }, FileValidator]}
        >
          <FileInput readOnly={readOnly} />
        </Form.Item>
      </Form>

      {activeEmail?.failed_recipients?.length > 0 && (
        <section>
          <p>{$t('Mislukte emails')}</p>

          <List
                style={{ maxHeight: 230, overflowY: "auto" }}
                itemLayout="horizontal"
                size='small'
                bordered={false}
                dataSource={activeEmail?.failed_recipients}
                renderItem={(recipient) => (
                  <List.Item
                    actions={[
                      <Tag key='1'>{$t(recipient.reason || 'failed')}</Tag>
                      // $t('invalid')
                      // $t('unsubscribed')
                      // $t('failed')
                    ]}
                  >
                    <List.Item.Meta
                      description={<span>
                        {recipient.id && <PilotLink
                                            name={recipient.name}
                                            id={recipient.id}
                                            object={{id: recipient.id}}
                                          />}{" "}{recipient.email} 
                      </span>}
                    />
                  </List.Item>
                )}
              />
        </section>
      )}
    </Modal>
  );
};
