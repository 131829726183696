import { Button } from "antd";
import React, { useState } from "react";

import { api } from "~/lib/api.js";
import { StartIcon } from "../../icon/icon.jsx";
import { ViewFlightModal } from "../viewFlight.modal.jsx";

import "./flightDetails.css";

export const FlightOpenIcon = ({ flight_uuid, icon }) => {
  // for updates we retrieve it for each flight
  const [flight, setFlight] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showFlight, setShow] = useState(false);

  const openFlight = async () => {
    if (flight) {
      setShow(true);
      return;
    }
    if (loading) return;
    setLoading(true);
    setShow(false);
    const res = await api.get(`flights/${flight_uuid}/view.json`);
    if (res) {
      setFlight(res);
      setShow(true);
    }
    setLoading(false);
  };
  return (
    <>
      {showFlight && flight && (
        <ViewFlightModal
          onClose={() => setShow(false)}
          flight={flight}
          currentUser={null}
          updateFlight={null}
          onUpdateFlight={null}
        />
      )}

      <Button
        onClick={openFlight}
        loading={loading}
        shape="circle"
        type="text"
        icon={icon || <StartIcon />}
        size="small"
      />
    </>
  );
};
