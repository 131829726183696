import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { colors } from '../../theme/colors'

const padDigit = (digit) => {
  if (digit < 10) return '0' + digit
  return digit
}

const formatCurrentTime = () => {
  const d = new Date()
  return `${d.getHours()}:${padDigit(d.getMinutes())}:${padDigit(d.getSeconds())}`
}

export const Clock = ({ size, topColor }) => {
  const [time, setTime] = useState(formatCurrentTime())

  useEffect(() => {
    // setup interval, return cleanup which will be fired after we close element to clear out the interval
    let mounted = true

    const execTimeout = () => {
      if (mounted) {
        setTime(formatCurrentTime())
        setTimeout(execTimeout, 1000)
      }
    }

    // first time fireoff
    execTimeout()

    // return that we remove interval
    return () => mounted = false
  }, [])

  return (
    <span style={{ fontSize: size, color: topColor, marginRight: 6 }}>
      {time}
    </span>
  )
}

Clock.propTypes = {
  size: PropTypes.number,
  topColor: PropTypes.string
}

Clock.defaultProps = {
  size: 16,
  topColor: colors.darkgray_light
}
