import { Table } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { defaultTableConfig } from "~/components/layout/table.jsx";

import { SearchTags } from "~/components/searchTag/searchTag.jsx";
import { AddMeldingButton } from "./addMeldingButton.jsx";
import {
  MeldingColumns,
  MeldingColumnsSingleMateriaal,
} from "./meldingTableColumns.jsx";

export const MeldingTable = ({
  meldingen,
  isPending,
  history,
  currentMaterial,
}) => {
  const { isAuthenticated } = useSelector((state) => state.persist);

  const [searchTag, setSearchTag] = useState([]);
  meldingen = meldingen?.filter((row) => {
    if (
      searchTag.indexOf("archief") === -1 &&
      (row.status === "afgerond" || row.status === "afgewezen")
    )
      return false;

    return (
      searchTag.length === 0 ||
      searchTag.some((t) => {
        return (
          (row.category === t && row.status === "aangemeld") ||
          (t === "archief" &&
            (row.status === "afgerond" || row.status === "afgewezen"))
        );
      })
    );
  });

  return (
    <div>
      <div
        className="row"
        style={{
          justifyContent: "space-between",
          flexWrap: "wrap",
          width: "100%",
          gap: 12,
        }}
      >
        <div
          className="row"
          style={{ alignItems: "flex-start", flexWrap: "wrap" }}
        >
          <SearchTags
            items={[
              { name: "defect", color: "red" }, // $t('defect')
              { name: "klacht", color: "orange" }, // $t('klacht')
              { name: "visueel", color: "blue" }, // $t('visueel')
              { name: "algemeen", color: "green" }, // $t('algemeen')
              { name: "archief", color: "default" }, // $t('archief')
            ]}
            searchTags={searchTag}
            setSearchTags={setSearchTag}
          />
        </div>

        {currentMaterial && (
          <AddMeldingButton
            history={history}
            currentMaterial={currentMaterial}
          />
        )}
      </div>
      <Table
        loading={isPending}
        style={{ marginTop: 12, width: "97vw" }}
        pagination={defaultTableConfig}
        className={isAuthenticated ? "table--clickable" : ""}
        size="small"
        rowKey="id"
        dataSource={meldingen}
        columns={
          currentMaterial ? MeldingColumnsSingleMateriaal() : MeldingColumns()
        }
        onRow={(melding) => {
          return {
            onClick: (_e) => {
              if (!isAuthenticated) return null;
              if (
                melding.workorder_id &&
                (_e.target.outerHTML.includes("tool") ||
                  _e.target.outerHTML.includes("opgepakt"))
              ) {
                // navigate to the workorder
                history.push(
                  `/material/${melding.material_id}/workorders/${melding.workorder_id}/view`
                );
              } else {
                // navigate to the detail page of the melding
                history.push(`/report/${melding.id}`);
              }
            },
          };
        }}
      />
    </div>
  );
};
