export const newActiveDay = {
  id: null,
  datum: null,
  type_dag: '',
  titel: '',
  vertrek_vliegveld: '',
  aankomst_vliegveld: '',
  gaat_door: true,
  can_start: false,
  is_today: false,
  briefing_tijd: '09:00'
}

export const daysState = {

  isPending: true,

  activeDay: newActiveDay,

  lastUpdated: null,
  allDays: [],
  mijn_diensten: {},
  mijn_aanmeldingen: {},
  totals: {},

  lastUpdatedRoosters: null,
  roosters: [],
  users: []
}
