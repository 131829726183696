import { aanmeldingenState } from './aanmeldingen.state'
import { normalizeAanmeldingen } from './aanmeldingen.normalizers'
import { normalizeVliegers } from '../persist/persist.normalizers'

export const aanmeldingenReducer = (state = aanmeldingenState, action) => {
  switch (action.type) {
    case 'AANMELDING_PENDING':
      return {
        ...state,
        isPending: true
      }

    case 'AANMELDING_ERROR':
      return {
        ...state,
        isPending: false
      }

    case 'AANMELDING_SUCCESS':

      let aanmeldingen = null
      if (action.normalizeAsVliegers) {
        aanmeldingen = action.aanmeldingen.sort((a, b) => a.vlieger.name.localeCompare(b.vlieger.name))
        aanmeldingen = { aanmeldingen: normalizeVliegers(aanmeldingen, action.default_vliegers) }
        aanmeldingen.sleepvliegers = aanmeldingen.aanmeldingen.filter(a => a.as_sleepvlieger)
      } else {
        aanmeldingen = normalizeAanmeldingen(action.aanmeldingen)
      }

      return {
        ...state,
        isPending: false,
        // only forward dag_id, used in dropdown of starts
        dag_id: action.normalizeAsVliegers ? action.dag_id : null,
        messages: action.messages,
        notities: action.notities,
        diensten: action.diensten,
        lastUpdated: new Date(),
        ...aanmeldingen
      }

    case 'SAVE_AANMELD_MESSAGE':
      let newMessages = state.messages.filter(m => m.id !== action.message.id)
      if (action.action !== 'delete') newMessages = [action.message].concat(newMessages)
      return {
        ...state,
        messages: newMessages
      }

    case 'RESET_AANMELDING_STATE':
      return {
        ...aanmeldingenState
      }

    default:
      return state
  }
}
