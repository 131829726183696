import { $t } from '~/i18n.js'
import React from 'react'
import { PartCard } from '../tabs/parts/partCard'
import { Button, Card, Tag } from 'antd'
import { Link } from 'react-router-dom'

export const PartOverviewTab = ({ parts, search, searchTag }) => {
  const list_parts = parts?.filter(row => {
    const inCategory = searchTag.length === 0 || searchTag.some(t => row.kist_category === t || row.material_category === t)
    if (search) {
      const searchString = `${row.description} ${row.manufacturer} ${row.material_name} ${row.name} ${row.part_number} ${row.serie_number}`
      return inCategory && searchString?.toLowerCase().indexOf(search) > -1
    }
    return inCategory
  })

  return (
    <div className='column' style={{ gap: 12, alignItems: 'flex-start' }}>
      {list_parts.map((part, key) => {
        const url = `/material/${part.material_id}/parts`
        return (
          <Card
            size='small'
            key={key}
            type='inner'
            title={
              <Link key='open' to={url} className='inherit'>
                <p className='clickable'>
                  <Tag style={{ marginRight: 12 }}>{part.material_name}</Tag>
                  {part.name}
                  {!part.is_installed && <Tag style={{ marginLeft: 18 }} color='red'>Niet geinstalleerd</Tag>}
                </p>
              </Link>
          }
            style={{ width: '100%' }}
            extra={[
              <Link key='open' to={url}>
                <Button type='primary' size='small'>
                  {$t('Open')}
                </Button>
              </Link>
            ]}
          >
            <PartCard readOnly part={part} currentMaterial={part} level={0} parts_path={[]} />
          </Card>
        )
      }
      )}
    </div>
  )
}
