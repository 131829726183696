import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { $t } from "~/i18n.js";

import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Modal, Popconfirm, Select } from "antd";
import { AnnuleerButton } from "~/components/buttons/AnnuleerButton.jsx";
import { HelpBubble } from "../../components/help/help.bubble.jsx";

export const TabModal = ({ closeModal, tab, saveTab, allTags }) => {
  const [form] = Form.useForm();
  const [activeTag, setTag] = useState("");
  const [allTagsList, setAllTags] = useState();
  useEffect(() => {
    setAllTags(allTags);
  }, [allTags]);

  // only allowed to view and add tabs which you are member of, this to ensure that privacy sensitive data is only visible in proper groups.
  const { profile } = useSelector((state) => state.persist);
  const { group_name_lookup } = useSelector((state) => state.flights);

  useEffect(() => {
    if (tab) form.setFieldsValue(tab);
  }, [tab, form]);

  const [saving, setSaving] = useState(null);

  const deleteTab = async () => {
    setSaving("delete");
    const result = await saveTab({ action: "delete", id: tab.id });
    result && closeModal();
    setSaving(null);
  };

  const triggerSave = async () => {
    try {
      setSaving("save");
      const values = await form.validateFields();
      const result = await saveTab({ ...tab, ...values });
      if (result) {
        closeModal();
        form.resetFields();
      }
      setSaving(null);
    } catch (info) {
      console.log("Validate Failed:", info);
      setSaving(null);
    }
  };

  const footer = [
    <AnnuleerButton  key="annuleren" onClick={closeModal} />,
    <Button
      type="primary"
      key="ok"
      onClick={triggerSave}
      loading={saving === "save"}
    >
      {$t("Opslaan")}
    </Button>,
  ];

  return (
    <Modal
      title={tab && tab.id ? $t("Pas tab aan") : $t("Voeg nieuw tab toe")}
      open={!!tab}
      onCancel={closeModal}
      okText={$t("Opslaan")}
      cancelText={$t("Annuleren")}
      footer={
        tab && tab.id
          ? [
              <Popconfirm
                placement="bottom"
                title={$t(
                  "Zeker weten dat je alle documenten hieronder wilt verwijderen?"
                )}
                key="delete"
                onConfirm={deleteTab}
                okText={$t("Ja")}
                cancelText={$t("Nee")}
              >
                <Button
                  danger
                  style={{ marginRight: 32 }}
                  loading={saving === "delete"}
                >
                  {$t("verwijder")}
                </Button>
              </Popconfirm>,
            ].concat(footer)
          : footer
      }
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="tab"
        preserve={false}
        requiredMark={false}
        initialValues={{ ...tab }}
      >
        <Form.Item
          label={$t("Naam")}
          name="name"
          rules={[
            {
              required: true,
              max: 50,
              message: $t("Maximaal ${c} characters", { c: 50 }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={$t("Positie")} name="order">
          <Input type="number" prefix="#" />
        </Form.Item>

        <Form.Item
          label={
            <p>
              Cluster{" "}
              <HelpBubble
                content={$t(
                  "Met de cluster kan je snel filteren bovenin de documenten."
                )}
              />
            </p>
          }
          name="cluster"
          rules={[
            {
              required: false,
              max: 20,
              message: $t("Maximaal ${c} characters", { c: 20 }),
            },
          ]}
        >
          <Select
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: "4px 0" }} />
                <div
                  style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                >
                  <Input
                    style={{ flex: "auto" }}
                    value={activeTag}
                    onChange={(event) =>
                      setTag(
                        event.target.value.replace(/^\w/, (c) =>
                          c.toUpperCase()
                        )
                      )
                    }
                  />
                  <Button
                    type="primary"
                    style={{ marginLeft: 4 }}
                    onClick={() => {
                      if (activeTag?.length > 0) {
                        setAllTags(allTagsList.concat(activeTag));
                        setTag("");
                      }
                    }}
                  >
                    <PlusOutlined /> {$t("Voeg toe")}
                  </Button>
                </div>
              </div>
            )}
          >
            {allTagsList?.map(
              (tag, key) =>
                tag && (
                  <Select.Option key={key} value={tag}>
                    {tag}
                  </Select.Option>
                )
            )}
          </Select>
        </Form.Item>

        <Form.Item label={$t("Zichtbaar voor")} name="group_key">
          <Select mode="single">
            <Select.Option value="zweefvlieger">
              {$t("alle zweefvliegers")}
            </Select.Option>
            {profile.group_keys.map((group, key) => (
              <Select.Option value={group} key={key}>
                {group_name_lookup[group] || group}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
